import React from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import styled from "styled-components";

const NavBar = styled.nav`
  position: absolute;
  // z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  // background-color: #ffffff; // Add a background color if needed
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow

  @media (max-width: 768px) {
    // display: none;
  }
`;

const TopBar = () => {
  const navigate = useNavigate();

  const handleUserSelect = (username) => {
    console.log("Selected user:", username);
    navigate(`/profile/${username}`);
  };

  return (
    <NavBar>
      <SearchBar onSelect={handleUserSelect} />
    </NavBar>
  );
};

export default TopBar;
