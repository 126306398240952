// calendarAPI.js

// import { gapi } from "gapi-script";

// const DISCOVERY_DOCS = [
//   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
// ];

// export const initClient = () => {
//   gapi.client.init({
//     apiKey: process.env.GOOGLE_CALENDAR_API_KEY,
//     discoveryDocs: DISCOVERY_DOCS,
//   });
// };

// export const fetchPublicCalendarEvents = async (
//   calendarId,
//   startDate,
//   endDate
// ) => {
//   const response = await gapi.client.calendar.events.list({
//     calendarId,
//     timeMin: startDate.toISOString(),
//     timeMax: endDate.toISOString(),
//     showDeleted: false,
//     singleEvents: true,
//     maxResults: 100,
//     orderBy: "startTime",
//   });

//   return response.result.items;
// };

// calendarAPI.js

const BASE_URL = "https://www.googleapis.com/calendar/v3/calendars";

export const initializeGoogleCalendar = () => {
  // This function is now a placeholder for any initialization
  // that might be needed in the future.
  console.log("Google Calendar API initialized");
};

export const fetchPublicCalendarEvents = async (
  calendarId,
  startDate,
  endDate
) => {
  const apiKey = process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY;
  const url = new URL(`${BASE_URL}/${encodeURIComponent(calendarId)}/events`);

  url.search = new URLSearchParams({
    key: apiKey,
    timeMin: startDate.toISOString(),
    timeMax: endDate.toISOString(),
    showDeleted: false,
    singleEvents: true,
    maxResults: 100,
    orderBy: "startTime",
  }).toString();

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error fetching calendar events:", error);
    throw error;
  }
};

// Add any other calendar-related functions here
