// goalEvaluator.js
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../../services/firebaseConfig";
import { useAuth } from "../../../hooks/useAuth";

const compareValues = (userValue, operator, targetValue) => {
  switch (operator) {
    case ">=":
      return userValue >= targetValue;
    case "<=":
      return userValue <= targetValue;
    case "==":
      return userValue === targetValue;
    case ">":
      return userValue > targetValue;
    case "<":
      return userValue < targetValue;
    default:
      return false;
  }
};

const getUserExerciseData = async (userId, programId, exercise, attribute) => {
  // Fetch workouts where the exercise is included
  const workoutsRef = collection(
    db,
    "users",
    userId,
    "programs",
    programId,
    "workouts"
  );
  const q = query(
    workoutsRef,
    where("categories.exercises", "array-contains", exercise)
  );

  const querySnapshot = await getDocs(q);

  let latestValue = null;
  let latestTimestamp = 0;

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const workoutTimestamp = data.timestamp || 0;

    const exercises = data.categories.exercises;
    exercises.forEach((ex) => {
      if (ex.name === exercise && workoutTimestamp > latestTimestamp) {
        latestValue = ex[attribute];
        latestTimestamp = workoutTimestamp;
      }
    });
  });

  if (latestValue !== null) {
    return latestValue;
  } else {
    throw new Error(`No data found for ${exercise} ${attribute}`);
  }
};

export const evaluateGoal = async (goal) => {
  const { userId, programId, conditions, logic } = goal;
  const evaluationResults = [];

  for (const condition of conditions) {
    try {
      const userValue = await getUserExerciseData(
        userId,
        programId,
        condition.exercise,
        condition.attribute
      );
      const result = compareValues(
        userValue,
        condition.operator,
        condition.value
      );
      evaluationResults.push(result);
    } catch (error) {
      console.error("Error evaluating condition:", error);
      evaluationResults.push(false);
    }
  }

  if (logic === "AND") {
    return evaluationResults.every(Boolean);
  } else if (logic === "OR") {
    return evaluationResults.some(Boolean);
  }
  return false;
};
