
import React from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaCircle,
  FaRegCircle,
} from "react-icons/fa";

const WorkoutCard = styled.div`
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  min-height: 65px;
  flex-direction: column;
  justify-content: space-between;
  cursor: move; /* Changed to 'move' to indicate draggability */
  border: 1px solid #3a3a3a;
  z-index: 1;
  position: relative;
  transition: all 0.2s ease-in-out;
  touch-action: none; /* Prevents scrolling while dragging on touch devices */

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const WorkoutHeader = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;
`;

const StatusIcon = styled.div`
  font-size: 1.2em;
  display: flex;
  align-items: center;
`;

const MainMovement = styled.div`
  font-size: 0.9em;
  font-weight: bold;
  color: #fff;
  margin-bottom: 4px;
`;

const Intensity = styled.div`
  font-size: 0.9em;
  color: #aaa;
`;

const StateDisplay = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: ${(props) =>
    props.state === "completed"
      ? "#4CAF50"
      : props.state === "cancelled"
      ? "#F44336"
      : props.state === "in progress"
      ? "#FFC107"
      : "#9E9E9E"};
  margin-top: 4px;
`;

const WorkoutItem = ({ workout, onSelectWorkout }) => {
  const [{ isDragging }, dragRef, preview] = useDrag({
    type: "WORKOUT",
    item: { id: workout.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getStatusIcon = () => {
    if (workout.status !== "published") return null;
    switch (workout.actions?.state) {
      case "completed":
        return <FaCheckCircle color="#4CAF50" />;
      case "cancelled":
        return <FaTimesCircle color="#F44336" />;
      case "in progress":
        return <FaCircle color="#FFC107" />;
      default:
        return <FaRegCircle />;
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    onSelectWorkout(workout);
  };

  const handleTouchStart = (e) => {
    // Prevent default touch behavior to ensure smooth dragging
    e.preventDefault();
  };

  return (
    <WorkoutCard
      ref={dragRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
    >
      <WorkoutHeader>
        <StatusIcon>{getStatusIcon()}</StatusIcon>
        {workout.mainMovement && (
        <MainMovement>{workout.mainMovement}</MainMovement>
      )}
      </WorkoutHeader>

      {workout.intensity && <Intensity>{workout.intensity}</Intensity>}
      {workout.status === "published" && (
        <StateDisplay state={workout.actions?.state}>
          {workout.actions?.state || "Ready"}
        </StateDisplay>
      )}
    </WorkoutCard>
  );
};

export default WorkoutItem;
