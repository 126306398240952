import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SignUpModal from "../components/SignUpModal";
import LoginModal from "../components/LoginModal";
import gsap from "gsap";
import RoshiImage from "../assets/Roshione.png";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom right, #41e1ff, #2bb1ff);
  // background: linear-gradient(to bottom right, #ff416c, #ff4b2b);
  color: white;
  position: relative;
  overflow: hidden;
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const HeroContent = styled.div`
  z-index: 2;
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const HeroHeadline = styled.h1`
  font-size: 4.5rem;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const HeroSubheadline = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
`;

const CTAButtons = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    display: flex;
    align-items: center;
  }
`;

const CTAButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }

  &.primary {
    background-color: #ff4800;
    color: #fff;
  }

  &.secondary {
    background-color: transparent;
    color: #ff4800;
    border: 2px solid #ff4800;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    width: 50%;
  }
`;

const RoshiImageStyled = styled.img`
  width: 400px;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    position: absolute;
    width: 90%;
    opacity: 0.25;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`;

const RoshiShadow = styled.div`
  position: absolute;
  bottom: 57px;
  right: 130px;
  width: 390px;
  height: 80px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  filter: blur(8px);
  z-index: 1;

  @media (max-width: 768px) {
    // display: none; // Hide shadow on mobile as it’s behind the text
    bottom: -190px;
    right: -53px;
    opacity: 0.6;
  }
`;

const Home = () => {
  const heroRef = useRef(null);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);

  useEffect(() => {
    const mm = gsap.matchMedia();

    mm.add("(max-width: 768px)", () => {
      // Simplified animations for mobile
      gsap.from(heroRef.current, {
        opacity: 0,
        y: -30,
        duration: 1,
        ease: "power2.out",
      });
    });

    mm.add("(min-width: 769px)", () => {
      // Regular animations for desktop
      gsap.from(heroRef.current, {
        opacity: 0,
        y: -50,
        duration: 1.2,
        ease: "power3.out",
      });
    });

    return () => mm.revert();
  }, []);

  return (
    <HomeContainer>
      {/* Hero Section */}
      <HeroSection ref={heroRef}>
        <HeroContent>
          <HeroHeadline>Roshi Health</HeroHeadline>
          <HeroSubheadline>Build. Track. Train.</HeroSubheadline>
          <CTAButtons>
            <CTAButton className="primary" onClick={() => setSignUpOpen(true)}>
              Sign Up
            </CTAButton>
            <CTAButton className="secondary" onClick={() => setLoginOpen(true)}>
              Login
            </CTAButton>
          </CTAButtons>
        </HeroContent>
        <RoshiImageStyled
          src={RoshiImage}
          alt="Master Roshi in Hawaiian shirt"
        />
        <RoshiShadow />
      </HeroSection>

      {/* SignUp Modal */}
      <SignUpModal show={isSignUpOpen} onClose={() => setSignUpOpen(false)} />
      {/* Login Modal */}
      <LoginModal show={isLoginOpen} onClose={() => setLoginOpen(false)} />
    </HomeContainer>
  );
};

export default Home;
