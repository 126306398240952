

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
  getDoc,
  serverTimestamp,
  onSnapshot,
  runTransaction,
} from "firebase/firestore";
import { db, auth } from "../../services/firebaseConfig";

const WorkoutContext = createContext();

export const useWorkout = () => useContext(WorkoutContext);

export const WorkoutProvider = ({ programId, children }) => {
  const [workouts, setWorkouts] = useState([]);
  const [currentWorkout, setCurrentWorkout] = useState(null);



  const subscribeToWorkouts = useCallback((programId) => {
    if (auth.currentUser && programId) {
      const user = auth.currentUser;
      const workoutsRef = collection(
        db,
        "users",
        user.uid,
        "programs",
        programId,
        "workouts"
      );
  
      const unsubscribe = onSnapshot(
        workoutsRef,
        (snapshot) => {
          const workoutsList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            programId,
          }));
          console.log("Received updated workouts from Firestore:", workoutsList);
          setWorkouts(workoutsList);
        },
        (error) => {
          console.error("Error fetching workouts:", error);
        }
      );
  
      return unsubscribe;
    }
  }, []);

  useEffect(() => {
    if (programId) {
      const unsubscribe = subscribeToWorkouts(programId);
      return () => unsubscribe && unsubscribe();
    }
  }, [programId, subscribeToWorkouts]);



  // ############################################

  // Fetch workouts (initial load or when needed)
  const fetchWorkouts = useCallback(async (programId) => {
    if (auth.currentUser && programId) {
      try {
        const user = auth.currentUser;
        const workoutsRef = collection(
          db,
          "users",
          user.uid,
          "programs",
          programId,
          "workouts"
        );
        const workoutsSnapshot = await getDocs(workoutsRef);
        const workoutsList = workoutsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWorkouts(workoutsList);
        return workoutsList;
      } catch (error) {
        console.error("Error fetching workouts:", error);
        throw error;
      }
    }
  }, []);

  const fetchWorkoutFromFirestore = useCallback(
    async (workoutId, programId) => {
      if (auth.currentUser && programId && workoutId) {
        
        try {
          const user = auth.currentUser;
          const workoutRef = doc(
            db,
            "users",
            user.uid,
            "programs",
            programId,
            "workouts",
            workoutId
          );
          const workoutDoc = await getDoc(workoutRef);
          if (workoutDoc.exists()) {
            const workoutData = { id: workoutDoc.id, ...workoutDoc.data() };
            setCurrentWorkout(workoutData);
            return workoutData;
          } else {
            console.log("No such workout!");
            return null;
          }
        } catch (error) {
          console.error("Error fetching workout:", error);
          throw error;
        }
      }
    },
    []
  );

  const updateWorkout = useCallback(
    async (updatedWorkout, programId) => {
      if (auth.currentUser && programId && updatedWorkout?.id) {
        try {
          const user = auth.currentUser;
          const workoutRef = doc(
            db,
            "users",
            user.uid,
            "programs",
            programId,
            "workouts",
            updatedWorkout.id
          );
          await setDoc(workoutRef, updatedWorkout, { merge: true });
          setCurrentWorkout(updatedWorkout);
          // No need to update workouts state manually since we have real-time listener
          return updatedWorkout;
        } catch (error) {
          console.error("Error updating workout:", error);
          throw error;
        }
      }
    },
    []
  );

  const toggleStatus = useCallback(
    async (workoutId, programId) => {
      try {
        const workout = await fetchWorkoutFromFirestore(workoutId, programId);
        if (workout) {
          const newStatus =
            workout.status === "unpublished" ? "published" : "unpublished";
          const updatedWorkout = {
            ...workout,
            status: newStatus,
            categories: Object.fromEntries(
              Object.entries(workout.categories).map(([category, exercises]) => [
                category,
                exercises.map((exercise) => ({
                  ...exercise,
                  performed:
                    newStatus === "published"
                      ? { ...exercise.goal }
                      : exercise.performed,
                })),
              ])
            ),
          };
          return await updateWorkout(updatedWorkout, programId);
        }
      } catch (error) {
        console.error("Error toggling workout status:", error);
        throw error;
      }
    },
    [fetchWorkoutFromFirestore, updateWorkout]
  );

  const deleteWorkout = useCallback(
    async (workoutId, programId) => {
      if (auth.currentUser && programId && workoutId) {
        try {
          const user = auth.currentUser;
          const workoutRef = doc(
            db,
            "users",
            user.uid,
            "programs",
            programId,
            "workouts",
            workoutId
          );
          await deleteDoc(workoutRef);
          // No need to update workouts state manually since we have real-time listener
          if (currentWorkout && currentWorkout.id === workoutId) {
            setCurrentWorkout(null);
          }
        } catch (error) {
          console.error("Error deleting workout:", error);
          throw error;
        }
      }
    },
    [currentWorkout]
  );

  const duplicateWorkout = useCallback(
    async (originalWorkout, programId) => {
      if (!auth.currentUser || !programId || !originalWorkout) return;
      const newWorkoutId = `workout-${Date.now()}`;
      const duplicatedWorkout = {
        ...originalWorkout,
        id: newWorkoutId,
        order: originalWorkout.order + 1,
        actions: { state: "Ready", startTime: null, endTime: null },
      };

      try {
        await updateWorkout(duplicatedWorkout, programId);
        // No need to update workouts state manually
        return duplicatedWorkout;
      } catch (error) {
        console.error("Error duplicating workout:", error);
        throw error;
      }
    },
    [updateWorkout]
  );

  const startWorkout = useCallback(
    async (workoutId, programId) => {
      try {
        const workout = await fetchWorkoutFromFirestore(workoutId, programId);
        if (workout) {
          const updatedWorkout = {
            ...workout,
            actions: {
              ...workout.actions,
              state: "in progress",
              startTime: serverTimestamp(),
            },
          };
          return await updateWorkout(updatedWorkout, programId);
        }
      } catch (error) {
        console.error("Error starting workout:", error);
        throw error;
      }
    },
    [fetchWorkoutFromFirestore, updateWorkout]
  );




  const createWorkoutActivity = useCallback(
    async (type, workout, programId) => {
      if (!auth.currentUser || !workout) return;
  
      try {
        const user = auth.currentUser;
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        const currentActivities = userDoc.data()?.activities || [];
  
        // Ensure required data exists and use fallbacks if needed
        const activityData = {
          id: `activity-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          type,
          timestamp: new Date().toISOString(),
          data: {
            workoutId: workout.id,
            workoutName: workout.mainMovement || 'Untitled Workout',
            programId,
            programName: workout.programName || 'My Program',
            ...(type === 'WORKOUT_COMPLETED' && {
              metrics: {
                totalExercises: Object.values(workout.categories || {}).flat().length,
                completedExercises: Object.values(workout.categories || {})
                  .flat()
                  .filter(exercise => 
                    exercise.performed?.sets?.every(set => 
                      set.reps && set.weight
                    )
                  ).length
              }
            })
          }
        };
  
        await updateDoc(userRef, {
          activities: [activityData, ...currentActivities]
        });
      } catch (error) {
        console.error("Error creating workout activity:", error);
      }
    },
    []
  );
  



  const completeWorkout = useCallback(
    async (workoutId, programId) => {
      try {
        const workout = await fetchWorkoutFromFirestore(workoutId, programId);
        if (workout) {
          const updatedWorkout = {
            ...workout,
            actions: {
              ...workout.actions,
              state: "completed",
              endTime: serverTimestamp(),
            },
          };
          return await updateWorkout(updatedWorkout, programId);
        }
      } catch (error) {
        console.error("Error completing workout:", error);
        throw error;
      }
    },
    [fetchWorkoutFromFirestore, updateWorkout]
  );

  const cancelWorkout = useCallback(
    async (workoutId, programId) => {
      try {
        const workout = await fetchWorkoutFromFirestore(workoutId, programId);
        if (workout) {
          const updatedWorkout = {
            ...workout,
            actions: {
              ...workout.actions,
              state: "cancelled",
              endTime: serverTimestamp(),
            },
          };
          return await updateWorkout(updatedWorkout, programId);
        }
      } catch (error) {
        console.error("Error cancelling workout:", error);
        throw error;
      }
    },
    [fetchWorkoutFromFirestore, updateWorkout]
  );






  const saveWorkoutToFirestore = useCallback(
    async (workout, programId) => {
      if (auth.currentUser && programId && workout?.id) {
        try {
          const user = auth.currentUser;
          const workoutRef = doc(
            db,
            "users",
            user.uid,
            "programs",
            programId,
            "workouts",
            workout.id
          );
          await setDoc(workoutRef, workout, { merge: true });
          console.log("Workout saved to Firestore successfully");
          return workout;
        } catch (error) {
          console.error("Error saving workout to Firestore:", error);
          throw error;
        }
      }
    },
    []
  );

  const updateAssignedDate = useCallback(
    async (workoutId, programId, assignedDate) => {
      if (auth.currentUser && programId && workoutId) {
        try {
          const user = auth.currentUser;
          const workoutRef = doc(
            db,
            "users",
            user.uid,
            "programs",
            programId,
            "workouts",
            workoutId
          );
          await updateDoc(workoutRef, { assignedDate });
          console.log("Assigned date updated successfully");
        } catch (error) {
          console.error("Error updating assigned date:", error);
          throw error;
        }
      }
    },
    []
  );

  const value = {
    workouts,
    currentWorkout,
    subscribeToWorkouts, // Expose the subscription function
    fetchWorkouts,
    fetchWorkoutFromFirestore,
    updateWorkout,
    toggleStatus,
    deleteWorkout,
    duplicateWorkout,
    startWorkout,
    completeWorkout,
    cancelWorkout,
    saveWorkoutToFirestore,
    updateAssignedDate,
  };

  return (
    <WorkoutContext.Provider value={value}>
      {children}
    </WorkoutContext.Provider>
  );
};
