// import React, { useRef, useState, useEffect } from "react";
// import styled from "styled-components";
// import { useNavigate } from "react-router-dom"; // Import useNavigate

// const gradients = [
//   ["#ff7e5f", "#feb47b"],
//   ["#6a11cb", "#2575fc"],
//   ["#ff6a00", "#ee0979"],
//   ["#3a1c71", "#d76d77", "#ffaf7b"],
//   ["#56ab2f", "#a8e063"],
//   ["#00c6ff", "#0072ff"],
//   ["#f7971e", "#ffd200"],
//   ["#fc4a1a", "#f7b733"],
//   ["#12c2e9", "#c471ed", "#f64f59"],
//   ["#ff9a9e", "#fecfef"],
// ];

// const getRandomGradient = () => {
//   const randomIndex = Math.floor(Math.random() * gradients.length);
//   return gradients[randomIndex];
// };

// const CardWrap = styled.div`
//   margin: 10px;
//   transform: perspective(800px);
//   transform-style: preserve-3d;
//   cursor: pointer;

//   &:hover {
//     .card-info {
//       transform: translateY(0);
//     }
//     .card-info p {
//       opacity: 1;
//     }
//     .card-info,
//     .card-info p {
//       transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
//     }
//     .card-info:after {
//       transition: 5s cubic-bezier(0.23, 1, 0.32, 1);
//       opacity: 1;
//       transform: translateY(0);
//     }
//     .card-bg {
//       transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1),
//         opacity 5s cubic-bezier(0.23, 1, 0.32, 1);
//       opacity: 0.8;
//     }
//     .card {
//       transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1),
//         box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
//       box-shadow: rgba(255, 255, 255, 0.2) 0 0 40px 5px,
//         rgba(255, 255, 255, 1) 0 0 0 1px, rgba(0, 0, 0, 0.66) 0 30px 60px 0,
//         inset #333 0 0 0 5px, inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
//     }
//   }
// `;

// const CardStyled = styled.div`
//   position: relative;
//   flex: 0 0 240px;
//   width: 150px;
//   height: 150px;
//   background-color: #333;
//   overflow: hidden;
//   border-radius: 10px;
//   box-shadow: rgba(0, 0, 0, 0.66) 0 30px 60px 0, inset #333 0 0 0 5px,
//     inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
//   transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);

//   @media (max-width: 480px) {
//     width: 100px;
//     height: 100px;
//   }
// `;

// const CardBg = styled.div`
//   opacity: 0.5;
//   position: absolute;
//   top: -20px;
//   left: -20px;
//   width: 100%;
//   height: 100%;
//   padding: 20px;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95),
//     opacity 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
//   pointer-events: none;
// `;

// const CardInfo = styled.div`
//   padding: 20px;
//   position: absolute;
//   bottom: 0;
//   color: #fff;
//   transform: translateY(40%);
//   transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);

//   p {
//     opacity: 0;
//     text-shadow: rgba(0, 0, 0, 1) 0 2px 3px;
//     transition: 0.6s 1.6s cubic-bezier(0.215, 0.61, 0.355, 1);
//   }

//   * {
//     position: relative;
//     z-index: 1;
//   }

//   // &:after {
//   //   content: "";
//   //   position: absolute;
//   //   top: 0;
//   //   left: 0;
//   //   z-index: 0;
//   //   width: 100%;
//   //   height: 100%;
//   //   background-image: linear-gradient(
//   //     to bottom,
//   //     transparent 0%,
//   //     rgba(0, 0, 0, 0.6) 100%
//   //   );
//   //   background-blend-mode: overlay;
//   //   opacity: 0;
//   //   transform: translateY(100%);
//   //   transition: 5s 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
//   // }
// `;

// const CardTitle = styled.h1`
//   font-family: "Playfair Display";
//   font-size: 30px;
//   font-weight: 700;
//   text-shadow: rgba(0, 0, 0, 0.5) 0 10px 10px;

//   @media (max-width: 480px) {
//     font-size: 20px;
//   }
// `;

// const CardCreator = styled.p`
//   font-family: "Raleway";
//   font-size: 14px;
//   font-weight: 400;
//   text-shadow: rgba(0, 0, 0, 0.5) 0 2px 3px;
// `;

// const MenuButton = styled.button`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;

//   &:focus {
//     outline: none;
//   }

//   @media (max-width: 480px) {
//     top: 0px;
//     right: 0px;
//     font-size: 16px;
//   }
// `;

// const Menu = styled.ul`
//   position: absolute;
//   top: 40px;
//   right: 10px;
//   background: #444;
//   color: white;
//   font-weight: 400;
//   list-style: none;
//   padding: 10px;
//   border-radius: 5px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   display: ${({ $isOpen }) =>
//     $isOpen ? "block" : "none"}; // Use $ prefix for transient prop
//   z-index: 100;
// `;

// const MenuItem = styled.li`
//   padding: 0px 7px;
//   cursor: pointer;

//   &:hover {
//     background: #3a3a3a;
//   }
// `;

// const Card = ({
//   image,
//   title,
//   content,
//   onDelete,
//   onDuplicate,
//   onShare,
//   onRename,
//   onEdit, // Add onEdit prop
//   username,
//   programId, // Add programId prop
// }) => {
//   const cardRef = useRef(null);
//   const [mouse, setMouse] = useState({ x: 0.5, y: 0.5 });
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [gradient] = useState(getRandomGradient());
//   const navigate = useNavigate(); // Initialize navigate hook

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (cardRef.current && !cardRef.current.contains(event.target)) {
//         setMenuOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [cardRef]);

//   const handleMouseMove = (e) => {
//     const { offsetWidth, offsetHeight, offsetLeft, offsetTop } =
//       cardRef.current;
//     const { clientX, clientY } = e;
//     const x = (clientX - offsetLeft) / offsetWidth;
//     const y = (clientY - offsetTop) / offsetHeight;
//     setMouse({
//       x: Math.min(Math.max(x, 0.25), 0.75),
//       y: Math.min(Math.max(y, 0.25), 0.75),
//     });
//   };

//   const handleMouseEnter = () => {
//     clearTimeout(cardRef.current.mouseLeaveDelay);
//   };

//   const handleMouseLeave = () => {
//     cardRef.current.mouseLeaveDelay = setTimeout(() => {
//       setMouse({ x: 0.5, y: 0.5 });
//     }, 1000);
//   };

//   const cardStyle = {
//     transform: `rotateY(${(mouse.x - 0.5) * 15}deg) rotateX(${
//       (0.5 - mouse.y) * 15
//     }deg)`,
//   };

//   const cardBgStyle = {
//     backgroundImage: `linear-gradient(to bottom right, ${gradient[0]}, ${
//       gradient[1]
//     }${gradient[2] ? `, ${gradient[2]}` : ""})`,
//     transform: `translateX(${(mouse.x - 0.5) * -20}px) translateY(${
//       (mouse.y - 0.5) * -20
//     }px)`,
//   };

//   const handleMenuToggle = (e) => {
//     e.stopPropagation(); // Prevent triggering the card click event
//     setMenuOpen(!menuOpen);
//   };

//   const handleDelete = () => {
//     setMenuOpen(false);
//     onDelete();
//   };

//   const handleDuplicate = () => {
//     setMenuOpen(false);
//     onDuplicate();
//   };

//   const handleShare = () => {
//     setMenuOpen(false);
//     onShare();
//   };

//   const handleRename = () => {
//     setMenuOpen(false);
//     onRename();
//   };

//   const handleEdit = () => {
//     setMenuOpen(false);
//     onEdit();
//   };

//   const handleCardClick = (e) => {
//     // Prevent the card click if clicking on the menu button or menu items
//     if (e.target.closest(".menu-button") || e.target.closest(".menu")) return;
//     navigate(`/program/${programId}`); // Navigate to the program page
//   };

//   return (
//     <CardWrap
//       ref={cardRef}
//       // onMouseMove={handleMouseMove}
//       // onMouseEnter={handleMouseEnter}
//       // onMouseLeave={handleMouseLeave}
//       onClick={handleCardClick}
//     >
//       <Menu className="menu" $isOpen={menuOpen}>
//         <MenuItem onClick={handleEdit}>Edit</MenuItem>
//         <MenuItem onClick={handleRename}>Rename</MenuItem>
//         <MenuItem onClick={handleDelete}>Delete</MenuItem>
//         <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
//         <MenuItem onClick={handleShare}>Share</MenuItem>
//       </Menu>
//       <CardStyled style={cardStyle}>
//         <CardBg style={cardBgStyle}></CardBg>
//         <CardInfo className="card-info">
//           <CardTitle>{title}</CardTitle>
//           <p>{content}</p>
//           <CardCreator>{`Created by ${username}`}</CardCreator>
//         </CardInfo>
//         <MenuButton className="menu-button" onClick={handleMenuToggle}>
//           ⋮
//         </MenuButton>
//       </CardStyled>
//     </CardWrap>
//   );
// };

// export default Card;


// #############################################################

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { HiDotsVertical, HiUser, HiClock, HiCollection } from 'react-icons/hi';

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${props => props.gradient};
  border-radius: 10px;
  padding: 10px;
  color: white;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

const CardInfo = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  transition: transform 0.3s ease;

  ${CardContainer}:hover & {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`;

const CardTitle = styled.h2`
  font-size: 20px;
  margin: 0 0 5px 0;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CardContent = styled.p`
  font-size: 14px;
  margin: 0 0 10px 0;
  opacity: 0.8;
`;

const CardCreator = styled.p`
  font-size: 12px;
  margin: 0;
  opacity: 0.7;
`;

const ProgramDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ProgramDetail = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const DetailIcon = styled.span`
  margin-right: 5px;
  font-size: 18px;
`;

const MenuButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const Menu = styled.ul`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #2c2c2c;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 100;
  padding: 5px 0;
  margin: 0;
  list-style: none;
  min-width: 75px;
`;

const MenuItem = styled.li`
  padding: 5px 5px;
  cursor: pointer;
  color: #fff;
  font-size: 9px;

  &:hover {
    background-color: #3d3d3d;
  }
`;

const gradients = [
  "linear-gradient(135deg, #ff7e5f, #feb47b)",
  "linear-gradient(135deg, #6a11cb, #2575fc)",
  "linear-gradient(135deg, #ff6a00, #ee0979)",
  "linear-gradient(135deg, #3a1c71, #d76d77, #ffaf7b)",
  "linear-gradient(135deg, #56ab2f, #a8e063)",
  "linear-gradient(135deg, #00c6ff, #0072ff)",
  "linear-gradient(135deg, #f7971e, #ffd200)",
  "linear-gradient(135deg, #fc4a1a, #f7b733)",
  "linear-gradient(135deg, #12c2e9, #c471ed, #f64f59)",
  "linear-gradient(135deg, #ff9a9e, #fecfef)",
];

const getRandomGradient = () => {
  const randomIndex = Math.floor(Math.random() * gradients.length);
  return gradients[randomIndex];
};

const ProgramCard = ({
  title,
  content,
  onDelete,
  onDuplicate,
  onShare,
  onRename,
  onEdit,
  username,
  programId,
  exerciseCount,
  lastModified,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const gradient = getRandomGradient();

  const handleMenuToggle = (e) => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };

  const handleAction = (action) => {
    setMenuOpen(false);
    action();
  };

  const handleCardClick = (e) => {
    if (e.target.closest('.menu-button') || e.target.closest('.menu')) return;
    navigate(`/program/${programId}`);
  };

  return (
    <CardContainer onClick={handleCardClick} gradient={gradient}>
      {/* <ProgramDetails>
        <ProgramDetail>
          <DetailIcon as={HiCollection} /> {exerciseCount} exercises
        </ProgramDetail>
        <ProgramDetail>
          <DetailIcon as={HiClock} /> Last modified: {lastModified}
        </ProgramDetail>
      </ProgramDetails> */}
      <CardInfo className="card-info">
        <CardTitle>{title}</CardTitle>
        <CardContent>{content}</CardContent>
        <CardCreator>{`Created by ${username}`}</CardCreator>
      </CardInfo>
      <MenuButton className="menu-button" onClick={handleMenuToggle}>
        <HiDotsVertical />
      </MenuButton>
      <Menu className="menu" $isOpen={menuOpen}>
        {/* <MenuItem onClick={() => handleAction(onEdit)}>Edit</MenuItem> */}
        <MenuItem onClick={() => handleAction(onRename)}>Rename</MenuItem>
        <MenuItem onClick={() => handleAction(onDelete)}>Delete</MenuItem>
        <MenuItem onClick={() => handleAction(onDuplicate)}>Duplicate</MenuItem>
        <MenuItem onClick={() => handleAction(onShare)}>Share</MenuItem>
      </Menu>
    </CardContainer>
  );
};

export default ProgramCard;