import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Canvas = styled.canvas`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // background-image: linear-gradient(#242424, #fff);
      background-image: linear-gradient(#000000, #575757);

`;

const ProgramBackground = () => {
  const canvasRef = useRef(null);
  const numRays = 50;
  const numParticles = 50;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const Ray = function() {
      // Ray implementation
      this.ctx = ctx;
      this.angle = 85 * Math.PI / 180;

      this.init = function() {
        this.velocity = 0.25 - Math.random() * 0.5;
        this.len = canvas.height / 2 + Math.random() * (canvas.height / 2);
        this.start = {
          x: Math.random() * (canvas.width + 100) - 50,
          y: 0
        };
        this.end = {
          x: this.start.x + this.len * Math.cos(this.angle),
          y: this.start.y + this.len * Math.sin(this.angle)
        };
        this.ttl = 100 + Math.random() * 200;
        this.life = 0;
        this.width = 0.5 + Math.random() * 4;
        this.hue = Math.round(45 + Math.random() * 15).toString();
        this.saturation = Math.round(20 + Math.random() * 40).toString();
      };

      this.color = function() {
        const alpha = Math.abs((this.life + this.ttl / 2) % this.ttl - this.ttl / 2) * 0.005;
        const color1 = `hsla(${this.hue},${this.saturation}%,60%,${alpha})`;
        const color2 = "hsla(190, 20%, 50%,0)";

        const gradient = ctx.createLinearGradient(this.start.x, this.start.y, this.end.x, this.end.y);
        gradient.addColorStop(0, color1);
        gradient.addColorStop(1, color2);
        return gradient;
      };

      this.draw = function() {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.color();
        this.ctx.lineWidth = this.width;
        this.ctx.moveTo(this.start.x, this.start.y);
        this.ctx.lineTo(this.end.x, this.end.y);
        this.ctx.stroke();
        this.ctx.closePath();
      };

      this.update = function() {
        if (this.life > this.ttl) {
          this.init();
        }
        this.life++;
        this.start.x += this.velocity;
        this.end.x += this.velocity;
      };

      this.init();
    };

    const Particle = function() {
      // Particle implementation
      this.ctx = ctx;

      this.init = function() {
        this.position = {
          x: Math.random() * canvas.width,
          y: canvas.height / 2 + Math.random() * canvas.height / 2
        };
        this.velocity = {
          x: 0.5 - Math.random() * 1,
          y: 0.5 - Math.random() * 1
        };
        this.ttl = 100 + Math.random() * 200;
        this.life = 0;
        this.size = 1 + Math.random() * 10;
      };

      this.color = function() {
        const alpha = Math.abs((this.life + this.ttl / 2) % this.ttl - this.ttl / 2) * 0.005;
        return `hsla(190, 20%, 50%,${alpha})`;
      };

      this.draw = function() {
        this.ctx.beginPath();
        this.ctx.fillStyle = this.color();
        this.ctx.arc(this.position.x, this.position.y, this.size, 0, Math.PI * 2);
        this.ctx.fill();
        this.ctx.closePath();
      };

      this.update = function() {
        if (this.life > this.ttl) {
          this.init();
        } else {
          this.life++;
          this.position.x += this.velocity.x;
          this.position.y += this.velocity.y;
        }
      };

      this.init();
    };

    const onResize = () => {
      const rect = canvas.getBoundingClientRect();
      canvas.height = rect.height;
      canvas.width = rect.width;
    };

    const objects = [];

    const createObjects = () => {
      for (let i = 0; i < numRays; i++) {
        objects.push(new Ray());
      }
      for (let i = 0; i < numParticles; i++) {
        objects.push(new Particle());
      }
    };

    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.shadowBlur = 30;
      ctx.shadowColor = "white";
      ctx.globalCompositeOperation = "lighter";
      for (let i = 0; i < objects.length; i++) {
        objects[i].update();
        objects[i].draw();
      }
    };

    const loop = () => {
      render();
      animationFrameId = window.requestAnimationFrame(loop);
    };

    const init = () => {
      onResize();
      createObjects();
      loop();
    };

    init();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <Canvas ref={canvasRef} id="canvas" />;
};

export default ProgramBackground;