// import React, { useState } from "react";
// import { collection, addDoc } from "firebase/firestore";
// import { db } from "../../services/firebase/firebaseConfig";
// import { useAuth } from "../../hooks/useAuth";

// const CreateProgramButton = ({ onProgramCreated }) => {
//   const { user } = useAuth();
//   const [showModal, setShowModal] = useState(false);
//   const [newProgramName, setNewProgramName] = useState("");

//   const handleCreateProgram = async () => {
//     if (!newProgramName.trim()) return;

//     try {
//       const programsCollection = collection(db, "users", user.uid, "programs");
//       const newProgramRef = await addDoc(programsCollection, {
//         name: newProgramName,
//         userId: user.uid,
//       });
//       const newProgram = {
//         id: newProgramRef.id,
//         name: newProgramName,
//         username: user.username || "Unknown",
//       };

//       setNewProgramName("");
//       setShowModal(false);
//       onProgramCreated(newProgram);
//     } catch (error) {
//       console.error("Error creating program:", error);
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleCreateProgram();
//     }
//   };

//   return (
//     <div>
//       <button onClick={() => setShowModal(true)}>Create New Program</button>
//       {showModal && (
//         <div className="create-new-program-modal">
//           <div className="create-new-program-modal-content">
//             <button className="close" onClick={() => setShowModal(false)}>
//               &times;
//             </button>
//             <h3 className="create-new-program-modal-title">
//               Name your new program
//             </h3>
//             <input
//               type="text"
//               value={newProgramName}
//               onChange={(e) => setNewProgramName(e.target.value)}
//               onKeyPress={handleKeyPress}
//             />
//             <button onClick={handleCreateProgram}>Create Program</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CreateProgramButton;

import React, { useState } from "react";
import ProgramCreationModal from "./ProgramCreationModal"; // Import the ProgramCreationModal component
import { MdAdd } from "react-icons/md";
import styled, { keyframes } from "styled-components";


const rotateGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledButton = styled.button`
  background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  background-size: 300% 300%;
  animation: ${rotateGradient} 5s ease infinite;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  svg {
    margin-right: 8px;
  }
`;

const CreateProgramButton = ({ onProgramCreated }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {/* <button onClick={() => setShowModal(true)}>+ Program</button> */}
      <StyledButton onClick={() => setShowModal(true)}>
        <MdAdd size={20} /> Program
      </StyledButton>
      {showModal && (
        <ProgramCreationModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          onProgramCreated={onProgramCreated}
        />
      )}
    </div>
  );
};

export default CreateProgramButton;
