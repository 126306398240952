import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";

const AvatarContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #666;
  font-size: 48px;
  font-weight: bold;
`;

const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${AvatarContainer}:hover & {
    opacity: 1;
  }
`;

const UploadText = styled.span`
  color: white;
  font-size: 14px;
  text-align: center;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Avatar = () => {
  const { user, uploadAvatar } = useAuth();
  const [isUploading, setIsUploading] = useState(false);

  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setIsUploading(true);
        console.log("Starting avatar upload...");
        await uploadAvatar(file);
        console.log("Avatar upload successful");
        // Handle successful upload (e.g., show a success message)
      } catch (error) {
        console.error("Error uploading avatar:", error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <AvatarContainer>
      {user.avatarUrl ? (
        <AvatarImage src={user.avatarUrl} alt={user.username} />
      ) : (
        <AvatarPlaceholder>
          {user.username.charAt(0).toUpperCase()}
        </AvatarPlaceholder>
      )}
      <AvatarOverlay>
        <label htmlFor="avatar-upload">
          <UploadText>
            {isUploading ? "Uploading..." : "Upload New Avatar"}
          </UploadText>
          <HiddenInput
            id="avatar-upload"
            type="file"
            accept="image/*"
            onChange={handleAvatarUpload}
            disabled={isUploading}
          />
        </label>
      </AvatarOverlay>
    </AvatarContainer>
  );
};

export default Avatar;
