import React, { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db, storage } from "../services/firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  onSnapshot,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Create a context for authentication
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext); // Custom hook to use the AuthContext
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // State to hold the user data
  const [loading, setLoading] = useState(true); // State to indicate loading status
  const [onboardingComplete, setOnboardingComplete] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const userDocRef = doc(db, "users", authUser.uid);

        // Initial fetch of user data
        const fetchUserData = async () => {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();

            setUser({
              uid: authUser.uid,
              email: authUser.email,

              ...docSnap.data(),
            });
            setOnboardingComplete(userData.onboardingComplete || false);

            // If onboardingComplete is undefined, update it in Firestore
            if (userData.onboardingComplete === undefined) {
              await updateDoc(userDocRef, { onboardingComplete: false });
            }
          } else {
            setUser(authUser);
            setOnboardingComplete(false);
          }
          setLoading(false);
        };
        await fetchUserData();

        // Set up real-time listener for user document
        const unsubscribeUser = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUser((prevUser) => ({ ...prevUser, ...docSnap.data() }));
            setOnboardingComplete(userData.onboardingComplete ?? false);
          }
        });

        // Clean up listener when component unmounts or user logs out
        return () => unsubscribeUser();
      } else {
        setUser(null);
        setOnboardingComplete(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const completeOnboarding = async () => {
    if (!user) throw new Error("No user logged in");
    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, { onboardingComplete: true });
      setOnboardingComplete(true);
      setUser((prevUser) => ({ ...prevUser, onboardingComplete: true }));
    } catch (error) {
      throw error;
    }
  };

  const signup = async (email, password, username) => {
    try {
      // Step 1: Check if the username exists
      const usernameRef = doc(db, "usernames", username);
      const usernameSnap = await getDoc(usernameRef);

      if (usernameSnap.exists()) {
        throw new Error("Username already exists.");
      }

      // Step 2: Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Step 3: Add user document to Firestore
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        email: user.email,
        username: username,
        theme: "light",
        membershipTier: "Free",
        friends: [],
        notifications: [],
        incomingFriendRequests: [],
        outgoingFriendRequests: [],
        programs: [],
        exercises: [], // Initialize the exercises array

        socialLinks: {
          instagram: "-",
          reddit: "-",
          twitter: "-",
          youtube: "-",
        },
      });

      // Step 4: Add username to usernames collection
      await setDoc(usernameRef, {
        uid: user.uid,
      });

      // Step 5: Fetch the stored data from Firestore
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUser({ uid: user.uid, email: user.email, ...docSnap.data() });
      } else {
        setUser(user);
      }

      setOnboardingComplete(false); // Add this line
    } catch (error) {
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  };

  const updateUserTheme = async (uid, theme) => {
    try {
      await setDoc(doc(db, "users", uid), { theme }, { merge: true });
      setUser((prevUser) => ({ ...prevUser, theme }));
      document.body.classList.toggle("dark-mode", theme === "dark");
    } catch (error) {
      throw error;
    }
  };

  const uploadAvatar = async (file) => {
    if (!user) throw new Error("No user logged in");

    try {
      if (!storage) {
        throw new Error("Firebase storage is not initialized");
      }

      // 1. Upload image to Firebase Storage
      const storageRef = ref(storage, `avatars/${user.uid}`);

      await uploadBytes(storageRef, file);

      // 2. Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // 3. Update user document in Firestore
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        avatarUrl: downloadURL,
        avatarStatus: "pending",
      });

      // 4. Update local user state
      setUser((prevUser) => ({
        ...prevUser,
        avatarUrl: downloadURL,
        avatarStatus: "pending",
      }));

      return downloadURL;
    } catch (error) {
      throw error;
    }
  };

  const updateUserAbout = async (aboutText) => {
    if (!user) throw new Error("No user logged in");

    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        about: aboutText,
      });

      // Update local user state
      setUser((prevUser) => ({
        ...prevUser,
        about: aboutText,
      }));
    } catch (error) {
      throw error;
    }
  };

  // Add the updateMembershipTier function
  const updateMembershipTier = async (membershipTier) => {
    if (!user) throw new Error("No user logged in");
    try {
      const userRef = doc(db, "users", user.uid);
      const newSubscriptionStatus =
        membershipTier === "Free" ? "canceled" : "active";

      // Update Firestore
      await updateDoc(userRef, {
        membershipTier,
        subscriptionStatus: newSubscriptionStatus,
      });

      // Update user context
      setUser((prevUser) => ({
        ...prevUser,
        membershipTier,
        subscriptionStatus: newSubscriptionStatus,
      }));
    } catch (error) {
      throw error;
    }
  };

  const updateUserSocials = async (socialLinks) => {
    if (!user) throw new Error("No user logged in");

    try {
      // Format URLs before saving
      const formatUrl = (url) => {
        if (!url || url === "-") return "-";
        if (url.match(/^https?:\/\//)) return url;
        return `https://${url}`;
      };

      const formattedLinks = {
        instagram: formatUrl(socialLinks.instagram),
        reddit: formatUrl(socialLinks.reddit),
        twitter: formatUrl(socialLinks.twitter),
        youtube: formatUrl(socialLinks.youtube),
      };

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        socialLinks: formattedLinks,
      });

      // Update local user state
      setUser((prevUser) => ({
        ...prevUser,
        socialLinks: formattedLinks,
      }));
    } catch (error) {
      throw error;
    }
  };

  const value = {
    user,
    loading,
    onboardingComplete,
    signup,
    login,
    logout,
    updateUserTheme,
    uploadAvatar,
    updateUserAbout,
    updateMembershipTier,
    completeOnboarding,
    updateUserSocials,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Render children only when not loading */}
    </AuthContext.Provider>
  );
};

// ####################################################################
