


import React, { memo, useState, useMemo, useCallback } from "react";
import { useUserExercises } from "../../context/UserExercisesContext";
import styled from "styled-components";
import { FaPlus, FaEdit, FaTrashAlt, FaDumbbell, FaBookOpen } from "react-icons/fa";
import Modal from "../../Modal";
import Library from "./Library";
import SideDrawer from "../../SideDrawer";
import PRCard from "./PRCard";
import ExerciseForm from "../../exercises/ExerciseForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  color: white;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 768px) {
    height: 85vh;
    padding: 10px;
  }
`;

const ExerciseListContainer = styled.div`
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-height: calc(100vh - 200px);
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    max-height: none;
  }
`;

const FilterSortContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  flex-wrap: wrap;
`;

const FilterInput = styled.input`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #4a4a4a;
  background-color: #2c2c2c;
  color: white;
  // margin-bottom: 10px;
`;

const FilterButton = styled.button`
  background-color: #6e8efb;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-left: 10px;
  // margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  &:hover {
    background-color: #5c7cfa;
  }
`;

const LibraryButton = styled.button`
  background-color: #28a745; /* Green color for the library button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-left: 10px;

  &:hover {
    background-color: #218838;
  }
`;

const LibraryModalContent = styled.div`
  background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  padding: 20px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
`;

const SortSelect = styled.select`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #4a4a4a;
  background-color: #2c2c2c;
  color: white;
  margin-bottom: 10px;
`;




const ExerciseCard = styled.div`
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background: linear-gradient(145deg, #1b1c1d, #34495e);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }

  display: flex;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ExerciseName = styled.h3`
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: animateGradient 5s ease infinite;
  margin: 0;

  @keyframes animateGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const PRValue = styled.p`
  font-size: 1.1em;
  margin: 0;
  color: #666;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// ################################

const AddPRSection = styled.div`
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 10px;
  // max-height: 168px;
  // min-height: 168px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  width: auto;
`;

const SelectedExercise = styled.h2`
  font-size: 1.5em;
  color: #6e8efb;
  margin-bottom: 20px;
  text-align: left;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.label`
  font-size: 0.9em;
  margin-bottom: 5px;
  color: #b0b0b0;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  font-size: 0.8em;
  background-color: #3a3a3a;
  color: #ffffff;
  width: 80%;

  &:focus {
    outline: none;
    border-color: #6e8efb;
  }
`;

const Button = styled.button`
  background-color: #6e8efb;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;

  &:hover {
    background-color: #5c7cfa;
  }

  &:disabled {
    background-color: #4a4a4a;
    cursor: not-allowed;
  }
`;

const PRButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
align-items: center;


`;


const CancelPRButton = styled(Button)`
  background: #fa5c5c;
  &:hover {
    background: #e04b4b;
  }
`;

const AddPRButton = styled.button`
  background-color: #ff00ec;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 0.9em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-left: 10px;
  

  &:hover {
    background-color: #e000d1;
  }
`;

const ViewPRButton = styled.button`
  background-color: #6e8efb;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 0.9em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-left: 10px;

  &:hover {
    background-color: #5c7cfa;
  }
`;

const AddExerciseButton = styled.button`
  background-color: #2c2c2c; /* Green color for the add button */
  color: white;
  border: none;
  // padding: 5px;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: 7px;
  transition: background-color 0.3s;
  margin-left: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  &:hover {
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
`;

const AddExerciseForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

// Helper function to sort exercises
const sortExercises = (exercises, sortOption) => {
  const sortOptions = {
    "name-asc": (a, b) => a.name.localeCompare(b.name),
    "name-desc": (a, b) => b.name.localeCompare(a.name),
    "pr-asc": (a, b) => (a.currentPR || 0) - (b.currentPR || 0),
    "pr-desc": (a, b) => (b.currentPR || 0) - (a.currentPR || 0),
    "date-asc": (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    "date-desc": (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  };

  return [...exercises].sort(sortOptions[sortOption] || (() => 0));
};

const ExerciseTracker = memo(() => {
  const {
    exercises,
    addExercise,
    editExercise,
    deleteExercise,
    exerciseExists,
  } = useUserExercises();
  const [filterTerm, setFilterTerm] = useState("");
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [currentPR, setCurrentPR] = useState("");
  const [goalPR, setGoalPR] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPROnly, setShowPROnly] = useState(false);
  const [sortOption, setSortOption] = useState("name-asc");
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPRExercise, setSelectedPRExercise] = useState(null);
  const [isAddExerciseDrawerOpen, setIsAddExerciseDrawerOpen] = useState(false);
  const [isEditExerciseDrawerOpen, setIsEditExerciseDrawerOpen] = useState(false);
  const [isAddExerciseModalOpen, setIsAddExerciseModalOpen] = useState(false);
  const [isEditExerciseModalOpen, setIsEditExerciseModalOpen] = useState(false);
  const [editingExercise, setEditingExercise] = useState(null);
  const [newExercise, setNewExercise] = useState({
    name: "",
    bodyPart: "",
    equipment: "",
    target: "",
    secondaryMuscles: "",
    instructions: "",
  });

  const togglePRFilter = () => {
    setShowPROnly((prev) => !prev);
  };

  const filteredExercises = useMemo(() => {
    let exercisesToFilter = exercises;

    if (showPROnly) {
      exercisesToFilter = exercisesToFilter.filter(
        (ex) => ex.currentPR || ex.goalPR
      );
    }

    let filtered = exercisesToFilter.filter((exercise) =>
      exercise.name.toLowerCase().includes(filterTerm.toLowerCase())
    );

    return sortExercises(filtered, sortOption);
  }, [exercises, filterTerm, showPROnly, sortOption]);



  const handleExerciseSelect = useCallback((exercise) => {
    // ...
  }, []);






  const handleAddPRClick = useCallback((exercise) => {
    setSelectedExercise(exercise);
    setIsModalOpen(true);

    setCurrentPR("");
    setGoalPR("");
  }, [setSelectedExercise, setIsModalOpen]);

  const handleViewPRClick = (exercise) => {
    setSelectedPRExercise(exercise);
    setIsDrawerOpen(true);
  };

  const handleAddPR = (e) => {
    e.preventDefault();
    if (selectedExercise && currentPR) {
      const updatedExercise = {
        ...selectedExercise,
        currentPR: parseFloat(currentPR),
        goalPR: goalPR ? parseFloat(goalPR) : null,
      };

      editExercise(updatedExercise);

      setSelectedExercise(null);
      setCurrentPR("");
      setGoalPR("");
      setIsModalOpen(false);
    }
  };

  const handleDeleteExercise = (objectID) => {
    if (window.confirm("Are you sure you want to delete this exercise?")) {
      deleteExercise(objectID);
    }
  };


  const handleAddExerciseClick = () => {
    setIsAddExerciseModalOpen(true);
  };

  const handleCloseAddExerciseModal = () => {
    setIsAddExerciseModalOpen(false);
    setNewExercise({
      name: "",
      bodyPart: "",
      equipment: "",
      target: "",
      secondaryMuscles: "",
      instructions: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExercise((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitNewExercise = async () => {
    const objectID = Date.now().toString();
    const exerciseWithId = { ...newExercise, objectID };

    await addExercise(exerciseWithId);
    handleCloseAddExerciseModal();
  };

  const handleEditClick = (exercise) => {
    setEditingExercise({
      ...exercise,
      secondaryMuscles: exercise.secondaryMuscles || [],
      instructions: exercise.instructions || [],
    });
    setIsEditExerciseModalOpen(true);
  };

  const handleCloseEditExerciseModal = () => {
    setIsEditExerciseModalOpen(false);
    setEditingExercise(null);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingExercise((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEditExercise = async () => {
    await editExercise(editingExercise);
    handleCloseEditExerciseModal();
  };

  const handleUpdatePR = useCallback((exerciseId, prData) => {
    const exerciseToUpdate = exercises.find(ex => ex.objectID === exerciseId);
    if (exerciseToUpdate) {
      const updatedExercise = {
        ...exerciseToUpdate,
        ...prData
      };
      editExercise(updatedExercise);
    }
  }, [exercises, editExercise]);

  // const handleAddExerciseFromLibrary = (exercise) => {
  //   addExercise(exercise);
  //   setIsLibraryModalOpen(false);
  // };

  return (
    <Container>
      <ExerciseListContainer>
        <FilterSortContainer>
          <FilterGroup>
            <FilterInput
              type="text"
              placeholder="Search exercises..."
              value={filterTerm}
              onChange={(e) => setFilterTerm(e.target.value)}
            />
            {/* <FilterButton onClick={togglePRFilter} aria-label="Filter exercises">
              {showPROnly ? "PRs" : "All Exercises"}
            </FilterButton> */}

            <FilterButton onClick={togglePRFilter} aria-label="Filter exercises">
              {showPROnly ? "PRs" : (
                <>
                  <span>All</span>
                  <FaDumbbell />
                </>
              )}
            </FilterButton>

            <LibraryButton
              onClick={() => setIsLibraryModalOpen(true)}
              aria-label="Open exercise library"
            >
              <FaBookOpen></FaBookOpen>

            </LibraryButton>
            <AddExerciseButton
              onClick={handleAddExerciseClick}
              aria-label="Add new exercise"
            >
              +
            </AddExerciseButton>


          </FilterGroup>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SortSelect
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              aria-label="Sort exercises"
            >
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="pr-asc">PR (Low to High)</option>
              <option value="pr-desc">PR (High to Low)</option>
              {/* You can include date options if `createdAt` is available */}
              {/* <option value="date-asc">Date (Oldest First)</option>
              <option value="date-desc">Date (Newest First)</option> */}
            </SortSelect>

          </div>
        </FilterSortContainer>
        {filteredExercises.length === 0 ? (
          <p>No exercises found.</p>
        ) : (
          filteredExercises.map((exercise) => (



            <ExerciseCard>
              <TopRow>
                <ExerciseName>{exercise.name}</ExerciseName>
                {exercise.currentPR && (
                  <PRValue>Current PR: {exercise.currentPR}</PRValue>
                )}
              </TopRow>
              <ButtonRow>
                <div>
                  <FaEdit
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(exercise);
                    }}
                    aria-label="Edit exercise"
                  />
                  <FaTrashAlt
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteExercise(exercise.objectID);
                    }}
                    aria-label="Delete exercise"
                  />
                </div>
                {exercise.currentPR ? (
                  <ViewPRButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleViewPRClick(exercise);
                    }}
                    aria-label="View PR"
                  >
                    View PR
                  </ViewPRButton>
                ) : (
                  <AddPRButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddPRClick(exercise);
                    }}
                    aria-label="Add PR"
                  >
                    Add PR
                  </AddPRButton>
                )}
              </ButtonRow>
            </ExerciseCard>


          ))
        )}


        {/* ########################### */}
      </ExerciseListContainer>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddPRSection>
          <SelectedExercise>{selectedExercise?.name}</SelectedExercise>
          <Form onSubmit={handleAddPR}>
            <InputRow>
              <FormGroup>
                <Label htmlFor="currentPR">Current PR</Label>
                <Input
                  type="number"
                  id="currentPR"
                  value={currentPR}
                  onChange={(e) => setCurrentPR(e.target.value)}
                  required
                  placeholder="Enter your current PR"
                  name="currentPR"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="goalPR">Goal PR (optional)</Label>
                <Input
                  type="number"
                  id="goalPR"
                  value={goalPR}
                  onChange={(e) => setGoalPR(e.target.value)}
                  placeholder="Enter your goal PR"
                  name="goalPR"
                />
              </FormGroup>
            </InputRow>
            <PRButtonRow>
              <Button type="submit">
                <FaPlus />{" "}
                {selectedExercise?.currentPR || selectedExercise?.goalPR
                  ? "Update PR"
                  : "Add PR"}
              </Button>
              <CancelPRButton onClick={() => setIsModalOpen(false)}>
                Cancel
              </CancelPRButton>
            </PRButtonRow>
          </Form>
        </AddPRSection>
      </Modal>
      <Modal
        isOpen={isLibraryModalOpen}
        onClose={() => setIsLibraryModalOpen(false)}
      >
        {/* Render the Library component inside the modal */}
        <Library />
      </Modal>
      <Modal isOpen={isAddExerciseModalOpen} onClose={handleCloseAddExerciseModal}>
        <ExerciseForm
          exercise={newExercise}
          setExercise={setNewExercise}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmitNewExercise}
          editing={false}
        />
      </Modal>
      <Modal isOpen={isEditExerciseModalOpen} onClose={handleCloseEditExerciseModal}>
        <ExerciseForm
          exercise={editingExercise}
          setExercise={setEditingExercise}
          handleInputChange={handleEditInputChange}
          handleSubmit={handleSubmitEditExercise}
          editing={true}
        />
      </Modal>
      <SideDrawer
        isopen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        width="50%"
        maxwidth="600px"
        backgroundcolor="#1c1c1e"
      >
        {selectedPRExercise && (
          <PRCard 
          exercise={selectedPRExercise} 
          onClose={() => setIsDrawerOpen(false)} 
          onUpdatePR={handleUpdatePR} 
          />
        )}
      </SideDrawer>
    </Container>
  );
});

export default ExerciseTracker;


// ##################trying to fix the refresh bug ############################




