


import React from "react";
import styled from "styled-components";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 0.9em;
  margin-bottom: 5px;
  color: #b0b0b0;
`;

const Input = styled.input`
  padding: 10px 5px;
  width: 100%;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;

  &:focus {
    outline: none;
    border-color: #6e8efb;
  }
`;

const Button = styled.button`
  padding: 12px 16px;
  background-color: #6e8efb;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s;
  align-self: flex-start;

  &:hover {
    background-color: #5c7cfa;
  }

  &:disabled {
    background-color: #4a4a4a;
    cursor: not-allowed;
  }
`;

const ExerciseForm = ({
  exercise,
  setExercise,
  handleInputChange,
  handleSubmit,
  editing,
}) => {
  return (
    <FormWrapper>
      <FormField>
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          value={exercise.name || ""}
          onChange={handleInputChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="bodyPart">Body Part</Label>
        <Input
          type="text"
          name="bodyPart"
          id="bodyPart"
          placeholder="Body Part"
          value={exercise.bodyPart || ""}
          onChange={handleInputChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="equipment">Equipment</Label>
        <Input
          type="text"
          name="equipment"
          id="equipment"
          placeholder="Equipment"
          value={exercise.equipment || ""}
          onChange={handleInputChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="target">Target</Label>
        <Input
          type="text"
          name="target"
          id="target"
          placeholder="Target"
          value={exercise.target || ""}
          onChange={handleInputChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="secondaryMuscles">Secondary Muscles</Label>
        <Input
          type="text"
          name="secondaryMuscles"
          id="secondaryMuscles"
          placeholder="Secondary Muscles (comma separated)"
          value={exercise.secondaryMuscles || ""}
          onChange={handleInputChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="instructions">Instructions</Label>
        <Input
          type="text"
          name="instructions"
          id="instructions"
          placeholder="Instructions (separated by periods)"
          value={exercise.instructions || ""}
          onChange={handleInputChange}
        />
      </FormField>
      <Button onClick={handleSubmit}>
        {editing ? "Update Exercise" : "Add Exercise"}
      </Button>
    </FormWrapper>
  );
};

export default ExerciseForm;
