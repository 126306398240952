// import React, { useRef, useEffect, useState } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import * as THREE from 'three';
// import styled from 'styled-components';
// import { useGLTF } from '@react-three/drei';


// const Container = styled.div`
//   position: relative;
//   top: 0;
//   left: 0;
//   width: auto;
//   height: 86.2vh;
//   overflow: hidden;
// `;


// const SceneContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   touch-action: none;
// `;


// function Planet({ position, scale, initialRotation = { x: 0, y: 0, z: 0 } }) {
//   const planetRef = useRef();
//   const gltf = useGLTF('/models/planet.glb');


//   const [isDragging, setIsDragging] = useState(false);
//   const previousPosition = useRef({ x: 0, y: 0 });
//   const rotationVelocity = useRef(0);
//   const lastTime = useRef(Date.now());


//   useEffect(() => {
//     if (planetRef.current) {
//       planetRef.current.position.set(position.x, position.y, position.z);
//       planetRef.current.scale.set(scale, scale, scale);
//       planetRef.current.rotation.set(initialRotation.x, initialRotation.y, initialRotation.z);
//     }
//   }, [position, scale, initialRotation]);


//   const handleStart = (event) => {
//     setIsDragging(true);
//     const clientX = event.touches ? event.touches[0].clientX : event.clientX;
//     previousPosition.current = { x: clientX };
//     lastTime.current = Date.now();
//     rotationVelocity.current = 0;
//   };


//   const handleEnd = () => {
//     setIsDragging(false);
//   };


//   const handleMove = (event) => {
//     if (isDragging && planetRef.current) {
//       const clientX = event.touches ? event.touches[0].clientX : event.clientX;
//       const deltaX = clientX - previousPosition.current.x;
//       const currentTime = Date.now();
//       const deltaTime = currentTime - lastTime.current;


//       if (deltaTime > 0) {
//         const speed = deltaX / deltaTime;
//         rotationVelocity.current = speed * 0.005; // Adjusted for smoother rotation
//       }


//       previousPosition.current = { x: clientX };
//       lastTime.current = currentTime;
//     }
//   };


//   useFrame(() => {
//     if (planetRef.current) {
//       if (isDragging) {
//         planetRef.current.rotation.y += rotationVelocity.current;
//       } else {
//         // Apply inertia
//         rotationVelocity.current *= 0.95;
//         planetRef.current.rotation.y += rotationVelocity.current;
//       }
//     }
//   });


//   return (
//     <primitive
//       ref={planetRef}
//       object={gltf.scene}
//       castShadow
//       receiveShadow
//       onPointerDown={handleStart}
//       onPointerUp={handleEnd}
//       onPointerOut={handleEnd}
//       onPointerMove={handleMove}
//       onTouchStart={handleStart}
//       onTouchEnd={handleEnd}
//       onTouchMove={handleMove}
//     />
//   );
// }


// function MacrocyclePlanet() {
//   const [planetPosition] = useState({ x: 3, y: -3, z: 3 });
//   const [planetScale] = useState(2.5);
//   const initialRotation = { x: 0, y: Math.PI / -3, z: 0 }; // -60 degrees around y-axis


//   return (
//     <Container>
//       <SceneContainer>
//         <Canvas
//           shadows
//           camera={{ position: [0, 0, 20], fov: 50 }}
//           style={{ width: '100%', height: '100%' }}
//         >
//           <color attach="background" args={['#000']} />
//           <ambientLight intensity={0.3} />
//           <directionalLight position={[10, 10, 10]} intensity={1.5} castShadow />
//           <pointLight position={[-10, -10, 5]} intensity={0.7} />
//           <Planet
//             position={planetPosition}
//             scale={planetScale}
//             initialRotation={initialRotation}
//           />
//         </Canvas>
//       </SceneContainer>
//     </Container>
//   );
// }


// export default MacrocyclePlanet;








import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import styled from 'styled-components';
import { useGLTF } from '@react-three/drei';
import { Stars } from '@react-three/drei';

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: auto;
  height: 86.2vh;
  overflow: hidden;
`;

const SceneContainer = styled.div`
  width: 100%;
  height: 100%;
  touch-action: none;
`;

function Planet({ position, scale, initialRotation = { x: 0, y: 0, z: 0 } }) {
  const planetRef = useRef();
  const gltf = useGLTF('/models/planet.glb');

  const [isDragging, setIsDragging] = useState(false);
  const previousPosition = useRef({ x: 0 });
  const rotationVelocity = useRef(0);
  const lastTime = useRef(Date.now());

  useEffect(() => {
    if (planetRef.current) {
      planetRef.current.position.set(position.x, position.y, position.z);
      planetRef.current.scale.set(scale, scale, scale);
      planetRef.current.rotation.set(initialRotation.x, initialRotation.y, initialRotation.z);
    }
  }, [position, scale, initialRotation]);

  const handleStart = (event) => {
    setIsDragging(true);
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    previousPosition.current = { x: clientX };
    lastTime.current = Date.now();
    rotationVelocity.current = 0;
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  const handleMove = (event) => {
    if (isDragging && planetRef.current) {
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      const deltaX = clientX - previousPosition.current.x;
      const currentTime = Date.now();
      const deltaTime = currentTime - lastTime.current;

      if (deltaTime > 0) {
        const speed = deltaX / deltaTime;
        rotationVelocity.current = speed * 0.005; // Adjusted for smoother rotation
      }

      previousPosition.current = { x: clientX };
      lastTime.current = currentTime;
    }
  };

  useFrame(() => {
    if (planetRef.current) {
      if (isDragging) {
        planetRef.current.rotation.y += rotationVelocity.current;
      } else {
        // Apply inertia
        rotationVelocity.current *= 0.95;
        planetRef.current.rotation.y += rotationVelocity.current;
      }
    }
  });

  return (
    <primitive
      ref={planetRef}
      object={gltf.scene}
      castShadow
      receiveShadow
      onPointerDown={handleStart}
      onPointerUp={handleEnd}
      onPointerOut={handleEnd}
      onPointerMove={handleMove}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
      onTouchMove={handleMove}
    />
  );
}

// New component for twinkling stars
function TwinklingStars() {
  const group = useRef();
  const { clock } = useThree();

  useFrame(() => {
    const elapsed = clock.getElapsedTime();
    group.current.rotation.y = elapsed * 0.02; // Slow rotation to give a sense of depth
  });

  return (
    <group ref={group}>
      <Stars
        radius={100}
        depth={50}
        count={10000}
        factor={4}
        variance={10}
        saturation={0}
        brightness={0.5}
        fade
        speed={0}
      />
    </group>
  );
}

function MacrocyclePlanet() {
  const [planetPosition] = useState({ x: 3, y: -3, z: 3 });
  const [planetScale] = useState(2.5);
  const initialRotation = { x: 0, y: Math.PI / -3, z: 0 }; // -60 degrees around y-axis

  return (
    <Container>
      <SceneContainer>
        <Canvas
          shadows
          camera={{ position: [0, 0, 20], fov: 50 }}
          style={{ width: '100%', height: '100%' }}
        >
          <color attach="background" args={['#000']} />
          <ambientLight intensity={0.3} />
          <directionalLight position={[10, 10, 10]} intensity={1.5} castShadow />
          <pointLight position={[-10, -10, 5]} intensity={0.7} />
          
          {/* Add the TwinklingStars component */}
          <TwinklingStars />

          <Planet
            position={planetPosition}
            scale={planetScale}
            initialRotation={initialRotation}
          />
        </Canvas>
      </SceneContainer>
    </Container>
  );
}

export default MacrocyclePlanet;
