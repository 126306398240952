// GoalList.jsx
import React, { useState, useEffect } from "react";
import { db } from "../../../services/firebaseConfig"; // Adjust the path
import { collection, getDocs } from "firebase/firestore";
import { evaluateGoal } from "./goalEvaluator";
import { useAuth } from "../../../hooks/useAuth"; // Adjust the path

const GoalList = ({ programId }) => {
  const { user } = useAuth(); // Access the authenticated user
  const [goals, setGoals] = useState([]);
  const [goalStatuses, setGoalStatuses] = useState({});

  useEffect(() => {
    const fetchGoals = async () => {
      if (user) {
        const goalsRef = collection(
          db,
          "users",
          user.uid,
          "programs",
          programId,
          "goals"
        );
        const querySnapshot = await getDocs(goalsRef);
        const goalsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setGoals(goalsData);
      }
    };
    fetchGoals();
  }, [user, programId]);

  useEffect(() => {
    const evaluateGoals = async () => {
      const statuses = {};
      for (const goal of goals) {
        const isAchieved = await evaluateGoal(goal);
        statuses[goal.id] = isAchieved;
      }
      setGoalStatuses(statuses);
    };
    if (goals.length > 0) {
      evaluateGoals();
    }
  }, [goals]);

  return (
    <div className="goal-list">
      <h2>Your Goals</h2>
      <ul>
        {goals.map((goal) => (
          <li key={goal.id}>
            <strong>Goal ID:</strong> {goal.id} - <strong>Status:</strong>{" "}
            {goalStatuses[goal.id] ? "Achieved 🎉" : "In Progress"}
            <ul>
              {goal.conditions.map((cond, index) => (
                <li key={index}>
                  {cond.exercise} {cond.attribute} {cond.operator} {cond.value}
                </li>
              ))}
            </ul>
            <strong>Logic:</strong> {goal.logic}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GoalList;
