// import * as THREE from "three";
// import { toggleTilt } from "../animations/toggleTilt";

// class WeekDay {
//   constructor(id, position) {
//     this.cube = new THREE.Mesh(
//       new THREE.BoxGeometry(1, 1, 1),
//       new THREE.MeshBasicMaterial({ color: new THREE.Color("#B15232") })
//     );
//     this.cube.position.set(position.x, position.y, position.z);
//     this.cube.userData = { id, isTilted: false };

//     // Add click event listener to the WeekDay object
//     this.cube.callback = () => {
//       toggleTilt(this.cube);
//     };
//   }
// }

// export default WeekDay;


import * as THREE from "three";

class WeekDay {
  constructor(id, position, label) {  
    this.cube = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 1),
      new THREE.MeshBasicMaterial({ color: new THREE.Color("#B15232") })
    );
    this.cube.position.set(position.x, position.y, position.z);
    this.cube.userData = { id, label };

    this.addLabelText(label.charAt(0));  // Pass only the first letter of the label
  }

  addLabelText(letter) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const fontSize = 600; // Same font size as DayTile for consistency
    const canvasSize = 512; // Same canvas size as DayTile

    canvas.width = canvasSize;
    canvas.height = canvasSize;

    context.font = `${fontSize}px Arial`;
    context.fillStyle = "white";
    context.textAlign = "center";
    context.textBaseline = "middle";  // Vertically center the text
    context.fillText(letter, canvasSize / 2, canvasSize / 2); // Render the first letter

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;

    const material = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(material);

    const scaleRatio = 0.3; // Same scale ratio as DayTile
    sprite.scale.set(scaleRatio, scaleRatio, scaleRatio);
    sprite.position.set(0, 0.6, 0); // Adjust position if needed

    this.cube.add(sprite);  // Add the sprite to the cube
  }
}

export default WeekDay;
