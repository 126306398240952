// AddCycleModal.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import styled from 'styled-components';
import { useProgram } from '../context/ProgramContext';
import { useAuth } from '../../hooks/useAuth';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #2a2a2a;
  color: #fff;
  width: 90%;
  max-width: 200px;
  margin: 100px auto;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4a4a4a;
  color: #fff;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #616161;
  }

  &:disabled {
    background-color: #333;
    cursor: not-allowed;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #3a3a3a;
  color: #fff;
  border: none;
  border-radius: 4px;
`;

const CloseButton = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  font-size: 20px;
  float: right;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }
`;

const AddCycleModal = ({ isOpen, onClose, programId, programType }) => {
  const { createProgram, importMesocycleToMacrocycle, programs, program } = useProgram();
  const { user } = useAuth();
  const [selectedMesocycle, setSelectedMesocycle] = useState('');

  useEffect(() => {
    if (!isOpen) return; // Exit early if modal is not open

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  // Now place the conditional return
  if (!isOpen) return null;

  const handleAddMesocycle = async () => {
    if (!user || !programId) return;

    try {
      const currentBlocksCount = program.programBlocks?.length || 0;
      const newMesocycleName = `Cycle ${currentBlocksCount + 1}`;

      const newMesocycle = await createProgram(newMesocycleName, programType);

      await importMesocycleToMacrocycle(programId, newMesocycle.id);

      console.log('New Mesocycle added successfully with ID:', newMesocycle.id);
      onClose();
    } catch (error) {
      console.error('Error creating mesocycle:', error);
    }
  };

  const handleImportMesocycle = async () => {
    if (!user || !programId || !selectedMesocycle) return;

    try {
      await importMesocycleToMacrocycle(programId, selectedMesocycle);
      console.log('Mesocycle imported successfully');
      onClose();
    } catch (error) {
      console.error('Error importing mesocycle:', error);
    }
  };

  const mesocycleOptions = programs
    .filter(
      (programItem) =>
        programItem.programType.startsWith('Mesocycle') &&
        programItem.id !== programId &&
        !program?.programBlocks?.includes(programItem.id)
    )
    .map((programItem) => ({
      value: programItem.id,
      label: programItem.name,
    }));

    const modalContent = (
        <ModalOverlay onClick={onClose}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <ModalHeader>Add Cycle</ModalHeader>
            <ModalButton onClick={handleAddMesocycle}>Create New Cycle</ModalButton>
            <Select
              value={selectedMesocycle}
              onChange={(e) => setSelectedMesocycle(e.target.value)}
            >
              <option value="">Import Mesocycle</option>
              {mesocycleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <ModalButton
              onClick={handleImportMesocycle}
              disabled={!selectedMesocycle}
            >
              Import Selected Cycle
            </ModalButton>
          </ModalContainer>
        </ModalOverlay>
      );
    
      return ReactDOM.createPortal(modalContent, document.body);
    };

export default AddCycleModal;
