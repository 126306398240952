// components/profile/ProfileTabBar.js
import React from 'react';
import styled from 'styled-components';

const TabContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const TabList = styled.div`
  display: flex;
  gap: 8px;
`;

const TabButton = styled.button`
  padding: 12px 24px;
  background: ${props => props.active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  border: none;
  border-bottom: 2px solid ${props => props.active ? '#4ecdc4' : 'transparent'};
  color: ${props => props.active ? '#fff' : 'rgba(255, 255, 255, 0.6)'};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.05);
  }
`;

const TabCounter = styled.span`
  margin-left: 8px;
  padding: 2px 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
`;

const ProfileTabBar = ({ activeTab, onTabChange, counts }) => {
  return (
    <TabContainer>
      <TabList>

        <TabButton
          active={activeTab === 'activity'}
          onClick={() => onTabChange('activity')}
        >
          Activity
          {counts?.activities > 0 && <TabCounter>{counts.activities}</TabCounter>}
        </TabButton>
        <TabButton
          active={activeTab === 'photos'}
          onClick={() => onTabChange('photos')}
        >
          Photos
          {counts?.photos > 0 && <TabCounter>{counts.photos}</TabCounter>}
        </TabButton>
      </TabList>
    </TabContainer>
  );
};

export default ProfileTabBar;