import React from 'react';
import styled, { keyframes, css, createGlobalStyle } from 'styled-components';



const deviceA = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    left: 0;
    width: 4em;
    height: 2.5em;
    transform: translateY(0);
  }
  12.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 0;
    width: 4em;
    height: 2.5em;
    transform: translateY(1.5em);
  }
  25% {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    left: 0;
    width: 4em;
    height: 2.5em;
    transform: translateY(0.375em);
  }
  37.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 0;
    width: 4em;
    height: 2.5em;
    transform: translateY(1.5em);
  }
  50% {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    left: 1em;
    width: 2em;
    height: 3em;
    transform: translateY(0.125em);
  }
  62.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 1em;
    width: 2em;
    height: 3em;
    transform: translateY(1em);
  }
  75% {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    left: 1em;
    width: 2em;
    height: 2em;
    transform: translateY(0.625em);
  }
  87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 1em;
    width: 2em;
    height: 2em;
    transform: translateY(1.375em);
  }
`;

const deviceA1 = keyframes`
  from {
    animation-timing-function: steps(1,end);
    left: 1.5em;
    width: 1em;
    transform: translateY(0);
    visibility: hidden;
  }
  12.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 1.5em;
    width: 1em;
    transform: translateY(0);
    visibility: visible;
  }
  25%, 37.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 1.5em;
    width: 1em;
    transform: translateY(-0.5em);
    visibility: visible;
  }
  50%, 62.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 0.875em;
    width: 0.25em;
    transform: translateY(0);
    visibility: visible;
  }
  75%, to {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 0.875em;
    width: 0.25em;
    transform: translateY(-0.5em);
    visibility: hidden;
  }
`;

const deviceA2 = keyframes`
  from {
    animation-timing-function: steps(1,end);
    transform: translate(0,0.375em);
    visibility: hidden;
  }
  62.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translate(0,0.375em);
    visibility: visible;
  }
  75%, 87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translate(0.375em,0);
    visibility: visible;
  }
  to {
    transform: translate(0,0.25em);
    visibility: visible;
  }
`;

const deviceB = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    transform: translateY(0);
    visibility: visible;
  }
  10%, 12.5% {
    animation-timing-function: steps(1,start);
    transform: translateY(0.75em);
    visibility: visible;
  }
  25% {
    animation-timing-function: steps(1,start);
    top: 2.25em;
    left: 1.875em;
    transform: translateY(0.75em);
    visibility: hidden;
  }
  87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translateY(0.75em);
    visibility: hidden;
  }
`;

const deviceC = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    transform: translateY(0);
    visibility: visible;
    width: 2em;
  }
  10%, 12.5% {
    animation-timing-function: steps(1,start);
    transform: translateY(0.75em);
    visibility: visible;
    width: 2em;
  }
  25% {
    animation-timing-function: steps(1,start);
    top: 3em;
    left: 1em;
    width: 2em;
    transform: translateY(0.75em);
    visibility: hidden;
    width: 2em;
  }
  87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    left: 1.5em;
    transform: translateY(0.75em);
    visibility: hidden;
    width: 1em;
  }
`;

const deviceD = keyframes`
  from {
    animation-timing-function: steps(1,end);
    transform: translateY(0.25em);
    visibility: hidden;
  }
  62.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translateY(0.25em);
    visibility: visible;
  }
  75% {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    transform: translateY(-0.75em);
  }
  87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translateY(0);
  }
  to {
    transform: translateY(-0.75em);
  }
`;

const deviceE = keyframes`
  from {
    animation-timing-function: steps(1,end);
    transform: translateY(1.5em);
    visibility: hidden;
  }
  62.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translateY(1.5em);
    visibility: visible;
  }
  75% {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    transform: translateY(0.75em);
  }
  87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    transform: translateY(1.5em);
  }
  to {
    transform: translateY(0);
  }
`;

const deviceF = keyframes`
  from {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    filter: blur(0.375em);
  }
  12.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    filter: blur(0.1875em);
    opacity: 0.5;
  }
  25%, to {
    filter: blur(0.375em);
    opacity: 0;
  }
`;

const deviceG = keyframes`
  from, 12.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    background-color: var(--fg);
    filter: blur(0.1875em);
    opacity: 0;
  }
  25% {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    background-color: var(--fg);
    filter: blur(0.375em);
    opacity: 0.5;
  }
  37.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    filter: blur(0.1875em);
    opacity: 0.5;
    left: 0;
    width: 4em;
  }
  50%, 75%, to {
    animation-timing-function: cubic-bezier(0.65,0,0.35,1);
    filter: blur(0.375em);
    opacity: 0.5;
    left: 1em;
    width: 2em;
  }
  62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.33,1,0.67,1);
    filter: blur(0.1875em);
    opacity: 0.5;
    left: 1em;
    width: 2em;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0A0A0A;  // Very dark, almost black background
  display: grid;
  place-items: center;
  z-index: 9999;
`;



const LoadingWrapper = styled.div`
  --hue: 223;
  --bg: hsl(var(--hue),10%,90%);
  --fg: hsl(var(--hue),10%,10%);
  --trans-dur: 0.3s;
  font-size: calc(28px + (60 - 28) * (100vw - 280px) / (3840 - 280));
  background-color: var(--bg);
  color: var(--fg);
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  @media (prefers-color-scheme: dark) {
    --bg: hsl(var(--hue),10%,10%);
    --fg: hsl(var(--hue),10%,90%);
  }
`;

const commonStyles = css`
  animation-duration: 3s;
  animation-iteration-count: infinite;
  position: absolute;
  transition:
    background-color var(--trans-dur),
    box-shadow var(--trans-dur);
`;

const Device = styled.div`
  position: relative;
  width: 4em;
  height: 4em;
`;

const DeviceA = styled.div`
  ${commonStyles}
  animation-name: ${deviceA};
  background-color: hsl(var(--hue),10%,70%);
  box-shadow: 0 0 0 0.25em inset;
  border-radius: 0.375em;
  top: 0;
  width: 4em;
  height: 2.5em;
  z-index: 1;

  @media (prefers-color-scheme: dark) {
    background-color: hsl(var(--hue),10%,30%);
  }
`;

const DeviceA1 = styled.div`
  ${commonStyles}
  animation-name: ${deviceA1};
  background-color: var(--fg);
  border-radius: 0.125em;
  visibility: hidden;
  top: 2.25em;
  left: 1.5em;
  width: 1em;
  height: 0.25em;
`;

const DeviceA2 = styled.div`
  ${commonStyles}
  animation-name: ${deviceA2};
  background-color: var(--fg);
  border-radius: 0.125em;
  visibility: hidden;
  top: 0.625em;
  right: 0;
  width: 0.25em;
  height: 0.75em;
`;

const DeviceB = styled.div`
  ${commonStyles}
  animation-name: ${deviceB};
  background-color: var(--fg);
  border-radius: 0.125em;
  top: 2.25em;
  left: 1.875em;
  width: 0.25em;
  height: 1em;
`;

const DeviceC = styled.div`
  ${commonStyles}
  animation-name: ${deviceC};
  background-color: var(--fg);
  border-radius: 0.125em;
  top: 3em;
  left: 1em;
  width: 2em;
  height: 0.25em;
`;

const DeviceD = styled.div`
  ${commonStyles}
  animation-name: ${deviceD};
  background-color: hsl(var(--hue),10%,70%);
  box-shadow: 0 0 0 0.25em inset;
  border-radius: 0.375em 0.375em 0 0;
  left: 1.25em;
  top: 0.75em;
  width: 1.5em;
  height: 0.875em;
  visibility: hidden;

  @media (prefers-color-scheme: dark) {
    background-color: hsl(var(--hue),10%,30%);
  }
`;

const DeviceE = styled.div`
  ${commonStyles}
  animation-name: ${deviceE};
  background-color: hsl(var(--hue),10%,70%);
  box-shadow: 0 0 0 0.25em inset;
  border-radius: 0 0 0.375em 0.375em;
  left: 1.25em;
  top: 1.625em;
  width: 1.5em;
  height: 0.875em;
  visibility: hidden;

  @media (prefers-color-scheme: dark) {
    background-color: hsl(var(--hue),10%,30%);
  }
`;

const DeviceF = styled.div`
  ${commonStyles}
  animation-name: ${deviceF};
  background-color: var(--fg);
  border-radius: 0.125em;
  filter: blur(0.375em);
  bottom: 0;
  height: 0.25em;
  opacity: 0.5;
  left: 1em;
  width: 2em;
`;

const DeviceG = styled.div`
  ${commonStyles}
  animation-name: ${deviceG};
  background-color: var(--fg);
  border-radius: 0.125em;
  filter: blur(0.375em);
  bottom: 0;
  height: 0.25em;
  opacity: 0;
  left: 0;
  width: 4em;
`;

const LoadingComponent = () => {
  return (
    <LoadingWrapper>
      <Device>
        <DeviceA>
          <DeviceA1 />
          <DeviceA2 />
        </DeviceA>
        <DeviceB />
        <DeviceC />
        <DeviceD />
        <DeviceE />
        <DeviceF />
        <DeviceG />
      </Device>
    </LoadingWrapper>
  );
};

export default LoadingComponent;