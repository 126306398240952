// import React, { useState, useRef, useEffect } from "react";
// import { useAuth } from "../../hooks/useAuth";
// import { useNavigate } from "react-router-dom";
// import CustomModal from "../CustomModal";
// import styled from "styled-components";
// import { usePayment } from "../context/PaymentContext";
// import { useProgram } from "../context/ProgramContext";
// import SubscriptionModal from "../Subscription/SubscriptionModal";
// import { gsap } from "gsap";
// import { FaChevronLeft, FaChevronRight, FaArrowLeft } from "react-icons/fa";

// // const ModalContent = styled.div`
// //   padding: 20px;

// //   /* Override h2, h3, h4 colors to white within this modal */
// //   h2,
// //   h3,
// //   h4 {
// //     color: white;
// //   }
// // `;

// const ModalContent = styled.div`
//   // padding: 20px;
//   max-height: 90vh;
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
//   overscroll-behavior: contain;

//   /* Prevent text selection inside the modal, consistent with global style */
//   user-select: none;

//   /* Ensure smooth scrolling on iOS */
//   -webkit-overflow-scrolling: touch;

//   h2, h3, h4 {
//     color: white;
//   }

//   /* Improve touch targets for better mobile interaction */
//   button, input, select {
//     min-height: 20px;
//     min-width: 44px;
//     display: hidden;
//   }

//   /* Prevent any additional zoom behaviors */
//   touch-action: pan-x pan-y;
// `;

// const Title = styled.h2`
//   margin-bottom: 20px;
//   font-size: 1.5rem;
//   display: flex;
//   justify-content: center;
// `;

// const Label = styled.label`
//   display: block;
//   margin-bottom: 10px;
// `;

// const Input = styled.input`
//   width: auto;
//   padding: 8px;
//   margin-top: 5px;
//   margin-bottom: 20px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// // Wrap the carousel and program information side by side
// const ModalMainContent = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
// `;

// const CarouselContainer = styled.div`
//   width: ${({ isPushed }) =>
//     isPushed ? "30%" : "100%"}; /* Shrink when program info is displayed */
//   height: 300px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 20px;
//   overflow: hidden;
//   transition: width 0.5s ease; /* Smooth shrinking of the carousel */
// `;

// const CarouselWrapper = styled.div`
//   position: relative;
//   width: 400px;
//   height: 300px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transform-style: preserve-3d;
//   perspective: 1000px;
// `;

// const ProgramTypeCard = styled.div`
//   position: absolute;
//   width: 105px;
//   height: 105px;
//   // background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
//     background: linear-gradient(135deg, #6e8efb, #a777e3);
//     transition: background-color 0.3s;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   border: 1px solid;
//   border-radius: 10px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   padding: 20px;
//   text-align: center;
//   transition: all 0.5s ease;
//   backface-visibility: hidden;
//   transform-style: preserve-3d;
//   color: white;
//   font-size: 0.8rem;

//   transition: background-color 0.3s;

//   background-color: ${({ selected }) => (selected ? "#e7f1ff" : "white")};
//   border-color: ${({ selected }) => (selected ? "#007bff" : "transparent")};
// `;

// const NavButton = styled.button`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background: transparent;
//   border: none;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   z-index: 10;

//   /* Disable pointer events when hidden */
//   pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

//   &:hover {
//     // background: rgba(255, 255, 255, 0.9);
//     color: #333;
//   }
// `;

// const LeftNavButton = styled(NavButton)`
//   left: 20px;
// `;

// const RightNavButton = styled(NavButton)`
//   right: 20px;
// `;

// const ErrorMessage = styled.div`
//   color: red;
//   margin-top: 1rem;
// `;

// // New section for displaying program information
// const ProgramInfoPanel = styled.div`
//   width: 50%;
//   padding: 20px;
//   border-radius: 10px;
//   border: 5px solid #ccc;
//   color: white;
//   background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
//   box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
//   transition: opacity 0.5s ease;
//   opacity: ${({ isVisible }) => (isVisible ? "1" : "0")}; /* Smoothly fade in */
// `;

// const Button = styled.button`
//   padding: 10px 20px;
//   background-color: #314f8c;
//   color: white;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// const BackButton = styled.button`
//   position: absolute;
//   padding: 0px;
//   // top: 10px;
//   // left: 10px;
//   background: transparent;
//   border: none;
//   cursor: pointer;
//   color: white;
//   font-size: 1.5rem;
//   z-index: 10;
// `;

// const ProgramCreationModal = ({ show, handleClose }) => {
//   const { user } = useAuth();
//   const navigate = useNavigate();
//   const [programName, setProgramName] = useState("");
//   const [programType, setProgramType] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
//   const { subscriptionStatus } = usePayment();
//   const carouselRef = useRef(null);
//   const cardsRef = useRef([]);
//   const leftNavRef = useRef(null);
//   const rightNavRef = useRef(null);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [selectedCard, setSelectedCard] = useState(null);
//   const programInfoRef = useRef(null);
//   const { createProgram } = useProgram();

//   const programTypes = [
//     { type: "Mesocycle Basic", description: "" },
//     { type: "Mesocycle Advanced", description: "" },
//     { type: "Macrocycle", description: "" },
//   ];

//   useEffect(() => {
//     if (carouselRef.current) {
//       const cards = cardsRef.current;

//       const positionCards = () => {
//         const radius = 180;
//         const angleStep = (2 * Math.PI) / programTypes.length;

//         cards.forEach((card, index) => {
//           const angle = angleStep * index - currentIndex * angleStep;
//           const x = Math.sin(angle) * radius;
//           const z = Math.cos(angle) * radius - radius;

//           let scale = 0.8;
//           let opacity = 0.8;

//           if (index === currentIndex) {
//             scale = 1;
//             opacity = 1;
//           }

//           gsap.to(card, {
//             x,
//             z,
//             scale,
//             opacity,
//             rotationY: 0, // Keep all cards facing forward
//             duration: 0.5,
//           });
//         });
//       };

//       positionCards();
//     }
//   }, [currentIndex, programTypes.length]);

//   const rotateCarousel = (direction) => {
//     const newIndex =
//       (currentIndex + direction + programTypes.length) % programTypes.length;
//     setCurrentIndex(newIndex);
//     // handleProgramTypeSelect(programTypes[newIndex].type, newIndex);
//   };

//   const canAccessProgram = (type) => {
//     const userTier = user.membershipTier ? user.membershipTier.toLowerCase() : "";

//     if (userTier === "trainer" || userTier === "god") {
//       return true;
//     }
//     if (type === "Mesocycle Basic") {
//       return true;
//     }
//     return subscriptionStatus === "subscribed";
//   };

//   const handleProgramTypeSelect = (type, index) => {
//     if (canAccessProgram(type)) {
//       // setProgramType(type);
//       // setSelectedCard(programTypes[index]);
//       setCurrentIndex(index);
//       setErrorMessage("");
//     } else {
//       setShowSubscriptionModal(true);
//     }
//   };

//   const handleCardSelect = (card, index) => {
//     setProgramType(card.type);
//     const timeline = gsap.timeline({
//       onComplete: () => {
//         setSelectedCard(card); // Set the selected card
//       },
//     });

//     // Animate out other cards and reduce the size of the selected card
//     cardsRef.current.forEach((cardEl, i) => {
//       if (i !== index) {
//         timeline.to(
//           cardEl,
//           {
//             opacity: 0,
//             scale: 0,
//             duration: 0.5,
//           },
//           0
//         ); // Fade out other cards
//       }
//     });

//     // Hide navigation buttons and disable them
//     timeline.to(
//       [leftNavRef.current, rightNavRef.current],
//       {
//         opacity: 0,
//         duration: 0.5,
//         onStart: () => {
//           leftNavRef.current.disabled = true;
//           rightNavRef.current.disabled = true;
//         },
//       },
//       0
//     );

//     // Animate selected card size reduction
//     const selectedCardEl = cardsRef.current[index];
//     timeline.to(
//       selectedCardEl,
//       {
//         scale: 0.7, // Reduce the size of the selected card
//         duration: 0.5,
//       },
//       0.2
//     ); // Start resizing slightly after fading other elements

//     // Show the program info panel using the ref
//     timeline.to(
//       programInfoRef.current,
//       {
//         opacity: 1,
//         duration: 0.5,
//       },
//       "-=0.3"
//     ); // Overlap the information panel animation with the carousel
//   };

//   const handleFinish = async () => {
//     if (!programName.trim() || !programType) {
//       setErrorMessage("Please fill out all fields.");
//       return;
//     }

//     try {
//       const newProgram = await createProgram(programName, programType);
//       handleClose();
//       navigate(`/program/${newProgram.id}`);
//     } catch (error) {
//       console.error("Error creating program:", error);
//       setErrorMessage("An error occurred while creating the program.");
//     }
//   };

//   const handleBack = () => {
//     const timeline = gsap.timeline({
//       onComplete: () => {
//         setSelectedCard(null); // Clear the selected card after the animation completes
//       },
//     });

//     // Hide the program info panel
//     timeline.to(
//       programInfoRef.current,
//       {
//         opacity: 0,
//         duration: 0.3,
//       },
//       0
//     );

//     // Bring back the other cards and navigation buttons
//     cardsRef.current.forEach((cardEl) => {
//       timeline.to(
//         cardEl,
//         {
//           opacity: 1,
//           scale: 1,
//           duration: 0.3,
//         },
//         0.3
//       ); // Delay this to let the program info panel fade out first
//     });

//     timeline.to(
//       [leftNavRef.current, rightNavRef.current],
//       {
//         opacity: 1,
//         duration: 0.3,
//         onStart: () => {
//           leftNavRef.current.disabled = false;
//           rightNavRef.current.disabled = false;
//         },
//       },
//       0.3
//     ); // Bring back the navigation buttons
//   };

//   return (
//     <>
//       <CustomModal show={show} handleClose={handleClose}>
//         <ModalContent>
//           <Title>Create Your Program</Title>

//           <ModalMainContent>
//             <CarouselContainer ref={carouselRef} isPushed={!!selectedCard}>
//               <LeftNavButton
//                 ref={leftNavRef}
//                 onClick={() => rotateCarousel(-1)}
//               >
//                 <FaChevronLeft />
//               </LeftNavButton>
//               <CarouselWrapper>
//                 {programTypes.map((type, index) => (
//                   <ProgramTypeCard
//                     key={type.type}
//                     ref={(el) => (cardsRef.current[index] = el)}
//                     selected={index === currentIndex}
//                     onClick={() => handleProgramTypeSelect(type.type, index)}
//                   >
//                     <h3>{type.type}</h3>
//                     <p>{type.description}</p>
//                     {!canAccessProgram(type.type) && (
//                       <p style={{ color: "red" }}>Requires subscription</p>
//                     )}
//                     <button
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCardSelect(type, index); // Handle card selection
//                       }}
//                     >
//                       Select
//                     </button>
//                   </ProgramTypeCard>
//                 ))}
//               </CarouselWrapper>
//               <RightNavButton
//                 ref={rightNavRef}
//                 onClick={() => rotateCarousel(1)}
//               >
//                 <FaChevronRight />
//               </RightNavButton>
//             </CarouselContainer>

//             {/* Program information panel */}
//             {selectedCard && (
//               // In the ProgramInfoPanel, add a back button
//               <ProgramInfoPanel
//                 ref={programInfoRef}
//                 className="program-info"
//                 isVisible={!!selectedCard}
//               >
//                 <BackButton onClick={handleBack}>
//                   <FaArrowLeft />
//                 </BackButton>
//                 <h2>{selectedCard.type} Program</h2>
//                 <p>{selectedCard.description}</p>

//                 <Label>
//                   Name Your Program:
//                   <Input
//                     type="text"
//                     value={programName}
//                     onChange={(e) => setProgramName(e.target.value)}
//                   />
//                 </Label>

//                 <Button onClick={handleFinish}>Finish</Button>

//                 {/* Back button to return to carousel */}
//                 {/* <Button onClick={handleBack}>Back</Button> */}
//               </ProgramInfoPanel>
//             )}
//           </ModalMainContent>

//           {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

//         </ModalContent>
//       </CustomModal>

//       <SubscriptionModal
//         isOpen={showSubscriptionModal}
//         onClose={() => setShowSubscriptionModal(false)}
//         productInfo={{
//           title: "Unlock Premium Programs",
//           description: "Subscribe to access Mesocycle and Macrocycle programs.",
//           priceId: "price_12345",
//         }}
//         ctaMessage="Unlock access to advanced programs and more!"
//       />
//     </>
//   );
// };

// export default ProgramCreationModal;

// // ##################Updating macrocycle creation#####################

import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import styled from "styled-components";
import { useProgram } from "../context/ProgramContext";
import { gsap } from "gsap";
import { FaChevronLeft, FaChevronRight, FaArrowLeft } from "react-icons/fa";

// const ModalContent = styled.div`
//   padding: 20px;

//   /* Override h2, h3, h4 colors to white within this modal */
//   h2,
//   h3,
//   h4 {
//     color: white;
//   }
// `;

const ModalContent = styled.div`
  // padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;

  /* Prevent text selection inside the modal, consistent with global style */
  user-select: none;

  /* Ensure smooth scrolling on iOS */
  -webkit-overflow-scrolling: touch;

  h2,
  h3,
  h4 {
    color: white;
  }

  /* Improve touch targets for better mobile interaction */
  button,
  input,
  select {
    min-height: 20px;
    min-width: 44px;
    display: hidden;
  }

  /* Prevent any additional zoom behaviors */
  touch-action: pan-x pan-y;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: auto;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// Wrap the carousel and program information side by side
const ModalMainContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CarouselContainer = styled.div`
  width: ${({ isPushed }) =>
    isPushed ? "30%" : "100%"}; /* Shrink when program info is displayed */
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
  transition: width 0.5s ease; /* Smooth shrinking of the carousel */
`;

const CarouselWrapper = styled.div`
  position: relative;
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 1000px;
`;

const ProgramTypeCard = styled.div`
  position: absolute;
  width: 105px;
  height: 105px;
  // background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  transition: all 0.5s ease;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  color: white;
  font-size: 0.8rem;

  transition: background-color 0.3s;

  background-color: ${({ selected }) => (selected ? "#e7f1ff" : "white")};
  border-color: ${({ selected }) => (selected ? "#007bff" : "transparent")};
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  /* Disable pointer events when hidden */
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    // background: rgba(255, 255, 255, 0.9);
    color: #333;
  }
`;

const LeftNavButton = styled(NavButton)`
  left: 20px;
`;

const RightNavButton = styled(NavButton)`
  right: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 1rem;
`;

// New section for displaying program information
const ProgramInfoPanel = styled.div`
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  border: 5px solid #ccc;
  color: white;
  background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: opacity 0.5s ease;
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")}; /* Smoothly fade in */
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #314f8c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const BackButton = styled.button`
  position: absolute;
  padding: 0px;
  // top: 10px;
  // left: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  z-index: 10;
`;

const ProgramCreationModal = ({ show, handleClose }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [programName, setProgramName] = useState("");
  const [programType, setProgramType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const carouselRef = useRef(null);
  const cardsRef = useRef([]);
  const leftNavRef = useRef(null);
  const rightNavRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const programInfoRef = useRef(null);
  const { createProgram } = useProgram();

  const programTypes = [
    { type: "Mesocycle Basic", description: "" },
    { type: "Mesocycle Advanced", description: "" },
    { type: "Macrocycle", description: "" },
  ];

  useEffect(() => {
    if (carouselRef.current) {
      const cards = cardsRef.current;

      const positionCards = () => {
        const radius = 180;
        const angleStep = (2 * Math.PI) / programTypes.length;

        cards.forEach((card, index) => {
          const angle = angleStep * index - currentIndex * angleStep;
          const x = Math.sin(angle) * radius;
          const z = Math.cos(angle) * radius - radius;

          let scale = 0.8;
          let opacity = 0.8;

          if (index === currentIndex) {
            scale = 1;
            opacity = 1;
          }

          gsap.to(card, {
            x,
            z,
            scale,
            opacity,
            rotationY: 0, // Keep all cards facing forward
            duration: 0.5,
          });
        });
      };

      positionCards();
    }
  }, [currentIndex, programTypes.length]);

  const rotateCarousel = (direction) => {
    const newIndex =
      (currentIndex + direction + programTypes.length) % programTypes.length;
    setCurrentIndex(newIndex);
    // handleProgramTypeSelect(programTypes[newIndex].type, newIndex);
  };

  const handleProgramTypeSelect = (type, index) => {
    // setProgramType(type);
    // setSelectedCard(programTypes[index]);
    setCurrentIndex(index);
    setErrorMessage("");
  };

  const handleCardSelect = (card, index) => {
    setProgramType(card.type);
    const timeline = gsap.timeline({
      onComplete: () => {
        setSelectedCard(card); // Set the selected card
      },
    });

    // Animate out other cards and reduce the size of the selected card
    cardsRef.current.forEach((cardEl, i) => {
      if (i !== index) {
        timeline.to(
          cardEl,
          {
            opacity: 0,
            scale: 0,
            duration: 0.5,
          },
          0
        ); // Fade out other cards
      }
    });

    // Hide navigation buttons and disable them
    timeline.to(
      [leftNavRef.current, rightNavRef.current],
      {
        opacity: 0,
        duration: 0.5,
        onStart: () => {
          leftNavRef.current.disabled = true;
          rightNavRef.current.disabled = true;
        },
      },
      0
    );

    // Animate selected card size reduction
    const selectedCardEl = cardsRef.current[index];
    timeline.to(
      selectedCardEl,
      {
        scale: 0.7, // Reduce the size of the selected card
        duration: 0.5,
      },
      0.2
    ); // Start resizing slightly after fading other elements

    // Show the program info panel using the ref
    timeline.to(
      programInfoRef.current,
      {
        opacity: 1,
        duration: 0.5,
      },
      "-=0.3"
    ); // Overlap the information panel animation with the carousel
  };

  const handleFinish = async () => {
    if (!programName.trim() || !programType) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    try {
      const newProgram = await createProgram(programName, programType);
      handleClose();
      navigate(`/program/${newProgram.id}`);
    } catch (error) {
      console.error("Error creating program:", error);
      setErrorMessage("An error occurred while creating the program.");
    }
  };

  const handleBack = () => {
    const timeline = gsap.timeline({
      onComplete: () => {
        setSelectedCard(null); // Clear the selected card after the animation completes
      },
    });

    // Hide the program info panel
    timeline.to(
      programInfoRef.current,
      {
        opacity: 0,
        duration: 0.3,
      },
      0
    );

    // Bring back the other cards and navigation buttons
    cardsRef.current.forEach((cardEl) => {
      timeline.to(
        cardEl,
        {
          opacity: 1,
          scale: 1,
          duration: 0.3,
        },
        0.3
      ); // Delay this to let the program info panel fade out first
    });

    timeline.to(
      [leftNavRef.current, rightNavRef.current],
      {
        opacity: 1,
        duration: 0.3,
        onStart: () => {
          leftNavRef.current.disabled = false;
          rightNavRef.current.disabled = false;
        },
      },
      0.3
    ); // Bring back the navigation buttons
  };

  return (
    <>
      <CustomModal show={show} handleClose={handleClose}>
        <ModalContent>
          <Title>Create Your Program</Title>

          <ModalMainContent>
            <CarouselContainer ref={carouselRef} isPushed={!!selectedCard}>
              <LeftNavButton
                ref={leftNavRef}
                onClick={() => rotateCarousel(-1)}
              >
                <FaChevronLeft />
              </LeftNavButton>
              <CarouselWrapper>
                {programTypes.map((type, index) => (
                  <ProgramTypeCard
                    key={type.type}
                    ref={(el) => (cardsRef.current[index] = el)}
                    selected={index === currentIndex}
                    onClick={() => handleProgramTypeSelect(type.type, index)}
                  >
                    <h3>{type.type}</h3>
                    <p>{type.description}</p>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCardSelect(type, index); // Handle card selection
                      }}
                    >
                      Select
                    </button>
                  </ProgramTypeCard>
                ))}
              </CarouselWrapper>
              <RightNavButton
                ref={rightNavRef}
                onClick={() => rotateCarousel(1)}
              >
                <FaChevronRight />
              </RightNavButton>
            </CarouselContainer>

            {/* Program information panel */}
            {selectedCard && (
              // In the ProgramInfoPanel, add a back button
              <ProgramInfoPanel
                ref={programInfoRef}
                className="program-info"
                isVisible={!!selectedCard}
              >
                <BackButton onClick={handleBack}>
                  <FaArrowLeft />
                </BackButton>
                <h2>{selectedCard.type} Program</h2>
                <p>{selectedCard.description}</p>

                <Label>
                  Name Your Program:
                  <Input
                    type="text"
                    value={programName}
                    onChange={(e) => setProgramName(e.target.value)}
                  />
                </Label>

                <Button onClick={handleFinish}>Finish</Button>

                {/* Back button to return to carousel */}
                {/* <Button onClick={handleBack}>Back</Button> */}
              </ProgramInfoPanel>
            )}
          </ModalMainContent>

          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </ModalContent>
      </CustomModal>
    </>
  );
};

export default ProgramCreationModal;
