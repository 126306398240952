import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaInstagram, FaSpinner, FaTimes, FaPlus, FaEdit, FaExclamationCircle  } from 'react-icons/fa';
import Modal from '../Modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ConnectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  background: #E1306C;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #c13584;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
`;

const PhotoCard = styled.div`
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  cursor: ${props => props.isSelectable ? 'pointer' : 'default'};

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  ${props => props.isSelected && `
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(78, 205, 196, 0.3);
      border: 3px solid #4ecdc4;
    }
  `}
`;

const PhotoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${PhotoCard}:hover & {
    transform: scale(1.05);
  }
`;

const PhotoOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: rgba(255, 0, 0, 0.9);
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.6);
`;

const LoadingSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  
  svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const AddPhotoCard = styled(PhotoCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.1);
  }
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  ${props => props.primary ? `
    background: #4ecdc4;
    color: white;
    
    &:hover {
      background: #45b7aa;
    }
  ` : `
    background: rgba(255, 255, 255, 0.1);
    color: white;
    
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  `}
`;

const SelectionCount = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 16px;
  border-radius: 6px;
  color: white;
`;


const LimitWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(255, 107, 107, 0.1);
  border-radius: 6px;
  color: #ff6b6b;
  font-size: 14px;
  margin-bottom: 16px;

  svg {
    flex-shrink: 0;
  }
`;

const SelectionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${props => props.isAtLimit ? '#ff6b6b' : 'white'};
`;

const PhotosSection = ({ isCurrentUser }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isSelectingFromIG, setIsSelectingFromIG] = useState(false);
  const [instagramPhotos, setInstagramPhotos] = useState([]);

  const PHOTO_LIMIT = 10;

  const handleConnectInstagram = async () => {
    setIsLoading(true);
    try {
      // Instagram OAuth implementation would go here
      setIsConnected(true);
      // Simulate fetching Instagram photos
      const mockInstagramPhotos = Array.from({ length: 20 }, (_, i) => ({
        id: `ig-${i}`,
        url: `/api/placeholder/400/400`,
        caption: `Instagram photo ${i}`
      }));
      setInstagramPhotos(mockInstagramPhotos);
    } catch (error) {
      console.error('Error connecting to Instagram:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhotoSelect = (photo) => {
    setSelectedPhotos(prev => {
      const isCurrentlySelected = prev.includes(photo.id);
      
      // If photo is already selected, allow deselection
      if (isCurrentlySelected) {
        return prev.filter(id => id !== photo.id);
      }
      
      // If trying to select new photo but already at limit, don't allow
      if (prev.length >= PHOTO_LIMIT) {
        return prev;
      }
      
      return [...prev, photo.id];
    });
  };

  const handleSaveSelection = async () => {
    if (selectedPhotos.length > PHOTO_LIMIT) {
      return; // Should never happen due to UI restrictions
    }

    setIsLoading(true);
    try {
      const selectedImages = instagramPhotos.filter(photo => 
        selectedPhotos.includes(photo.id)
      );
      setPhotos(selectedImages);
      setIsSelectingFromIG(false);
      setSelectedPhotos([]);
      // API call to save selected photos would go here
    } catch (error) {
      console.error('Error saving photos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePhoto = async (photoId) => {
    setIsLoading(true);
    try {
      // API call to remove photo would go here
      setPhotos(prev => prev.filter(photo => photo.id !== photoId));
    } catch (error) {
      console.error('Error removing photo:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Don't allow entering selection mode if already at limit
  const handleAddPhotosClick = () => {
    if (photos.length >= PHOTO_LIMIT) {
      return;
    }
    setIsSelectingFromIG(true);
  };

  if (isLoading) {
    return (
      <LoadingSpinner>
        <FaSpinner size={24} />
      </LoadingSpinner>
    );
  }

  if (!isConnected && isCurrentUser) {
    return (
      <EmptyState>
        <p>Connect your Instagram account to share your photos</p>
        <ConnectButton onClick={handleConnectInstagram}>
          <FaInstagram size={20} />
          Connect Instagram
        </ConnectButton>
      </EmptyState>
    );
  }

  if (isSelectingFromIG) {
    const remainingSlots = PHOTO_LIMIT - photos.length;
    const isAtLimit = selectedPhotos.length >= remainingSlots;

    return (
      <Container>
        <ActionBar>
          <SelectionInfo isAtLimit={isAtLimit}>
            <span>
              {selectedPhotos.length}/{remainingSlots} photos selected
            </span>
            {isAtLimit && (
              <FaExclamationCircle size={16} />
            )}
          </SelectionInfo>
          <Button onClick={() => {
            setIsSelectingFromIG(false);
            setSelectedPhotos([]);
          }}>
            Cancel
          </Button>
          <Button 
            primary 
            onClick={handleSaveSelection}
            disabled={selectedPhotos.length === 0}
          >
            Save Selection
          </Button>
        </ActionBar>

        {isAtLimit && (
          <LimitWarning>
            <FaExclamationCircle />
            <span>Maximum photo limit reached ({PHOTO_LIMIT} photos total)</span>
          </LimitWarning>
        )}

        <PhotoGrid>
          {instagramPhotos.map(photo => (
            <PhotoCard 
              key={photo.id}
              isSelectable={true}
              isSelected={selectedPhotos.includes(photo.id)}
              onClick={() => handlePhotoSelect(photo)}
              style={{ 
                opacity: isAtLimit && !selectedPhotos.includes(photo.id) ? 0.5 : 1,
                cursor: isAtLimit && !selectedPhotos.includes(photo.id) ? 'not-allowed' : 'pointer'
              }}
            >
              <PhotoImage src={photo.url} alt={photo.caption} />
            </PhotoCard>
          ))}
        </PhotoGrid>
      </Container>
    );
  }

  if (!photos.length) {
    return (
      <EmptyState>
        {isCurrentUser ? (
          <>
            <p>Start by adding some photos (maximum {PHOTO_LIMIT})</p>
            <Button primary onClick={handleAddPhotosClick}>
              <FaPlus size={16} />
              Add Photos
            </Button>
          </>
        ) : (
          <p>No photos to display</p>
        )}
      </EmptyState>
    );
  }

  return (
    <Container>
      {isCurrentUser && (
        <>
          {photos.length >= PHOTO_LIMIT && (
            <LimitWarning>
              <FaExclamationCircle />
              <span>Maximum photo limit reached ({PHOTO_LIMIT} photos)</span>
            </LimitWarning>
          )}
          <ActionBar>
            {isSelecting ? (
              <>
                <Button onClick={() => {
                  setIsSelecting(false);
                  setSelectedPhotos([]);
                }}>
                  Cancel
                </Button>
                <Button 
                  primary 
                  onClick={() => {
                    // Handle batch delete
                    const remainingPhotos = photos.filter(
                      photo => !selectedPhotos.includes(photo.id)
                    );
                    setPhotos(remainingPhotos);
                    setIsSelecting(false);
                    setSelectedPhotos([]);
                  }}
                  disabled={selectedPhotos.length === 0}
                >
                  Delete Selected ({selectedPhotos.length})
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => setIsSelecting(true)}>
                  <FaEdit size={16} />
                  Edit
                </Button>
                {photos.length < PHOTO_LIMIT && (
                  <Button primary onClick={handleAddPhotosClick}>
                    <FaPlus size={16} />
                    Add Photos ({PHOTO_LIMIT - photos.length} remaining)
                  </Button>
                )}
              </>
            )}
          </ActionBar>
        </>
      )}
      <PhotoGrid>
        {photos.map(photo => (
          <PhotoCard 
            key={photo.id}
            isSelectable={isSelecting}
            isSelected={selectedPhotos.includes(photo.id)}
            onClick={() => isSelecting && handlePhotoSelect(photo)}
          >
            <PhotoImage src={photo.url} alt={photo.caption} />
            {isCurrentUser && !isSelecting && (
              <RemoveButton onClick={(e) => {
                e.stopPropagation();
                handleRemovePhoto(photo.id);
              }}>
                <FaTimes size={12} />
              </RemoveButton>
            )}
          </PhotoCard>
        ))}
      </PhotoGrid>
    </Container>
  );
};

export default PhotosSection;