// import React, { useState, useEffect, useCallback, useRef } from "react";
// import styled from "styled-components";
// import {
//   FaPlus,
//   FaDumbbell,
//   FaRunning,
//   FaTimesCircle,
//   FaPercent,
//   FaCaretDown,
// } from "react-icons/fa";
// import { useUserExercises } from "../../context/UserExercisesContext";

// const InputWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 5px;
//   width: 100%;
//   touch-action: none; // Prevent scrolling while dragging on touch devices
// `;

// const InputFieldWrapper = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   padding: ${({ $isEditing }) => ($isEditing ? "5px" : "0")};
//   background-color: ${({ $isEditing }) =>
//     $isEditing ? "transparent" : "transparent"};
//   color: ${({ $exerciseType }) => {
//     switch ($exerciseType) {
//       case "single":
//         return "#4caf50";
//       case "super":
//         return "#2196f3";
//       case "circuit":
//         return "#ffeb3b";
//       default:
//         return "#ccc";
//     }
//   }};
//   border-radius: 4px;
//   cursor: move; // Indicate that the entire item is draggable
// `;

// const InputField = styled.input`
//   padding: ${({ $isEditing }) => ($isEditing ? "5px" : "0")};
//   width: 100%;
//   background-color: transparent;
//   border: none;
//   outline: none;
//   font-size: 0.8em;
//   color: inherit;
//   // z-index: 1;
//   cursor: ${({ $isEditing }) => ($isEditing ? "text" : "pointer")};

//   @media (max-width: 768px) {
//     font-size: 0.6em;
//   }
// `;

// const AdditionalFieldsWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const AdditionalInputField = styled.input`
//   width: ${({ $isCardio }) => ($isCardio ? "50px" : "35px")};
//   padding: 1px;
//   background-color: transparent;
//   border: none;
//   border-bottom: ${({ $isEditing }) => ($isEditing ? "none" : "none")};
//   text-align: center;
//   font-size: 0.6em;
//   color: inherit;
// `;

// const WeightToggle = styled.button`
//   padding: 3px 1px;
//   background-color: transparent;
//   border: none;
//   border-bottom: ${({ $isEditing }) => ($isEditing ? "none" : "none")};
//   color: inherit;
//   cursor: pointer;
//   font-size: 0.6em;

//   &:hover {
//     background: none;
//   }
// `;

// const Suggestion = styled.div`
//   position: absolute;
//   top: 48%;
//   left: 42px;
//   transform: translateY(-50%);
//   opacity: 0.4;
//   cursor: pointer;

//   @media (max-width: 768px) {
//     font-size: 0.6em;
//   }
// `;

// const AddExerciseButton = styled.button`
//   background-color: #3a3a3a;
//   border: none;
//   color: #fff;
//   padding: 5px;
//   cursor: pointer;
//   margin-left: 5px;
//   display: flex;
//   align-items: center;
//   font-size: 0.5em;

//   &:hover {
//     color: #4caf50;
//     background-color: #4a4a4a;
//   }
// `;

// const ToggleButton = styled.button`
//   background-color: transparent;
//   border: none;
//   color: inherit;
//   cursor: pointer;
//   padding: 5px;
//   margin-right: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const SelectField = styled.select`
//   background-color: transparent;
//   border: none;
//   color: inherit;
//   font-size: 0.7em;
//   margin-left: 2px;
// `;

// const CompactInput = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: #3a3a3a;
//   border-radius: 4px;
//   overflow: hidden;
//   margin-right: 4px;
// `;

// const CompactInputField = styled.input`
//   width: 24px;
//   border: none;
//   background: transparent;
//   padding: 2px 4px;
//   font-size: 10px;
//   text-align: right;
//   color: inherit;
// `;

// const Divider = styled.div`
//   width: 1px;
//   height: 16px;
//   background-color: #ccc;
// `;

// const CompactSelect = styled.select`
//   border: none;
//   background: transparent;
//   padding: 2px 4px;
//   font-size: 10px;
//   appearance: none;
//   cursor: pointer;
//   color: inherit;
// `;

// const StaticText = styled.span`
//   padding: 0px 4px;
//   font-size: 10px;
//   color: inherit;
// `;

// const ExpandableBorder = styled.div`
//   height: 3px;
//   background-image: ${({ $hasMultipleSets }) =>
//     $hasMultipleSets
//       ? "linear-gradient(90deg, #9100ff 0%, #ff00ec 33%, #ffc800 66%, #9100ff 100%)"
//       : "none"};
//   background-size: 300% 3px;
//   background-repeat: no-repeat;
//   background-position: 0% 100%;
//   animation: ${({ $hasMultipleSets }) =>
//     $hasMultipleSets ? "animateGradient 5s linear infinite" : "none"};
//   cursor: ${({ $hasMultipleSets }) =>
//     $hasMultipleSets ? "pointer" : "default"};
//   transition: all 0.3s ease;

//   @keyframes animateGradient {
//     0% {
//       background-position: 0% 100%;
//     }
//     100% {
//       background-position: 100% 100%;
//     }
//   }

//   @keyframes animateHoverGradient {
//     0% {
//       background-position: 100% 0%;
//     }
//     100% {
//       background-position: 0% 0%;
//     }
//   }

//   &:hover {
//     animation: ${({ $hasMultipleSets }) =>
//       $hasMultipleSets ? "animateHoverGradient 2s linear infinite" : "none"};
//     background-image: ${({ $hasMultipleSets }) =>
//       $hasMultipleSets
//         ? "linear-gradient(43deg, #2c2c2c 0%, #3a3a3a 50%, #2c2c2c 100%)"
//         : "none"};
//     background-size: 200% 3px;
//   }
// `;

// const ExpandedRowsWrapper = styled.div`
//   margin-left: 20px;
//   // border-left: 1px solid #ccc;
// `;

// const ExpandedRow = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 5px 0;
//   justify-content: flex-end;
//   color: ${({ $exerciseType }) => {
//     switch ($exerciseType) {
//       case "single":
//         return "#4caf50";
//       case "super":
//         return "#2196f3";
//       case "circuit":
//         return "#ffeb3b";
//       default:
//         return "#ccc";
//     }
//   }};
// `;

// const DeleteButton = styled.button`
//   background-color: transparent;
//   border: none;
//   color: #ff0000;
//   cursor: pointer;
//   padding: 5px;
//   margin-right: 10px;

//   &:hover {
//     background: none;
//   }
// `;

// const ExpandedAdditionalFieldsWrapper = styled(AdditionalFieldsWrapper)`
//   justify-content: flex-end;
// `;

// // const VolumeDisplay = styled.span`
// //   font-size: 0.8em;
// //   color: #888;
// //   margin-left: 5px;
// // `;

// const PercentageButton = styled(AddExerciseButton)`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 30px;
//   position: relative;
// `;

// const PercentageDropdown = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   background-color: #3a3a3a;
//   border: 1px solid #4a4a4a;
//   border-radius: 4px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
//   z-index: 10;
//   display: ${({ isOpen }) => (isOpen ? "block" : "none")};
// `;

// const PercentageOption = styled.div`
//   padding: 5px 10px;
//   cursor: pointer;
//   &:hover {
//     background-color: #4a4a4a;
//   }
// `;

// const AddSetButton = styled.button`
//   background-color: #3a3a3a;
//   border: none;
//   color: #fff;
//   padding: 5px 10px;
//   margin-top: 10px;
//   margin-left: auto; // This will push the button to the right
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 0.6em;
//   border-radius: 4px;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #4a4a4a;
//   }

//   svg {
//     margin-right: 5px;
//   }
// `;

// const ExerciseInput = ({
//   exercise,
//   onUpdate,
//   isPublished,
//   isCompleted,
//   isExpanded,
//   onToggleExpand,
// }) => {
//   const { exercises: userExercises, addExercise } = useUserExercises();
//   const [inputValue, setInputValue] = useState(exercise.value || "");
//   const [isCardio, setIsCardio] = useState(exercise.isCardio || false);

//   // Weight exercise states
//   const [sets, setSets] = useState(exercise.goal?.sets?.toString() || "");
//   const [reps, setReps] = useState(exercise.goal?.reps?.toString() || "");
//   const [weight, setWeight] = useState(exercise.goal?.weight?.toString() || "");
//   const [goalSets, setGoalSets] = useState(
//     exercise.goal?.sets || [{ reps: "", weight: "" }]
//   );
//   const [performedSets, setPerformedSets] = useState(
//     exercise.performed?.sets || [{ reps: "", weight: "" }]
//   );

//   const [weightUnit, setWeightUnit] = useState(
//     exercise.goal?.weightUnit || "lbs"
//   );
//   // const [isExpanded, setIsExpanded] = useState(false);
//   const [expandedSets, setExpandedSets] = useState([]);

//   // Cardio exercise states
//   const [duration, setDuration] = useState(
//     exercise.goal?.duration?.toString() || ""
//   );
//   const [distance, setDistance] = useState(
//     exercise.goal?.distance?.toString() || ""
//   );
//   const [intensity, setIntensity] = useState(
//     exercise.goal?.intensity?.toString() || ""
//   );
//   const [cardioSets, setCardioSets] = useState(
//     exercise.goal?.sets?.toString() || ""
//   );
//   const [durationUnit, setDurationUnit] = useState(
//     exercise.goal?.durationUnit || "time"
//   );
//   const [distanceUnit, setDistanceUnit] = useState(
//     exercise.goal?.distanceUnit || "m"
//   );

//   const [isEditing, setIsEditing] = useState(false);
//   const [suggestion, setSuggestion] = useState("");

//   // Percentage logic ##############################################################################
//   const [percentageDropdownStates, setPercentageDropdownStates] = useState([]);
//   const percentageDropdownRefs = useRef([]);
//   const [percentages, setPercentages] = useState([]);
//   const [isPercentageDropdownOpen, setIsPercentageDropdownOpen] =
//     useState(false);
//   const percentageDropdownRef = useRef(null);
//   const [currentUserExercise, setCurrentUserExercise] = useState(null);

//   useEffect(() => {
//     const matchingExercise = userExercises.find(
//       (ex) => ex.name.toLowerCase() === exercise.value?.toLowerCase()
//     );
//     setCurrentUserExercise(matchingExercise);
//   }, [exercise.value, userExercises]);

//   useEffect(() => {
//     if (
//       !isCardio &&
//       currentUserExercise &&
//       currentUserExercise.currentPR != null
//     ) {
//       const sets = isPublished ? performedSets : goalSets;
//       const initialPercentages = sets.map((set) =>
//         set.weight
//           ? calculatePercentage(set.weight, currentUserExercise.currentPR)
//           : null
//       );
//       setPercentages(initialPercentages);
//     } else {
//       setPercentages([]);
//     }
//   }, [currentUserExercise, isPublished, isCardio, performedSets, goalSets]);

//   useEffect(() => {
//     // Close dropdown when clicking outside
//     const handleClickOutside = (event) => {
//       if (
//         percentageDropdownRef.current &&
//         !percentageDropdownRef.current.contains(event.target)
//       ) {
//         setIsPercentageDropdownOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const calculatePercentage = (weight, currentPR) => {
//     return Math.round((weight / currentPR) * 100);
//   };

//   const calculateWeight = (percentage, currentPR) => {
//     return Math.round((percentage / 100) * currentPR);
//   };

//   const handleNumericChange = (field, value, setIndex = 0) => {
//     const numericValue = value.replace(/[^0-9]/g, "");
//     if (isCardio) {
//       switch (field) {
//         case "duration":
//           setDuration(numericValue);
//           break;
//         case "distance":
//           setDistance(numericValue);
//           break;
//         case "intensity":
//           setIntensity(numericValue);
//           break;
//         case "sets":
//           setCardioSets(numericValue);
//           break;
//         default:
//           break;
//       }
//     } else {
//       const updateSets = (prevSets) => {
//         if (field === "sets") {
//           const newSetCount = parseInt(numericValue) || 0;
//           const newSets = [...prevSets];
//           // Preserve existing data when increasing sets
//           while (newSets.length < newSetCount) {
//             newSets.push({ reps: "", weight: "" });
//           }
//           // Trim excess sets but don't create an empty array
//           return newSets.slice(0, Math.max(newSetCount, 1));
//         } else {
//           const newSets =
//             prevSets.length > 0 ? [...prevSets] : [{ reps: "", weight: "" }];
//           if (newSets[setIndex]) {
//             newSets[setIndex] = { ...newSets[setIndex], [field]: numericValue };
//           }
//           return newSets;
//         }
//       };

//       if (isPublished) {
//         const newPerformedSets = updateSets(performedSets);
//         setPerformedSets(newPerformedSets);
//         onUpdate({
//           ...exercise,
//           performed: { ...exercise.performed, sets: newPerformedSets },
//         });
//       } else {
//         const newGoalSets = updateSets(goalSets);
//         setGoalSets(newGoalSets);
//         onUpdate({
//           ...exercise,
//           goal: { ...exercise.goal, sets: newGoalSets },
//         });
//       }

//       // Update percentages when weight changes

//       if (
//         field === "weight" &&
//         currentUserExercise &&
//         currentUserExercise.currentPR != null
//       ) {
//         const newPercentages = [...percentages];
//         newPercentages[setIndex] = calculatePercentage(
//           parseInt(value),
//           currentUserExercise.currentPR
//         );
//         setPercentages(newPercentages);
//       }
//     }
//   };

//   const handlePercentageChange = useCallback(
//     (value, index) => {
//       if (currentUserExercise && currentUserExercise.currentPR != null) {
//         const newWeight = calculateWeight(value, currentUserExercise.currentPR);
//         handleNumericChange("weight", newWeight.toString(), index);

//         const newPercentages = [...percentages];
//         newPercentages[index] = value;
//         setPercentages(newPercentages);
//       }
//       togglePercentageDropdown(index);
//     },
//     [currentUserExercise, calculateWeight, handleNumericChange, percentages]
//   );

//   const renderPercentageDropdown = (index) => (
//     <PercentageDropdown
//       isOpen={percentageDropdownStates[index]}
//       ref={(el) => (percentageDropdownRefs.current[index] = el)}
//     >
//       {[100, 95, 90, 85, 80, 75, 70, 65, 60, 50].map((percent) => (
//         <PercentageOption
//           key={percent}
//           onClick={() => handlePercentageChange(percent, index)}
//         >
//           {percent}%
//         </PercentageOption>
//       ))}
//     </PercentageDropdown>
//   );

//   const renderPercentageButton = (index) => {
//     if (currentUserExercise && currentUserExercise.currentPR != null) {
//       return (
//         <PercentageButton onClick={() => togglePercentageDropdown(index)}>
//           {/* <FaPercent />  */}
//           {percentages[index] || ""}%{/* <FaCaretDown /> */}
//           {renderPercentageDropdown(index)}
//         </PercentageButton>
//       );
//     }
//     return null;
//   };

//   useEffect(() => {
//     const sets = isPublished ? performedSets : goalSets;
//     setPercentageDropdownStates(new Array(sets.length).fill(false));
//     percentageDropdownRefs.current = percentageDropdownRefs.current.slice(
//       0,
//       sets.length
//     );
//   }, [performedSets, goalSets, isPublished]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       percentageDropdownRefs.current.forEach((ref, index) => {
//         if (ref && !ref.contains(event.target)) {
//           setPercentageDropdownStates((prev) => {
//             const newState = [...prev];
//             newState[index] = false;
//             return newState;
//           });
//         }
//       });
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const togglePercentageDropdown = (index) => {
//     setPercentageDropdownStates((prev) => {
//       const newState = [...prev];
//       newState[index] = !newState[index];
//       return newState;
//     });
//   };

//   // Percentage logic ##############################################################################

//   useEffect(() => {
//     setInputValue(exercise.value || "");
//     setIsCardio(exercise.isCardio || false);
//     if (exercise.isCardio) {
//       setDuration(
//         isPublished
//           ? exercise.performed?.duration
//           : exercise.goal?.duration || ""
//       );
//       setDistance(
//         isPublished
//           ? exercise.performed?.distance
//           : exercise.goal?.distance || ""
//       );
//       setIntensity(
//         isPublished
//           ? exercise.performed?.intensity
//           : exercise.goal?.intensity || ""
//       );
//       setCardioSets(
//         isPublished ? exercise.performed?.sets : exercise.goal?.sets || ""
//       );
//       setDurationUnit(
//         isPublished
//           ? exercise.performed?.durationUnit
//           : exercise.goal?.durationUnit || "m"
//       );
//       setDistanceUnit(
//         isPublished
//           ? exercise.performed?.distanceUnit
//           : exercise.goal?.distanceUnit || "m"
//       );
//       // setIntensityLevel(
//       //   isPublished
//       //     ? exercise.performed?.intensityLevel
//       //     : exercise.goal?.intensityLevel || "med"
//       // );
//     } else {
//       const newGoalSets = exercise.goal?.sets || [{ reps: "", weight: "" }];
//       const newPerformedSets = exercise.performed?.sets || [
//         { reps: "", weight: "" },
//       ];
//       setGoalSets(newGoalSets);
//       setPerformedSets(newPerformedSets);
//       setWeightUnit(exercise.goal?.weightUnit || "lbs");
//     }
//   }, [exercise, isPublished]);

//   const handleToggle = () => {
//     setIsCardio(!isCardio);
//     onUpdate({
//       ...exercise,
//       isCardio: !isCardio,
//       goal: {},
//       performed: {},
//     });
//   };

//   const handleInputChange = useCallback(
//     (e) => {
//       if (isPublished) return;

//       const newValue = e.target.value;
//       setInputValue(newValue);

//       if (newValue) {
//         const filteredExercises = userExercises.filter((ex) =>
//           ex.name.toLowerCase().startsWith(newValue.toLowerCase())
//         );
//         setSuggestion(
//           filteredExercises.length > 0
//             ? filteredExercises[0].name.slice(newValue.length)
//             : ""
//         );
//       } else {
//         setSuggestion("");
//         onUpdate({
//           ...exercise,
//           value: null,
//           exercises: exercise.type === "circuit" ? [] : undefined,
//         });
//       }
//     },
//     [userExercises, exercise, onUpdate, isPublished]
//   );

//   const handleBlur = useCallback(() => {
//     if (isPublished) return;

//     setIsEditing(false);
//     if (!inputValue.trim()) {
//       onUpdate({
//         ...exercise,
//         value: null,
//         exercises: exercise.type === "circuit" ? [] : undefined,
//       });
//       return;
//     }

//     const updatedExercise = {
//       ...exercise,
//       value: inputValue.trim(),
//       isCardio,
//       goal: isCardio
//         ? {
//             duration: duration || 0,
//             distance: distance || 0,
//             intensity: intensity || 0,
//             sets: cardioSets || 0,
//           }
//         : {
//             sets: goalSets,
//             weightUnit,
//           },
//       performed: isPublished
//         ? {
//             sets: performedSets,
//             weightUnit,
//           }
//         : exercise.performed,
//     };

//     onUpdate(updatedExercise);
//     setSuggestion("");
//   }, [
//     exercise,
//     inputValue,
//     isCardio,
//     goalSets,
//     performedSets,
//     weightUnit,
//     duration,
//     distance,
//     intensity,
//     cardioSets,
//     isPublished,
//     onUpdate,
//   ]);

//   const handleSuggestionClick = useCallback(
//     (e) => {
//       e.stopPropagation();
//       const newValue = inputValue + suggestion;
//       setInputValue(newValue);
//       setSuggestion("");
//       onUpdate({ ...exercise, value: newValue });
//     },
//     [inputValue, suggestion, exercise, onUpdate]
//   );

//   const handleAddExercise = useCallback(() => {
//     if (inputValue.trim()) {
//       const newExercise = {
//         objectID: new Date().toISOString(),
//         name: inputValue.trim(),
//         bodyPart: "",
//         equipment: "",
//         target: "",
//         secondaryMuscles: [],
//         instructions: [],
//         isCardio: isCardio,
//       };
//       addExercise(newExercise);
//       setInputValue(newExercise.name);
//       setSuggestion("");
//     }
//   }, [inputValue, addExercise, isCardio]);

//   const handleSelectChange = (field, value) => {
//     const updatedExercise = {
//       ...exercise,
//       [isPublished ? "performed" : "goal"]: {
//         ...exercise[isPublished ? "performed" : "goal"],
//         [field]: value,
//       },
//     };
//     onUpdate(updatedExercise);
//   };

//   const handleDurationUnitChange = (e) => {
//     const newUnit = e.target.value;
//     setDurationUnit(newUnit);
//     handleSelectChange("durationUnit", newUnit === "time" ? null : newUnit);
//   };

//   // ########Row Expander Logic Start########

//   const handleExpandedSetChange = (index, field, value) => {
//     const updateSets = isPublished ? setPerformedSets : setGoalSets;
//     updateSets((prevSets) => {
//       const newSets = [...prevSets];
//       if (newSets[index]) {
//         newSets[index] = { ...newSets[index], [field]: value };
//       }
//       return newSets;
//     });

//     onUpdate({
//       ...exercise,
//       goal: { ...exercise.goal, sets: isPublished ? goalSets : [...goalSets] },
//       performed: {
//         ...exercise.performed,
//         sets: isPublished ? [...performedSets] : performedSets,
//       },
//     });
//   };

//   const handleDeleteSet = useCallback(
//     (index) => {
//       const sets = isPublished ? performedSets : goalSets;
//       if (sets.length > 1) {
//         const newSets = sets.filter((_, i) => i !== index);
//         if (isPublished) {
//           setPerformedSets(newSets);
//         } else {
//           setGoalSets(newSets);
//         }
//         onUpdate({
//           ...exercise,
//           goal: { ...exercise.goal, sets: isPublished ? goalSets : newSets },
//           performed: {
//             ...exercise.performed,
//             sets: isPublished ? newSets : performedSets,
//           },
//         });
//       }
//     },
//     [isPublished, performedSets, goalSets, exercise, onUpdate]
//   );

//   const toggleExpand = useCallback(() => {
//     const sets = isPublished ? performedSets : goalSets;
//     if (sets.length > 1) {
//       onToggleExpand(exercise.id);
//     }
//   }, [isPublished, performedSets, goalSets, onToggleExpand, exercise.id]);

//   const calculateVolume = useCallback((setData) => {
//     return setData.reduce((total, set) => total + (parseInt(set.reps) || 0), 0);
//   }, []);

//   const handleSetChange = useCallback((index, field, value) => {
//     setSets((prevSets) => {
//       const newSets = [...prevSets];
//       newSets[index] = { ...newSets[index], [field]: value };
//       return newSets;
//     });
//   }, []);

//   const handleAddSet = useCallback(() => {
//     const sets = isPublished ? performedSets : goalSets;
//     const newSet = { reps: "", weight: "" };
//     const newSets = [...sets, newSet];

//     if (isPublished) {
//       setPerformedSets(newSets);
//     } else {
//       setGoalSets(newSets);
//     }

//     onUpdate({
//       ...exercise,
//       goal: { ...exercise.goal, sets: isPublished ? goalSets : newSets },
//       performed: {
//         ...exercise.performed,
//         sets: isPublished ? newSets : performedSets,
//       },
//     });
//   }, [isPublished, performedSets, goalSets, exercise, onUpdate]);

//   const renderExpandedRows = () => {
//     const sets = isPublished ? performedSets : goalSets;
//     const currentExercise = userExercises.find(
//       (ex) => ex.objectID === exercise.objectID
//     );

//     return (
//       <>
//         {sets.map((set, index) => (
//           <ExpandedRow key={index}>
//             <DeleteButton
//               onClick={() => handleDeleteSet(index)}
//               disabled={isCompleted || sets.length === 1}
//             >
//               <FaTimesCircle />
//             </DeleteButton>
//             {renderPercentageButton(index)}

//             <ExpandedAdditionalFieldsWrapper>
//               <AdditionalInputField
//                 type="text"
//                 placeholder="R"
//                 value={set.reps}
//                 onChange={(e) =>
//                   handleNumericChange("reps", e.target.value, index)
//                 }
//                 $isEditing={isEditing}
//                 readOnly={isCompleted}
//               />
//               <AdditionalInputField
//                 type="text"
//                 placeholder="W"
//                 value={set.weight}
//                 onChange={(e) =>
//                   handleNumericChange("weight", e.target.value, index)
//                 }
//                 $isEditing={isEditing}
//                 readOnly={isCompleted}
//               />
//               <WeightToggle
//                 onClick={() => {
//                   const newWeightUnit = weightUnit === "lbs" ? "kg" : "lbs";
//                   setWeightUnit(newWeightUnit);
//                   onUpdate({
//                     ...exercise,
//                     goal: { ...exercise.goal, weightUnit: newWeightUnit },
//                     performed: {
//                       ...exercise.performed,
//                       weightUnit: newWeightUnit,
//                     },
//                   });
//                 }}
//                 $isEditing={isEditing}
//                 disabled={isCompleted}
//               >
//                 {weightUnit}
//               </WeightToggle>
//             </ExpandedAdditionalFieldsWrapper>
//           </ExpandedRow>
//         ))}
//         {!isCompleted && !isPublished && (
//           <AddSetButton onClick={handleAddSet}>
//             <FaPlus /> Set
//           </AddSetButton>
//         )}
//       </>
//     );
//   };

//   // ########Row Expander Logic End########

//   const renderAdditionalFields = () => {
//     if (isCardio) {
//       return (
//         <>
//           <CompactInput>
//             <CompactInputField
//               type="number"
//               placeholder="0"
//               value={cardioSets || ""}
//               onChange={(e) => handleNumericChange("sets", e.target.value)}
//               readOnly={isCompleted}
//             />
//             <Divider />
//             <StaticText>{cardioSets ? "X" : "sets"}</StaticText>
//           </CompactInput>
//           <CompactInput>
//             <CompactInputField
//               type="number"
//               placeholder="0"
//               value={duration}
//               onChange={(e) => handleNumericChange("duration", e.target.value)}
//               readOnly={isCompleted}
//             />
//             <Divider />
//             <CompactSelect
//               value={durationUnit}
//               onChange={handleDurationUnitChange}
//               disabled={isCompleted}
//             >
//               <option value="sec">sec</option>
//               <option value="min">min</option>
//               <option value="hr">hr</option>
//             </CompactSelect>
//           </CompactInput>
//           <CompactInput>
//             <CompactInputField
//               type="number"
//               placeholder="0"
//               value={distance}
//               onChange={(e) => handleNumericChange("distance", e.target.value)}
//               readOnly={isCompleted}
//             />
//             <Divider />
//             <CompactSelect
//               value={distanceUnit}
//               onChange={(e) => {
//                 setDistanceUnit(e.target.value);
//                 handleSelectChange("distanceUnit", e.target.value);
//               }}
//               disabled={isCompleted}
//             >
//               <option value="m">m</option>
//               <option value="km">km</option>
//               <option value="y">y</option>
//               <option value="mi">mi</option>
//             </CompactSelect>
//           </CompactInput>
//         </>
//       );
//     } else {
//       const sets = isPublished ? performedSets : goalSets;
//       const volume = calculateVolume(sets);
//       const currentExercise = userExercises.find(
//         (ex) => ex.objectID === exercise.objectID
//       );

//       return (
//         <>
//           {sets.length === 1 && renderPercentageButton(0)}

//           {/* {renderPercentageButton(0)} */}
//           <AdditionalInputField
//             type="text"
//             placeholder="1 Set"
//             value={sets.length > 1 ? `${sets.length} sets` : ""}
//             // value={sets.length > 1 ? sets.length.toString() : ""}
//             onChange={(e) => handleNumericChange("sets", e.target.value)}
//             onFocus={(e) => {
//               if (sets.length === 1) {
//                 e.target.value = "";
//               }
//             }}
//             onBlur={(e) => {
//               if (e.target.value === "") {
//                 handleNumericChange("sets", "1");
//               }
//             }}
//             $isEditing={!isCompleted}
//             readOnly={isCompleted || sets.length > 1}
//           />
//           <AdditionalInputField
//             type="text"
//             placeholder={`Reps${sets.length > 1 ? "*" : ""}`}
//             value={
//               sets.length > 1
//                 ? `${volume} vol`
//                 : sets[0]?.reps
//                 ? `${sets[0].reps} reps`
//                 : ""
//             }
//             // value={sets.length > 1 ? volume.toString() : sets[0]?.reps || ""}
//             onChange={(e) => handleNumericChange("reps", e.target.value, 0)}
//             onClick={() => sets.length > 1 && onToggleExpand(exercise.id)}
//             $isEditing={!isCompleted}
//             readOnly={isCompleted || sets.length > 1}
//           />
//           <AdditionalInputField
//             type="text"
//             placeholder="Wt."
//             // value={sets[0]?.weight ? `${sets[0].weight} wt` : ""}
//             value={
//               sets.length > 1
//                 ? "weight"
//                 : sets[0]?.weight
//                 ? `${sets[0].weight} wt`
//                 : ""
//             }
//             // value={sets[0]?.weight || ""}
//             onChange={(e) => handleNumericChange("weight", e.target.value)}
//             $isEditing={!isCompleted}
//             readOnly={isCompleted || sets.length > 1}
//           />
//           <WeightToggle
//             onClick={() => {
//               const newWeightUnit = weightUnit === "lbs" ? "kg" : "lbs";
//               setWeightUnit(newWeightUnit);
//               onUpdate({
//                 ...exercise,
//                 goal: { ...exercise.goal, weightUnit: newWeightUnit },
//                 performed: { ...exercise.performed, weightUnit: newWeightUnit },
//               });
//             }}
//             $isEditing={!isCompleted}
//             disabled={isCompleted}
//           >
//             {weightUnit}
//           </WeightToggle>
//         </>
//       );
//     }
//   };

//   return (
//     <InputWrapper>
//       <InputFieldWrapper $isEditing={isEditing} $exerciseType={exercise.type}>
//         <ToggleButton onClick={handleToggle}>
//           {isCardio ? <FaRunning /> : <FaDumbbell />}
//         </ToggleButton>
//         <InputField
//           type="text"
//           value={inputValue}
//           onClick={() => setIsEditing(true)}
//           onChange={handleInputChange}
//           onBlur={handleBlur}
//           placeholder="Search or add an exercise"
//           $isEditing={isEditing}
//           readOnly={isCompleted}
//         />
//         {suggestion && (
//           <Suggestion onMouseDown={handleSuggestionClick}>
//             {inputValue}
//             {suggestion}
//           </Suggestion>
//         )}
//         {inputValue &&
//           !userExercises.some(
//             (ex) => ex.name.toLowerCase() === inputValue.toLowerCase()
//           ) && (
//             <AddExerciseButton onClick={handleAddExercise}>
//               <FaPlus />
//             </AddExerciseButton>
//           )}
//         <AdditionalFieldsWrapper>
//           {renderAdditionalFields()}
//         </AdditionalFieldsWrapper>
//       </InputFieldWrapper>

//       <ExpandableBorder
//         onClick={toggleExpand}
//         $hasMultipleSets={(isPublished ? performedSets : goalSets).length > 1}
//       />
//       {isExpanded && (isPublished ? performedSets : goalSets).length > 1 && (
//         <ExpandedRowsWrapper>{renderExpandedRows()}</ExpandedRowsWrapper>
//       )}
//     </InputWrapper>
//   );
// };

// export default React.memo(ExerciseInput);

// ############################################################################################################
// Fixing the cardio mode
// ############################################################################################################

import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import {
  FaPlus,
  FaDumbbell,
  FaRunning,
  FaTimesCircle,
  FaPercent,
  FaCaretDown,
} from "react-icons/fa";
import { useUserExercises } from "../../context/UserExercisesContext";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;
  touch-action: none; // Prevent scrolling while dragging on touch devices
`;

const InputFieldWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ $isEditing }) => ($isEditing ? "5px" : "0")};
  background-color: ${({ $isEditing }) =>
    $isEditing ? "transparent" : "transparent"};
  color: ${({ $exerciseType }) => {
    switch ($exerciseType) {
      case "single":
        return "#4caf50";
      case "super":
        return "#2196f3";
      case "circuit":
        return "#ffeb3b";
      default:
        return "#ccc";
    }
  }};
  border-radius: 4px;
  cursor: move; // Indicate that the entire item is draggable
`;

const InputField = styled.input`
  padding: ${({ $isEditing }) => ($isEditing ? "5px" : "0")};
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 0.8em;
  color: inherit;
  // z-index: 1;
  cursor: ${({ $isEditing }) => ($isEditing ? "text" : "pointer")};

  @media (max-width: 768px) {
    font-size: 0.6em;
  }
`;

const AdditionalFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AdditionalInputField = styled.input`
  width: ${({ $isCardio }) => ($isCardio ? "50px" : "35px")};
  padding: 1px;
  background-color: transparent;
  border: none;
  border-bottom: ${({ $isEditing }) => ($isEditing ? "none" : "none")};
  text-align: center;
  font-size: 0.6em;
  color: inherit;
`;

const WeightToggle = styled.button`
  padding: 3px 1px;
  background-color: transparent;
  border: none;
  border-bottom: ${({ $isEditing }) => ($isEditing ? "none" : "none")};
  color: inherit;
  cursor: pointer;
  font-size: 0.6em;

  &:hover {
    background: none;
  }
`;

const Suggestion = styled.div`
  position: absolute;
  top: 48%;
  left: 42px;
  transform: translateY(-50%);
  opacity: 0.4;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.6em;
  }
`;

const AddExerciseButton = styled.button`
  background-color: #3a3a3a;
  border: none;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  font-size: 0.5em;

  &:hover {
    color: #4caf50;
    background-color: #4a4a4a;
  }
`;

const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectField = styled.select`
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 0.7em;
  margin-left: 2px;
`;

const CompactInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #3a3a3a;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 4px;
`;

const CompactInputField = styled.input`
  width: 24px;
  border: none;
  background: transparent;
  padding: 2px 4px;
  font-size: 10px;
  text-align: right;
  color: inherit;
`;

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background-color: #ccc;
`;

const CompactSelect = styled.select`
  border: none;
  background: transparent;
  padding: 2px 4px;
  font-size: 10px;
  appearance: none;
  cursor: pointer;
  color: inherit;
`;

const StaticText = styled.span`
  padding: 0px 4px;
  font-size: 10px;
  color: inherit;
`;

const ExpandableBorder = styled.div`
  height: 3px;
  background-image: ${({ $hasMultipleSets }) =>
    $hasMultipleSets
      ? "linear-gradient(90deg, #9100ff 0%, #ff00ec 33%, #ffc800 66%, #9100ff 100%)"
      : "none"};
  background-size: 300% 3px;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  animation: ${({ $hasMultipleSets }) =>
    $hasMultipleSets ? "animateGradient 5s linear infinite" : "none"};
  cursor: ${({ $hasMultipleSets }) =>
    $hasMultipleSets ? "pointer" : "default"};
  transition: all 0.3s ease;

  @keyframes animateGradient {
    0% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 100% 100%;
    }
  }

  @keyframes animateHoverGradient {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  &:hover {
    animation: ${({ $hasMultipleSets }) =>
      $hasMultipleSets ? "animateHoverGradient 2s linear infinite" : "none"};
    background-image: ${({ $hasMultipleSets }) =>
      $hasMultipleSets
        ? "linear-gradient(43deg, #2c2c2c 0%, #3a3a3a 50%, #2c2c2c 100%)"
        : "none"};
    background-size: 200% 3px;
  }
`;

const ExpandedRowsWrapper = styled.div`
  margin-left: 20px;
  // border-left: 1px solid #ccc;
`;

const ExpandedRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  justify-content: flex-end;
  color: ${({ $exerciseType }) => {
    switch ($exerciseType) {
      case "single":
        return "#4caf50";
      case "super":
        return "#2196f3";
      case "circuit":
        return "#ffeb3b";
      default:
        return "#ccc";
    }
  }};
`;

const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  color: #ff0000;
  cursor: pointer;
  padding: 5px;
  margin-right: 10px;

  &:hover {
    background: none;
  }
`;

const ExpandedAdditionalFieldsWrapper = styled(AdditionalFieldsWrapper)`
  justify-content: flex-end;
`;

// const VolumeDisplay = styled.span`
//   font-size: 0.8em;
//   color: #888;
//   margin-left: 5px;
// `;

const PercentageButton = styled(AddExerciseButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30px;
  position: relative;
`;

const PercentageDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const PercentageOption = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #4a4a4a;
  }
`;

const AddSetButton = styled.button`
  background-color: #3a3a3a;
  border: none;
  color: #fff;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: auto; // This will push the button to the right
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6em;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a4a4a;
  }

  svg {
    margin-right: 5px;
  }
`;

const ExerciseInput = ({
  exercise,
  onUpdate,
  isPublished,
  isCompleted,
  isExpanded,
  onToggleExpand,
}) => {
  const { exercises: userExercises, addExercise } = useUserExercises();
  const [inputValue, setInputValue] = useState(exercise.value || "");
  const [isCardio, setIsCardio] = useState(exercise.isCardio || false);

  // Weight exercise states
  const [sets, setSets] = useState(exercise.goal?.sets?.toString() || "");
  const [reps, setReps] = useState(exercise.goal?.reps?.toString() || "");
  const [weight, setWeight] = useState(exercise.goal?.weight?.toString() || "");
  const [goalSets, setGoalSets] = useState(
    exercise.goal?.sets || [{ reps: "", weight: "" }]
  );
  const [performedSets, setPerformedSets] = useState(
    exercise.performed?.sets || [{ reps: "", weight: "" }]
  );

  const [weightUnit, setWeightUnit] = useState(
    exercise.goal?.weightUnit || "lbs"
  );
  // const [isExpanded, setIsExpanded] = useState(false);
  const [expandedSets, setExpandedSets] = useState([]);

  // Cardio exercise states
  const [duration, setDuration] = useState(
    exercise.goal?.duration?.toString() || ""
  );
  const [distance, setDistance] = useState(
    exercise.goal?.distance?.toString() || ""
  );
  const [intensity, setIntensity] = useState(
    exercise.goal?.intensity?.toString() || ""
  );

  const [cardioSets, setCardioSets] = useState(() => {
    if (exercise.isCardio) {
      return (
        (isPublished ? exercise.performed?.sets : exercise.goal?.sets) || [
          { duration: "", distance: "" },
        ]
      );
    }
    return [{ duration: "", distance: "" }];
  });

  const [durationUnit, setDurationUnit] = useState(
    exercise.goal?.durationUnit || "time"
  );
  const [distanceUnit, setDistanceUnit] = useState(
    exercise.goal?.distanceUnit || "m"
  );

  const [isEditing, setIsEditing] = useState(false);
  const [suggestion, setSuggestion] = useState("");

  const handleCardioSetChange = (index, field, value) => {
    setCardioSets((prevSets) => {
      const newSets = [...prevSets];
      newSets[index] = { ...newSets[index], [field]: value };
      return newSets;
    });
  };

  const handleCardioSetBlur = () => {
    onUpdate({
      ...exercise,
      goal: {
        ...exercise.goal,
        sets: isPublished ? cardioSets : [...cardioSets],
      },
      performed: {
        ...exercise.performed,
        sets: isPublished ? [...cardioSets] : cardioSets,
      },
    });
  };

  const handleAddCardioSet = () => {
    setCardioSets((prevSets) => [...prevSets, { duration: "", distance: "" }]);
  };

  const handleDeleteCardioSet = (index) => {
    if (cardioSets.length > 1) {
      setCardioSets((prevSets) => prevSets.filter((_, i) => i !== index));
    }
  };

  // const handleAddCardioSet = () => {
  //   const newSet = { duration: "", distance: "", intensity: "" };
  //   const newSets = [...cardioSets, newSet];
  //   setCardioSets(newSets);

  //   onUpdate({
  //     ...exercise,
  //     goal: { ...exercise.goal, sets: isPublished ? cardioSets : newSets },
  //     performed: {
  //       ...exercise.performed,
  //       sets: isPublished ? newSets : cardioSets,
  //     },
  //   });
  // };

  // const handleDeleteCardioSet = (index) => {
  //   if (cardioSets.length > 1) {
  //     const newSets = cardioSets.filter((_, i) => i !== index);
  //     setCardioSets(newSets);
  //     onUpdate({
  //       ...exercise,
  //       goal: { ...exercise.goal, sets: isPublished ? cardioSets : newSets },
  //       performed: {
  //         ...exercise.performed,
  //         sets: isPublished ? newSets : cardioSets,
  //       },
  //     });
  //   }
  // };

  // Percentage logic ##############################################################################
  const [percentageDropdownStates, setPercentageDropdownStates] = useState([]);
  const percentageDropdownRefs = useRef([]);
  const [percentages, setPercentages] = useState([]);
  const [isPercentageDropdownOpen, setIsPercentageDropdownOpen] =
    useState(false);
  const percentageDropdownRef = useRef(null);
  const [currentUserExercise, setCurrentUserExercise] = useState(null);

  useEffect(() => {
    const matchingExercise = userExercises.find(
      (ex) => ex.name.toLowerCase() === exercise.value?.toLowerCase()
    );
    setCurrentUserExercise(matchingExercise);
  }, [exercise.value, userExercises]);

  useEffect(() => {
    if (
      !isCardio &&
      currentUserExercise &&
      currentUserExercise.currentPR != null
    ) {
      const sets = isPublished ? performedSets : goalSets;
      const initialPercentages = sets.map((set) =>
        set.weight
          ? calculatePercentage(set.weight, currentUserExercise.currentPR)
          : null
      );
      setPercentages(initialPercentages);
    } else {
      setPercentages([]);
    }
  }, [currentUserExercise, isPublished, isCardio, performedSets, goalSets]);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (
        percentageDropdownRef.current &&
        !percentageDropdownRef.current.contains(event.target)
      ) {
        setIsPercentageDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const calculatePercentage = (weight, currentPR) => {
    return Math.round((weight / currentPR) * 100);
  };

  const calculateWeight = (percentage, currentPR) => {
    return Math.round((percentage / 100) * currentPR);
  };

  const handleNumericChange = (field, value, setIndex = 0) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    if (isCardio) {
      if (field === "sets") {
        const newSetCount = parseInt(numericValue) || 1;
        const newSets = [...cardioSets];
        while (newSets.length < newSetCount) {
          newSets.push({ duration: "", distance: "", intensity: "" });
        }
        setCardioSets(newSets.slice(0, Math.max(newSetCount, 1)));
      } else {
        handleCardioSetChange(setIndex, field, numericValue);
      }
    } else {
      const updateSets = (prevSets) => {
        if (field === "sets") {
          const newSetCount = parseInt(numericValue) || 0;
          const newSets = [...prevSets];
          // Preserve existing data when increasing sets
          while (newSets.length < newSetCount) {
            newSets.push({ reps: "", weight: "" });
          }
          // Trim excess sets but don't create an empty array
          return newSets.slice(0, Math.max(newSetCount, 1));
        } else {
          const newSets =
            prevSets.length > 0 ? [...prevSets] : [{ reps: "", weight: "" }];
          if (newSets[setIndex]) {
            newSets[setIndex] = { ...newSets[setIndex], [field]: numericValue };
          }
          return newSets;
        }
      };

      if (isPublished) {
        const newPerformedSets = updateSets(performedSets);
        setPerformedSets(newPerformedSets);
        onUpdate({
          ...exercise,
          performed: { ...exercise.performed, sets: newPerformedSets },
        });
      } else {
        const newGoalSets = updateSets(goalSets);
        setGoalSets(newGoalSets);
        onUpdate({
          ...exercise,
          goal: { ...exercise.goal, sets: newGoalSets },
        });
      }

      // Update percentages when weight changes

      if (
        field === "weight" &&
        currentUserExercise &&
        currentUserExercise.currentPR != null
      ) {
        const newPercentages = [...percentages];
        newPercentages[setIndex] = calculatePercentage(
          parseInt(value),
          currentUserExercise.currentPR
        );
        setPercentages(newPercentages);
      }
    }
  };

  const handlePercentageChange = useCallback(
    (value, index) => {
      if (currentUserExercise && currentUserExercise.currentPR != null) {
        const newWeight = calculateWeight(value, currentUserExercise.currentPR);
        handleNumericChange("weight", newWeight.toString(), index);

        const newPercentages = [...percentages];
        newPercentages[index] = value;
        setPercentages(newPercentages);
      }
      togglePercentageDropdown(index);
    },
    [currentUserExercise, calculateWeight, handleNumericChange, percentages]
  );

  const renderPercentageDropdown = (index) => (
    <PercentageDropdown
      isOpen={percentageDropdownStates[index]}
      ref={(el) => (percentageDropdownRefs.current[index] = el)}
    >
      {[100, 95, 90, 85, 80, 75, 70, 65, 60, 50].map((percent) => (
        <PercentageOption
          key={percent}
          onClick={() => handlePercentageChange(percent, index)}
        >
          {percent}%
        </PercentageOption>
      ))}
    </PercentageDropdown>
  );

  const renderPercentageButton = (index) => {
    if (currentUserExercise && currentUserExercise.currentPR != null) {
      return (
        <PercentageButton onClick={() => togglePercentageDropdown(index)}>
          {/* <FaPercent />  */}
          {percentages[index] || ""}%{/* <FaCaretDown /> */}
          {renderPercentageDropdown(index)}
        </PercentageButton>
      );
    }
    return null;
  };

  useEffect(() => {
    const sets = isPublished ? performedSets : goalSets;
    setPercentageDropdownStates(new Array(sets.length).fill(false));
    percentageDropdownRefs.current = percentageDropdownRefs.current.slice(
      0,
      sets.length
    );
  }, [performedSets, goalSets, isPublished]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      percentageDropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          setPercentageDropdownStates((prev) => {
            const newState = [...prev];
            newState[index] = false;
            return newState;
          });
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const togglePercentageDropdown = (index) => {
    setPercentageDropdownStates((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // Percentage logic ##############################################################################

  useEffect(() => {
    setInputValue(exercise.value || "");
    setIsCardio(exercise.isCardio || false);
    if (exercise.isCardio) {
      setDuration(
        isPublished
          ? exercise.performed?.duration
          : exercise.goal?.duration || ""
      );
      setDistance(
        isPublished
          ? exercise.performed?.distance
          : exercise.goal?.distance || ""
      );
      setIntensity(
        isPublished
          ? exercise.performed?.intensity
          : exercise.goal?.intensity || ""
      );
      setCardioSets(
        isPublished ? exercise.performed?.sets : exercise.goal?.sets || ""
      );
      setDurationUnit(
        isPublished
          ? exercise.performed?.durationUnit
          : exercise.goal?.durationUnit || "m"
      );
      setDistanceUnit(
        isPublished
          ? exercise.performed?.distanceUnit
          : exercise.goal?.distanceUnit || "m"
      );
      // setIntensityLevel(
      //   isPublished
      //     ? exercise.performed?.intensityLevel
      //     : exercise.goal?.intensityLevel || "med"
      // );
    } else {
      const newGoalSets = exercise.goal?.sets || [{ reps: "", weight: "" }];
      const newPerformedSets = exercise.performed?.sets || [
        { reps: "", weight: "" },
      ];
      setGoalSets(newGoalSets);
      setPerformedSets(newPerformedSets);
      setWeightUnit(exercise.goal?.weightUnit || "lbs");
    }
  }, [exercise, isPublished]);

  const handleToggle = () => {
    const newIsCardio = !isCardio;
    setIsCardio(newIsCardio);
    if (newIsCardio) {
      setCardioSets([{ duration: "", distance: "", intensity: "" }]);
    } else {
      setGoalSets([{ reps: "", weight: "" }]);
      setPerformedSets([{ reps: "", weight: "" }]);
    }
    onUpdate({
      ...exercise,
      isCardio: newIsCardio,
      goal: newIsCardio
        ? { sets: [{ duration: "", distance: "", intensity: "" }] }
        : { sets: [{ reps: "", weight: "" }] },
      performed: newIsCardio
        ? { sets: [{ duration: "", distance: "", intensity: "" }] }
        : { sets: [{ reps: "", weight: "" }] },
    });
  };

  const handleInputChange = useCallback(
    (e) => {
      if (isPublished) return;

      const newValue = e.target.value;
      setInputValue(newValue);

      if (newValue) {
        const filteredExercises = userExercises.filter((ex) =>
          ex.name.toLowerCase().startsWith(newValue.toLowerCase())
        );
        setSuggestion(
          filteredExercises.length > 0
            ? filteredExercises[0].name.slice(newValue.length)
            : ""
        );
      } else {
        setSuggestion("");
        onUpdate({
          ...exercise,
          value: null,
          exercises: exercise.type === "circuit" ? [] : undefined,
        });
      }
    },
    [userExercises, exercise, onUpdate, isPublished]
  );

  const handleBlur = useCallback(() => {
    if (isPublished) return;

    setIsEditing(false);
    if (!inputValue.trim()) {
      onUpdate({
        ...exercise,
        value: null,
        exercises: exercise.type === "circuit" ? [] : undefined,
      });
      return;
    }

    const updatedExercise = {
      ...exercise,
      value: inputValue.trim(),
      isCardio,
      goal: isCardio
        ? {
            duration: duration || 0,
            distance: distance || 0,
            intensity: intensity || 0,
            sets: cardioSets || 0,
          }
        : {
            sets: goalSets,
            weightUnit,
          },
      performed: isPublished
        ? {
            sets: performedSets,
            weightUnit,
          }
        : exercise.performed,
    };

    onUpdate(updatedExercise);
    setSuggestion("");
  }, [
    exercise,
    inputValue,
    isCardio,
    goalSets,
    performedSets,
    weightUnit,
    duration,
    distance,
    intensity,
    cardioSets,
    isPublished,
    onUpdate,
  ]);

  const handleSuggestionClick = useCallback(
    (e) => {
      e.stopPropagation();
      const newValue = inputValue + suggestion;
      setInputValue(newValue);
      setSuggestion("");
      onUpdate({ ...exercise, value: newValue });
    },
    [inputValue, suggestion, exercise, onUpdate]
  );

  const handleAddExercise = useCallback(() => {
    if (inputValue.trim()) {
      const newExercise = {
        objectID: new Date().toISOString(),
        name: inputValue.trim(),
        bodyPart: "",
        equipment: "",
        target: "",
        secondaryMuscles: [],
        instructions: [],
        isCardio: isCardio,
      };
      addExercise(newExercise);
      setInputValue(newExercise.name);
      setSuggestion("");
    }
  }, [inputValue, addExercise, isCardio]);

  const handleSelectChange = (field, value) => {
    const updatedExercise = {
      ...exercise,
      [isPublished ? "performed" : "goal"]: {
        ...exercise[isPublished ? "performed" : "goal"],
        [field]: value,
      },
    };
    onUpdate(updatedExercise);
  };

  const handleDurationUnitChange = (e) => {
    const newUnit = e.target.value;
    setDurationUnit(newUnit);
    onUpdate({
      ...exercise,
      goal: { ...exercise.goal, durationUnit: newUnit },
      performed: { ...exercise.performed, durationUnit: newUnit },
    });
  };

  const handleDistanceUnitChange = (e) => {
    const newUnit = e.target.value;
    setDistanceUnit(newUnit);
    onUpdate({
      ...exercise,
      goal: { ...exercise.goal, distanceUnit: newUnit },
      performed: { ...exercise.performed, distanceUnit: newUnit },
    });
  };

  // ########Row Expander Logic Start########

  const handleExpandedSetChange = (index, field, value) => {
    const updateSets = isPublished ? setPerformedSets : setGoalSets;
    updateSets((prevSets) => {
      const newSets = [...prevSets];
      if (newSets[index]) {
        newSets[index] = { ...newSets[index], [field]: value };
      }
      return newSets;
    });

    onUpdate({
      ...exercise,
      goal: { ...exercise.goal, sets: isPublished ? goalSets : [...goalSets] },
      performed: {
        ...exercise.performed,
        sets: isPublished ? [...performedSets] : performedSets,
      },
    });
  };

  const handleDeleteSet = useCallback(
    (index) => {
      const sets = isPublished ? performedSets : goalSets;
      if (sets.length > 1) {
        const newSets = sets.filter((_, i) => i !== index);
        if (isPublished) {
          setPerformedSets(newSets);
        } else {
          setGoalSets(newSets);
        }
        onUpdate({
          ...exercise,
          goal: { ...exercise.goal, sets: isPublished ? goalSets : newSets },
          performed: {
            ...exercise.performed,
            sets: isPublished ? newSets : performedSets,
          },
        });
      }
    },
    [isPublished, performedSets, goalSets, exercise, onUpdate]
  );

  const toggleExpand = useCallback(() => {
    if (
      isCardio
        ? cardioSets.length > 1
        : (isPublished ? performedSets : goalSets).length > 1
    ) {
      onToggleExpand(exercise.id);
    }
  }, [
    isCardio,
    cardioSets,
    isPublished,
    performedSets,
    goalSets,
    onToggleExpand,
    exercise.id,
  ]);

  // const toggleExpand = useCallback(() => {
  //   const sets = isPublished ? performedSets : goalSets;
  //   if (sets.length > 1) {
  //     onToggleExpand(exercise.id);
  //   }
  // }, [isPublished, performedSets, goalSets, onToggleExpand, exercise.id]);

  const calculateVolume = useCallback((setData) => {
    return setData.reduce((total, set) => total + (parseInt(set.reps) || 0), 0);
  }, []);

  const handleSetChange = useCallback((index, field, value) => {
    setSets((prevSets) => {
      const newSets = [...prevSets];
      newSets[index] = { ...newSets[index], [field]: value };
      return newSets;
    });
  }, []);

  const handleAddSet = useCallback(() => {
    const sets = isPublished ? performedSets : goalSets;
    const newSet = { reps: "", weight: "" };
    const newSets = [...sets, newSet];

    if (isPublished) {
      setPerformedSets(newSets);
    } else {
      setGoalSets(newSets);
    }

    onUpdate({
      ...exercise,
      goal: { ...exercise.goal, sets: isPublished ? goalSets : newSets },
      performed: {
        ...exercise.performed,
        sets: isPublished ? newSets : performedSets,
      },
    });
  }, [isPublished, performedSets, goalSets, exercise, onUpdate]);

  const renderCardioFields = () => {
    const singleSet = cardioSets[0] || { duration: "", distance: "" };
    const isMultipleSets = cardioSets.length > 1;
    const totalDuration = cardioSets.reduce(
      (total, set) => total + (parseInt(set.duration) || 0),
      0
    );
    const totalDistance = cardioSets.reduce(
      (total, set) => total + (parseInt(set.distance) || 0),
      0
    );

    return (
      <>
        <AdditionalInputField
          type="text"
          placeholder="1 Set"
          value={isMultipleSets ? `${cardioSets.length} sets` : ""}
          onChange={(e) => {
            const newSetCount = Math.max(1, parseInt(e.target.value) || 1);
            setCardioSets((prevSets) => {
              const newSets = [...prevSets];
              while (newSets.length < newSetCount) {
                newSets.push({ duration: "", distance: "" });
              }
              return newSets.slice(0, newSetCount);
            });
          }}
          onBlur={handleCardioSetBlur}
          readOnly={isCompleted || isMultipleSets}
          onClick={isMultipleSets ? toggleExpand : undefined}
        />
        <CompactInput>
          <CompactInputField
            type="text"
            placeholder="Duration"
            value={singleSet.duration}
            onChange={(e) =>
              handleCardioSetChange(0, "duration", e.target.value)
            }
            onBlur={handleCardioSetBlur}
            readOnly={isCompleted || isMultipleSets}
            onClick={isMultipleSets ? toggleExpand : undefined}
          />
          <Divider />
          <CompactSelect
            value={durationUnit}
            onChange={handleDurationUnitChange}
            onBlur={handleCardioSetBlur}
            disabled={isCompleted}
          >
            <option value="sec">sec</option>
            <option value="min">min</option>
            <option value="hr">hr</option>
          </CompactSelect>
        </CompactInput>
        <CompactInput>
          <CompactInputField
            type="text"
            placeholder="Distance"
            value={singleSet.distance}
            onChange={(e) =>
              handleCardioSetChange(0, "distance", e.target.value)
            }
            onBlur={handleCardioSetBlur}
            readOnly={isCompleted || isMultipleSets}
            onClick={isMultipleSets ? toggleExpand : undefined}
          />
          <Divider />
          <CompactSelect
            value={distanceUnit}
            onChange={handleDistanceUnitChange}
            onBlur={handleCardioSetBlur}
            disabled={isCompleted}
          >
            <option value="m">m</option>
            <option value="km">km</option>
            <option value="y">y</option>
            <option value="mi">mi</option>
          </CompactSelect>
        </CompactInput>
      </>
    );
  };

  const renderExpandedCardioRows = () => {
    return (
      <>
        {cardioSets.map((set, index) => (
          <ExpandedRow key={index}>
            <DeleteButton
              onClick={() => handleDeleteCardioSet(index)}
              disabled={isCompleted || cardioSets.length === 1}
            >
              <FaTimesCircle />
            </DeleteButton>
            <ExpandedAdditionalFieldsWrapper>
              <AdditionalInputField
                type="text"
                placeholder="Duration"
                value={set.duration}
                onChange={(e) =>
                  handleCardioSetChange(index, "duration", e.target.value)
                }
                onBlur={handleCardioSetBlur}
                $isEditing={!isCompleted}
                readOnly={isCompleted}
              />
              <AdditionalInputField
                type="text"
                placeholder="Distance"
                value={set.distance}
                onChange={(e) =>
                  handleCardioSetChange(index, "distance", e.target.value)
                }
                onBlur={handleCardioSetBlur}
                $isEditing={!isCompleted}
                readOnly={isCompleted}
              />
            </ExpandedAdditionalFieldsWrapper>
          </ExpandedRow>
        ))}
        {!isCompleted && !isPublished && (
          <AddSetButton onClick={handleAddCardioSet}>
            <FaPlus /> Set
          </AddSetButton>
        )}
      </>
    );
  };

  const renderExpandedRows = () => {
    const sets = isPublished ? performedSets : goalSets;
    const currentExercise = userExercises.find(
      (ex) => ex.objectID === exercise.objectID
    );

    return (
      <>
        {sets.map((set, index) => (
          <ExpandedRow key={index}>
            <DeleteButton
              onClick={() => handleDeleteSet(index)}
              disabled={isCompleted || sets.length === 1}
            >
              <FaTimesCircle />
            </DeleteButton>
            {renderPercentageButton(index)}

            <ExpandedAdditionalFieldsWrapper>
              <AdditionalInputField
                type="text"
                placeholder="R"
                value={set.reps}
                onChange={(e) =>
                  handleNumericChange("reps", e.target.value, index)
                }
                $isEditing={isEditing}
                readOnly={isCompleted}
              />
              <AdditionalInputField
                type="text"
                placeholder="W"
                value={set.weight}
                onChange={(e) =>
                  handleNumericChange("weight", e.target.value, index)
                }
                $isEditing={isEditing}
                readOnly={isCompleted}
              />
              <WeightToggle
                onClick={() => {
                  const newWeightUnit = weightUnit === "lbs" ? "kg" : "lbs";
                  setWeightUnit(newWeightUnit);
                  onUpdate({
                    ...exercise,
                    goal: { ...exercise.goal, weightUnit: newWeightUnit },
                    performed: {
                      ...exercise.performed,
                      weightUnit: newWeightUnit,
                    },
                  });
                }}
                $isEditing={isEditing}
                disabled={isCompleted}
              >
                {weightUnit}
              </WeightToggle>
            </ExpandedAdditionalFieldsWrapper>
          </ExpandedRow>
        ))}
        {!isCompleted && !isPublished && (
          <AddSetButton onClick={handleAddSet}>
            <FaPlus /> Set
          </AddSetButton>
        )}
      </>
    );
  };

  // ########Row Expander Logic End########

  const renderAdditionalFields = () => {
    if (isCardio) {
      return renderCardioFields();

      //
    } else {
      const sets = isPublished ? performedSets : goalSets;
      const volume = calculateVolume(sets);
      const currentExercise = userExercises.find(
        (ex) => ex.objectID === exercise.objectID
      );

      return (
        <>
          {sets.length === 1 && renderPercentageButton(0)}

          {/* {renderPercentageButton(0)} */}
          <AdditionalInputField
            type="text"
            placeholder="1 Set"
            value={sets.length > 1 ? `${sets.length} sets` : ""}
            // value={sets.length > 1 ? sets.length.toString() : ""}
            onChange={(e) => handleNumericChange("sets", e.target.value)}
            onFocus={(e) => {
              if (sets.length === 1) {
                e.target.value = "";
              }
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                handleNumericChange("sets", "1");
              }
            }}
            $isEditing={!isCompleted}
            readOnly={isCompleted || sets.length > 1}
          />
          <AdditionalInputField
            type="text"
            placeholder={`Reps${sets.length > 1 ? "*" : ""}`}
            value={
              sets.length > 1
                ? `${volume} vol`
                : sets[0]?.reps
                ? `${sets[0].reps} reps`
                : ""
            }
            // value={sets.length > 1 ? volume.toString() : sets[0]?.reps || ""}
            onChange={(e) => handleNumericChange("reps", e.target.value, 0)}
            onClick={() => sets.length > 1 && onToggleExpand(exercise.id)}
            $isEditing={!isCompleted}
            readOnly={isCompleted || sets.length > 1}
          />
          <AdditionalInputField
            type="text"
            placeholder="Wt."
            // value={sets[0]?.weight ? `${sets[0].weight} wt` : ""}
            value={
              sets.length > 1
                ? "weight"
                : sets[0]?.weight
                ? `${sets[0].weight} wt`
                : ""
            }
            // value={sets[0]?.weight || ""}
            onChange={(e) => handleNumericChange("weight", e.target.value)}
            $isEditing={!isCompleted}
            readOnly={isCompleted || sets.length > 1}
          />
          <WeightToggle
            onClick={() => {
              const newWeightUnit = weightUnit === "lbs" ? "kg" : "lbs";
              setWeightUnit(newWeightUnit);
              onUpdate({
                ...exercise,
                goal: { ...exercise.goal, weightUnit: newWeightUnit },
                performed: { ...exercise.performed, weightUnit: newWeightUnit },
              });
            }}
            $isEditing={!isCompleted}
            disabled={isCompleted}
          >
            {weightUnit}
          </WeightToggle>
        </>
      );
    }
  };

  useEffect(() => {
    if (exercise.isCardio) {
      const initialCardioSets = (isPublished
        ? exercise.performed?.sets
        : exercise.goal?.sets) || [
        { duration: "", distance: "", intensity: "" },
      ];
      setCardioSets(
        Array.isArray(initialCardioSets)
          ? initialCardioSets
          : [{ duration: "", distance: "", intensity: "" }]
      );
    }
  }, [exercise, isPublished]);

  return (
    <InputWrapper>
      <InputFieldWrapper $isEditing={isEditing} $exerciseType={exercise.type}>
        <ToggleButton onClick={handleToggle}>
          {isCardio ? <FaRunning /> : <FaDumbbell />}
        </ToggleButton>
        <InputField
          type="text"
          value={inputValue}
          onClick={() => setIsEditing(true)}
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder="Search or add an exercise"
          $isEditing={isEditing}
          readOnly={isCompleted}
        />
        {suggestion && (
          <Suggestion onMouseDown={handleSuggestionClick}>
            {inputValue}
            {suggestion}
          </Suggestion>
        )}
        {inputValue &&
          !userExercises.some(
            (ex) => ex.name.toLowerCase() === inputValue.toLowerCase()
          ) && (
            <AddExerciseButton onClick={handleAddExercise}>
              <FaPlus />
            </AddExerciseButton>
          )}
        <AdditionalFieldsWrapper>
          {renderAdditionalFields()}
        </AdditionalFieldsWrapper>
      </InputFieldWrapper>

      <ExpandableBorder
        onClick={toggleExpand}
        $hasMultipleSets={
          isCardio
            ? Array.isArray(cardioSets) && cardioSets.length > 1
            : (isPublished ? performedSets : goalSets).length > 1
        }
      />
      {isExpanded && (isPublished ? performedSets : goalSets).length > 1 && (
        <ExpandedRowsWrapper>
          {isCardio ? renderExpandedCardioRows() : renderExpandedRows()}
        </ExpandedRowsWrapper>
      )}
    </InputWrapper>
  );
};

export default React.memo(ExerciseInput);
