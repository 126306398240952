import * as THREE from "three";
import DayCube from "./DayCube";
import DayTile from "./DayTile";
import { handleIncrementorRowClick } from "../animations/handleIncrementorRowClick";
import ScheduleConfig from "../config/ScheduleConfig"; // Import ScheduleConfig to access daysOfWeek

class Day {
  constructor(
    id,
    position,
    row,
    column, // Add column
    sceneConfig,
    isSelected,
    type,
    addRow,
    cubes,
    onDayClick, // Add onDayClick as a parameter
    date = null // Add date as a parameter
  ) {
    // Create a DayCube object
    const dayCube = new DayCube(
      id,
      { x: 0, y: 0, z: 0 },
      { width: 1, height: 0.8, depth: 1 }
    );

    // Create a DayTile object with specified dimensions and position it under the DayCube
    const dayTile = new DayTile(
      id,
      { x: 0, y: 0.8, z: 0 }, // Adjust the y position to lay it under the cube
      { width: 1, height: 0.2, depth: 1 },
      sceneConfig === "Schedule" ? date : null // Only pass date if the config is Schedule
    );

    // Create a group to hold both DayCube and DayTile
    this.group = new THREE.Group();
    this.group.add(dayCube.mesh);
    this.group.add(dayTile.mesh);

    // Attach the date to the group, DayCube, and DayTile
    this.group.userData = { id, sceneConfig, row, column, type, date };
    dayCube.mesh.userData = { id, date }; // Attach the date to the DayCube
    dayTile.mesh.userData = { id, date }; // Attach the date to the DayTile

    // Set the position of the group
    this.group.position.set(position.x, position.y, position.z);
    this.group.userData = { id, sceneConfig, row, column, type };

    // Set initial opacity for the Period configuration
    if (sceneConfig === "Period") {
      this.group.children.forEach((child) => {
        child.material.opacity = type === "incrementor" ? 0.5 : 1; // Toggle based on type
        child.material.transparent = true;
      });
    }

    // Add click event listener for toggles based on scene configuration
    this.group.callback = (cubes) => {
      const dayName = ScheduleConfig.daysOfWeek[column];
      console.log(`Day: ${dayName}, Week: ${row}, Date: ${date}`);
      onDayClick(row, column, date); // Call onDayClick with row, column, and date
    };
  }
}

export default Day;


// ####################################################


// import * as THREE from "three";
// import DayCube from "./DayCube";
// import DayTile from "./DayTile";
// import { handleIncrementorRowClick } from "../animations/handleIncrementorRowClick";
// import ScheduleConfig from "../config/ScheduleConfig"; // Import ScheduleConfig to access daysOfWeek


// class Day {
//   constructor(
//     id,
//     position,
//     row,
//     column, // Add column
//     sceneConfig,
//     isSelected,
//     type,
//     addRow,
//     cubes,
//     onDayClick, // Add onDayClick as a parameter
//     date = null // Add date as a parameter
//   ) {
//     // Create a DayCube object
//     const dayCube = new DayCube(
//       id,
//       { x: 0, y: 0, z: 0 },
//       { width: 1, height: 0.8, depth: 1 }
//     );

//     // Create a DayTile object with specified dimensions and position it under the DayCube
//     const dayTile = new DayTile(
//       id,
//       { x: 0, y: 0.8, z: 0 }, // Adjust the y position to lay it under the cube
//       { width: 1, height: 0.2, depth: 1 },
//       sceneConfig === "Schedule" ? date : null // Only pass date if the config is Schedule
//     );

//     // Create a group to hold both DayCube and DayTile
//     this.group = new THREE.Group();
//     this.group.add(dayCube.mesh);
//     this.group.add(dayTile.mesh);

//     // Attach the date to the group, DayCube, and DayTile
//     this.group.userData = { id, sceneConfig, row, column, type, date };
//     dayCube.mesh.userData = { id, date }; // Attach the date to the DayCube
//     dayTile.mesh.userData = { id, date }; // Attach the date to the DayTile

//     // Set the position of the group
//     this.group.position.set(position.x, position.y, position.z);

//     // Set initial opacity for the Period configuration
//     if (sceneConfig === "Period") {
//       this.group.children.forEach((child) => {
//         child.material.opacity = type === "incrementor" ? 0.5 : 1; // Toggle based on type
//         child.material.transparent = true;
//       });
//     }

//     // Add click event listener for toggles based on scene configuration
//     this.group.callback = (cubes) => {
//       const dayName = ScheduleConfig.daysOfWeek[column];
//       console.log(`Day: ${dayName}, Week: ${row}, Date: ${date}`);
//       onDayClick(row, column, date); // Call onDayClick with row, column, and date
//     };
//   }
// }
