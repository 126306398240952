
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaDumbbell, FaTrophy } from "react-icons/fa";

const Card = styled.div`
  // background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 10px;
  padding: 20px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ExerciseName = styled.h2`
  //   font-size: 1.5em;
  margin-bottom: 15px;

  text-transform: uppercase;

  font-size: 1.2em;
  font-weight: bold;
  background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: animateGradient 5s ease infinite;

  @keyframes animateGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const PRInput = styled.input`
  background: #333;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  width: 100px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const PRRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const PRLabel = styled.span`
  margin-right: 10px;
  width: 70px;
`;

const PercentageRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
`;

const PercentageButton = styled.button`
  background: ${(props) => (props.selected ? "#4a4a4a" : "#333")};
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background: #4a4a4a;
  }
`;

const CustomPercentageInput = styled(PRInput)`
  width: 60px;
`;

const CalculatedPRRow = styled(PRRow)`
  background: ${(props) =>
    props.type === "current"
      ? "linear-gradient(135deg, #6eccfb, #77e3b1)"
      : "linear-gradient(135deg, #3d6173, #e3d677)"};
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const Description = styled.p`
  font-size: 0.9em;
  color: #5f5c5c;
  margin-top: 15px;
`;

const PRCard = ({ exercise, onUpdatePR }) => {
  const [currentPR, setCurrentPR] = useState(exercise.currentPR || "");
  const [goalPR, setGoalPR] = useState(exercise.goalPR || "");
  const [selectedPercentage, setSelectedPercentage] = useState(100);
  const [customPercentage, setCustomPercentage] = useState("");
  const [isCustomPercentage, setIsCustomPercentage] = useState(false);
  const [calculatedCurrentPR, setCalculatedCurrentPR] = useState("");
  const [calculatedGoalPR, setCalculatedGoalPR] = useState("");

  const percentages = [100, 95, 90, 85, 80, 75, 70];

  useEffect(() => {
    setCurrentPR(exercise.currentPR || "");
    setGoalPR(exercise.goalPR || "");
    setSelectedPercentage(100);
    setCustomPercentage("");
    setIsCustomPercentage(false);
    updateCalculatedPRs(100);
  }, [exercise]);

  const calculatePR = (pr, percentage) => {
    const result = (parseFloat(pr) * percentage) / 100;
    return Number.isInteger(result) ? result.toString() : result.toFixed(2);
  };

  const formatPercentage = (value) => {
    return Math.round(parseFloat(value)).toString();
  };

  const calculatePercentage = (value, basePR) => {
    return ((parseFloat(value) / parseFloat(basePR)) * 100).toFixed(2);
  };

  const updateCalculatedPRs = (percentage) => {
    setCalculatedCurrentPR(calculatePR(currentPR, percentage));
    setCalculatedGoalPR(calculatePR(goalPR, percentage));
  };

  const handlePercentageClick = (percentage) => {
    setSelectedPercentage(percentage);
    setCustomPercentage(percentage.toString());
    setIsCustomPercentage(false);
  };

  const handleCustomPercentageChange = (e) => {
    const value = e.target.value;
    setCustomPercentage(value);
    if (value && !isNaN(value)) {
      const numValue = Number(value);
      setSelectedPercentage(numValue);
      setIsCustomPercentage(true);
      updateCalculatedPRs(numValue);
    }
  };

  const handleWeightChange = (type, value) => {
    if (type === "current") {
      setCurrentPR(value);
      onUpdatePR(exercise.objectID, {
        currentPR: parseFloat(value) || 0,
        goalPR: parseFloat(goalPR) || 0,
      });
    } else {
      setGoalPR(value);
      onUpdatePR(exercise.objectID, {
        currentPR: parseFloat(currentPR) || 0,
        goalPR: parseFloat(value) || 0,
      });
    }
  };

  const handleCalculatedPRChange = (type, value) => {
    const basePR = type === "current" ? currentPR : goalPR;
    if (basePR) {
      const newPercentage = calculatePercentage(value, basePR);
      setSelectedPercentage(parseFloat(newPercentage));
      setCustomPercentage(formatPercentage(newPercentage));
      setIsCustomPercentage(true);
      
      if (type === "current") {
        setCalculatedCurrentPR(value);
        setCalculatedGoalPR(calculatePR(goalPR, newPercentage));
      } else {
        setCalculatedGoalPR(value);
        setCalculatedCurrentPR(calculatePR(currentPR, newPercentage));
      }
    }
  };

  const getDescription = (percentage) => {
    // This is a placeholder. You should replace it with actual data.
    const descriptions = {
      100: "1 rep max",
      95: "1-3 sets of 1 rep",
      90: "1-3 sets of 2 reps",
      85: "3 sets of 3 reps",
      80: "5 sets of 3 reps",
      75: "3 sets of 5 reps",
      70: "5 sets of 5 reps",
    };
    return descriptions[percentage] || "";
  };

  return (
    <Card>
      <ExerciseName>{exercise.name}</ExerciseName>
      <PRRow>
        <PRLabel>Current PR:</PRLabel>
        <PRInput
          value={currentPR}
          onChange={(e) => handleWeightChange("current", e.target.value)}
        />
        <PRLabel>Goal PR:</PRLabel>
        <PRInput
          value={goalPR}
          onChange={(e) => handleWeightChange("goal", e.target.value)}
        />
      </PRRow>
      <PercentageRow>
        {percentages.map((p) => (
          <PercentageButton
            key={p}
            selected={selectedPercentage === p}
            onClick={() => handlePercentageClick(p)}
          >
            {p}%
          </PercentageButton>
        ))}
        <CustomPercentageInput
          value={customPercentage}
          onChange={handleCustomPercentageChange}
          onBlur={() => setCustomPercentage(formatPercentage(customPercentage))}
          placeholder="Custom %"
        />
      </PercentageRow>
      <CalculatedPRRow type="current">
        <PRLabel>Current:</PRLabel>
        <PRInput
          value={calculatePR(currentPR, selectedPercentage)}
          onChange={(e) => handleCalculatedPRChange("current", e.target.value)}
        />
      </CalculatedPRRow>
      <CalculatedPRRow type="goal">
        <PRLabel>Goal:</PRLabel>
        <PRInput
          value={calculatePR(goalPR, selectedPercentage)}
          onChange={(e) => handleCalculatedPRChange("goal", e.target.value)}
        />
      </CalculatedPRRow>
      {!isCustomPercentage && percentages.includes(selectedPercentage) && (
        <Description>
          At {selectedPercentage}%, typically an individual will be able to
          perform {getDescription(selectedPercentage)}
        </Description>
      )}
    </Card>
  );
};
export default PRCard;