

// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import AllMeso from './AllMeso';
// import { useProgram } from '../context/ProgramContext';
// import { useParams } from 'react-router-dom';
// import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
// import { db } from '../../services/firebaseConfig';
// import { useAuth } from '../../hooks/useAuth';

// const TrainingBlocksContainer = styled.div`
//   overflow-y: hidden;
// `;

// const AddProgramButton = styled.button`
//   position: fixed;
//   bottom: 20px;
//   right: 20px;
//   padding: 10px 20px;
//   background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
//   background-size: 300% 300%;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   font-size: 16px;
//   cursor: pointer;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const TrainingBlocks = () => {
//   const { programId } = useParams();
//   const { program, fetchProgram, createProgram } = useProgram();
//   const [isAllMesoOpen, setIsAllMesoOpen] = useState(true);
//   const { user } = useAuth();

//   useEffect(() => {
//     // Fetch the macrocycle data here if needed
//   }, [programId]);

//   const handleClose = () => {
//     setIsAllMesoOpen(false);
//   };

//   const handleAddMesocycle = async () => {
//     if (!user || !programId) return;
  
//     try {
//       const macrocycleRef = doc(db, "users", user.uid, "programs", programId);
//       const macrocycleDoc = await getDoc(macrocycleRef);
//       const macrocycleData = macrocycleDoc.data();
      
//       const currentBlocksCount = macrocycleData.programBlocks?.length || 0;
//       const newMesocycleName = `Cycle ${currentBlocksCount + 1}`;
  
//       // Use the centralized createProgram function with "Mesocycle Advanced" type
//       const newMesocycle = await createProgram(newMesocycleName, "Mesocycle Advanced", user.uid);
  
//       // Update macrocycle to include the new mesocycle in programBlocks
//       await updateDoc(macrocycleRef, {
//         programBlocks: arrayUnion(newMesocycle.id)
//       });
  
//       console.log("New Advanced Mesocycle added successfully with ID:", newMesocycle.id);
  
//       // Refresh the program data
//       await fetchProgram(programId);
  
//     } catch (error) {
//       console.error("Error creating advanced mesocycle:", error);
//       // You might want to set an error message here
//     }
//   };

//   if (!program || program.programType !== 'Macrocycle') {
//     return <div>Loading or invalid program type...</div>;
//   }

//   return (
//     <TrainingBlocksContainer>
//       {isAllMesoOpen && (
//         <AllMeso 
//           macrocycleId={programId} 
//           onClose={handleClose} 
//         />
//       )}
//       <AddProgramButton onClick={handleAddMesocycle}>
//         + Cycle
//       </AddProgramButton>
//     </TrainingBlocksContainer>
//   );
// };

// export default TrainingBlocks;




// ###############




// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import CarouselMeso from './CarouselMeso';
// import { useParams } from 'react-router-dom';
// import { useAuth } from '../../hooks/useAuth';
// import AddCycleButton from './AddCycleButton'; // Import the new component

// const TrainingBlocksContainer = styled.div`
//   overflow-y: hidden;
// `;



// const TrainingBlocks = () => {
//   const { programId } = useParams();
//   const [isAllMesoOpen, setIsAllMesoOpen] = useState(true);
//   const { user } = useAuth();

//   useEffect(() => {
//     // Fetch the macrocycle data here if needed
//   }, [programId]);

//   const handleClose = () => {
//     setIsAllMesoOpen(false);
//   };

//   return (
//     <TrainingBlocksContainer>
//               <AddCycleButton programId={programId}  />

//       {isAllMesoOpen && (
//         <CarouselMeso 
//           macrocycleId={programId} 
//           onClose={handleClose} 
//         />
//       )}
//       {/* Use the extracted button component */}
//     </TrainingBlocksContainer>
//   );
// };

// export default TrainingBlocks;


// // ####Code above works but is tied to CarouselMeso #########



import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CarouselMeso from './CarouselMeso';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import AddCycleButton from './AddCycleButton'; // Import the new component
import ScrollGallery from './ScrollGallery';


const TrainingBlocksContainer = styled.div`
//   overflow-y: hidden;
`;



const TrainingBlocks = () => {
  const { programId } = useParams();
  const [isAllMesoOpen, setIsAllMesoOpen] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    // Fetch the macrocycle data here if needed
  }, [programId]);

  const handleClose = () => {
    setIsAllMesoOpen(false);
  };

  return (
    <TrainingBlocksContainer>
              <AddCycleButton programId={programId}  />
              {/* <ScrollGallery /> */}
              <CarouselMeso />
    </TrainingBlocksContainer>
  );
};

export default TrainingBlocks;