
import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { useWorkout } from "../context/WorkoutContext";
import {
  FaChevronLeft,
} from "react-icons/fa";
import ExerciseInput from "../exercises/exerciseInput/ExerciseInput";
import WorkoutHeader from "./WorkoutHeader";
import { v4 as uuidv4 } from "uuid";
import { DndProvider, useDrag, useDrop, useDragLayer } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import WorkoutSummary from "./WorkoutSummary";

const WorkoutContainer = styled.div`
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  height: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #333; /* The inner background color */

  /* Gradient Border */
  border: 5px solid transparent;
  border-image: radial-gradient(circle, #9100ff, #ff00ec, #ffc800) 1;

  /* Ensuring border-radius is applied correctly */
  mask-image: radial-gradient(circle, white 99%, transparent 100%);
  -webkit-mask-image: radial-gradient(circle, white 99%, transparent 100%);

  animation: animateBorder 5s infinite;

  @keyframes animateBorder {
    0% {
      border-image: radial-gradient(circle, #9100ff, #ff00ec, #ffc800) 1;
    }
    50% {
      border-image: radial-gradient(circle, #ffc800, #ff00ec, #9100ff) 1;
    }
    100% {
      border-image: radial-gradient(circle, #9100ff, #ff00ec, #ffc800) 1;
    }
  }

  @media (max-width: 768px) {
    /* Add your responsive styles here */
  }
`;

const MainSection = styled.div`
  flex: 1;
  position: relative;
  overflow-y: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  scrollbar-width: none; /* Firefox */
`;

const Section = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
  padding-bottom: 30px;

  background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  border-radius: 8px;
  padding: 1px 10px 33px;
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #545454;

  // background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  background: linear-gradient(43deg, #2c2c2c, #3a3a3a);
  margin: 15px 0px 5px 0px;
  padding: 0px 10px;
  border-radius: 7px;
  width: 60%;

  color: ${({ type }) =>
    type === "single"
      ? "#4caf50"
      : type === "super"
        ? "#2196f3"
        : type === "circuit"
          ? "#ffeb3b"
          : "#545454"};
`;









const PlusButton = styled.button`
  border: none;
  color: white;
  height: 12px;
  font-size: 1em;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  background-color: #3a3a3a;

  &:hover {
    color: #fff;
    background-color: #4a4a4a;
  }
`;

const ExerciseTypeSelector = styled.div`
  position: absolute;
  bottom: 40px;
  left: 10px;
  background: #3a3a3a;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;



const DraggableExercise = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;



const CloseButton = styled.button`
  position: fixed;
  top: 42px;
  left: 25px;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: #ccc;
    background: none;
  }
  @media (max-width: 768px) {
    top: 27px;
  }

  // Hide on desktop
  @media (min-width: 769px) {
    display: none;
  }
`;






// #########################################################################

const DragPreview = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          padding: "10px",
          borderRadius: "4px",
          left: currentOffset?.x ?? 0,
          top: currentOffset?.y ?? 0,
          transform: "translate(-50%, -50%)",
        }}
      >
        Moving: {item?.exercise?.value || "Exercise"}
      </div>
    </div>
  );
};

// ###############################################################################

const ExerciseItem = ({
  exercise,
  index,
  moveExercise,
  category,
  isPublished,
  isCompleted,
  onUpdate,
  isExpanded,
  onToggleExpand,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "EXERCISE",
    hover(item, monitor) {
      if (!ref.current || isPublished) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveExercise(dragIndex, hoverIndex, category);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "EXERCISE",
    item: { type: "EXERCISE", id: exercise.id, index, category },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isPublished,
  });

  drag(drop(ref));

  return (
    <DraggableExercise ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {!isPublished}
      <ExerciseInput
        exercise={exercise}
        category={category}
        isPublished={isPublished}
        isCompleted={isCompleted}
        onUpdate={onUpdate}
        isExpanded={isExpanded}
        onToggleExpand={onToggleExpand}
      />
    </DraggableExercise>
  );
};

// ########################################################################

const WorkoutForm = ({
  workout: initialWorkout,
  programId,
  onDelete,
  onClose,
  onUpdate,
}) => {
  const {
    updateWorkout,
    toggleStatus,
    startWorkout,
    completeWorkout,
    cancelWorkout,
    duplicateWorkout,
    saveWorkoutToFirestore,
    fetchWorkoutFromFirestore,
    deleteWorkout,
  } = useWorkout();

  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showTypeSelector, setShowTypeSelector] = useState(null);
  const typeSelectorRef = useRef(null);
  const backend = isMobile ? TouchBackend : HTML5Backend;
  const [expandedExerciseId, setExpandedExerciseId] = useState(null);
  const [showValidation, setShowValidation] = useState(false);

  const handleToggleExpand = useCallback((exerciseId) => {
    setExpandedExerciseId((prevId) =>
      prevId === exerciseId ? null : exerciseId
    );
  }, []);

  console.log("Current workout:", currentWorkout);



  useEffect(() => {
    const fetchWorkout = async () => {
      try {
        setLoading(true);
        console.log("Fetching workout:", initialWorkout.id, programId);
        const fetchedWorkout = await fetchWorkoutFromFirestore(
          initialWorkout.id,
          programId
        );
        console.log("Fetched workout:", fetchedWorkout);
        if (fetchedWorkout) {
          setCurrentWorkout(fetchedWorkout);
        } else {
          setError("Workout not found");
        }
      } catch (err) {
        console.error("Error fetching workout:", err);
        setError("Failed to load workout");
      } finally {
        setLoading(false);
      }
    };

    fetchWorkout();
  }, [initialWorkout.id, programId, fetchWorkoutFromFirestore]);

  const moveExercise = useCallback(
    (dragIndex, hoverIndex, category) => {
      setCurrentWorkout((prevWorkout) => {
        if (prevWorkout.status === "published") {
          return prevWorkout;
        }

        const newExercises = [...prevWorkout.categories[category]];
        const draggedExercise = newExercises[dragIndex];

        // Function to check if an index is within a group
        const isWithinGroup = (index, exercises) => {
          const exercise = exercises[index];
          if (exercise.type === "super" || exercise.type === "circuit") {
            const groupId =
              exercise.type === "super" ? exercise.superID : exercise.circuitID;
            const prevExercise = exercises[index - 1];
            const nextExercise = exercises[index + 1];
            return (
              (prevExercise &&
                (prevExercise.superID === groupId ||
                  prevExercise.circuitID === groupId)) ||
              (nextExercise &&
                (nextExercise.superID === groupId ||
                  nextExercise.circuitID === groupId))
            );
          }
          return false;
        };

        // Prevent single exercises from being inserted into groups
        if (
          draggedExercise.type === "single" &&
          isWithinGroup(hoverIndex, newExercises)
        ) {
          return prevWorkout; // Don't allow the move
        }

        if (
          draggedExercise.type === "super" ||
          draggedExercise.type === "circuit"
        ) {
          const groupId =
            draggedExercise.type === "super"
              ? draggedExercise.superID
              : draggedExercise.circuitID;
          const groupExercises = newExercises.filter(
            (ex) =>
              (ex.type === "super" && ex.superID === groupId) ||
              (ex.type === "circuit" && ex.circuitID === groupId)
          );
          const nonGroupExercises = newExercises.filter(
            (ex) =>
              !(ex.type === "super" && ex.superID === groupId) &&
              !(ex.type === "circuit" && ex.circuitID === groupId)
          );

          // Find the correct insertion index for the group
          let insertIndex = hoverIndex;
          while (
            insertIndex > 0 &&
            isWithinGroup(insertIndex - 1, newExercises)
          ) {
            insertIndex--;
          }

          nonGroupExercises.splice(insertIndex, 0, ...groupExercises);
          const updatedWorkout = {
            ...prevWorkout,
            categories: {
              ...prevWorkout.categories,
              [category]: nonGroupExercises,
            },
          };

          saveWorkoutToFirestore(updatedWorkout, programId);
          return updatedWorkout;
        } else {
          // For single exercises, find the nearest valid position
          let validHoverIndex = hoverIndex;
          while (
            validHoverIndex < newExercises.length &&
            isWithinGroup(validHoverIndex, newExercises)
          ) {
            validHoverIndex++;
          }
          while (
            validHoverIndex > 0 &&
            isWithinGroup(validHoverIndex - 1, newExercises)
          ) {
            validHoverIndex--;
          }

          newExercises.splice(dragIndex, 1);
          newExercises.splice(validHoverIndex, 0, draggedExercise);
          const updatedWorkout = {
            ...prevWorkout,
            categories: {
              ...prevWorkout.categories,
              [category]: newExercises,
            },
          };

          saveWorkoutToFirestore(updatedWorkout, programId);
          return updatedWorkout;
        }
      });
    },
    [saveWorkoutToFirestore, programId]
  );

  // ####################################################################

  const handleTypeSelection = useCallback((category, type) => {
    setShowTypeSelector(null);
    let newExercises;
    if (type === "single") {
      newExercises = [
        {
          id: uuidv4(),
          type,
          value: "",
          goal: {
            sets: [{ reps: "", weight: "" }], // Default to one set with empty reps and weight
            weightUnit: "lbs",
          },
          performed: {
            sets: [{ reps: "", weight: "" }], // Default to one set with empty reps and weight
            weightUnit: "lbs",
          },
        },
      ];
    } else if (type === "super") {
      const superID = uuidv4();
      newExercises = [
        {
          id: uuidv4(),
          type,
          superID,
          value: "",
          goal: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
          performed: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
        },
        {
          id: uuidv4(),
          type,
          superID,
          value: "",
          goal: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
          performed: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
        },
      ];
    } else if (type === "circuit") {
      const circuitID = uuidv4();
      newExercises = [
        {
          id: uuidv4(),
          type,
          circuitID,
          value: "",
          goal: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
          performed: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
        },
      ];
    }

    setCurrentWorkout((prev) => ({
      ...prev,
      categories: {
        ...prev.categories,
        [category]: [...prev.categories[category], ...newExercises],
      },
    }));
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (
      typeSelectorRef.current &&
      !typeSelectorRef.current.contains(event.target)
    ) {
      setShowTypeSelector(null);
    }
  }, []);

  useEffect(() => {
    if (showTypeSelector) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showTypeSelector, handleClickOutside]);



  const handlePublish = useCallback(async () => {
    if (!currentWorkout) return;
  
    // Check for required fields using the latest version of currentWorkout
    const latestWorkout = await fetchWorkoutFromFirestore(currentWorkout.id, programId);
    
    if (!latestWorkout.mainMovement?.trim() || !latestWorkout.intensity?.trim()) {
      setShowValidation(true);
      return;
    }
  
    try {
      const updatedWorkout = await toggleStatus(currentWorkout.id, programId);
      if (updatedWorkout) {
        setCurrentWorkout(updatedWorkout);
        setShowValidation(false);
      }
    } catch (err) {
      console.error("Error publishing workout:", err);
      setError("Failed to publish workout");
    }
  }, [currentWorkout, toggleStatus, programId, fetchWorkoutFromFirestore]);


  // const handlePublish = useCallback(async () => {
  //   if (!currentWorkout) return;

  //   // Check for required fields
  //   if (!currentWorkout.mainMovement?.trim() || !currentWorkout.intensity?.trim()) {
  //     setShowValidation(true); // Show validation state
  //     return;
  //   }

  //   try {
  //     const updatedWorkout = await toggleStatus(currentWorkout.id, programId);
  //     if (updatedWorkout) {
  //       setCurrentWorkout(updatedWorkout);
  //       setShowValidation(false); // Reset validation state on success
  //     }
  //   } catch (err) {
  //     console.error("Error publishing workout:", err);
  //     setError("Failed to publish workout");
  //   }
  // }, [currentWorkout, toggleStatus, programId]);



  const handleDelete = useCallback(async () => {
    if (!currentWorkout) return;
    try {
      await deleteWorkout(currentWorkout.id, programId);
      onDelete(currentWorkout.id);
    } catch (err) {
      console.error("Error deleting workout:", err);
      setError("Failed to delete workout");
    }
  }, [currentWorkout, deleteWorkout, programId, onDelete]);

  const handleDuplicate = useCallback(async () => {
    if (!currentWorkout) return;
    try {
      await duplicateWorkout(currentWorkout, programId);
    } catch (err) {
      console.error("Error duplicating workout:", err);
      setError("Failed to duplicate workout");
    }
  }, [currentWorkout, duplicateWorkout, programId]);

  // ######################################3

  const handleExerciseUpdate = useCallback(
    (category, updatedExercise) => {
      if (!currentWorkout || !programId) return;

      setCurrentWorkout((prev) => {
        let updatedCategories = {
          ...prev.categories,
          [category]: prev.categories[category].map((ex) =>
            ex.id === updatedExercise.id ? updatedExercise : ex
          ),
        };

        // New deletion logic
        if (updatedExercise.value === null) {
          updatedCategories[category] = updatedCategories[category].filter(
            (ex) => ex.id !== updatedExercise.id
          );
        }

        if (
          updatedExercise.type === "circuit" &&
          prev.status === "unpublished"
        ) {
          const circuitExercises = updatedCategories[category].filter(
            (ex) =>
              ex.type === "circuit" &&
              ex.circuitID === updatedExercise.circuitID
          );

          // Only add a new field locally if the last field has a value and it's the current field being updated
          const lastCircuitExercise =
            circuitExercises[circuitExercises.length - 1];
          if (
            lastCircuitExercise.id === updatedExercise.id &&
            updatedExercise.value.trim() !== ""
          ) {
            const newExercise = {
              id: uuidv4(),
              type: "circuit",
              circuitID: updatedExercise.circuitID,
              value: "",
              goal: { sets: [{ reps: "", weight: "" }], weightUnit: "lbs" },
              performed: {
                sets: [{ reps: "", weight: "" }],
                weightUnit: "lbs",
              },
            };
            updatedCategories[category].push(newExercise);
          }
        }

        const updatedWorkout = {
          ...prev,
          categories: updatedCategories,
        };

        // Filter out empty circuit exercises before saving to Firestore
        const firestoreWorkout = {
          ...updatedWorkout,
          categories: Object.fromEntries(
            Object.entries(updatedWorkout.categories).map(
              ([cat, exercises]) => [
                cat,
                exercises.filter(
                  (ex) =>
                    ex.type !== "circuit" || // Keep all non-circuit exercises
                    (ex.type === "circuit" && ex.value.trim() !== "") // Only keep non-empty circuit exercises
                ),
              ]
            )
          ),
        };

        // Only update Firestore if there are actual changes
        if (JSON.stringify(prev) !== JSON.stringify(firestoreWorkout)) {
          updateWorkout(firestoreWorkout, programId);
        }

        return updatedWorkout;
      });
    },
    [currentWorkout, programId, updateWorkout]
  );

  //  ########################################################################

  useEffect(() => {
    const refreshWorkout = async () => {
      if (
        currentWorkout?.id &&
        (currentWorkout.actions?.state === "completed" ||
          currentWorkout.actions?.state === "cancelled")
      ) {
        console.log("Refreshing workout data...");
        const refreshedWorkout = await fetchWorkoutFromFirestore(
          currentWorkout.id,
          programId
        );
        setCurrentWorkout(refreshedWorkout);
      }
    };

    refreshWorkout();
  }, [
    currentWorkout?.id,
    currentWorkout?.actions?.state,
    fetchWorkoutFromFirestore,
    programId,
  ]);



  const handleCompleteWorkout = useCallback(async () => {
    try {
      console.log("Completing workout...");
      const completedWorkout = await completeWorkout(
        currentWorkout.id,
        programId
      );
      console.log("Completed workout:", completedWorkout);
      setCurrentWorkout(completedWorkout);
    } catch (err) {
      console.error("Error completing workout:", err);
    }
  }, [currentWorkout, completeWorkout, programId]);

  const handleCancelWorkout = useCallback(async () => {
    try {
      console.log("Cancelling workout...");
      const cancelledWorkout = await cancelWorkout(
        currentWorkout.id,
        programId
      );
      console.log("Cancelled workout:", cancelledWorkout);
      setCurrentWorkout(cancelledWorkout);
    } catch (err) {
      console.error("Error cancelling workout:", err);
    }
  }, [currentWorkout, cancelWorkout, programId]);

  if (loading) {
    return <div>Loading workout...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentWorkout) {
    return <div>Workout not found</div>;
  }

  const renderExercises = (category) => {
    return (
      <>
        {showTypeSelector === category && (
          <ExerciseTypeSelector ref={typeSelectorRef}>
            {["single", "super", "circuit"].map((type) => (
              <button
                key={type}
                onClick={() => handleTypeSelection(category, type)}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </ExerciseTypeSelector>
        )}
        {currentWorkout.categories[category]?.map((exercise, index) => (
          <ExerciseItem
            key={exercise.id}
            exercise={exercise}
            index={index}
            moveExercise={moveExercise}
            category={category}
            isPublished={currentWorkout.status === "published"}
            isCompleted={currentWorkout.actions?.state === "completed"}
            onUpdate={(updatedExercise) =>
              handleExerciseUpdate(category, updatedExercise)
            }
            isExpanded={expandedExerciseId === exercise.id}
            onToggleExpand={handleToggleExpand}
          />
        ))}
      </>
    );
  };

  return (
    <DndProvider backend={backend} options={{ enableMouseEvents: true }}>
      <WorkoutContainer>
        <CloseButton onClick={onClose}>
          <FaChevronLeft />
        </CloseButton>
        <MainSection>
          <WorkoutHeader
            currentWorkout={currentWorkout}
            handlePublish={handlePublish}
            handleDelete={handleDelete}
            handleCompleteWorkout={handleCompleteWorkout}
            handleCancelWorkout={handleCancelWorkout}
            handleDuplicate={handleDuplicate}
            updateWorkout={updateWorkout}
            saveWorkoutToFirestore={saveWorkoutToFirestore}
            programId={programId}
            showValidation={showValidation}
            setShowValidation={setShowValidation}
            onWorkoutUpdate={(updatedWorkout) => {
              console.log('Workout Updated:', updatedWorkout);
              setCurrentWorkout(updatedWorkout);
              if (onUpdate) {
                onUpdate(updatedWorkout); // Notify parent of the update
              }
            }}
          />


          {currentWorkout.actions?.state === "completed" ? (
            <WorkoutSummary workout={currentWorkout} />
          ) : (
            <>
              {/* ####################DND STUFF ##################################### */}

              {["corrective", "activation", "exercises", "esd"].map(
                (category) => (
                  <Section key={category}>
                    <SectionTitle>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                      {currentWorkout.status === "unpublished" && (
                        <PlusButton
                          onClick={() => setShowTypeSelector(category)}
                        >
                          +{/* <FaPlus /> */}
                        </PlusButton>
                      )}
                    </SectionTitle>

                    {renderExercises(category)}
                  </Section>
                )
              )}
            </>
          )}
        </MainSection>
      </WorkoutContainer>
      <DragPreview />
    </DndProvider>
  );
};

export default React.memo(WorkoutForm);


