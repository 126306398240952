

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  updateDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import { useAuth } from "../../hooks/useAuth";

const UserExercisesContext = createContext();

export const useUserExercises = () => useContext(UserExercisesContext);

export const UserExercisesProvider = ({ children }) => {
  const [exercises, setExercises] = useState([]);
  const { user } = useAuth();

  // Set up real-time listener for userExercises
  useEffect(() => {
    if (!user?.uid) {
      setExercises([]);
      return;
    }

    console.log("Setting up real-time listener for userExercises");
    const userExercisesRef = collection(db, "users", user.uid, "userExercises");
    const exercisesQuery = query(userExercisesRef, orderBy("createdAt", "desc"));
    
    const unsubscribe = onSnapshot(
      exercisesQuery,
      (snapshot) => {
        const exercisesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Real-time update received:", exercisesList);
        setExercises(exercisesList);
      },
      (error) => {
        console.error("Error in real-time listener:", error);
      }
    );

    // Cleanup subscription
    return () => {
      console.log("Cleaning up real-time listener");
      unsubscribe();
    };
  }, [user?.uid]);

  // Add exercise - no need to update state manually due to real-time listener
  const addExercise = useCallback(async (exerciseData) => {
    if (!user?.uid) return null;

    try {
      const userExercisesRef = collection(db, "users", user.uid, "userExercises");
      const newExerciseRef = doc(userExercisesRef);
      
      const exercise = {
        ...exerciseData,
        id: newExerciseRef.id,
        objectID: exerciseData.objectID || newExerciseRef.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        sourceExerciseId: exerciseData.objectID,
        customized: false,
      };

      await setDoc(newExerciseRef, exercise);
      console.log("Exercise added:", exercise);
      return exercise;
    } catch (error) {
      console.error("Error adding exercise:", error);
      throw error;
    }
  }, [user?.uid]);

  // Edit exercise - no need to update state manually
  const editExercise = useCallback(async (updatedExercise) => {
    if (!user?.uid) return false;

    try {
      const exerciseRef = doc(
        db, 
        "users", 
        user.uid, 
        "userExercises", 
        updatedExercise.id
      );

      const exerciseToUpdate = {
        ...updatedExercise,
        updatedAt: new Date().toISOString(),
        customized: true,
      };

      await updateDoc(exerciseRef, exerciseToUpdate);
      console.log("Exercise updated:", exerciseToUpdate);
      return true;
    } catch (error) {
      console.error("Error editing exercise:", error);
      throw error;
    }
  }, [user?.uid]);

  // Delete exercise - no need to update state manually
  const deleteExercise = useCallback(async (exerciseId) => {
    if (!user?.uid) {
      console.error("No user authenticated");
      return false;
    }

    try {
      // Log the exercise being deleted
      console.log("Attempting to delete exercise with ID:", exerciseId);

      // Find the exercise in our current state to get the correct ID
      const exerciseToDelete = exercises.find(ex => 
        ex.id === exerciseId || 
        ex.objectID === exerciseId || 
        ex.sourceExerciseId === exerciseId
      );

      if (!exerciseToDelete) {
        console.error("Exercise not found:", exerciseId);
        return false;
      }

      // Use the document ID (id property) for deletion
      const exerciseRef = doc(
        db, 
        "users", 
        user.uid, 
        "userExercises", 
        exerciseToDelete.id
      );

      console.log("Deleting exercise at path:", exerciseRef.path);
      
      await deleteDoc(exerciseRef);
      console.log("Exercise successfully deleted:", exerciseToDelete.id);
      return true;
    } catch (error) {
      console.error("Error deleting exercise:", error);
      console.error("Failed exercise ID:", exerciseId);
      throw error;
    }
  }, [user?.uid, exercises]);

  // Helper functions using real-time data
  const exerciseExists = useCallback((exerciseId) => {
    return exercises.some(ex => 
      ex.id === exerciseId || 
      ex.objectID === exerciseId || 
      ex.sourceExerciseId === exerciseId
    );
  }, [exercises]);

  const getExerciseById = useCallback((exerciseId) => {
    return exercises.find(ex => 
      ex.id === exerciseId || 
      ex.objectID === exerciseId || 
      ex.sourceExerciseId === exerciseId
    ) || null;
  }, [exercises]);

  // Memoize the context value
  const value = React.useMemo(() => ({
    exercises,
    addExercise,
    editExercise,
    deleteExercise,
    exerciseExists,
    getExerciseById,
  }), [
    exercises,
    addExercise,
    editExercise,
    deleteExercise,
    exerciseExists,
    getExerciseById
  ]);

  return (
    <UserExercisesContext.Provider value={value}>
      {children}
    </UserExercisesContext.Provider>
  );
};