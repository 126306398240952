// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import CalendarGrid from '../Calendar3d/config/CalendarGrid';
// import { useProgram } from '../context/ProgramContext';

// const CalendarContainer = styled.div`
//   width: 300px;
//   background-color: #f5f5f5;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// `;

// const Header = styled.h2`
//   text-align: center;
//   margin-bottom: 20px;
//   color: #333;
// `;

// const WorkoutCalendar = ({ selectedDate, onSelectDate }) => {
//   const { program } = useProgram();
//   const [currentMonth, setCurrentMonth] = useState(new Date());

//   useEffect(() => {
//     if (selectedDate) {
//       setCurrentMonth(new Date(selectedDate));
//     }
//   }, [selectedDate]);

//   const handleDayClick = (row, column, date) => {
//     onSelectDate(date);
//   };

//   return (
//     <CalendarContainer>
//       <Header>{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</Header>
//       <CalendarGrid 
//         periodLength={1}  // Show only one month
//         currentMonth={currentMonth}
//         onDayClick={handleDayClick}
//         selectedDate={selectedDate}
//       />
//     </CalendarContainer>
//   );
// };

// export default WorkoutCalendar;


// #####################################################
// Adding 3d Calendar to the WorkoutCalendar component
// #####################################################





import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { useWorkout } from '../context/WorkoutContext';
import { useProgram } from '../context/ProgramContext';
import Day from '../Calendar3d/objects/Day';
import WeekDay from '../Calendar3d/objects/WeekDay';

import ScheduleConfig from '../Calendar3d/config/ScheduleConfig';
import { mapDatesToGrid } from '../Calendar3d/utils/calendarUtils';

const CalendarContainer = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  
    
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2c2c2c;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -10%
  
`;

const MonthYearDisplay = styled.h2`
  margin: 0;
  color: white;
  font-size: 16px;
`;

const NavigationButton = styled.button`
  background-color: #333;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;

const CalendarCanvas = styled.div`
  flex-grow: 1;
  position: relative;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const WeekDayLabel = styled.div`
  position: absolute;
  color: white;
  font-size: 14px;
  font-weight: bold;
`;

const DateLabel = styled.div`
  position: absolute;
  color: white;
  font-size: 12px;
`;

const WorkoutCalendar = ({ selectedDate, onSelectDate }) => {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [textPositions, setTextPositions] = useState([]);

  const setupScene = useCallback(() => {
    const scene = new THREE.Scene();
    const canvas = containerRef.current.querySelector('.calendar-canvas');
    const aspectRatio = canvas.clientWidth / canvas.clientHeight;
    const camera = new THREE.PerspectiveCamera(50, aspectRatio, 0.1, 1000);
    // camera.position.set(ScheduleConfig.cameraPosition.x, ScheduleConfig.cameraPosition.y, ScheduleConfig.cameraPosition.z);
    camera.position.set(0, 10, 5);  // Adjust the camera position for better orientation
    camera.lookAt(new THREE.Vector3(0, 0 , 0));

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
    canvas.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;
    controls.minDistance = 6;  // Minimum zoom distance
    controls.maxDistance = 12;  // Maximum zoom distance

    controls.enablePan = true;  // Enable panning
    controls.enableRotate = true;  // Enable rotation

    controls.autoRotate = false;  // Disable auto-rotation
    controls.autoRotateSpeed = 2.0;  // Adjust auto-rotate speed if needed

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 10, 7.5);
    scene.add(directionalLight);

    return { scene, camera, renderer, controls };
  }, []);

  const renderCalendar = useCallback(() => {
    if (!sceneRef.current) return;

    const scene = sceneRef.current;
    scene.clear();

    const dateGrid = mapDatesToGrid(currentMonth.getFullYear(), currentMonth.getMonth());
    console.log("Generated Date Grid: ", dateGrid);

    const newTextPositions = [];

// Rendering WeekDay objects
ScheduleConfig.daysOfWeek.forEach((day, j) => {
    const position = {
      x: j * ScheduleConfig.spacing - ((ScheduleConfig.gridSize - 1) * ScheduleConfig.spacing) / 2,
      y: 0,
      z: -((ScheduleConfig.rows) * ScheduleConfig.spacing) / 2 - 1.5,
    };
  
    const weekDay = new WeekDay(j, position, day);  // Pass the label here
    scene.add(weekDay.cube);
  });
  

    // Render Day objects
    dateGrid.forEach((week, i) => {
        week.forEach((day, j) => {
          if (day) {
            const dayDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
            console.log(`Rendering Day: ${dayDate.toDateString()} at row ${i}, column ${j}`);
            
            // Proceed with rendering as before
            const position = {
              x: j * ScheduleConfig.spacing - ((ScheduleConfig.gridSize - 1) * ScheduleConfig.spacing) / 2,
              y: 0,
              z: i * ScheduleConfig.spacing - ((ScheduleConfig.rows - 1) * ScheduleConfig.spacing) / 2,
            };
            
            const dayObject = new Day(
              i * ScheduleConfig.gridSize + j,
              position,
              i,
              j,
              'Schedule',
              selectedDate && dayDate.toDateString() === selectedDate.toDateString(),
              'Day',
              null,
              null,
              onSelectDate,
              dayDate // Ensure this is a Date object
            );
            
            scene.add(dayObject.group);
          }
        });
      });
      
      

    setTextPositions(newTextPositions);
  }, [currentMonth, selectedDate, onSelectDate]);

  // #####################################################

  useEffect(() => {
    const sceneSetup = setupScene();
    if (sceneSetup) {
      const { scene, camera, renderer, controls } = sceneSetup;
      sceneRef.current = scene;
      cameraRef.current = camera;
      rendererRef.current = renderer;
      controlsRef.current = controls;
  
      renderCalendar();
  
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
  
      const handleResize = () => {
        const canvas = containerRef.current?.querySelector('.calendar-canvas');
        if (canvas) {
          const { clientWidth, clientHeight } = canvas;
          camera.aspect = clientWidth / clientHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(clientWidth, clientHeight);
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        controls.dispose();
        renderer.dispose();
  
        // Safeguard: Ensure containerRef.current exists before using it
        if (containerRef.current) {
          const canvas = containerRef.current.querySelector('.calendar-canvas');
          if (canvas && renderer.domElement) {
            canvas.removeChild(renderer.domElement);
          }
        }
      };
    }
  }, [setupScene, renderCalendar]);
  



  // #####################################################

  useEffect(() => {
    renderCalendar();
  }, [renderCalendar, currentMonth, selectedDate]);

  const updateTextPositions = useCallback(() => {
    if (!cameraRef.current || !rendererRef.current) return;

    const camera = cameraRef.current;
    const renderer = rendererRef.current;
    const canvas = renderer.domElement;

    setTextPositions(prevPositions => 
        prevPositions.map(item => {
          const screenPosition = item.position.clone().project(camera);
          return {
            ...item,
            screenX: (screenPosition.x * 0.5 + 0.5) * canvas.clientWidth,
            screenY: ((-screenPosition.y) * 0.5 + 0.5) * canvas.clientHeight
          };
        })
      );
    }, []);

    useEffect(() => {
        const animationId = requestAnimationFrame(updateTextPositions);
        return () => cancelAnimationFrame(animationId);
      }, [updateTextPositions]);


      // #####################################################

      const handleClick = useCallback((event) => {
        if (!cameraRef.current || !sceneRef.current) return;
      
        const canvas = containerRef.current.querySelector('.calendar-canvas');
        const rect = canvas.getBoundingClientRect();
        
        const mouse = new THREE.Vector2();
        const raycaster = new THREE.Raycaster();
        
        mouse.x = ((event.clientX - rect.left) / canvas.clientWidth) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / canvas.clientHeight) * 2 + 1;
      
        raycaster.setFromCamera(mouse, cameraRef.current);
      
        const intersectedObjects = sceneRef.current.children;
        console.log("Intersected Objects:", intersectedObjects);
      
        const intersects = raycaster.intersectObjects(intersectedObjects, true);
        console.log("Intersects:", intersects);
      
        if (intersects.length > 0) {
          let intersectedObject = intersects[0].object;
          console.log("Intersected Object:", intersectedObject);
      
          // Traverse up the hierarchy and log the objects
          while (intersectedObject) {
            console.log("Inspecting Object:", intersectedObject);
            if (intersectedObject.userData && intersectedObject.userData.date) {
              console.log("Found Date in Object:", intersectedObject.userData.date);
              onSelectDate(intersectedObject.userData.date);
              return;
            }
            intersectedObject = intersectedObject.parent;
          }
      
          console.log("Date not found in intersected object or its parents.");
        }
      }, [onSelectDate]);
      
      
      
      
      
      
      



  // #####################################################

  const changeMonth = useCallback((increment) => {
    setCurrentMonth(prevMonth => {
      const newMonth = new Date(prevMonth);
      newMonth.setMonth(newMonth.getMonth() + increment);
      return newMonth;
    });
  }, []);

  return (
    <CalendarContainer ref={containerRef}>
      <CalendarHeader>
        <NavigationButton onClick={() => changeMonth(-1)}>Prev</NavigationButton>
        <MonthYearDisplay>
          {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </MonthYearDisplay>
        <NavigationButton onClick={() => changeMonth(1)}>Next</NavigationButton>
      </CalendarHeader>
      <CalendarCanvas className="calendar-canvas" onClick={handleClick}>
        <TextOverlay>
          {textPositions.map((item, index) => 
            item.type === 'weekday' ? (
              <WeekDayLabel key={index} style={{ left: `${item.screenX}px`, top: `${item.screenY}px` }}>
                {item.text}
              </WeekDayLabel>
            ) : (
              <DateLabel key={index} style={{ left: `${item.screenX}px`, top: `${item.screenY}px` }}>
                {item.text}
              </DateLabel>
            )
          )}
        </TextOverlay>
      </CalendarCanvas>
    </CalendarContainer>
  );
};

export default WorkoutCalendar;