
// ########################################################

import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgramsList from '../components/ProgramsPage/ProgramsList';
import ActiveProgramStatus from '../components/ProgramsPage/ActiveProgramStatus';
import styled from 'styled-components';
import { useAuth } from '../hooks/useAuth';
import { useProgram } from '../components/context/ProgramContext';
import { WorkoutProvider } from '../components/context/WorkoutContext';

const ProgramsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #333;
  position: relative;
`;





const MainContent = styled.main`
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1;
  margin: 0 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Programs = () => {
  const { user } = useAuth();
  const { createProgram, programs } = useProgram();
  const mainContentRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const mainContent = mainContentRef.current;

    if (mainContent) {
      const handleTouchMove = (e) => {
        // Allow scrolling within MainContent
        e.stopPropagation();
      };

      mainContent.addEventListener('touchmove', handleTouchMove, { passive: false });

      return () => {
        mainContent.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, []);

  const handleViewProgram = (programId) => {
    navigate(`/program/${programId}`);
  };

  const activeProgram = programs.find(program => 
    program.programType.startsWith('Mesocycle') && program.status === 'Current'
  );

  return (
    <ProgramsContainer>

      <MainContent ref={mainContentRef}>

        {activeProgram && (
          <WorkoutProvider programId={activeProgram.id}>
            <ActiveProgramStatus onViewProgram={handleViewProgram} />
          </WorkoutProvider>
        )}

        <ProgramsList />
      </MainContent>
    </ProgramsContainer>
  );
};

export default Programs;