// import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
// import styled from 'styled-components';
// import { useProgram } from '../context/ProgramContext';
// import SideDrawer from '../SideDrawer';
// import { useAuth } from '../../hooks/useAuth';
// import ProgramDescriptionEditor from './ProgramDescriptionEditor';
// import AddCycleButton from './AddCycleButton'; // Importing AddCycleButton

// // Styled Components for Modal
// const ModalOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0,0,0,0.9); // Darker background
//   display: ${(props) => (props.isOpen ? 'block' : 'none')};
//   z-index: 999;
//   overflow: hidden; // Prevent scrolling when modal is open
// `;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 12px;
//   right: 16px;
//   background: transparent;
//   color: #fff;
//   border: none;
//   font-size: 30px;
//   cursor: pointer;
//   z-index: 10;
// `;

// // Styled Components from CarouselMeso.js
// const Carousel = styled.div`
//   position: relative;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   overflow: hidden;

//   @media (max-width: 768px) {
//     height: auto;
//     min-height: 100vh;
//   }
// `;

// const CarouselItem = styled.div`
//   --width: clamp(150px, 30vw, 300px);
//   --height: clamp(200px, 40vw, 400px);
//   --x: calc(var(--active) * 100%);
//   --y: calc(var(--active) * 20%);
//   --rot: calc(var(--active) * 12deg);
//   --opacity: calc(var(--zIndex) / ${({ itemCount }) => itemCount} * 3 - 2);
//   position: absolute;
//   z-index: var(--zIndex);
//   width: var(--width);
//   height: var(--height);
//   margin: calc(var(--height) * -0.5) 0 0 calc(var(--width) * -0.5);
//   border-radius: 10px;
//   top: 40%;
//   left: 50%;
//   user-select: none;
//   transform-origin: 0% 100%;
//   box-shadow: 0 10px 50px 10px rgba(0, 0, 0, 0.5);
//   background: black;
//   pointer-events: all;
//   transform: translate(var(--x), var(--y)) rotate(var(--rot));
//   transition: transform 0.8s cubic-bezier(0, 0.02, 0, 1);

//   @media (max-width: 768px) {
//     --width: clamp(200px, 30vw, 300px);
//     --height: clamp(250px, 40vw, 400px);
//   }
// `;

// const CarouselBox = styled.div`
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
//   opacity: var(--opacity);
//   font-family: 'Orelo-sw-db', serif;

//   &:before {
//     content: '';
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(
//       to bottom,
//       rgba(0, 0, 0, 0.3),
//       rgba(0, 0, 0, 0) 30%,
//       rgba(0, 0, 0, 0) 50%,
//       rgba(0, 0, 0, 0.5)
//     );
//   }

//   @media (max-width: 768px) {
//     opacity: 1;
//   }
// `;

// const Title = styled.div`
//   position: absolute;
//   z-index: 1;
//   color: #fff;
//   bottom: 20px;
//   left: 20px;
//   transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
//   font-size: clamp(20px, 3vw, 30px);
//   text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
// `;

// const Num = styled.div`
//   position: absolute;
//   z-index: 1;
//   color: #fff;
//   top: 10px;
//   left: 20px;
//   transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
//   font-size: clamp(20px, 10vw, 80px);
// `;

// const Image = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   pointer-events: none;
// `;

// const Cursor = styled.div`
//   position: fixed;
//   z-index: 10;
//   top: 0;
//   left: 0;
//   --size: 40px;
//   width: var(--size);
//   height: var(--size);
//   border-radius: 50%;
//   border: 1px solid rgba(255, 255, 255, 0.2);
//   margin: calc(var(--size) * -0.5) 0 0 calc(var(--size) * -0.5);
//   transition: transform 0.85s cubic-bezier(0, 0.02, 0, 1);
//   display: none;
//   pointer-events: none;

//   @media (pointer: fine) {
//     display: block;
//   }

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const Cursor2 = styled(Cursor)`
//   --size: 2px;
//   transition-duration: 0.7s;
// `;

// const RemoveButton = styled.button`
//   background-color: #ff4136;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;
//   margin-top: 20px;

//   &:hover {
//     background-color: #e60000;
//   }

//   @media (max-width: 768px) {
//     width: 100%;
//   }
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     gap: 10px;
//   }
// `;

// const GoToProgramButton = styled.button`
//   background-color: #4CAF50;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;

//   &:hover {
//     background-color: #45a049;
//   }

//   @media (max-width: 768px) {
//     width: 100%;
//   }
// `;

// const AddCycleButtonContainer = styled.div`
//   position: absolute;
//   top: 20px;
//   left: 20px;
//   z-index: 1000;

//   @media (max-width: 768px) {
//     top: 10px;
//     left: 10px;
//   }
// `;

// // React Component
// const CaroMesoModal = ({ isOpen, onClose, onTabChange }) => {
//   const [progress, setProgress] = useState(50);
//   const [active, setActive] = useState(0);
//   const [isDown, setIsDown] = useState(false);
//   const [startX, setStartX] = useState(0);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const carouselRef = useRef(null);
//   const itemsRef = useRef([]);
//   const cursorsRef = useRef([]);
//   const [isTouchDevice, setIsTouchDevice] = useState(false);

//   const speedWheel = 0.1;
//   const speedDrag = -0.1;

//   const { user } = useAuth();
//   const {
//     program,
//     programs,
//     removeMesocycleFromMacrocycle,
//     createProgram,
//     importMesocycleToMacrocycle,
//   } = useProgram();

//   // Fetch mesocycles associated with the macrocycle
//   const mesocyclesList = useMemo(() => {
//     if (!program || !program.programBlocks) return [];
//     return programs.filter(
//       (mesocycle) =>
//         mesocycle.programType &&
//         mesocycle.programType.startsWith('Mesocycle') &&
//         program.programBlocks.includes(mesocycle.id)
//     );
//   }, [program, programs]);

//   const items = useMemo(
//     () =>
//       mesocyclesList.map((mesocycle) => ({
//         title: mesocycle.name,
//         img: mesocycle.imageUrl || 'https://via.placeholder.com/300x400',
//         id: mesocycle.id,
//         description: mesocycle.description || '',
//       })),
//     [mesocyclesList]
//   );

//   useEffect(() => {
//     itemsRef.current = itemsRef.current.slice(0, items.length);
//   }, [items.length]);

//   useEffect(() => {
//     setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
//   }, []);

//   const getZindex = useCallback(
//     (array, index) =>
//       array.map((_, i) =>
//         index === i ? array.length : array.length - Math.abs(index - i)
//       ),
//     []
//   );

//   const displayItems = useCallback(
//     (item, index, active) => {
//       const zIndex = getZindex([...itemsRef.current], active)[index];
//       item.style.setProperty('--zIndex', zIndex);
//       item.style.setProperty('--active', index - active);
//     },
//     [getZindex]
//   );

//   const animate = useCallback(() => {
//     setProgress((prev) => {
//       const newProgress = Math.max(0, Math.min(prev, 100));
//       const activeIndex = (items.length - 1) * (newProgress / 100);
//       setActive(activeIndex);
//       return newProgress;
//     });

//     itemsRef.current.forEach((item, index) => {
//       if (item) {
//         displayItems(item, index, active);
//       }
//     });
//   }, [items.length, active, displayItems]);

// //   #######################################

  
  

//   useEffect(() => {
//     animate();
//   }, [progress, animate]);

//   const handleWheel = useCallback(
//     (e) => {
//       e.preventDefault(); // Prevent default scrolling
//       const wheelProgress = e.deltaY * speedWheel;
//       setProgress((prev) => prev + wheelProgress);
//     },
//     []
//   );

//   const handlePointerMove = useCallback(
//     (e) => {
//       if (e.type === 'mousemove' && !isTouchDevice) {
//         cursorsRef.current.forEach((cursor) => {
//           cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
//         });
//       }
//       if (!isDown) return;
//       const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
//       const mouseProgress = (x - startX) * speedDrag;
//       setProgress((prev) => prev + mouseProgress);
//       setStartX(x);
//     },
//     [isDown, startX, isTouchDevice]
//   );

//   const handlePointerDown = useCallback((e) => {
//     setIsDown(true);
//     setStartX(e.clientX || (e.touches && e.touches[0].clientX) || 0);
//   }, []);

//   const handlePointerUp = useCallback(() => {
//     setIsDown(false);
//   }, []);

//   const handleItemClick = useCallback(
//     (item, index) => {
//       setProgress((index / (items.length - 1)) * 100);
//       setSelectedItem(item);
//       setIsDrawerOpen(true);
//     },
//     [items.length]
//   );

//   const handleRemoveMesocycle = useCallback(
//     async () => {
//       if (
//         selectedItem &&
//         window.confirm(
//           'Are you sure you want to remove this mesocycle from the macrocycle?'
//         )
//       ) {
//         try {
//           await removeMesocycleFromMacrocycle(program.id, selectedItem.id);
//           setIsDrawerOpen(false);
//         } catch (error) {
//           console.error('Error removing mesocycle from macrocycle:', error);
//           alert('Failed to remove mesocycle. Please try again.');
//         }
//       }
//     },
//     [selectedItem, program, removeMesocycleFromMacrocycle]
//   );

//   const handleGoToProgram = useCallback(() => {
//     if (selectedItem) {
//       onTabChange('program');
//       setIsDrawerOpen(false);
//       onClose(); // Close the modal
//     }
//   }, [selectedItem, onTabChange, onClose]);

//   // Attach event listeners
//   useEffect(() => {
//     if (!isTouchDevice) {
//       document.addEventListener('wheel', handleWheel, { passive: false });
//     }
//     document.addEventListener('pointerdown', handlePointerDown);
//     document.addEventListener('pointermove', handlePointerMove);
//     document.addEventListener('pointerup', handlePointerUp);

//     return () => {
//       if (!isTouchDevice) {
//         document.removeEventListener('wheel', handleWheel, { passive: false });
//       }
//       document.removeEventListener('pointerdown', handlePointerDown);
//       document.removeEventListener('pointermove', handlePointerMove);
//       document.removeEventListener('pointerup', handlePointerUp);
//     };
//   }, [handleWheel, handlePointerDown, handlePointerMove, handlePointerUp, isTouchDevice]);

//   // Handle division by zero in items.length
//   if (items.length === 0) {
//     return (
//       <ModalOverlay isOpen={isOpen}>
//         <CloseButton onClick={onClose}>&times;</CloseButton>
//         <AddCycleButtonContainer>
//           <AddCycleButton programId={program.id} />
//         </AddCycleButtonContainer>
//       </ModalOverlay>
//     );
//   }

//   return (
//     <ModalOverlay isOpen={isOpen}>
//       <CloseButton onClick={onClose}>&times;</CloseButton>

//       {/* AddCycleButton */}
//       <AddCycleButtonContainer>
//         <AddCycleButton programId={program.id} />
//       </AddCycleButtonContainer>

//       <Carousel ref={carouselRef}>
//         {items.map((item, index) => (
//           <CarouselItem
//             key={item.id}
//             ref={(el) => (itemsRef.current[index] = el)}
//             onClick={() => handleItemClick(item, index)}
//             itemCount={items.length}
//           >
//             <CarouselBox>
//               <Title>{item.title}</Title>
//               <Num>{String(index + 1).padStart(2, '0')}</Num>
//               <Image src={item.img} alt={item.title} />
//             </CarouselBox>
//           </CarouselItem>
//         ))}
//       </Carousel>

//       {!isTouchDevice && (
//         <>
//           <Cursor ref={(el) => (cursorsRef.current[0] = el)} />
//           <Cursor2 ref={(el) => (cursorsRef.current[1] = el)} />
//         </>
//       )}

//       {/* Side Drawer */}
//       <SideDrawer isopen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
//         {selectedItem && (
//           <div>
//             <h2>{selectedItem.title}</h2>
//             <ProgramDescriptionEditor
//               programId={selectedItem.id}
//               initialDescription={selectedItem.description}
//             />
//             <ButtonContainer>
//               <GoToProgramButton onClick={handleGoToProgram}>
//                 Go to Program
//               </GoToProgramButton>
//               <RemoveButton onClick={handleRemoveMesocycle}>
//                 Remove Program
//               </RemoveButton>
//             </ButtonContainer>
//           </div>
//         )}
//       </SideDrawer>
//     </ModalOverlay>
//   );
// };

// export default CaroMesoModal;

// ############Optimizing for touch screens #################


// import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
// import styled from 'styled-components';
// import { useProgram } from '../context/ProgramContext';
// import SideDrawer from '../SideDrawer';
// import { useAuth } from '../../hooks/useAuth';
// import ProgramDescriptionEditor from './ProgramDescriptionEditor';
// import AddCycleButton from './AddCycleButton'; // Importing AddCycleButton

// // Styled Components for Modal
// const ModalOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0,0,0,0.9); // Darker background
//   display: ${(props) => (props.isOpen ? 'block' : 'none')};
//   z-index: 999;
//   overflow: hidden; // Prevent scrolling when modal is open
// `;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 12px;
//   right: 16px;
//   background: transparent;
//   color: #fff;
//   border: none;
//   font-size: 30px;
//   cursor: pointer;
//   z-index: 10;
// `;

// // Styled Components from CarouselMeso.js
// const Carousel = styled.div`
//   position: relative;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   overflow: hidden;
//       touch-action: pan-y; /* Allow vertical scrolling */


//   @media (max-width: 768px) {
//     height: auto;
//     min-height: 100vh;
//   }
// `;

// const CarouselItem = styled.div`
//   --width: clamp(150px, 30vw, 300px);
//   --height: clamp(200px, 40vw, 400px);
//   --x: calc(var(--active) * 100%);
//   --y: calc(var(--active) * 20%);
//   --rot: calc(var(--active) * 12deg);
//   --opacity: calc(var(--zIndex) / ${({ itemCount }) => itemCount} * 3 - 2);
//   position: absolute;
//   z-index: var(--zIndex);
//   width: var(--width);
//   height: var(--height);
//   margin: calc(var(--height) * -0.5) 0 0 calc(var(--width) * -0.5);
//   border-radius: 10px;
//   top: 40%;
//   left: 50%;
//   user-select: none;
//   transform-origin: 0% 100%;
//   box-shadow: 0 10px 50px 10px rgba(0, 0, 0, 0.5);
//   background: black;
//   pointer-events: all;
//   transform: translate(var(--x), var(--y)) rotate(var(--rot));
//   transition: transform 0.8s cubic-bezier(0, 0.02, 0, 1);

//   @media (max-width: 768px) {
//     --width: clamp(200px, 30vw, 300px);
//     --height: clamp(250px, 40vw, 400px);
//   }
// `;

// const CarouselBox = styled.div`
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
//   opacity: var(--opacity);
//   font-family: 'Orelo-sw-db', serif;

//   &:before {
//     content: '';
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(
//       to bottom,
//       rgba(0, 0, 0, 0.3),
//       rgba(0, 0, 0, 0) 30%,
//       rgba(0, 0, 0, 0) 50%,
//       rgba(0, 0, 0, 0.5)
//     );
//   }

//   @media (max-width: 768px) {
//     opacity: 1;
//   }
// `;

// const Title = styled.div`
//   position: absolute;
//   z-index: 1;
//   color: #fff;
//   bottom: 20px;
//   left: 20px;
//   transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
//   font-size: clamp(20px, 3vw, 30px);
//   text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
// `;

// const Num = styled.div`
//   position: absolute;
//   z-index: 1;
//   color: #fff;
//   top: 10px;
//   left: 20px;
//   transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
//   font-size: clamp(20px, 10vw, 80px);
// `;

// const Image = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   pointer-events: none;
// `;

// const Cursor = styled.div`
//   position: fixed;
//   z-index: 10;
//   top: 0;
//   left: 0;
//   --size: 40px;
//   width: var(--size);
//   height: var(--size);
//   border-radius: 50%;
//   border: 1px solid rgba(255, 255, 255, 0.2);
//   margin: calc(var(--size) * -0.5) 0 0 calc(var(--size) * -0.5);
//   transition: transform 0.85s cubic-bezier(0, 0.02, 0, 1);
//   display: none;
//   pointer-events: none;

//   @media (pointer: fine) {
//     display: block;
//   }

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const Cursor2 = styled(Cursor)`
//   --size: 2px;
//   transition-duration: 0.7s;
// `;

// const RemoveButton = styled.button`
//   background-color: #ff4136;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;
//   margin-top: 20px;

//   &:hover {
//     background-color: #e60000;
//   }

//   @media (max-width: 768px) {
//     width: 100%;
//   }
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     gap: 10px;
//   }
// `;

// const GoToProgramButton = styled.button`
//   background-color: #4CAF50;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;

//   &:hover {
//     background-color: #45a049;
//   }

//   @media (max-width: 768px) {
//     width: 100%;
//   }
// `;

// const AddCycleButtonContainer = styled.div`
//   position: absolute;
//   top: 20px;
//   left: 20px;
//   z-index: 1000;

//   @media (max-width: 768px) {
//     top: 10px;
//     left: 10px;
//   }
// `;

// const AddButtonContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #3a3a3a; /* Match the carousel item background */
//   border-radius: 10px;
// `;


// // React Component
// const CaroMesoModal = ({ isOpen, onClose, onTabChange }) => {
//   const [progress, setProgress] = useState(50);
//   const [active, setActive] = useState(0);
//   const [isDown, setIsDown] = useState(false);
//   const [startX, setStartX] = useState(0);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const carouselRef = useRef(null);
//   const itemsRef = useRef([]);
//   const cursorsRef = useRef([]);
//   const [isTouchDevice, setIsTouchDevice] = useState(false);

//   const speedWheel = 0.1;
//   const speedDrag = -0.1;

//   const { user } = useAuth();
//   const {
//     program,
//     programs,
//     removeMesocycleFromMacrocycle,
//     createProgram,
//     importMesocycleToMacrocycle,
//   } = useProgram();

//   // Fetch mesocycles associated with the macrocycle
//   const mesocyclesList = useMemo(() => {
//     if (!program || !program.programBlocks) return [];
//     return programs.filter(
//       (mesocycle) =>
//         mesocycle.programType &&
//         mesocycle.programType.startsWith('Mesocycle') &&
//         program.programBlocks.includes(mesocycle.id)
//     );
//   }, [program, programs]);

//   const items = useMemo(
//     () =>
//       mesocyclesList.map((mesocycle) => ({
//         title: mesocycle.name,
//         img: mesocycle.imageUrl || 'https://via.placeholder.com/300x400',
//         id: mesocycle.id,
//         description: mesocycle.description || '',
//       })),
//     [mesocyclesList]
//   );

//   useEffect(() => {
//     itemsRef.current = itemsRef.current.slice(0, items.length);
//   }, [items.length]);

//   useEffect(() => {
//     setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
//   }, []);

//   const getZindex = useCallback(
//     (array, index) =>
//       array.map((_, i) =>
//         index === i ? array.length : array.length - Math.abs(index - i)
//       ),
//     []
//   );

//   const displayItems = useCallback(
//     (item, index, active) => {
//       const zIndex = getZindex([...itemsRef.current], active)[index];
//       item.style.setProperty('--zIndex', zIndex);
//       item.style.setProperty('--active', index - active);
//     },
//     [getZindex]
//   );

//   const animate = useCallback(() => {
//     setProgress((prev) => {
//       const newProgress = Math.max(0, Math.min(prev, 100));
//       const activeIndex = (items.length - 1) * (newProgress / 100);
//       setActive(activeIndex);
//       return newProgress;
//     });

//     itemsRef.current.forEach((item, index) => {
//       if (item) {
//         displayItems(item, index, active);
//       }
//     });
//   }, [items.length, active, displayItems]);

// //   #######################################

  
  

//   useEffect(() => {
//     animate();
//   }, [progress, animate]);

//   const handleWheel = useCallback(
//     (e) => {
//       e.preventDefault(); // Prevent default scrolling
//       const wheelProgress = e.deltaY * speedWheel;
//       setProgress((prev) => prev + wheelProgress);
//     },
//     []
//   );

//   const handlePointerMove = useCallback(
//     (e) => {
//       if (e.type === 'mousemove' && !isTouchDevice) {
//         cursorsRef.current.forEach((cursor) => {
//           cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
//         });
//       }
//       if (!isDown) return;
//       const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
//       const mouseProgress = (x - startX) * speedDrag;
//       setProgress((prev) => prev + mouseProgress);
//       setStartX(x);
//     },
//     [isDown, startX, isTouchDevice]
//   );

//   const handlePointerDown = useCallback((e) => {
//     setIsDown(true);
//     setStartX(e.clientX || (e.touches && e.touches[0].clientX) || 0);
//   }, []);

//   const handlePointerUp = useCallback(() => {
//     setIsDown(false);
//   }, []);

//   const handleItemClick = useCallback(
//     (item, index) => {
//       setProgress((index / (items.length - 1)) * 100);
//       setSelectedItem(item);
//       setIsDrawerOpen(true);
//     },
//     [items.length]
//   );

//   const handleRemoveMesocycle = useCallback(
//     async () => {
//       if (
//         selectedItem &&
//         window.confirm(
//           'Are you sure you want to remove this mesocycle from the macrocycle?'
//         )
//       ) {
//         try {
//           await removeMesocycleFromMacrocycle(program.id, selectedItem.id);
//           setIsDrawerOpen(false);
//         } catch (error) {
//           console.error('Error removing mesocycle from macrocycle:', error);
//           alert('Failed to remove mesocycle. Please try again.');
//         }
//       }
//     },
//     [selectedItem, program, removeMesocycleFromMacrocycle]
//   );

//   const handleGoToProgram = useCallback(() => {
//     if (selectedItem) {
//       onTabChange('program');
//       setIsDrawerOpen(false);
//       onClose(); // Close the modal
//     }
//   }, [selectedItem, onTabChange, onClose]);

//   // Attach event listeners
//   useEffect(() => {
//     const carouselElement = carouselRef.current;

//     if (!carouselElement) return;

//     if (!isTouchDevice) {
//       carouselElement.addEventListener('wheel', handleWheel, { passive: false });
//     }

//     carouselElement.addEventListener('pointerdown', handlePointerDown, { passive: true });
//     carouselElement.addEventListener('pointermove', handlePointerMove, { passive: true });
//     carouselElement.addEventListener('pointerup', handlePointerUp, { passive: true });

//     return () => {
//       if (!isTouchDevice) {
//         carouselElement.removeEventListener('wheel', handleWheel);
//       }
//       carouselElement.removeEventListener('pointerdown', handlePointerDown);
//       carouselElement.removeEventListener('pointermove', handlePointerMove);
//       carouselElement.removeEventListener('pointerup', handlePointerUp);
//     };
//   }, [handleWheel, handlePointerDown, handlePointerMove, handlePointerUp, isTouchDevice]);

//   // Handle division by zero in items.length
//   if (items.length === 0) {
//     return (
//       <ModalOverlay isOpen={isOpen}>
//         <CloseButton onClick={onClose}>&times;</CloseButton>
//         <AddCycleButtonContainer>
//           <AddCycleButton programId={program.id} />
//         </AddCycleButtonContainer>
//       </ModalOverlay>
//     );
//   }

//   return (
//     <ModalOverlay isOpen={isOpen}>
//       <CloseButton onClick={onClose}>&times;</CloseButton>

//       {/* AddCycleButton */}
//       <AddCycleButtonContainer>
//         <AddCycleButton programId={program.id} />
//       </AddCycleButtonContainer>

//       <Carousel ref={carouselRef}>
//         {items.map((item, index) => (
//           <CarouselItem
//             key={item.id}
//             ref={(el) => (itemsRef.current[index] = el)}
//             onClick={() => handleItemClick(item, index)}
//             itemCount={items.length}
//           >
//             <CarouselBox>
//               <Title>{item.title}</Title>
//               <Num>{String(index + 1).padStart(2, '0')}</Num>
//               <Image src={item.img} alt={item.title} />
//             </CarouselBox>
//           </CarouselItem>
//         ))}
//       </Carousel>

//       {!isTouchDevice && (
//         <>
//           <Cursor ref={(el) => (cursorsRef.current[0] = el)} />
//           <Cursor2 ref={(el) => (cursorsRef.current[1] = el)} />
//         </>
//       )}

//       {/* Side Drawer */}
//       <SideDrawer isopen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
//         {selectedItem && (
//           <div>
//             <h2>{selectedItem.title}</h2>
//             <ProgramDescriptionEditor
//               programId={selectedItem.id}
//               initialDescription={selectedItem.description}
//             />
//             <ButtonContainer>
//               <GoToProgramButton onClick={handleGoToProgram}>
//                 Go to Program
//               </GoToProgramButton>
//               <RemoveButton onClick={handleRemoveMesocycle}>
//                 Remove Program
//               </RemoveButton>
//             </ButtonContainer>
//           </div>
//         )}
//       </SideDrawer>
//     </ModalOverlay>
//   );
// };

// export default CaroMesoModal;


// ##########appending add button to the carousel ###########

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useProgram } from '../context/ProgramContext';
import SideDrawer from '../SideDrawer';
import { useAuth } from '../../hooks/useAuth';
import ProgramDescriptionEditor from './ProgramDescriptionEditor';
import AddCycleButton from './AddCycleButton'; // Importing AddCycleButton

// Styled Components for Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9); // Darker background
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 999;
  overflow: hidden; // Prevent scrolling when modal is open
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 16px;
  background: transparent;
  color: #fff;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
`;

// Styled Components from CarouselMeso.js
const Carousel = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // touch-action: pan-y;
  // touch-action: pan-x; 
  touch-action: none;


  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
  }
`;

const CarouselItem = styled.div`
  --width: clamp(150px, 30vw, 300px);
  --height: clamp(200px, 40vw, 400px);
  --x: calc(var(--active) * 100%);
  --y: calc(var(--active) * 20%);
  --rot: calc(var(--active) * 12deg);
  --opacity: calc(var(--zIndex) / ${({ itemCount }) => itemCount} * 3 - 2);
  position: absolute;
  z-index: var(--zIndex);
  width: var(--width);
  height: var(--height);
  margin: calc(var(--height) * -0.5) 0 0 calc(var(--width) * -0.5);
  border-radius: 10px;
  top: 40%;
  left: 50%;
  user-select: none;
  transform-origin: 0% 100%;
  box-shadow: 0 10px 50px 10px rgba(0, 0, 0, 0.5);
  background: black;
  pointer-events: all;
  transform: translate(var(--x), var(--y)) rotate(var(--rot));
  transition: transform 0.8s cubic-bezier(0, 0.02, 0, 1);

  @media (max-width: 768px) {
    --width: clamp(200px, 30vw, 300px);
    --height: clamp(250px, 40vw, 400px);
  }
`;

const CarouselBox = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  opacity: var(--opacity);
  font-family: 'Orelo-sw-db', serif;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.5)
    );
  }

  @media (max-width: 768px) {
    opacity: 1;
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 1;
  color: #fff;
  bottom: 20px;
  left: 20px;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  font-size: clamp(20px, 3vw, 30px);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
`;

const Num = styled.div`
  position: absolute;
  z-index: 1;
  color: #fff;
  top: 10px;
  left: 20px;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  font-size: clamp(20px, 10vw, 80px);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

const Cursor = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  --size: 40px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: calc(var(--size) * -0.5) 0 0 calc(var(--size) * -0.5);
  transition: transform 0.85s cubic-bezier(0, 0.02, 0, 1);
  display: none;
  pointer-events: none;

  @media (pointer: fine) {
    display: block;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Cursor2 = styled(Cursor)`
  --size: 2px;
  transition-duration: 0.7s;
`;

const RemoveButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #e60000;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const GoToProgramButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AddCycleButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
  }
`;

const AddButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a3a3a; /* Match the carousel item background */
  border-radius: 10px;
`;


// React Component
const CaroMesoModal = ({ isOpen, onClose, onTabChange }) => {
  const [progress, setProgress] = useState(50);
  const [active, setActive] = useState(0);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const carouselRef = useRef(null);
  const itemsRef = useRef([]);
  const cursorsRef = useRef([]);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [startY, setStartY] = useState(0);
const [verticalProgress, setVerticalProgress] = useState(50);
const speedDragY = -0.1;
const [activeY, setActiveY] = useState(0);



  const speedWheel = 0.1;
  const speedDrag = -0.1;

  const { user } = useAuth();
  const {
    program,
    programs,
    removeMesocycleFromMacrocycle,
    createProgram,
    importMesocycleToMacrocycle,
  } = useProgram();

  // Fetch mesocycles associated with the macrocycle
  const mesocyclesList = useMemo(() => {
    if (!program || !program.programBlocks) return [];
    return programs.filter(
      (mesocycle) =>
        mesocycle.programType &&
        mesocycle.programType.startsWith('Mesocycle') &&
        program.programBlocks.includes(mesocycle.id)
    );
  }, [program, programs]);

  const items = useMemo(() => {
    // Map mesocycles to carousel items
    const mesocycleItems = mesocyclesList.map((mesocycle) => ({
      type: 'mesocycle',
      title: mesocycle.name,
      img: mesocycle.imageUrl || 'https://via.placeholder.com/300x400',
      id: mesocycle.id,
      description: mesocycle.description || '',
    }));

    // Add the AddCycleButton item
    const addButtonItem = {
      type: 'addButton',
      id: 'add-button', // Unique ID
    };

    // Return the combined array
    return [...mesocycleItems, addButtonItem];
  }, [mesocyclesList]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length);
  }, [items.length]);

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  const getZindex = useCallback(
    (array, index) =>
      array.map((_, i) =>
        index === i ? array.length : array.length - Math.abs(index - i)
      ),
    []
  );

  // const displayItems = useCallback(
  //   (item, index, active) => {
  //     const zIndex = getZindex([...itemsRef.current], active)[index];
  //     item.style.setProperty('--zIndex', zIndex);
  //     item.style.setProperty('--active', index - active);
  //   },
  //   [getZindex]
  // );

  const displayItems = useCallback(
    (item, index, active) => {
      const zIndex = getZindex([...itemsRef.current], active)[index];
      item.style.setProperty('--zIndex', zIndex);
      item.style.setProperty('--active', index - active);
      item.style.setProperty('--activeY', index - activeY);
    },
    [getZindex]
  );
  

  // const animate = useCallback(() => {
  //   setProgress((prev) => {
  //     const newProgress = Math.max(0, Math.min(prev, 100));
  //     const activeIndex = (items.length - 1) * (newProgress / 100);
  //     setActive(activeIndex);
  //     return newProgress;
  //   });

  //   itemsRef.current.forEach((item, index) => {
  //     if (item) {
  //       displayItems(item, index, active);
  //     }
  //   });
  // }, [items.length, active, displayItems]);


  const animate = useCallback(() => {
    setProgress((prev) => {
      const newProgress = Math.max(0, Math.min(prev, 100));
      const activeIndex = (items.length - 1) * (newProgress / 100);
      setActive(activeIndex);
      return newProgress;
    });
  
    setVerticalProgress((prev) => {
      const newVerticalProgress = Math.max(0, Math.min(prev, 100));
      setActiveY(newVerticalProgress);
      return newVerticalProgress;
    });
  
    itemsRef.current.forEach((item, index) => {
      if (item) {
        displayItems(item, index, active);
      }
    });
  }, [items.length, active, displayItems]);
  

//   #######################################

  
  

  useEffect(() => {
    animate();
  }, [progress, animate]);

  const handleWheel = useCallback(
    (e) => {
      e.preventDefault(); // Prevent default scrolling
      const wheelProgress = e.deltaY * speedWheel;
      setProgress((prev) => prev + wheelProgress);
    },
    []
  );

  // const handlePointerMove = useCallback(
  //   (e) => {
  //     if (e.type === 'mousemove' && !isTouchDevice) {
  //       cursorsRef.current.forEach((cursor) => {
  //         cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
  //       });
  //     }
  //     if (!isDown) return;
  //     const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
  //     const mouseProgress = (x - startX) * speedDrag;
  //     setProgress((prev) => prev + mouseProgress);
  //     setStartX(x);
  //   },
  //   [isDown, startX, isTouchDevice]
  // );


  
  const handlePointerMove = useCallback(
    (e) => {
      if (!isDown) return;
  
      e.preventDefault(); // Prevent default behavior
  
      if (e.type === 'mousemove' && !isTouchDevice) {
        cursorsRef.current.forEach((cursor) => {
          cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
        });
      }
  
      const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
      const y = e.clientY || (e.touches && e.touches[0].clientY) || 0;
  
      const deltaX = x - startX;
      const deltaY = y - startY;
  
      const mouseProgress = deltaX * speedDrag;
      const mouseProgressY = deltaY * speedDragY;
  
      setProgress((prev) => prev + mouseProgress);
      setVerticalProgress((prev) => prev + mouseProgressY);
  
      setStartX(x);
      setStartY(y);
    },
    [isDown, startX, startY, isTouchDevice]
  );
  
  const handlePointerDown = useCallback((e) => {
    e.preventDefault(); // Prevent default behavior
    setIsDown(true);
    setStartX(e.clientX || (e.touches && e.touches[0].clientX) || 0);
    setStartY(e.clientY || (e.touches && e.touches[0].clientY) || 0);
  }, []);


  // const handlePointerDown = useCallback((e) => {
  //   setIsDown(true);
  //   setStartX(e.clientX || (e.touches && e.touches[0].clientX) || 0);
  // }, []);

  const handlePointerUp = useCallback(() => {
    setIsDown(false);
  }, []);

  const handleItemClick = useCallback(
    (item, index) => {
      setProgress((index / (items.length - 1)) * 100);
      setSelectedItem(item);
      setIsDrawerOpen(true);
    },
    [items.length]
  );

  const handleRemoveMesocycle = useCallback(
    async () => {
      if (
        selectedItem &&
        window.confirm(
          'Are you sure you want to remove this mesocycle from the macrocycle?'
        )
      ) {
        try {
          await removeMesocycleFromMacrocycle(program.id, selectedItem.id);
          setIsDrawerOpen(false);
        } catch (error) {
          console.error('Error removing mesocycle from macrocycle:', error);
          alert('Failed to remove mesocycle. Please try again.');
        }
      }
    },
    [selectedItem, program, removeMesocycleFromMacrocycle]
  );

  const handleGoToProgram = useCallback(() => {
    if (selectedItem) {
      onTabChange('program');
      setIsDrawerOpen(false);
      onClose(); // Close the modal
    }
  }, [selectedItem, onTabChange, onClose]);

  // Attach event listeners
  // useEffect(() => {
  //   const carouselElement = carouselRef.current;

  //   if (!carouselElement) return;

  //   if (!isTouchDevice) {
  //     carouselElement.addEventListener('wheel', handleWheel, { passive: false });
  //   }

  //   carouselElement.addEventListener('pointerdown', handlePointerDown, { passive: true });
  //   carouselElement.addEventListener('pointermove', handlePointerMove, { passive: true });
  //   carouselElement.addEventListener('pointerup', handlePointerUp, { passive: true });

  //   return () => {
  //     if (!isTouchDevice) {
  //       carouselElement.removeEventListener('wheel', handleWheel);
  //     }
  //     carouselElement.removeEventListener('pointerdown', handlePointerDown);
  //     carouselElement.removeEventListener('pointermove', handlePointerMove);
  //     carouselElement.removeEventListener('pointerup', handlePointerUp);
  //   };
  // }, [handleWheel, handlePointerDown, handlePointerMove, handlePointerUp, isTouchDevice]);

  useEffect(() => {
    const carouselElement = carouselRef.current;
  
    if (!carouselElement) return;
  
    if (!isTouchDevice) {
      carouselElement.addEventListener('wheel', handleWheel, { passive: false });
    }
  
    carouselElement.addEventListener('pointerdown', handlePointerDown);
    carouselElement.addEventListener('pointermove', handlePointerMove);
    carouselElement.addEventListener('pointerup', handlePointerUp);
  
    return () => {
      if (!isTouchDevice) {
        carouselElement.removeEventListener('wheel', handleWheel);
      }
      carouselElement.removeEventListener('pointerdown', handlePointerDown);
      carouselElement.removeEventListener('pointermove', handlePointerMove);
      carouselElement.removeEventListener('pointerup', handlePointerUp);
    };
  }, [handleWheel, handlePointerDown, handlePointerMove, handlePointerUp, isTouchDevice]);
  

  // Handle division by zero in items.length
  if (items.length === 0) {
    return (
      <ModalOverlay isOpen={isOpen}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <AddCycleButtonContainer>
          <AddCycleButton programId={program.id} />
        </AddCycleButtonContainer>
      </ModalOverlay>
    );
  }

  return (
    <ModalOverlay isOpen={isOpen}>
      <CloseButton onClick={onClose}>&times;</CloseButton>

      <Carousel ref={carouselRef}>
        {items.map((item, index) => (
          <CarouselItem
            key={item.id}
            ref={(el) => (itemsRef.current[index] = el)}
            // Apply onClick handler only if the item is a mesocycle
            onClick={
              item.type === 'mesocycle'
                ? () => handleItemClick(item, index)
                : undefined
            }
            itemCount={items.length}
          >
            {item.type === 'mesocycle' ? (
              <CarouselBox>
                <Title>{item.title}</Title>
                <Num>{String(index + 1).padStart(2, '0')}</Num>
                <Image src={item.img} alt={item.title} />
              </CarouselBox>
            ) : item.type === 'addButton' ? (
              // Render the AddCycleButton
              <AddButtonContainer>
                <AddCycleButton programId={program.id} />
              </AddButtonContainer>
            ) : null}
          </CarouselItem>
        ))}
      </Carousel>

      {!isTouchDevice && (
        <>
          <Cursor ref={(el) => (cursorsRef.current[0] = el)} />
          <Cursor2 ref={(el) => (cursorsRef.current[1] = el)} />
        </>
      )}

      {/* Side Drawer */}
      <SideDrawer isopen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        {selectedItem && (
          <div>
            <h2>{selectedItem.title}</h2>
            <ProgramDescriptionEditor
              programId={selectedItem.id}
              initialDescription={selectedItem.description}
            />
            <ButtonContainer>
              <GoToProgramButton onClick={handleGoToProgram}>
                Go to Program
              </GoToProgramButton>
              <RemoveButton onClick={handleRemoveMesocycle}>
                Remove Program
              </RemoveButton>
            </ButtonContainer>
          </div>
        )}
      </SideDrawer>
    </ModalOverlay>
  );
};
export default CaroMesoModal;

