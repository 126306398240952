import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useProgram } from '../context/ProgramContext';

const EditorContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  min-width: 350px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  // padding: 10px;
  background-color: transparent;
  color: #ffffff;
  border: ${props => props.isEditing ? '1px solid #444' : 'none'};
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.isEditing ? '#666' : 'transparent'};
  }

  &:not(:focus) {
    cursor: ${props => props.isEditing ? 'text' : 'pointer'};
  }
`;

const PlaceholderText = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #888;
  pointer-events: none;
`;

const ProgramDescriptionEditor = ({ programId, initialDescription = "" }) => {
  const [description, setDescription] = useState(initialDescription);
  const [isEditing, setIsEditing] = useState(false);
  const { updateProgramDescription } = useProgram();
  const textareaRef = useRef(null);

  useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription]);

  const handleSave = useCallback(async () => {
    try {
      await updateProgramDescription(programId, description);
      console.log("Description updated successfully");
    } catch (error) {
      console.error("Failed to update description:", error);
      // Optionally, you can show an error message to the user here
    }
  }, [programId, description, updateProgramDescription]);

  const handleBlur = () => {
    setIsEditing(false);
    handleSave();
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
      textareaRef.current.focus();
    }
  };

  return (
    <EditorContainer onClick={handleClick}>
      <StyledTextarea
        ref={textareaRef}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        readOnly={!isEditing}
        isEditing={isEditing}
      />
      {!isEditing && !description && (
        <PlaceholderText>What's this program about?</PlaceholderText>
      )}
    </EditorContainer>
  );
};

export default ProgramDescriptionEditor;