import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import AppRoutes from "./components/AppRoutes";
import { ThemeProvider } from "./hooks/ThemeContext";
import { AuthProvider } from "./hooks/useAuth";
import { ProgramProvider } from "./components/context/ProgramContext";
import { WorkoutProvider } from "./components/context/WorkoutContext";
import { UserExercisesProvider } from "./components/context/UserExercisesContext";
// import { PaymentProvider } from "./components/context/PaymentContext";
import { LoadingProvider } from "./components/context/LoadingContext";
import { initializeGoogleCalendar } from "../src/components/Calendar3d/calendarAPI";
import "./styles/App.css";

const GlobalStyle = createGlobalStyle`
  body {
    overscroll-behavior-y: contain;
    // touch-action: none;
    user-select: none;
  }
`;

const App = () => {
  useEffect(() => {
    const initializeCalendar = async () => {
      try {
        await initializeGoogleCalendar();
        console.log("Google Calendar initialized successfully");
      } catch (error) {
        console.error("Error initializing Google Calendar:", error);
      }
    };

    initializeCalendar();
  }, []);

  useEffect(() => {
    const handleTouchStart = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault(); // Prevent pinch zoom
      }
    };

    document.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider>
        <LoadingProvider>
          <ProgramProvider>
            <UserExercisesProvider>
              <Router>
                <GlobalStyle />
                <AppRoutes />
              </Router>
            </UserExercisesProvider>
          </ProgramProvider>
        </LoadingProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
