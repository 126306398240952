// import React, { useRef, useEffect, useState } from "react";
// import styled from "styled-components";

// const DrawerContainer = styled.div.withConfig({
//   shouldForwardProp: (prop) => prop !== "isOpen",
// })`
//   position: fixed;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top: 0;
//   right: 0;
//   height: 100%;
//   width: 50%;
//   max-width: 600px;
//   background-color: #1c1c1e;
//   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
//   transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
//   transition: transform 0.3s ease-in-out;
//   z-index: 1000;
//   overflow-y: auto;

//   @media (max-width: 768px) {
//     width: 100%;
//   }
// `;

// const Overlay = styled.div.withConfig({
//   shouldForwardProp: (prop) => prop !== "isOpen",
// })`
//   position: fixed;

//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
//   pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
//   transition: opacity 0.3s ease-in-out;
//   z-index: 999;
// `;

// const CloseTab = styled.div`
//   position: absolute;
//   left: -30px;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 30px;
//   height: 60px;
//   background-color: #1c1c1e;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   z-index: 1001;
//   border-radius: 8px 0 0 8px;

//   @media (min-width: 769px) {
//     display: none; /* Hide the close tab on desktop */
//   }
// `;

// const ProgramWorkoutDrawer = ({ isOpen, onClose, children }) => {
//   const drawerRef = useRef();

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const handleClickOutside = (event) => {
//     if (drawerRef.current && !drawerRef.current.contains(event.target)) {
//       onClose();
//     }
//   };

//   useEffect(() => {
//     if (isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen]);

//   return (
//     <>
//       <Overlay isOpen={isOpen} onClick={onClose} />
//       <DrawerContainer ref={drawerRef} isOpen={isOpen}>
//         {React.Children.map(children, (child) =>
//           React.cloneElement(child, { onClose })
//         )}
//       </DrawerContainer>
//     </>
//   );
// };

// export default ProgramWorkoutDrawer;


import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const DrawerContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpen",
})`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  max-width: 600px;
  background-color: #1c1c1e;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  // overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Overlay = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpen",
})`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
`;

// const CloseTab = styled.div`
//   position: absolute;
//   left: -30px;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 30px;
//   height: 60px;
//   background-color: #1c1c1e;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   z-index: 1001;
//   border-radius: 8px 0 0 8px;

//   @media (min-width: 769px) {
//     display: none; /* Hide the close tab on desktop */
//   }
// `;

const ProgramWorkoutDrawer = ({ isOpen, onClose, children, disableBackdropClick  }) => {
  const drawerRef = useRef();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleClose = (event) => {
    // If it's a direct click on the overlay (not bubbled), prevent immediate close
    if (event && event.target === event.currentTarget) {
      event.stopPropagation();
    }
    onClose();
  };
  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      handleClose(event);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (disableBackdropClick) {
        // Do not close the drawer when dialog is open
        return;
      }
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleClose(event);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, disableBackdropClick, onClose]);

  // useEffect(() => {
  //   if (isOpen) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isOpen]);

  const drawerContent = (
    <>
 <Overlay isOpen={isOpen} onClick={disableBackdropClick ? null : handleClose} />
       <DrawerContainer ref={drawerRef} isOpen={isOpen}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { onClose: handleClose })
        )}
      </DrawerContainer>
    </>
  );

  return ReactDOM.createPortal(drawerContent, document.body);
};

export default ProgramWorkoutDrawer;