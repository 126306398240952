


// import React, { useState, useMemo, memo, useEffect, useRef, useCallback } from 'react';
// import styled from 'styled-components';
// import { useAuth } from '../../hooks/useAuth';
// import { useProgram } from '../context/ProgramContext';
// import MesocycleHeader from './MesocycleHeader';
// import MesocycleProgram from './MesocycleProgram';
// import ExerciseTracker from './Exercises/ExerciseTracker';
// import GoalBuilder from './goals/GoalBuilder';
// import GoalList from './goals/GoalList';
// import MacrocyclePlanet from './MacrocyclePlanet';
// import PersonalRecordComponent from '../PersonalRecordComponent';
// import ProgramBackground from './ProgramBackground';
// import TrainingBlocks from './TrainingBlocks';
// import { gsap } from 'gsap';
// import { FaArrowUp, FaPlus, FaRegEdit, FaCube, FaEye } from 'react-icons/fa';
// import AddCycleButton from './AddCycleButton';

// import CaroMesoModal from './CaroMesoModal';
// import Overview from './ProgramOverview';

// const LayoutContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
//   overflow: hidden;
//   position: relative;
// `;

// const MainContent = styled.main`
//   flex: 1;
//   overflow-y: hidden;
//   position: relative;
//   z-index: 1;
// `;

// const BottomDrawer = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   background-color: #2c2c2c;
//   box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
//   border-top-left-radius: 16px;
//   border-top-right-radius: 16px;
//   transform: ${(props) => (props.open ? 'translateY(0)' : 'translateY(100%)')};
//   transition: transform 0.3s ease;
//   z-index: 999;
// `;

// const DrawerHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 16px;
//   background-color: #3a3a3a;
//   border-top-left-radius: 16px;
//   border-top-right-radius: 16px;
//   cursor: pointer;
// `;

// const DrawerContent = styled.div`
//   display: flex;
//   overflow-x: auto;
//   padding: 16px;
//   gap: 16px;

//   /* Hide scrollbar for WebKit browsers */
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   /* Hide scrollbar for Firefox */
//   scrollbar-width: none;

//   /* Hide scrollbar for Internet Explorer and Edge */
//   -ms-overflow-style: none;
// `;

// const MesocycleCard = styled.div`
//   min-width: 200px;
//   background-color: #3a3a3a;
//   padding: 16px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   cursor: pointer;
//   transition: transform 0.2s;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const MesocycleNumber = styled.div`
//   font-size: 12px;
//   color: #aaa;
//   margin-bottom: 4px;
// `;

// const MesocycleName = styled.h3`
//   margin: 0 0 8px 0;
//   font-size: 16px;
//   color: #fff;
// `;





// const StatusSelect = styled.select`
//   background-color: ${(props) =>
//     props.value === 'Current' ? '#4caf50' :
//       props.value === 'Upcoming' ? '#2196f3' :
//         props.value === 'Completed' ? '#ff9800' :
//           '#555'};
//   color: #fff;
//   border: none;
//   border-radius: 4px;
//   padding: 2px 2px 2px 8px; // Increased right padding for the custom arrow
//   font-size: 12px;
//   cursor: pointer;
//   transition: background-color 0.2s;
//   margin-left: 8px;
//   appearance: none;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   min-width: 90px; // Ensure minimum width to fit content
  
//   // Custom dropdown arrow
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M0 2l4 4 4-4z'/%3E%3C/svg%3E");
//   background-repeat: no-repeat;
//   background-position: right 8px center;

//   &:hover, &:focus {
//     background-color: ${(props) =>
//     props.value === 'Current' ? '#45a049' :
//       props.value === 'Upcoming' ? '#1e88e5' :
//         props.value === 'Completed' ? '#f57c00' :
//           '#4a4a4a'};
//     outline: none;
//   }

//   /* Hide the default arrow in IE */
//   &::-ms-expand {
//     display: none;
//   }

//   option {
//     background-color: #2a2a2a;
//     color: #fff;
//   }
// `;

// const SelectMesoButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: transparent;
//   color: #fff;
//   border: 1px solid #4a4a4a;
//   padding: 6px 12px;
//   border-radius: 4px;
//   cursor: pointer;
//   font-size: 10px;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     background-color: #4a4a4a;
//     color: #fff;
//   }

//   svg {
//     margin-right: 6px;
//     font-size: 14px;
//   }
// `;

// const ButtonGroup = styled.div`
//   display: flex;
//   gap: 8px;
// `;

// const AnimationContainer = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// `;

// const AnimatedContent = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
// `;



// const CenteredContent = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;



// const SubHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background-color: #2a2a2a;
//   padding: 8px 16px;
//   color: #fff;
//   font-size: 14px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   z-index: 1;

//     @media (max-width: 768px) {
//  font-size: 12px;
//   }
// `;



// const SubHeaderLeft = styled.div`
//   display: flex;
//   align-items: center;
// `;



// const SubHeaderRight = styled.div`
//   display: flex;
//   gap: 8px;
// `;

// const SubHeaderTitle = styled.h3`
//   margin: 0;
//   font-weight: 400;
//   color: #fff;
//   width: 7em;

//   @media (max-width: 768px) {
//   width: 5em;
//   front-size: 11px;
//   }
// `;

// const EditableTitle = styled.input`
//   background: none;
//   border: none;
//   font-size: inherit;
//   font-weight: inherit;
//   color: inherit;
//   padding: 0;
//   margin: 0;
//   width: 7em;
//   &:focus {
//     outline: none;
//     border: 1px solid #fff;
//   }
// `;


// const ProgramLayout = ({ program }) => {
//   const { user } = useAuth();
//   const [activeTab, setActiveTab] = useState('overview');
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const drawerRef = useRef(null);
//   const [selectedMesocycle, setSelectedMesocycle] = useState(null);
//   const [mesocycleStatus, setMesocycleStatus] = useState('Not Set');
//   const contentRef = useRef(null);
//   const [isTransitioning, setIsTransitioning] = useState(false);
//   const { updateMesocycleStatus, fetchMesocycleById, renameProgram, createProgram, importMesocycleToMacrocycle } = useProgram();
//   const [isEditingName, setIsEditingName] = useState(false);
//   const [editedName, setEditedName] = useState('');
//   const editInputRef = useRef(null);
//   const [mesocycleData, setMesocycleData] = useState({});

//   const handleNameClick = () => {
//     setEditedName(getCurrentMesocycleName());
//     setIsEditingName(true);
//   };

//   const handleNameChange = (e) => {
//     setEditedName(e.target.value);
//   };

//   const fetchMesocyclesData = useCallback(async () => {
//     if (program.programType === 'Macrocycle' && program.programBlocks) {
//       const mesocyclesData = {};
//       for (const mesocycleId of program.programBlocks) {
//         try {
//           const data = await fetchMesocycleById(mesocycleId);
//           if (data) {
//             mesocyclesData[mesocycleId] = data;
//           }
//         } catch (error) {
//           console.error(`Error fetching mesocycle ${mesocycleId}:`, error);
//         }
//       }
//       setMesocycleData(mesocyclesData);
//     }
//   }, [program.programType, program.programBlocks, fetchMesocycleById]);

//   useEffect(() => {
//     fetchMesocyclesData();
//   }, [fetchMesocyclesData, program.programBlocks]);


//   const handleNameSubmit = async () => {
//     if (editedName.trim() && editedName !== getCurrentMesocycleName()) {
//       try {
//         await renameProgram(selectedMesocycle.id, editedName.trim());
//         // Update the local state
//         setSelectedMesocycle(prevState => ({
//           ...prevState,
//           name: editedName.trim()
//         }));
//         // Update the mesocycleData state
//         setMesocycleData(prevData => ({
//           ...prevData,
//           [selectedMesocycle.id]: {
//             ...prevData[selectedMesocycle.id],
//             name: editedName.trim()
//           }
//         }));
//       } catch (error) {
//         console.error("Error renaming mesocycle:", error);
//       }
//     }
//     setIsEditingName(false);
//   };


//   const handleNameKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleNameSubmit();
//     } else if (e.key === 'Escape') {
//       setIsEditingName(false);
//       setEditedName(getCurrentMesocycleName()); // Reset to original name
//     }
//   };

//   useEffect(() => {
//     if (isEditingName && editInputRef.current) {
//       editInputRef.current.focus();
//     }
//   }, [isEditingName]);

//   // **Add state variable to manage modal open state**
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     const fetchSelectedMesocycle = async () => {
//       try {
//         if (
//           program.programType === 'Macrocycle' &&
//           program.programBlocks &&
//           program.programBlocks.length > 0
//         ) {
//           const mesocycleId = program.programBlocks[0];
//           const mesocycleData = await fetchMesocycleById(mesocycleId);
//           if (mesocycleData) {
//             setSelectedMesocycle(mesocycleData);
//             setMesocycleStatus(mesocycleData.status || 'Current');
//           }
//         } else if (
//           ['Mesocycle Advanced', 'Mesocycle Basic'].includes(program.programType)
//         ) {
//           setSelectedMesocycle(program);
//           setMesocycleStatus(program.status || 'Current');
//         }
//       } catch (error) {
//         console.error('Error fetching selected mesocycle:', error);
//         // Optionally display an error message to the user
//       }
//     };

//     fetchSelectedMesocycle();
//   }, [program, fetchMesocycleById]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (drawerRef.current && !drawerRef.current.contains(event.target) && isDrawerOpen) {
//         setIsDrawerOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isDrawerOpen]);

//   const handleDrawerToggle = () => {
//     setIsDrawerOpen((prev) => !prev);
//   };

//   // **Handler to open the modal**
//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   // **Handler to close the modal**
//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };



//   const handleMesocycleSelect = useCallback(async (mesocycleId) => {
//     if (isTransitioning) return;
//     setIsTransitioning(true);

//     try {
//       const mesocycleData = await fetchMesocycleById(mesocycleId);
//       if (mesocycleData) {
//         // Animate the old content out
//         await gsap.to(contentRef.current, {
//           x: '-100%',
//           opacity: 0,
//           duration: 0.5,
//           ease: 'power2.inOut',
//         });

//         // Update state after the animation completes
//         setSelectedMesocycle(mesocycleData);
//         setMesocycleStatus(mesocycleData.status || 'Not Set');
//         setActiveTab('program');
//       }
//     } catch (error) {
//       console.error('Error selecting mesocycle:', error);
//     }
//   }, [isTransitioning, fetchMesocycleById]);
  
//   // Add useEffect to animate new content in
//   useEffect(() => {
//     if (isTransitioning) {
//       // Ensure the DOM has updated
//       const animation = gsap.fromTo(
//         contentRef.current,
//         { x: '100%', opacity: 0 },
//         {
//           x: '0%',
//           opacity: 1,
//           duration: 0.5,
//           ease: 'power2.inOut',
//           onComplete: () => setIsTransitioning(false),
//         }
//       );

//       return () => {
//         // Clean up the animation if the component unmounts
//         animation.kill();
//       };
//     }
//   }, [selectedMesocycle]);
  


//   const handleStatusChange = async (event) => {
//     const newStatus = event.target.value;
//     setMesocycleStatus(newStatus);
//     if (selectedMesocycle) {
//       try {
//         if (program.programType === 'Macrocycle') {
//           if (
//             program.programBlocks &&
//             program.programBlocks.includes(selectedMesocycle.id)
//           ) {
//             await updateMesocycleStatus(selectedMesocycle.id, newStatus);
//           } else {
//             console.error('Selected mesocycle is not part of this macrocycle');
//             setMesocycleStatus(selectedMesocycle.status || 'Not Set');
//           }
//         } else {
//           await updateMesocycleStatus(selectedMesocycle.id, newStatus);
//         }
//       } catch (error) {
//         console.error('Error updating mesocycle status:', error);
//         setMesocycleStatus(selectedMesocycle.status || 'Not Set');
//       }
//     }
//   };

//   const availableTabs = useMemo(() => {
//     const baseTabs = ['overview', 'exercises'];

//     switch (program?.programType) {
//       case 'Macrocycle':
//         const macrocycleTabs = [
//           ...baseTabs,
//           'macrocycle',
//           'training-blocks',
//           'goals',
//           'calendar',
//           'records',
//         ];
//         if (program.programBlocks && program.programBlocks.length > 0) {
//           macrocycleTabs.splice(1, 0, 'program'); // Insert 'program' after 'overview'
//         }
//         return macrocycleTabs;
//       case 'Mesocycle Advanced':
//         return [...baseTabs, 'program', 'goals', 'calendar'];
//       case 'Mesocycle Basic':
//       default:
//         return [...baseTabs, 'program'];
//     }
//   }, [program?.programType, program?.programBlocks]);

//   // #################################################

//     const memoizedComponents = useMemo(() => ({
//     ExerciseTracker: <ExerciseTracker />,
//     MacrocyclePlanet: <MacrocyclePlanet />,
//     Overview: <Overview username={user.username} avatarUrl={user.avatarUrl} />,
//     PersonalRecordComponent: <PersonalRecordComponent />,
//     TrainingBlocks: <TrainingBlocks />,
//   }), [user.username, user.avatarUrl]);

//   const renderTabContent = () => {
//     if (!program) {
//       return <div>Loading program data...</div>;
//     }

//     let tabContent = null;

//     if (activeTab === 'overview') {
//       if (program.programType === 'Macrocycle') {
//         tabContent = <MacrocyclePlanet />;
//       } else {
//         tabContent = <Overview username={user.username} avatarUrl={user.avatarUrl} />;
//       }

//     } else if (activeTab === 'program') {



//       if (program.programType === 'Macrocycle' && (!program.programBlocks || program.programBlocks.length === 0)) {
//         tabContent = (
//           <CenteredContent>
//             <AddCycleButton
//               programId={program.id}
//               programType="Mesocycle Advanced"
//               onCycleAdded={() => {
//                 // You can add any necessary logic here after a cycle is added
//                 // For example, you might want to refetch the program data
//               }}
//             />
//           </CenteredContent>
//         );
//       } else {
//         tabContent = (
//         <MesocycleProgram 
//         key={selectedMesocycle?.id || program.id}
//         program={selectedMesocycle || program} 
//         />
//         );
//       }


//     } else if (activeTab === 'exercises') {
//       tabContent = 
//       <ExerciseTracker />
//       // <Exercises />;
//     } else if (activeTab === 'goals') {
//       tabContent = (
//         <>
//           <GoalBuilder programId={program.id} />
//           <GoalList programId={program.id} />
//         </>
//       );
//     } else if (activeTab === 'calendar') {
//       tabContent = <div>Calendar content</div>;
//     } else if (activeTab === 'records') {
//       tabContent = <PersonalRecordComponent />;
//     } else if (activeTab === 'training-blocks' && program.programType === 'Macrocycle') {
//       tabContent = <TrainingBlocks />;
//     } else {
//       tabContent = <MesocycleProgram program={selectedMesocycle || program} />;
//     }



//   return (
//     // ... [other components]
//     <AnimationContainer>
//       <AnimatedContent
//         ref={(el) => {
//           contentRef.current = el;
//         }}
//         key={selectedMesocycle?.id || program.id}
//       >
//         {tabContent}
//       </AnimatedContent>
//     </AnimationContainer>
//     // ... [other components]
//   );
// };

//   const getCurrentMesocycleName = () => {
//     if (program.programType === 'Macrocycle') {
//       if (!selectedMesocycle) {
//         return 'Select a Mesocycle';
//       }
//       return selectedMesocycle.name;
//     }

//     return program.name || 'Program Name Unavailable';
//   };

//   const shouldRenderSubHeader = program.programType === 'Macrocycle' && program.programBlocks && program.programBlocks.length > 0;


//   return (
//     <LayoutContainer>
//       <ProgramBackground />
//       <MesocycleHeader
//         programName={program.name}
//         activeTab={activeTab}
//         onTabChange={setActiveTab}
//         onShare={() => console.log('Share clicked')}
//         programType={program.programType}
//         availableTabs={availableTabs}
//         programBlocks={program.programBlocks}
//       />
//       {activeTab === 'program' && shouldRenderSubHeader && (
//         // {activeTab === 'program' && (

//         <SubHeader>
//           <SubHeaderLeft>
//             <SubHeaderTitle onClick={handleNameClick}>
//               {isEditingName ? (
//                 <EditableTitle
//                   ref={editInputRef}
//                   value={editedName}
//                   onChange={handleNameChange}
//                   onKeyDown={handleNameKeyDown}
//                   onBlur={handleNameSubmit}
//                 />
//               ) : (
//                 getCurrentMesocycleName()
//               )}
//             </SubHeaderTitle>
//             <StatusSelect value={mesocycleStatus} onChange={handleStatusChange}>
//               <option value="Not Set">Not Set</option>
//               <option value="Current">Current</option>
//               <option value="Upcoming">Upcoming</option>
//               <option value="Completed">Completed</option>
//             </StatusSelect>
//           </SubHeaderLeft>
//           {program.programType === 'Macrocycle' && (

//             <ButtonGroup>
//               <SelectMesoButton onClick={handleDrawerToggle}>
//                 <FaCube /> Select
//               </SelectMesoButton>
//               <SelectMesoButton onClick={handleOpenModal}>
//                 <FaEye /> Cycles
//               </SelectMesoButton>
//             </ButtonGroup>

//           )}
//         </SubHeader>
//       )}
//       <MainContent>{renderTabContent()}</MainContent>

//       {/* **Render the CaroMesoModal when isModalOpen is true** */}
//       {isModalOpen && (
//         <CaroMesoModal
//           isOpen={isModalOpen}
//           onClose={handleCloseModal}
//           onTabChange={setActiveTab}
//         />
//       )}

//       {program.programType === 'Macrocycle' && (
//         <BottomDrawer open={isDrawerOpen} ref={drawerRef}>
//           <DrawerHeader onClick={handleDrawerToggle}>

//           </DrawerHeader>
//           <DrawerContent>
//             {program.programBlocks?.map((mesocycleId, index) => (
//               <MesocycleCard
//                 key={mesocycleId}
//                 onClick={() => handleMesocycleSelect(mesocycleId)}
//               >
//                 <MesocycleNumber>Block {index + 1}</MesocycleNumber>
//                 <MesocycleName>
//                   {mesocycleData[mesocycleId]?.name || `Mesocycle ${index + 1}`}
//                 </MesocycleName>
//               </MesocycleCard>
              
//             ))}
//             <AddCycleButton programId={program.id} />
//           </DrawerContent>
//         </BottomDrawer>
//       )}
//     </LayoutContainer>
//   );
// };

// export default memo(ProgramLayout);

// // ######################################################


import React, { useState, useMemo, memo, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth';
import { useProgram } from '../context/ProgramContext';
import MesocycleHeader from './MesocycleHeader';
import MesocycleProgram from './MesocycleProgram';
import ExerciseTracker from './Exercises/ExerciseTracker';
import GoalBuilder from './goals/GoalBuilder';
import GoalList from './goals/GoalList';
import MacrocyclePlanet from './MacrocyclePlanet';
import PersonalRecordComponent from './Exercises/PersonalRecordComponent';
import ProgramBackground from './ProgramBackground';
import TrainingBlocks from './TrainingBlocks';
import { gsap } from 'gsap';
import { FaArrowUp, FaPlus, FaRegEdit, FaCube, FaEye } from 'react-icons/fa';
import AddCycleButton from './AddCycleButton';

import CaroMesoModal from './CaroMesoModal';
import Overview from './ProgramOverview';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const MainContent = styled.main`
  flex: 1;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
`;

const BottomDrawer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2c2c2c;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: ${(props) => (props.open ? 'translateY(0)' : 'translateY(100%)')};
  transition: transform 0.3s ease;
  z-index: 999;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #3a3a3a;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  cursor: pointer;
`;

const DrawerContent = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 16px;
  gap: 16px;

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none;
`;

const MesocycleCard = styled.div`
  min-width: 200px;
  background-color: #3a3a3a;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const MesocycleNumber = styled.div`
  font-size: 12px;
  color: #aaa;
  margin-bottom: 4px;
`;

const MesocycleName = styled.h3`
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #fff;
`;





const StatusSelect = styled.select`
  background-color: ${(props) =>
    props.value === 'Current' ? '#4caf50' :
      props.value === 'Upcoming' ? '#2196f3' :
        props.value === 'Completed' ? '#ff9800' :
          '#555'};
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 2px 2px 2px 8px; // Increased right padding for the custom arrow
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 8px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 90px; // Ensure minimum width to fit content
  
  // Custom dropdown arrow
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M0 2l4 4 4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;

  &:hover, &:focus {
    background-color: ${(props) =>
    props.value === 'Current' ? '#45a049' :
      props.value === 'Upcoming' ? '#1e88e5' :
        props.value === 'Completed' ? '#f57c00' :
          '#4a4a4a'};
    outline: none;
  }

  /* Hide the default arrow in IE */
  &::-ms-expand {
    display: none;
  }

  option {
    background-color: #2a2a2a;
    color: #fff;
  }
`;

const SelectMesoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #fff;
  border: 1px solid #4a4a4a;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #4a4a4a;
    color: #fff;
  }

  svg {
    margin-right: 6px;
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const AnimatedContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;



const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;



const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2a2a;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;

    @media (max-width: 768px) {
 font-size: 12px;
  }
`;



const SubHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;



const SubHeaderRight = styled.div`
  display: flex;
  gap: 8px;
`;

const SubHeaderTitle = styled.h3`
  margin: 0;
  font-weight: 400;
  color: #fff;
  width: 7em;

  @media (max-width: 768px) {
  width: 5em;
  front-size: 11px;
  }
`;

const EditableTitle = styled.input`
  background: none;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  width: 7em;
  &:focus {
    outline: none;
    border: 1px solid #fff;
  }
`;


const ProgramLayout = memo(({ program }) => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const [selectedMesocycle, setSelectedMesocycle] = useState(null);
  const [mesocycleStatus, setMesocycleStatus] = useState('Not Set');
  const contentRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { updateMesocycleStatus, fetchMesocycleById, renameProgram, createProgram, importMesocycleToMacrocycle } = useProgram();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');
  const editInputRef = useRef(null);
  const [mesocycleData, setMesocycleData] = useState({});
  const memoizedComponents = useMemo(() => ({
    ExerciseTracker: <ExerciseTracker />,
    MacrocyclePlanet: <MacrocyclePlanet />,
    Overview: <Overview username={user.username} avatarUrl={user.avatarUrl} />,
    PersonalRecordComponent: <PersonalRecordComponent />,
    TrainingBlocks: <TrainingBlocks />,
  }), [user.username, user.avatarUrl]);

  const handleNameClick = () => {
    setEditedName(getCurrentMesocycleName());
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const fetchMesocyclesData = useCallback(async () => {
    if (program.programType === 'Macrocycle' && program.programBlocks) {
      const mesocyclesData = {};
      for (const mesocycleId of program.programBlocks) {
        try {
          const data = await fetchMesocycleById(mesocycleId);
          if (data) {
            mesocyclesData[mesocycleId] = data;
          }
        } catch (error) {
          console.error(`Error fetching mesocycle ${mesocycleId}:`, error);
        }
      }
      setMesocycleData(mesocyclesData);
    }
  }, [program.programType, program.programBlocks, fetchMesocycleById]);

  useEffect(() => {
    fetchMesocyclesData();
  }, [fetchMesocyclesData, program.programBlocks]);


  const handleNameSubmit = async () => {
    if (editedName.trim() && editedName !== getCurrentMesocycleName()) {
      try {
        await renameProgram(selectedMesocycle.id, editedName.trim());
        // Update the local state
        setSelectedMesocycle(prevState => ({
          ...prevState,
          name: editedName.trim()
        }));
        // Update the mesocycleData state
        setMesocycleData(prevData => ({
          ...prevData,
          [selectedMesocycle.id]: {
            ...prevData[selectedMesocycle.id],
            name: editedName.trim()
          }
        }));
      } catch (error) {
        console.error("Error renaming mesocycle:", error);
      }
    }
    setIsEditingName(false);
  };


  const handleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleNameSubmit();
    } else if (e.key === 'Escape') {
      setIsEditingName(false);
      setEditedName(getCurrentMesocycleName()); // Reset to original name
    }
  };

  useEffect(() => {
    if (isEditingName && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [isEditingName]);

  // **Add state variable to manage modal open state**
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchSelectedMesocycle = async () => {
      try {
        if (
          program.programType === 'Macrocycle' &&
          program.programBlocks &&
          program.programBlocks.length > 0
        ) {
          const mesocycleId = program.programBlocks[0];
          const mesocycleData = await fetchMesocycleById(mesocycleId);
          if (mesocycleData) {
            setSelectedMesocycle(mesocycleData);
            setMesocycleStatus(mesocycleData.status || 'Current');
          }
        } else if (
          ['Mesocycle Advanced', 'Mesocycle Basic'].includes(program.programType)
        ) {
          setSelectedMesocycle(program);
          setMesocycleStatus(program.status || 'Current');
        }
      } catch (error) {
        console.error('Error fetching selected mesocycle:', error);
        // Optionally display an error message to the user
      }
    };

    fetchSelectedMesocycle();
  }, [program, fetchMesocycleById]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target) && isDrawerOpen) {
        setIsDrawerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDrawerOpen]);

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // **Handler to open the modal**
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // **Handler to close the modal**
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  const handleMesocycleSelect = useCallback(async (mesocycleId) => {
    if (isTransitioning) return;
    setIsTransitioning(true);

    try {
      const mesocycleData = await fetchMesocycleById(mesocycleId);
      if (mesocycleData) {
        // Animate the old content out
        await gsap.to(contentRef.current, {
          x: '-100%',
          opacity: 0,
          duration: 0.5,
          ease: 'power2.inOut',
        });

        // Update state after the animation completes
        setSelectedMesocycle(mesocycleData);
        setMesocycleStatus(mesocycleData.status || 'Not Set');
        setActiveTab('program');
      }
    } catch (error) {
      console.error('Error selecting mesocycle:', error);
    }
  }, [isTransitioning, fetchMesocycleById]);
  
  // Add useEffect to animate new content in
  useEffect(() => {
    if (isTransitioning) {
      // Ensure the DOM has updated
      const animation = gsap.fromTo(
        contentRef.current,
        { x: '100%', opacity: 0 },
        {
          x: '0%',
          opacity: 1,
          duration: 0.5,
          ease: 'power2.inOut',
          onComplete: () => setIsTransitioning(false),
        }
      );

      return () => {
        // Clean up the animation if the component unmounts
        animation.kill();
      };
    }
  }, [selectedMesocycle]);
  


  const handleStatusChange = useCallback(async (event) => {
    const newStatus = event.target.value;
    setMesocycleStatus(newStatus);
    if (selectedMesocycle) {
      try {
        if (program.programType === 'Macrocycle') {
          if (
            program.programBlocks &&
            program.programBlocks.includes(selectedMesocycle.id)
          ) {
            await updateMesocycleStatus(selectedMesocycle.id, newStatus);
          } else {
            console.error('Selected mesocycle is not part of this macrocycle');
            setMesocycleStatus(selectedMesocycle.status || 'Not Set');
          }
        } else {
          await updateMesocycleStatus(selectedMesocycle.id, newStatus);
        }
      } catch (error) {
        console.error('Error updating mesocycle status:', error);
        setMesocycleStatus(selectedMesocycle.status || 'Not Set');
      }
    }
  }, [program.programType, program.programBlocks, selectedMesocycle, updateMesocycleStatus]);


  const availableTabs = useMemo(() => {
    const baseTabs = ['overview', 'exercises'];

    switch (program?.programType) {
      case 'Macrocycle':
        const macrocycleTabs = [
          ...baseTabs,
          'macrocycle',
          'training-blocks',
          'goals',
          'calendar',
          'records',
        ];
        if (program.programBlocks && program.programBlocks.length > 0) {
          macrocycleTabs.splice(1, 0, 'program'); // Insert 'program' after 'overview'
        }
        return macrocycleTabs;
      case 'Mesocycle Advanced':
        return [...baseTabs, 'program', 'goals', 'calendar'];
      case 'Mesocycle Basic':
      default:
        return [...baseTabs, 'program'];
    }
  }, [program?.programType, program?.programBlocks]);

  // #################################################



  const renderTabContent = () => {
    if (!program) {
      return <div>Loading program data...</div>;
    }

    let tabContent = null;

    if (activeTab === 'overview') {
      if (program.programType === 'Macrocycle') {
        tabContent = <MacrocyclePlanet />;
      } else {
        tabContent = <Overview username={user.username} avatarUrl={user.avatarUrl} />;
      }

    } else if (activeTab === 'program') {



      if (program.programType === 'Macrocycle' && (!program.programBlocks || program.programBlocks.length === 0)) {
        tabContent = (
          <CenteredContent>
            <AddCycleButton
              programId={program.id}
              programType="Mesocycle Advanced"
              onCycleAdded={() => {
                // You can add any necessary logic here after a cycle is added
                // For example, you might want to refetch the program data
              }}
            />
          </CenteredContent>
        );
      } else {
        tabContent = (
        <MesocycleProgram 
        key={selectedMesocycle?.id || program.id}
        program={selectedMesocycle || program} 
        />
        );
      }


    } else if (activeTab === 'exercises') {
      tabContent = 
      <ExerciseTracker />
      // <Exercises />;
    } else if (activeTab === 'goals') {
      tabContent = (
        <>
          <GoalBuilder programId={program.id} />
          <GoalList programId={program.id} />
        </>
      );
    } else if (activeTab === 'calendar') {
      tabContent = <div>Calendar content</div>;
    } else if (activeTab === 'records') {
      tabContent = <PersonalRecordComponent />;
    } else if (activeTab === 'training-blocks' && program.programType === 'Macrocycle') {
      tabContent = <TrainingBlocks />;
    } else {
      tabContent = <MesocycleProgram program={selectedMesocycle || program} />;
    }



  return (
    // ... [other components]
    <AnimationContainer>
      <AnimatedContent
        ref={(el) => {
          contentRef.current = el;
        }}
        key={selectedMesocycle?.id || program.id}
      >
        {tabContent}
      </AnimatedContent>
    </AnimationContainer>
    // ... [other components]
  );
};

  const getCurrentMesocycleName = () => {
    if (program.programType === 'Macrocycle') {
      if (!selectedMesocycle) {
        return 'Select a Mesocycle';
      }
      return selectedMesocycle.name;
    }

    return program.name || 'Program Name Unavailable';
  };

  const shouldRenderSubHeader = program.programType === 'Macrocycle' && program.programBlocks && program.programBlocks.length > 0;


  return (
    <LayoutContainer>
      <ProgramBackground />
      <MesocycleHeader
        programName={program.name}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onShare={() => console.log('Share clicked')}
        programType={program.programType}
        availableTabs={availableTabs}
        programBlocks={program.programBlocks}
      />
      {activeTab === 'program' && shouldRenderSubHeader && (
        // {activeTab === 'program' && (

        <SubHeader>
          <SubHeaderLeft>
            <SubHeaderTitle onClick={handleNameClick}>
              {isEditingName ? (
                <EditableTitle
                  ref={editInputRef}
                  value={editedName}
                  onChange={handleNameChange}
                  onKeyDown={handleNameKeyDown}
                  onBlur={handleNameSubmit}
                />
              ) : (
                getCurrentMesocycleName()
              )}
            </SubHeaderTitle>
            <StatusSelect value={mesocycleStatus} onChange={handleStatusChange}>
              <option value="Not Set">Not Set</option>
              <option value="Current">Current</option>
              <option value="Upcoming">Upcoming</option>
              <option value="Completed">Completed</option>
            </StatusSelect>
          </SubHeaderLeft>
          {program.programType === 'Macrocycle' && (

            <ButtonGroup>
              <SelectMesoButton onClick={handleDrawerToggle}>
                <FaCube /> Select
              </SelectMesoButton>
              <SelectMesoButton onClick={handleOpenModal}>
                <FaEye /> Cycles
              </SelectMesoButton>
            </ButtonGroup>

          )}
        </SubHeader>
      )}
      <MainContent>{renderTabContent()}</MainContent>

      {/* **Render the CaroMesoModal when isModalOpen is true** */}
      {isModalOpen && (
        <CaroMesoModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onTabChange={setActiveTab}
        />
      )}

      {program.programType === 'Macrocycle' && (
        <BottomDrawer open={isDrawerOpen} ref={drawerRef}>
          <DrawerHeader onClick={handleDrawerToggle}>

          </DrawerHeader>
          <DrawerContent>
            {program.programBlocks?.map((mesocycleId, index) => (
              <MesocycleCard
                key={mesocycleId}
                onClick={() => handleMesocycleSelect(mesocycleId)}
              >
                <MesocycleNumber>Block {index + 1}</MesocycleNumber>
                <MesocycleName>
                  {mesocycleData[mesocycleId]?.name || `Mesocycle ${index + 1}`}
                </MesocycleName>
              </MesocycleCard>
              
            ))}
            <AddCycleButton programId={program.id} />
          </DrawerContent>
        </BottomDrawer>
      )}
    </LayoutContainer>
  );
});

export default memo(ProgramLayout);