

import React, { useEffect, useState }  from 'react';
import styled from 'styled-components';
import { FaArrowRight, FaCalendarAlt, FaBullseye, FaDumbbell  } from 'react-icons/fa';
import { useProgram } from '../context/ProgramContext';
import { useWorkout } from '../context/WorkoutContext';

const ActiveProgramContainer = styled.div`
  // background: linear-gradient(135deg, #2c3e50, #34495e);
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 12px;
  padding: 24px;
  color: #ecf0f1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const ProgramHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ProgramTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0;
  color: #fff;

  @media (max-width: 480px) {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
`;

const StatusBadge = styled.span`
  background-color: #27ae60;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

const ProgramInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  svg {
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    font-size: 0.875rem;
  }
`;

const ProgressBar = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  height: 8px;
  margin-bottom: 16px;
  overflow: hidden;
`;

const Progress = styled.div`
  background-color: #3498db;
  height: 100%;
  width: ${props => props.percent}%;
  transition: width 0.3s ease;
`;

const ViewProgramButton = styled.button`
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  svg {
    margin-left: 8px;
  }

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    font-size: 0.875rem;
  }
`;

const WeekProgressBar = styled(ProgressBar)`
  margin-top: 8px;
  background-color: rgba(255, 255, 255, 0.1);
`;

const WeekProgress = styled(Progress)`
  background-color: #f39c12;
`;

const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-size: 0.9rem;
`;



const ActiveProgramStatus = ({ onViewProgram }) => {
  const { programs } = useProgram();
  const { workouts } = useWorkout();
  const [activeProgram, setActiveProgram] = useState(null);

  useEffect(() => {
    const currentProgram = programs.find(program => 
      program.programType.startsWith('Mesocycle') && program.status === 'Current'
    );
    setActiveProgram(currentProgram);
  }, [programs]);

  if (!activeProgram) {
    return null;
  }

  console.log('Active Program:', activeProgram);
  console.log('All Workouts:', workouts);

  // Filter workouts for the active program
  const programWorkouts = workouts.filter(workout => workout.programId === activeProgram.id);
  console.log('Program Workouts:', programWorkouts);

  const totalWorkouts = programWorkouts.length;
  const completedWorkouts = programWorkouts.filter(workout => workout.actions?.state === 'completed').length;
  
  console.log('Total Workouts:', totalWorkouts);
  console.log('Completed Workouts:', completedWorkouts);

  const progressPercent = totalWorkouts > 0 ? (completedWorkouts / totalWorkouts) * 100 : 0;

  const totalWeeks = activeProgram.totalWeeks || Math.max(...programWorkouts.map(w => w.week || 0), 0);

  const currentWeek = programWorkouts.reduce((maxWeek, workout) => {
    if (workout.actions?.state !== 'completed' && workout.week > maxWeek) {
      return workout.week;
    }
    return maxWeek;
  }, 0) || 1;

  const nextWorkout = programWorkouts.find(workout => workout.actions?.state !== 'completed');
  const nextWorkoutDate = nextWorkout?.assignedDate 
    ? new Date(nextWorkout.assignedDate).toLocaleDateString()
    : 'Not scheduled';

  return (
    <ActiveProgramContainer>
      <ProgramHeader>
        <ProgramTitle>{activeProgram.name}</ProgramTitle>
        <StatusBadge>{activeProgram.status}</StatusBadge>
      </ProgramHeader>
      <ProgramInfo>
        {/* <InfoItem>
          <FaCalendarAlt size={18} />
          Total Weeks: {totalWeeks}
        </InfoItem> */}
        {/* <InfoItem>
          <FaBullseye size={18} />
          Goal: {activeProgram.goals?.[0] || 'Not set'}
        </InfoItem> */}
      </ProgramInfo>

      <ProgressInfo>
        <span>
          <FaDumbbell /> {completedWorkouts} / {totalWorkouts} workouts completed
        </span>
        <span>{progressPercent.toFixed(1)}%</span>
      </ProgressInfo>

      <ProgressBar>
        <Progress percent={progressPercent} />
      </ProgressBar>

      {/* <InfoItem>
        Next workout: {nextWorkoutDate}
      </InfoItem> */}
      <ViewProgramButton onClick={() => onViewProgram(activeProgram.id)}>
        View Program
        <FaArrowRight size={18} />
      </ViewProgramButton>
    </ActiveProgramContainer>
  );
};

export default ActiveProgramStatus;

// #############################################

// 