// import React from "react";
// import TopBar from "../components/TopBar";
// import RotaryNav from "../components/RotaryNav";

// function AppLayout() {
//   return (
//     <>
//       <TopBar />
//       <RotaryNav />
//     </>
//   );
// }

// export default AppLayout;

// #######################################################

// import React from "react";
// import styled from "styled-components";

// import TopBar from "../components/TopBar";
// import RotaryNav from "../components/RotaryNav";
// import Tback from "./TBack";

// const BackgroundWrapper = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   z-index: 0; // Ensure background is below all other content
//   pointer-events: none; // Ensure background doesn't interfere with clicking
//   overflow: hidden;
// `;

// function AppLayout({ children }) {
//   return (
//     <>
//       <TopBar />
//       <RotaryNav />
//       <main>{children}</main>
//       <BackgroundWrapper>
//         <Tback color="#DD3845" />
//       </BackgroundWrapper>
//     </>
//   );
// }

// export default AppLayout;


import React from "react";
import styled from "styled-components";

import TopBar from "../components/TopBar";
import RotaryNav from "../components/RotaryNav";
import Tback from "./TBack";

const LayoutContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: 1;
  pointer-events: none;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

function AppLayout({ children }) {
  return (
    <LayoutContainer>
      <BackgroundWrapper>
        <Tback color="#DD3845" />
      </BackgroundWrapper>
      <ContentWrapper>
        {/* <TopBar /> */}
        <MainContent>{children}</MainContent>
      </ContentWrapper>
      <RotaryNav />
    </LayoutContainer>
  );
}

export default AppLayout;