

// import React, { useState, useCallback, useEffect } from "react";
// import styled from "styled-components";
// import { FaEllipsisV, FaCalendarAlt, FaCheck, FaTimes, FaEdit, FaClone } from "react-icons/fa";
// import { useWorkout } from "../context/WorkoutContext";
// import WorkoutCalendar from "./WorkoutCalendar";

// const Header = styled.div`
//   padding: 20px;
//   background-color: #2c2c2c;
//   color: white;
//   border-radius: 8px 8px 0px 0px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-size: 0.8em;
//   // min-width: 320px;
// `;

// const EditableMainMovement = styled.input.withConfig({
//   shouldForwardProp: (prop) => !['isEditing', 'isEditable'].includes(prop),
// })`
// border: ${({ isEditing, isEditable, showValidation, value }) => {
//     if (showValidation && !value?.trim()) return "1px solid #ff4444";
//     if (isEditing && isEditable) return "1px solid white";
//     return "none";
//   }};
//     background: transparent;
//   color: white;
//   font-size: 1.3em;
//   padding: ${({ isEditing, isEditable }) => (isEditing && isEditable ? "5px" : "0")};
//   padding-left: 2px;
//   margin: 0;
//   outline: none;
//   width: 100%;
//   max-width: 100%;
//   cursor: ${({ isEditable }) => (isEditable ? "text" : "default")};
//   pointer-events: ${({ isEditable }) => (isEditable ? "auto" : "none")};
//   // opacity: ${({ isEditable }) => (isEditable ? 1 : 0.7)};
//   &::placeholder {
//     color: #aaa;
//     font-style: italic;
//     font-size: 1em;
//   }

//   @media (max-width: 768px) {
//     font-size: 1em;
//     font-weight: bold;
//     &::placeholder {
//       font-size: 0.8em;
//     }
//   }
// `;

// const HeaderSection = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;
// `;

// const IntensitySelector = styled.select`
//  border: ${({ showValidation, value }) => 
//     showValidation && !value ? "1px solid #ff4444" : "none"};
//   background: transparent;
//   color: ${({ $intensity }) =>
//     $intensity === "Easy/Light"
//       ? "#4caf50"
//       : $intensity === "Moderate"
//       ? "#ffeb3b"
//       : $intensity === "Hard/Heavy"
//       ? "#f44336"
//       : "#fff"};
//   font-size: 1em;
//   outline: none;
//   cursor: pointer;
//   padding-bottom: 1px;
//   font-size: 0.8em;

//   option {
//     background-color: #1c1c1e;
//     color: white;
//   }
// `;



// const Button = styled.button`
//   padding: 10px 20px;
//   margin: 5px;
//   border: none;
//   border-radius: 4px;
//   background-color: ${(props) => (props.primary ? "#4caf50" : "#3A3A3A")};
//   color: white;
//   cursor: pointer;

//   &:hover {
//     background-color: ${(props) => (props.primary ? "#45a049" : "#bbb")};
//   }
// `;

// const MeatballMenu = styled.div`
//   position: relative;
//   display: inline-block;

//   & .menu-content {
//     display: none;
//     position: absolute;
//     right: 0;
//     background-color: #3a3a3a;
//     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
//     z-index: 1;

//     & button {
//       display: block;
//       width: 100%;
//       padding: 12px 16px;
//       text-align: left;
//       background: none;
//       border: none;
//       cursor: pointer;
//       color: #ffffff;
//       font-size: 0.8em;

//       &:hover {
//         background-color: #4e4e4e;
//       }
//     }
//   }

//   &:hover .menu-content {
//     display: block;
//   }
// `;

// const DateSelector = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const CalendarIcon = styled(FaCalendarAlt)`
//   margin-right: 5px;
// `;

// const CalendarModal = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background: linear-gradient(45deg, #333, #545454, #333);
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   padding: 20px;
//   border-radius: 8px;
//   z-index: 1000;
//   width: 100%;
//   max-width: 600px;
// `;

// const Backdrop = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 999;
// `;

// const WorkoutNumber = styled.div`
// color: #6f6f6f;
// font-size: 0.8em;
// `;



// const ActionBar = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 20px;
//   align-items: center;
//   padding: 10px;
//   background: linear-gradient(90deg, #474747, #545454);
//   border-radius: 0px 0px 8px 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;

// const ActionInfo = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// `;

// const ActionButtons = styled.div`
//   display: flex;
//   gap: 10px;
// `;

// const IconButton = styled.button`
//   padding: 5px;
//   border: none;
//   border-radius: 4px;
//   background-color: ${(props) => (props.primary ? "#4caf50" : "#3A3A3A")};
//   color: white;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     background-color: ${(props) => (props.primary ? "#45a049" : "#bbb")};
//   }

//   svg {
//     width: 10px;
//     height: 10px;
//   }
// `;

// const PublishButton = styled(IconButton)`
//   background-color: ${(props) => (props.isPublished ? "#3A3A3A" : "#4caf50")};
//   &:hover {
//     background-color: ${(props) => (props.isPublished ? "#d32f2f" : "#45a049")};
//   }
// `;

// const StateDisplay = styled.div`
//   font-size: 1em;
//   font-weight: bold;
//   background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
//   background-clip: text;
//   color: transparent;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-size: 200% 200%;
//   animation: animateGradient 5s ease infinite;

//   @keyframes animateGradient {
//     0% { background-position: 0% 50%; }
//     50% { background-position: 100% 50%; }
//     100% { background-position: 0% 50%; }
//   }
// `;

// const TimestampDisplay = styled.div`
//   font-size: 0.9em;
//   color: #ffffff;
//   margin-top: 5px;
// `;

// const BuildModeMessage = styled.div`
//   color: white;
//   font-size: 0.7em;
//   font-weight: bold;
// `;



// const useResolvedTimestamp = (timestamp) => {
//   const [resolvedTimestamp, setResolvedTimestamp] = useState(null);

//   useEffect(() => {
//     if (!timestamp) return;

//     const resolveTimestamp = () => {
//       if (timestamp instanceof Date) {
//         setResolvedTimestamp(timestamp);
//       } else if (timestamp.toDate && typeof timestamp.toDate === "function") {
//         setResolvedTimestamp(timestamp.toDate());
//       } else if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
//         setResolvedTimestamp(new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000));
//       } else if (typeof timestamp === "number") {
//         setResolvedTimestamp(new Date(timestamp));
//       }
//     };

//     resolveTimestamp();
//   }, [timestamp]);

//   return resolvedTimestamp;
// };

// const formatTimestamp = (date) => {
//   if (!date || !(date instanceof Date)) return "N/A";
  
//   return date.toLocaleString("en-US", {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//   });
// };

// const WorkoutHeader = ({
//   currentWorkout,
//   handlePublish,
//   handleCompleteWorkout,
//   handleCancelWorkout,
//   handleDuplicate,
//   programId,
//   showValidation,
//   handleDelete,
// }) => {
//   const { updateWorkout, updateAssignedDate } = useWorkout();
//   const [isEditing, setIsEditing] = useState(false);
//   const [mainMovement, setMainMovement] = useState(
//     currentWorkout.mainMovement || ""
//   );
//   const [intensity, setIntensity] = useState(currentWorkout.intensity || "");
//   const [assignedDate, setAssignedDate] = useState(
//     currentWorkout.assignedDate ? new Date(currentWorkout.assignedDate) : null
//   );
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//   const resolvedEndTime = useResolvedTimestamp(currentWorkout?.actions?.endTime);

//   const isWorkoutEditable = !currentWorkout.actions?.state || 
//   (currentWorkout.actions.state !== "completed" && 
//    currentWorkout.actions.state !== "cancelled");

//   const handleMainMovementChange = useCallback((e) => {
//     if (isWorkoutEditable) {
//       const newValue = e.target.value.slice(0, 25); // Truncate input at 50 characters
//       setMainMovement(e.target.value);
//     }
//   }, []);

//   const handleMainMovementBlur = useCallback(() => {
//     if (isWorkoutEditable) {
//     setIsEditing(false);
//     if (mainMovement !== currentWorkout.mainMovement) {
//       const updatedWorkout = {
//         ...currentWorkout,
//         mainMovement,
//         // name: `${mainMovement} - ${currentWorkout.intensity}`,
//         name: `${mainMovement} - ${currentWorkout.intensity}`.slice(0, 25),
//       };
//       updateWorkout(updatedWorkout, programId);
//     }
//   }
//   }, [currentWorkout, mainMovement, updateWorkout, programId]);

//   const handleIntensityChange = useCallback(
//     (e) => {
//       if (isWorkoutEditable) {
//       const newIntensity = e.target.value;
//       setIntensity(newIntensity);
//       if (newIntensity !== currentWorkout.intensity) {
//         const updatedWorkout = {
//           ...currentWorkout,
//           intensity: newIntensity,
//           name: `${currentWorkout.mainMovement} - ${newIntensity}`,
//         };
//         updateWorkout(updatedWorkout, programId);
//       }
//       }
//     },
//     [currentWorkout, updateWorkout, programId]
//   );

//   const handleDateChange = useCallback(
//     (date) => {
//       if (isWorkoutEditable) {
//       setAssignedDate(date);
//       updateAssignedDate(currentWorkout.id, programId, date.toISOString());
//       setIsCalendarOpen(false);
//       }
//     },
//     [currentWorkout.id, programId, updateAssignedDate]
//   );

//   const toggleCalendar = () => {
//     if (isWorkoutEditable) {
//       setIsCalendarOpen(!isCalendarOpen);
//     }
//   };

//   const formatTimestamp = (date) => {
//     if (!date) {
//       console.log("No date provided to formatTimestamp");
//       return "N/A";
//     }
//     if (!(date instanceof Date)) {
//       console.error("Invalid date object provided to formatTimestamp:", date);
//       return "Invalid Date";
//     }
  
//     return date.toLocaleString("en-US", {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "2-digit",
//       minute: "2-digit",
//     });
//   };

 

//   return (
//     <>
//       <Header>
//         <HeaderSection>
//           <div>
//             {/* <WorkoutNumber>Workout: {currentWorkout.order}</WorkoutNumber> */}
//             <EditableMainMovement
//               isEditing={isEditing}
//               isEditable={isWorkoutEditable}
//               value={mainMovement}
//               showValidation={showValidation}
//               placeholder="Workout Name.."
//               onClick={() => isWorkoutEditable && setIsEditing(true)}
//               onChange={handleMainMovementChange}
//               onBlur={handleMainMovementBlur}
//               onFocus={() => isWorkoutEditable && setIsEditing(true)}
//               readOnly={!isWorkoutEditable}
//               autoFocus={isEditing} // Auto-focus when editing starts
//               tabIndex={isWorkoutEditable ? 0 : -1}
//               maxLength={25} 
//            />
//             <IntensitySelector
//               value={intensity}
//               onChange={handleIntensityChange}
//               $intensity={intensity}
//               disabled={!isWorkoutEditable}
//               showValidation={showValidation}
//             >
//               <option value="" disabled>
//                 Intensity
//               </option>
//               <option value="Easy/Light">Easy/Light</option>
//               <option value="Moderate">Moderate</option>
//               <option value="Hard/Heavy">Hard/Heavy</option>
//             </IntensitySelector>
//             <DateSelector onClick={toggleCalendar}>
//             <CalendarIcon />
//             <div>
//               {assignedDate ? assignedDate.toLocaleDateString() : "Select Date"}
//             </div>
//           </DateSelector>
//           </div>
        
        
//         </HeaderSection>
        
//         <MeatballMenu>
//           <Button>
//             <FaEllipsisV />
//           </Button>
//           <div className="menu-content">
//           {isWorkoutEditable && (
//             <button onClick={handlePublish}>
//               {currentWorkout.status === "unpublished"
//                 ? "Publish"
//                 : "Unpublish"}
//             </button>
//              )}
//             <button onClick={handleDelete}>Delete Workout</button>
//             <button>Share Workout</button>
//           </div>
//         </MeatballMenu>
       
//       </Header>


//       <ActionBar>
//         <ActionInfo>
//           {currentWorkout.status === "published" ? (
//             <>
//               <StateDisplay>{currentWorkout.actions?.state}</StateDisplay>
//               {(currentWorkout.actions?.state === "completed" ||
//                 currentWorkout.actions?.state === "cancelled") && (
//                 <TimestampDisplay>
//                   {currentWorkout.actions?.state.charAt(0).toUpperCase() +
//                     currentWorkout.actions?.state.slice(1)}{" "}
//                   on:{" "}
//                   {resolvedEndTime
//                     ? formatTimestamp(resolvedEndTime)
//                     : "Updating..."}
//                 </TimestampDisplay>
//               )}
//             </>
//           ) : (
//             <BuildModeMessage>
//               You are currently in build mode
//             </BuildModeMessage>
//           )}
//         </ActionInfo>
//         <ActionButtons>
//           {currentWorkout.status === "published" &&
//             currentWorkout.actions?.state !== "completed" &&
//             currentWorkout.actions?.state !== "cancelled" && (
//               <>
//                 <PublishButton
//                   onClick={handlePublish}
//                   isPublished={true}
//                   title="Unpublish"
//                 >
//                   <FaEdit color="yellow" title="Edit" />
//                 </PublishButton>

//                 {currentWorkout.actions?.state !== "completed" &&
//                   currentWorkout.actions?.state !== "cancelled" && (
//                     <>
//                       <IconButton onClick={handleCompleteWorkout}>
//                         <FaCheck color="green" title="Complete" />
//                       </IconButton>
//                       <IconButton onClick={handleCancelWorkout}>
//                         <FaTimes color="red" title="Cancel" />
//                       </IconButton>
//                     </>
//                   )}
//               </>
//             )}
//           {currentWorkout.status !== "published" && (
//             <PublishButton
//               onClick={handlePublish}
//               isPublished={false}
//               title="Publish"
//             >
//               Publish
//             </PublishButton>
//           )}

//           {(currentWorkout.actions?.state === "completed" ||
//             currentWorkout.actions?.state === "cancelled")}
//         </ActionButtons>
//       </ActionBar>

//       {isCalendarOpen && (
//         <>
//           <Backdrop onClick={toggleCalendar} />
//           <CalendarModal>
//             <WorkoutCalendar
//               selectedDate={assignedDate}
//               onSelectDate={handleDateChange}
//             />

//           </CalendarModal>
//         </>
//       )}
//     </>
//   );
// };

// export default React.memo(WorkoutHeader);


// #########################################################




import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { FaEllipsisV, FaCalendarAlt, FaCheck, FaTimes, FaEdit, FaClone } from "react-icons/fa";
import { useWorkout } from "../context/WorkoutContext";
import WorkoutCalendar from "./WorkoutCalendar";

const Header = styled.div`
  padding: 20px;
  background-color: #2c2c2c;
  color: white;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  // min-width: 320px;
`;

const EditableMainMovement = styled.input.withConfig({
  shouldForwardProp: (prop) => !['isEditing', 'isEditable'].includes(prop),
})`
border: ${({ isEditing, isEditable, showValidation, value }) => {
    if (showValidation && !value?.trim()) return "1px solid #ff4444";
    if (isEditing && isEditable) return "1px solid white";
    return "none";
  }};
    background: transparent;
  color: white;
  font-size: 1.3em;
  padding: ${({ isEditing, isEditable }) => (isEditing && isEditable ? "5px" : "0")};
  padding-left: 2px;
  margin: 0;
  outline: none;
  width: 100%;
  max-width: 100%;
  cursor: ${({ isEditable }) => (isEditable ? "text" : "default")};
  pointer-events: ${({ isEditable }) => (isEditable ? "auto" : "none")};
  // opacity: ${({ isEditable }) => (isEditable ? 1 : 0.7)};
  &::placeholder {
    color: #aaa;
    font-style: italic;
    font-size: 1em;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    font-weight: bold;
    &::placeholder {
      font-size: 0.8em;
    }
  }
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IntensitySelector = styled.select`
 border: ${({ showValidation, value }) => 
    showValidation && !value ? "1px solid #ff4444" : "none"};
  background: transparent;
  color: ${({ $intensity }) =>
    $intensity === "Easy/Light"
      ? "#4caf50"
      : $intensity === "Moderate"
      ? "#ffeb3b"
      : $intensity === "Hard/Heavy"
      ? "#f44336"
      : "#fff"};
  font-size: 1em;
  outline: none;
  cursor: pointer;
  padding-bottom: 1px;
  font-size: 0.8em;

  option {
    background-color: #1c1c1e;
    color: white;
  }
`;



const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#4caf50" : "#3A3A3A")};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#45a049" : "#bbb")};
  }
`;

const MeatballMenu = styled.div`
  position: relative;
  display: inline-block;

  & .menu-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #3a3a3a;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;

    & button {
      display: block;
      width: 100%;
      padding: 12px 16px;
      text-align: left;
      background: none;
      border: none;
      cursor: pointer;
      color: #ffffff;
      font-size: 0.8em;

      &:hover {
        background-color: #4e4e4e;
      }
    }
  }

  &:hover .menu-content {
    display: block;
  }
`;

const DateSelector = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const CalendarIcon = styled(FaCalendarAlt)`
  margin-right: 5px;
`;

const CalendarModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(45deg, #333, #545454, #333);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  width: 100%;
  max-width: 600px;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const WorkoutNumber = styled.div`
color: #6f6f6f;
font-size: 0.8em;
`;



const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  padding: 10px;
  background: linear-gradient(90deg, #474747, #545454);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ActionInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#4caf50" : "#3A3A3A")};
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => (props.primary ? "#45a049" : "#bbb")};
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

const PublishButton = styled(IconButton)`
  background-color: ${(props) => (props.isPublished ? "#3A3A3A" : "#4caf50")};
  &:hover {
    background-color: ${(props) => (props.isPublished ? "#d32f2f" : "#45a049")};
  }
`;

const StateDisplay = styled.div`
  font-size: 1em;
  font-weight: bold;
  background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: animateGradient 5s ease infinite;

  @keyframes animateGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const TimestampDisplay = styled.div`
  font-size: 0.9em;
  color: #ffffff;
  margin-top: 5px;
`;

const BuildModeMessage = styled.div`
  color: white;
  font-size: 0.7em;
  font-weight: bold;
`;



const useResolvedTimestamp = (timestamp) => {
  const [resolvedTimestamp, setResolvedTimestamp] = useState(null);

  useEffect(() => {
    if (!timestamp) return;

    const resolveTimestamp = () => {
      if (timestamp instanceof Date) {
        setResolvedTimestamp(timestamp);
      } else if (timestamp.toDate && typeof timestamp.toDate === "function") {
        setResolvedTimestamp(timestamp.toDate());
      } else if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
        setResolvedTimestamp(new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000));
      } else if (typeof timestamp === "number") {
        setResolvedTimestamp(new Date(timestamp));
      }
    };

    resolveTimestamp();
  }, [timestamp]);

  return resolvedTimestamp;
};

const formatTimestamp = (date) => {
  if (!date || !(date instanceof Date)) return "N/A";
  
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const WorkoutHeader = ({
  currentWorkout,
  handlePublish,
  handleCompleteWorkout,
  handleCancelWorkout,
  handleDuplicate,
  programId,
  showValidation,
  handleDelete,
  setShowValidation,
  onWorkoutUpdate,
}) => {
  const { updateWorkout, updateAssignedDate } = useWorkout();
  const [isEditing, setIsEditing] = useState(false);
  const [mainMovement, setMainMovement] = useState(
    currentWorkout.mainMovement || ""
  );
  const [intensity, setIntensity] = useState(currentWorkout.intensity || "");
  const [assignedDate, setAssignedDate] = useState(
    currentWorkout.assignedDate ? new Date(currentWorkout.assignedDate) : null
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const resolvedEndTime = useResolvedTimestamp(currentWorkout?.actions?.endTime);

  const isWorkoutEditable = !currentWorkout.actions?.state || 
  (currentWorkout.actions.state !== "completed" && 
   currentWorkout.actions.state !== "cancelled");

   useEffect(() => {
    setMainMovement(currentWorkout.mainMovement || "");
    setIntensity(currentWorkout.intensity || "");
  }, [currentWorkout]);

  const handleMainMovementChange = useCallback((e) => {
    if (isWorkoutEditable) {
      const newValue = e.target.value.slice(0, 25);
      setMainMovement(newValue);
    }
  }, [isWorkoutEditable]);


  const handleMainMovementBlur = useCallback(() => {
    if (isWorkoutEditable) {
      setIsEditing(false);
      if (mainMovement !== currentWorkout.mainMovement) {
        const updatedWorkout = {
          ...currentWorkout,
          mainMovement,
          name: `${mainMovement} - ${currentWorkout.intensity}`.slice(0, 25),
        };
        updateWorkout(updatedWorkout, programId).then(() => {
          if (mainMovement.trim() && currentWorkout.intensity?.trim()) {
            setShowValidation(false);
          }
          if (onWorkoutUpdate) {
            onWorkoutUpdate(updatedWorkout); // Notify parent of the update
          }
        });
      }
    }
  }, [currentWorkout, mainMovement, updateWorkout, programId, isWorkoutEditable, setShowValidation, onWorkoutUpdate]);
  
  const handleIntensityChange = useCallback((e) => {
    if (isWorkoutEditable) {
      const newIntensity = e.target.value;
      setIntensity(newIntensity);
      const updatedWorkout = {
        ...currentWorkout,
        intensity: newIntensity,
        name: `${currentWorkout.mainMovement} - ${newIntensity}`,
      };
      updateWorkout(updatedWorkout, programId).then(() => {
        if (currentWorkout.mainMovement?.trim() && newIntensity.trim()) {
          setShowValidation(false);
        }
        if (onWorkoutUpdate) {
          onWorkoutUpdate(updatedWorkout); // Notify parent of the update
        }
      });
    }
  }, [currentWorkout, updateWorkout, programId, isWorkoutEditable, setShowValidation, onWorkoutUpdate]);


  const handleDateChange = useCallback(
    (date) => {
      if (isWorkoutEditable) {
      setAssignedDate(date);
      updateAssignedDate(currentWorkout.id, programId, date.toISOString());
      setIsCalendarOpen(false);
      }
    },
    [currentWorkout.id, programId, updateAssignedDate]
  );

  const toggleCalendar = () => {
    if (isWorkoutEditable) {
      setIsCalendarOpen(!isCalendarOpen);
    }
  };

  const formatTimestamp = (date) => {
    if (!date) {
      console.log("No date provided to formatTimestamp");
      return "N/A";
    }
    if (!(date instanceof Date)) {
      console.error("Invalid date object provided to formatTimestamp:", date);
      return "Invalid Date";
    }
  
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

 

  return (
    <>
      <Header>
        <HeaderSection>
          <div>
            {/* <WorkoutNumber>Workout: {currentWorkout.order}</WorkoutNumber> */}
            <EditableMainMovement
              isEditing={isEditing}
              isEditable={isWorkoutEditable}
              value={mainMovement}
              showValidation={showValidation}
              placeholder="Workout Name.."
              onClick={() => isWorkoutEditable && setIsEditing(true)}
              onChange={handleMainMovementChange}
              onBlur={handleMainMovementBlur}
              onFocus={() => isWorkoutEditable && setIsEditing(true)}
              readOnly={!isWorkoutEditable}
              autoFocus={isEditing} // Auto-focus when editing starts
              tabIndex={isWorkoutEditable ? 0 : -1}
              maxLength={25} 
           />
            <IntensitySelector
              value={intensity}
              onChange={handleIntensityChange}
              $intensity={intensity}
              disabled={!isWorkoutEditable}
              showValidation={showValidation}
            >
              <option value="" disabled>
                Intensity
              </option>
              <option value="Easy/Light">Easy/Light</option>
              <option value="Moderate">Moderate</option>
              <option value="Hard/Heavy">Hard/Heavy</option>
            </IntensitySelector>
            <DateSelector onClick={toggleCalendar}>
            <CalendarIcon />
            <div>
              {assignedDate ? assignedDate.toLocaleDateString() : "Select Date"}
            </div>
          </DateSelector>
          </div>
        
        
        </HeaderSection>
        
        <MeatballMenu>
          <Button>
            <FaEllipsisV />
          </Button>
          <div className="menu-content">
          {isWorkoutEditable && (
            <button onClick={handlePublish}>
              {currentWorkout.status === "unpublished"
                ? "Publish"
                : "Unpublish"}
            </button>
             )}
            <button onClick={handleDelete}>Delete Workout</button>
            <button>Share Workout</button>
          </div>
        </MeatballMenu>
       
      </Header>


      <ActionBar>
        <ActionInfo>
          {currentWorkout.status === "published" ? (
            <>
              <StateDisplay>{currentWorkout.actions?.state}</StateDisplay>
              {(currentWorkout.actions?.state === "completed" ||
                currentWorkout.actions?.state === "cancelled") && (
                <TimestampDisplay>
                  {currentWorkout.actions?.state.charAt(0).toUpperCase() +
                    currentWorkout.actions?.state.slice(1)}{" "}
                  on:{" "}
                  {resolvedEndTime
                    ? formatTimestamp(resolvedEndTime)
                    : "Updating..."}
                </TimestampDisplay>
              )}
            </>
          ) : (
            <BuildModeMessage>
              You are currently in build mode
            </BuildModeMessage>
          )}
        </ActionInfo>
        <ActionButtons>
          {currentWorkout.status === "published" &&
            currentWorkout.actions?.state !== "completed" &&
            currentWorkout.actions?.state !== "cancelled" && (
              <>
                <PublishButton
                  onClick={handlePublish}
                  isPublished={true}
                  title="Unpublish"
                >
                  <FaEdit color="yellow" title="Edit" />
                </PublishButton>

                {currentWorkout.actions?.state !== "completed" &&
                  currentWorkout.actions?.state !== "cancelled" && (
                    <>
                      <IconButton onClick={handleCompleteWorkout}>
                        <FaCheck color="green" title="Complete" />
                      </IconButton>
                      <IconButton onClick={handleCancelWorkout}>
                        <FaTimes color="red" title="Cancel" />
                      </IconButton>
                    </>
                  )}
              </>
            )}
          {currentWorkout.status !== "published" && (
            <PublishButton
              onClick={handlePublish}
              isPublished={false}
              title="Publish"
            >
              Publish
            </PublishButton>
          )}

          {(currentWorkout.actions?.state === "completed" ||
            currentWorkout.actions?.state === "cancelled")}
        </ActionButtons>
      </ActionBar>

      {isCalendarOpen && (
        <>
          <Backdrop onClick={toggleCalendar} />
          <CalendarModal>
            <WorkoutCalendar
              selectedDate={assignedDate}
              onSelectDate={handleDateChange}
            />

          </CalendarModal>
        </>
      )}
    </>
  );
};

export default React.memo(WorkoutHeader);
