import * as THREE from "three";

class DayCube {
  /**
   * Constructs a DayTile object.
   * @param {number} id - The unique identifier for the DayTile.
   * @param {object} position - The initial position of the DayTile with x, y, and z coordinates.
   * @param {object} dimensions - The dimensions of the DayTile with width, height, and depth.
   */
  constructor(id, position, dimensions = { width: 1, height: 1, depth: 1 }) {
    const geometry = new THREE.BoxGeometry(
      dimensions.width,
      dimensions.height,
      dimensions.depth
    );
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color("#333333"),
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.position.set(position.x, position.y, position.z);
    this.mesh.userData = { id };
  }
}

export default DayCube;
