

// import React, { useState, useRef, useEffect } from 'react';
// import styled, { keyframes } from "styled-components";
// import { useProgram } from '../context/ProgramContext';
// import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
// import { db } from '../../services/firebaseConfig';
// import { useAuth } from '../../hooks/useAuth';

// const rotateGradient = keyframes`
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// `;

// const StyledButton = styled.button`
//   padding: 10px 20px;
//   background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
//   background-size: 300% 300%;
//   animation: ${rotateGradient} 5s ease infinite;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   font-size: 16px;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   display: flex;
//   align-items: center;
//   font-weight: bold;

//   &:hover {
//     transform: scale(1.05);
//   }

//   @media (max-width: 768px) {
//     font-size: 14px;
//     padding: 8px 16px;
//   }
// `;

// const DropdownContainer = styled.div`
//   position: absolute;
//   top: 100%;
//   right: 0;
//   background-color: white;
//   border-radius: 5px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   display: ${props => props.isOpen ? 'block' : 'none'};
//   z-index: 20;
//   min-width: 200px;
// `;

// const DropdownButton = styled.button`
//   width: 100%;
//   padding: 10px 20px;
//   background-color: white;
//   color: #333;
//   border: none;
//   text-align: left;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #f0f0f0;
//   }

//   &:disabled {
//     color: #999;
//     cursor: not-allowed;
//   }
// `;

// const Select = styled.select`
//   width: 100%;
//   padding: 10px;
//   margin: 10px 0;
//   border: 1px solid #ccc;
//   border-radius: 4px;
// `;

// const AddCycleButton = ({ programId, programType = "Mesocycle Advanced", onCycleAdded }) => {
//   const { createProgram, importMesocycleToMacrocycle, programs } = useProgram();
//   const { user } = useAuth();
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedMesocycle, setSelectedMesocycle] = useState('');
//   const [macrocycleData, setMacrocycleData] = useState(null);
//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);


// // Fetch macrocycle data
// useEffect(() => {
//   const fetchMacrocycleData = async () => {
//     if (!user || !programId) return;
//     try {
//       const macrocycleRef = doc(db, "users", user.uid, "programs", programId);
//       const macrocycleDoc = await getDoc(macrocycleRef);
//       if (macrocycleDoc.exists()) {
//         setMacrocycleData(macrocycleDoc.data());
//       } else {
//         console.error("Macrocycle not found");
//       }
//     } catch (error) {
//       console.error("Error fetching macrocycle data:", error);
//     }
//   };

//   fetchMacrocycleData();
// }, [user, programId]);




//   const handleAddMesocycle = async () => {
//     if (!user || !programId) return;

//     try {
//       const macrocycleRef = doc(db, "users", user.uid, "programs", programId);
//       const macrocycleDoc = await getDoc(macrocycleRef);
//       const macrocycleData = macrocycleDoc.data();

//       const currentBlocksCount = macrocycleData.programBlocks?.length || 0;
//       const newMesocycleName = `Cycle ${currentBlocksCount + 1}`;

//       // Remove user.uid from createProgram call
//       const newMesocycle = await createProgram(newMesocycleName, programType);

//       await updateDoc(macrocycleRef, {
//         programBlocks: arrayUnion(newMesocycle.id)
//       });

//       console.log("New Advanced Mesocycle added successfully with ID:", newMesocycle.id);
//       // Remove fetchProgram call; real-time updates will handle it
//       setIsOpen(false);
//     } catch (error) {
//       console.error("Error creating advanced mesocycle:", error);
//     }
//   };

//   const handleImportMesocycle = async () => {
//     if (!user || !programId || !selectedMesocycle) return;

//     try {
//       await importMesocycleToMacrocycle(programId, selectedMesocycle);
//       console.log("Mesocycle imported successfully");
//       // No need to fetch the program manually
//       setIsOpen(false);
//     } catch (error) {
//       console.error("Error importing mesocycle:", error);
//     }
//   };

//   const mesocycleOptions = programs
//     .filter(
//       (program) =>
//         program.programType.startsWith("Mesocycle") &&
//         program.id !== programId && // Exclude the macrocycle itself
//         !macrocycleData?.programBlocks?.includes(program.id) // Exclude mesocycles already in programBlocks
//     )
//     .map((program) => ({
//       value: program.id,
//       label: program.name,
//     }));

//     return (
//       <div ref={dropdownRef} style={{ position: 'relative' }}>
//         <StyledButton onClick={() => setIsOpen(!isOpen)}>
//           + Cycle
//         </StyledButton>
//         <DropdownContainer isOpen={isOpen}>
//           <DropdownButton onClick={handleAddMesocycle}>Create New Cycle</DropdownButton>
//           <Select
//             value={selectedMesocycle}
//             onChange={(e) => setSelectedMesocycle(e.target.value)}
//           >
//             <option value="">Select a mesocycle to import</option>
//             {mesocycleOptions.map(option => (
//               <option key={option.value} value={option.value}>{option.label}</option>
//             ))}
//           </Select>
//           <DropdownButton onClick={handleImportMesocycle} disabled={!selectedMesocycle}>
//             Import Selected Cycle
//           </DropdownButton>
//         </DropdownContainer>
//       </div>
//     );
//   };

// export default AddCycleButton;
















// ###########OPtimizing drop down ######################

// import React, { useState, useRef, useEffect } from 'react';
// import styled, { keyframes } from 'styled-components';
// import { useProgram } from '../context/ProgramContext';
// import { useAuth } from '../../hooks/useAuth';

// const rotateGradient = keyframes`
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// `;

// const StyledButton = styled.button`
//   padding: 10px 20px;
//   background: radial-gradient(circle, #353637, #5e5d5e, #1c1c1c);
//   // background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
//   background-size: 300% 300%;
//   animation: ${rotateGradient} 5s ease infinite;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   font-size: 16px;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   display: flex;
//   align-items: center;
//   font-weight: bold;
//   height: 5em;
//   width: 5em;

//   &:hover {
//     transform: scale(1.05);
//   }

//   @media (max-width: 768px) {
//     font-size: 14px;
//     padding: 8px 16px;
//   }
// `;

// const DropdownContainer = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0; /* Changed from right: 0 to left: 0 */
//   background-color: white;
//   border-radius: 5px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   display: ${(props) => (props.isOpen ? 'block' : 'none')};
//   z-index: 20;
//   min-width: 200px;
//   max-width: 90vw; /* Ensure the dropdown doesn't exceed viewport width */
//   overflow: hidden;
//   margin-top: 5px; /* Add some spacing between button and dropdown */
// `;

// const DropdownButton = styled.button`
//   width: 100%;
//   padding: 10px 20px;
//   background-color: white;
//   color: #333;
//   border: none;
//   text-align: left;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
//   font-size: 14px;

//   &:hover {
//     background-color: #f0f0f0;
//   }

//   &:disabled {
//     color: #999;
//     cursor: not-allowed;
//   }
// `;

// const Select = styled.select`
//   width: calc(100% - 20px);
//   padding: 10px;
//   margin: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   font-size: 14px;
// `;





// const AddCycleButton = ({ programId, programType = 'Mesocycle Advanced', onCycleAdded }) => {
//   const { createProgram, importMesocycleToMacrocycle, programs, program } = useProgram();
//   const { user } = useAuth();
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedMesocycle, setSelectedMesocycle] = useState('');
//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         dropdownRef.current &&
//         !dropdownRef.current.contains(event.target) &&
//         !event.target.closest('.dropdown-toggle')
//       ) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const handleAddMesocycle = async () => {
//     if (!user || !programId) return;

//     try {
//       const currentBlocksCount = program.programBlocks?.length || 0;
//       const newMesocycleName = `Cycle ${currentBlocksCount + 1}`;

//       const newMesocycle = await createProgram(newMesocycleName, programType);

//       // Use the context function to add the mesocycle to the macrocycle
//       await importMesocycleToMacrocycle(programId, newMesocycle.id);

//       console.log('New Advanced Mesocycle added successfully with ID:', newMesocycle.id);
//       setIsOpen(false);
//     } catch (error) {
//       console.error('Error creating advanced mesocycle:', error);
//     }
//   };

//   const handleImportMesocycle = async () => {
//     if (!user || !programId || !selectedMesocycle) return;

//     try {
//       await importMesocycleToMacrocycle(programId, selectedMesocycle);
//       console.log('Mesocycle imported successfully');
//       setIsOpen(false);
//     } catch (error) {
//       console.error('Error importing mesocycle:', error);
//     }
//   };

//   const mesocycleOptions = programs
//     .filter(
//       (programItem) =>
//         programItem.programType.startsWith('Mesocycle') &&
//         programItem.id !== programId && // Exclude the macrocycle itself
//         !program?.programBlocks?.includes(programItem.id) // Exclude mesocycles already in programBlocks
//     )
//     .map((programItem) => ({
//       value: programItem.id,
//       label: programItem.name,
//     }));

//   return (
//     <div ref={dropdownRef} style={{ position: 'relative' }}>
//       <StyledButton
//         className="dropdown-toggle"
//         onClick={() => setIsOpen((prev) => !prev)}
//       >
//         + Cycle
//       </StyledButton>
//       <DropdownContainer isOpen={isOpen}>
//         <DropdownButton onClick={handleAddMesocycle}>
//           Create New Cycle
//         </DropdownButton>
//         <Select
//           value={selectedMesocycle}
//           onChange={(e) => setSelectedMesocycle(e.target.value)}
//         >
//           <option value="">Select a mesocycle to import</option>
//           {mesocycleOptions.map((option) => (
//             <option key={option.value} value={option.value}>
//               {option.label}
//             </option>
//           ))}
//         </Select>
//         <DropdownButton
//           onClick={handleImportMesocycle}
//           disabled={!selectedMesocycle}
//         >
//           Import Selected Cycle
//         </DropdownButton>
//       </DropdownContainer>
//     </div>
//   );
// };

// export default AddCycleButton;



// AddCycleButton.js
import React, { useState } from 'react';
import styled from 'styled-components';
import AddCycleModal from './AddCycleModal'; // Import the modal component

const AddCard = styled.div`
  min-width: 140px;
  background-color: #3a3a3a;
  padding: 16px;
  border-radius: 8px;
  border: 2px dashed #aaa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #aaa;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
    color: #fff;
  }
`;

const AddMesocycleText = styled.h3`
  margin: 8px 0 0 0;
  font-size: 16px;
  color: inherit;
`;

const AddCycleButton = ({ programId, programType = 'Mesocycle Advanced' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddCycleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <AddCard onClick={handleAddCycleClick}>
        <AddMesocycleText>+ Add Cycle</AddMesocycleText>
      </AddCard>
      <AddCycleModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        programId={programId}
        programType={programType}
      />
    </>
  );
};

export default AddCycleButton;
