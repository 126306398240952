





import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { FaUserFriends, FaEllipsisV } from "react-icons/fa";

const Card = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  // width: 100%;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const CardTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const FriendCount = styled.span`
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: auto;
`;

const FriendsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 4px;

  /* Custom scrollbar - only show on desktop */
  @media (min-width: 768px) {
    gap: 12px;
    padding-right: 8px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
`;

const FriendCard = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  text-decoration: none;
  transition: background-color 0.2s ease;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #2a2a3c, #1e1e2d);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  font-size: 1rem;
  flex-shrink: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden; // Ensure the image stays within bounds

  @media (min-width: 768px) {
    width: 48px;
    height: 48px;
    font-size: 1.25rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  flex: 1;
`;

const Username = styled.span`
  font-size: 0.9375rem;
  font-weight: 500;
  color: white;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const StatusLabel = styled.span`
  font-size: 0.8125rem;
  color: rgba(255, 255, 255, 0.5);

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
`;

const OptionsButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8);
  }
`;

const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 16px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;

  svg {
    font-size: 1.5rem;
    opacity: 0.5;
  }
`;

const LoadingMessage = styled(EmptyMessage)`
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const StatusButton = styled.button`
  background: none;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: auto;
  
  // Different styles based on status
  ${props => {
    switch (props.status) {
      case 'friends':
        return `
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.8);
          &:hover {
            background: rgba(255, 0, 0, 0.1);
            color: #ff4444;
          }
        `;
      case 'not_friends':
        return `
          background: rgba(78, 205, 196, 0.1);
          color: #4ecdc4;
          &:hover {
            background: rgba(78, 205, 196, 0.2);
          }
        `;
      default:
        return `
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.6);
        `;
    }
  }}
`;

const FriendCardContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0; // Helps with text truncation
`;

const FriendsListComponent = ({ username, userId, isPublicView = false, onNavigate }) => {
  const { user: currentUser, addFriend, removeFriend } = useAuth();
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actionLoading, setActionLoading] = useState({});

  // Function to check if a user is already a friend
  const isUserFriend = (friendId) => {
    return currentUser?.friends?.some(friend => friend.uid === friendId) || false;
  };

  // Handle friend actions
  const handleFriendAction = async (e, friend) => {
    e.preventDefault(); // Prevent card navigation
    e.stopPropagation(); // Prevent event bubbling

    setActionLoading(prev => ({ ...prev, [friend.uid]: true }));

    try {
      if (isPublicView) {
        // Public profile - Add friend
        if (!isUserFriend(friend.uid)) {
          await addFriend(friend.username);
        }
      } else {
        // Own profile - Unfriend
        await removeFriend(friend.username);
        // Update local state to remove the friend
        setFriends(prev => prev.filter(f => f.uid !== friend.uid));
      }
    } catch (error) {
      // Could add toast notification here
    } finally {
      setActionLoading(prev => ({ ...prev, [friend.uid]: false }));
    }
  };



  useEffect(() => {

    const fetchFriendData = async (friendUsername) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/users/username/${friendUsername}`
        );
        return response.data;
      } catch (error) {
        return null;
      }
    };

    const fetchAllFriends = async () => {
      try {
        setLoading(true);
        let userData;

        if (isPublicView || userId !== currentUser?.uid) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/users/username/${username}`
          );
          userData = response.data;
        } else {
          userData = currentUser;
        }

        if (!userData?.friends) {
          setFriends([]);
          return;
        }

        // Fetch complete data for each friend
        const friendsPromises = userData.friends.map(friend =>
          fetchFriendData(friend.username)
        );

        const friendsData = await Promise.all(friendsPromises);
        const completeFriends = friendsData.filter(friend => friend !== null);

        setFriends(completeFriends);
        setError(null);
      } catch (error) {
        setError("Failed to load friends");
      } finally {
        setLoading(false);
      }
    };

    if (username || userId) {
      fetchAllFriends();
    }
  }, [username, userId, currentUser, isPublicView]);



  const renderAvatar = (friend) => {
    if (friend.avatarUrl) {
      return <img src={friend.avatarUrl} alt={friend.username} />;
    }
    return friend.username.charAt(0).toUpperCase();
  };

  if (loading) {
    return (
      <Card>
        <HeaderSection>
          <CardTitle>Friends</CardTitle>
        </HeaderSection>
        <LoadingMessage>Loading friends...</LoadingMessage>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <HeaderSection>
          <CardTitle>Friends</CardTitle>
        </HeaderSection>
        <EmptyMessage>{error}</EmptyMessage>
      </Card>
    );
  }


  const renderFriendStatus = (friend) => {
    // Don't show any status button if this is the current user
    if (friend.uid === currentUser?.uid) {
      return null;
    }

    const isLoading = actionLoading[friend.uid];

    if (isLoading) {
      return (
        <StatusButton disabled>
          Loading...
        </StatusButton>
      );
    }

    if (isPublicView) {
      // In public profile view
      const isFriend = isUserFriend(friend.uid);
      return (
        <StatusButton
          status={isFriend ? 'friends' : 'not_friends'}
          onClick={(e) => !isFriend && handleFriendAction(e, friend)}
          disabled={isFriend}
        >
          {isFriend ? 'Friends' : 'Add Friend'}
        </StatusButton>
      );
    } else {
      // In own profile view - don't show unfriend button for self
      return (
        <StatusButton
          status="friends"
          onClick={(e) => handleFriendAction(e, friend)}
        >
          Unfriend
        </StatusButton>
      );
    }
  };
  

  const getProfilePath = (friend) => {
    // If this friend is the current user, return the profile path
    if (friend.uid === currentUser?.uid) {
      return '/profile';
    }
    // Otherwise return the public profile path
    return `/profile/${friend.username}`;
  };

  return (
    <Card>
      <HeaderSection>
        <CardTitle>Friends</CardTitle>
        <FriendCount>{friends.length}</FriendCount>
      </HeaderSection>

      {friends.length === 0 ? (
        <EmptyMessage>
          <FaUserFriends />
          <span>No friends found</span>
        </EmptyMessage>
      ) : (
        <FriendsList>
          {friends.map((friend) => (

            <FriendCard
              key={friend.uid}
              to={getProfilePath(friend)}
            >

              <FriendCardContent>
                <Avatar>
                  {renderAvatar(friend)}
                </Avatar>
                <UserInfo>
                  <Username>{friend.username}</Username>
                  <StatusLabel>Friend</StatusLabel>
                </UserInfo>
              </FriendCardContent>
              {renderFriendStatus(friend)}
            </FriendCard>
          ))}
        </FriendsList>
      )}
    </Card>
  );
};

export default FriendsListComponent;


