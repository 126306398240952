import React, { useMemo, useState, useCallback } from 'react';
import { InstantSearch, SearchBox, Hits, Configure, Pagination } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";
import { useUserExercises } from "../../context/UserExercisesContext";


const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);

const LibraryContainer = styled.div`
//   background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  background: linear-gradient(145deg, #1b1c1d, #34495e);
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 200px);

  @media (max-width: 768px) {
    max-height: 65vh;
  }
`;

const CustomSearchBox = styled(SearchBox)`
  margin-bottom: 20px;

  .ais-SearchBox-form {
    display: flex;
  }

  .ais-SearchBox-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #4a4a4a;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: white;
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`;

const Accordion = styled.div`
  border-bottom: 1px solid #4a4a4a;
`;

const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const TitleContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const FavoriteButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.$isFavorite ? "#ff00ec" : "#b0b0b0")};
  font-size: 1.5em;
  cursor: pointer;
  transition: color 0.3s;
  margin-right: 10px;

  &:hover {
    color: #ff00ec;
  }
`;



const AccordionContent = styled.div`
  padding: 10px;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
`;

const ExerciseName = styled.h3`
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: animateGradient 5s ease infinite;

  @keyframes animateGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ExerciseDetails = styled.p`
  font-size: 0.9em;
  color: #b0b0b0;
  margin: 5px 0;
`;



const CustomPagination = styled(Pagination)`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .ais-Pagination-list {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .ais-Pagination-item {
    margin: 0 5px;
  }

  .ais-Pagination-link {
    padding: 5px 10px;
    border: 1px solid #4a4a4a;
    background-color: #2c2c2c;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: #4a4a4a;
    }
  }

  .ais-Pagination-item--selected .ais-Pagination-link {
    background-color: #ff00ec;
    border-color: #ff00ec;
  }
`;

const Hit = React.memo(({ hit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { addExercise, deleteExercise, exerciseExists } = useUserExercises();
  
    const isFavorite = useMemo(
      () => exerciseExists(hit.objectID),
      [hit.objectID, exerciseExists]
    );
  
    const toggleFavorite = useCallback(
      async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
          if (isFavorite) {
            await deleteExercise(hit.objectID);
          } else {
            await addExercise({ objectID: hit.objectID, name: hit.name });
          }
        } catch (error) {
          console.error("Error toggling favorite:", error);
        }
      },
      [isFavorite, addExercise, deleteExercise, hit.objectID, hit.name]
    );
      

    return (
        <Accordion>
          <AccordionTitle onClick={() => setIsOpen(!isOpen)}>
            <TitleContent>
              <FavoriteButton onClick={toggleFavorite} $isFavorite={isFavorite}>
                <FaHeart />
              </FavoriteButton>
              <ExerciseName>{hit.name}</ExerciseName>
            </TitleContent>
            {isOpen ? <FiChevronUp /> : <FiChevronDown />}
          </AccordionTitle>
          <AccordionContent $isOpen={isOpen}>
            <ExerciseDetails>Body Part: {hit.bodyPart}</ExerciseDetails>
            <ExerciseDetails>Equipment: {hit.equipment}</ExerciseDetails>
            <ExerciseDetails>Target: {hit.target}</ExerciseDetails>
            <ExerciseDetails>Secondary Muscles: {hit.secondaryMuscles ? hit.secondaryMuscles.join(", ") : ""}</ExerciseDetails>
            <ExerciseDetails>Instructions: {hit.instructions ? hit.instructions.join(". ") : ""}</ExerciseDetails>
          </AccordionContent>
        </Accordion>
      );
    });

const Library = () => {
  return (
    <LibraryContainer>
      <InstantSearch searchClient={searchClient} indexName="exercises">
        <Configure hitsPerPage={10} />
        <CustomSearchBox />
        <Hits hitComponent={Hit} />
        <CustomPagination />
      </InstantSearch>
    </LibraryContainer>
  );
};

export default React.memo(Library);
