import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";
import FriendsList from "../components/profile/FriendsList";
import Avatar from "../components/profile/Avatar";
import SettingsModal from "../components/profile/SettingsModal";

import TopBar from "../components/TopBar";
import ProfileHero from "../components/profile/ProfileHero";

const PageWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #1a1a2e, #16213e);
`;

const ProfileContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
`;

const Profile = () => {
  const { user, updateUserAbout, updateUserSocials } = useAuth();
  const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);
  const [counts, setCounts] = useState({
    friends: 0,
    activities: 0,
    photos: 0,
  });

  useEffect(() => {
    if (user) {
      setCounts({
        friends: user.friends?.length || 0,
        activities: user.activities?.length || 0,
        photos: user.photos?.length || 0,
      });
    }
  }, [user]);

  const handleProfileUpdate = async (data) => {
    try {
      if (data.about) {
        await updateUserAbout(data.about);
      }
      if (data.socialLinks) {
        await updateUserSocials(data.socialLinks);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // You might want to add error handling/notification here
    }
  };

  if (!user)
    return (
      <PageWrapper>
        <div style={{ color: "white", textAlign: "center", padding: "20px" }}>
          Loading...
        </div>
      </PageWrapper>
    );

  return (
    <>
      <TopBar />
      <PageWrapper>
        <ProfileHero
          user={user}
          isCurrentUser={true}
          socialLinks={user.socialLinks}
          friendCount={counts.friends}
          isFriendsModalOpen={isFriendsModalOpen}
          setIsFriendsModalOpen={setIsFriendsModalOpen}
          currentUser={user}
          onUpdateProfile={handleProfileUpdate}
        />

        <ProfileContent></ProfileContent>
      </PageWrapper>
    </>
  );
};

export default Profile;
