// import React, { useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { useProgram } from "../components/context/ProgramContext";
// import { useAuth } from "../hooks/useAuth";
// import BasicProgram from "../components/program/BasicProgram";
// import MesocycleProgram from "../components/program/MesocycleProgram";
// import MacrocycleProgram from "../components/program/MacrocycleProgram";
// import { doc, getDoc, updateDoc } from "firebase/firestore";
// import { db } from "../services/firebaseConfig";

// const CurrentProgram = () => {
//   const { programId } = useParams();
//   const { user } = useAuth();
//   const { program, fetchProgram, addWorkout, updatePeriodLength } =
//     useProgram();

//   useEffect(() => {
//     const fetchProgramData = async () => {
//       if (user && programId) {
//         const docRef = doc(db, "users", user.uid, "programs", programId);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           const programData = { ...docSnap.data(), id: programId }; // Ensure the program ID is set
//           fetchProgram(programData);
//           console.log("Fetched Program Data:", programData);
//         } else {
//           console.error("No such document!");
//         }
//       }
//     };

//     fetchProgramData();
//   }, [programId, user]);

//   const handleAddWorkout = async () => {
//     if (!program) return;
//     const newWorkout = {
//       id: program.workouts.length + 1,
//       name: `Workout ${program.workouts.length + 1}`,
//       status: "Unpublished",
//     };
//     addWorkout(newWorkout);
//     const docRef = doc(db, "users", user.uid, "programs", program.id);
//     await updateDoc(docRef, { workouts: [...program.workouts, newWorkout] });
//   };

//   const handleUpdatePeriodLength = async (newLength) => {
//     if (!user || !program || !program.id) {
//       console.error("User or program information is missing");
//       return;
//     }

//     try {
//       const docRef = doc(db, "users", user.uid, "programs", program.id);
//       await updateDoc(docRef, { periodLength: newLength });
//       updatePeriodLength(newLength);
//     } catch (error) {
//       console.error("Error updating period length in Firestore:", error);
//     }
//   };

//   if (!program) {
//     return <div>Loading...</div>;
//   }

//   let ProgramComponent;

//   switch (program.programType) {
//     case "Basic":
//       ProgramComponent = BasicProgram;
//       break;
//     case "Mesocycle":
//       ProgramComponent = MesocycleProgram;
//       break;
//     case "Macrocycle":
//       ProgramComponent = MacrocycleProgram;
//       break;
//     default:
//       return <div>Invalid program type</div>;
//   }

//   return (
//     <ProgramComponent
//       program={program}
//       handleAddWorkout={handleAddWorkout}
//       handleUpdatePeriodLength={handleUpdatePeriodLength}
//     />
//   );
// };

// export default CurrentProgram;

// #######Attempting to incorporate a Program Layout################

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useProgram } from "../components/context/ProgramContext";
import { useAuth } from "../hooks/useAuth";
import ProgramLayout from "../components/program/ProgramLayout";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../services/firebaseConfig";

const CurrentProgram = () => {
  const { programId } = useParams();
  const { user } = useAuth();
  const { program, fetchProgram } = useProgram();

  useEffect(() => {
    const fetchProgramData = async () => {
      if (user && programId) {
        const docRef = doc(db, "users", user.uid, "programs", programId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const programData = { ...docSnap.data(), id: programId };
          fetchProgram(programData);
        } else {
          console.error("No such document!");
        }
      }
    };

    fetchProgramData();
  }, [programId, user, fetchProgram]);

  if (!program) {
    return <div>Loading...</div>;
  }

  return <ProgramLayout program={program} />;
};

export default CurrentProgram;
