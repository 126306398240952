


import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  deleteDoc,
  getDoc,
  getDocs,
  query,
  where,
  writeBatch,
  onSnapshot,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import isEqual from "lodash/isEqual"; // For deep comparison in useEffect

const ProgramContext = createContext();

export const useProgram = () => useContext(ProgramContext);

export const ProgramProvider = ({ programId, children }) => {
  const [program, setProgram] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const { user } = useAuth();

  // Real-time listener for programs
  useEffect(() => {
    if (!user) {
      setPrograms([]);
      return;
    }

    const programsCollection = collection(db, "users", user.uid, "programs");

    const unsubscribe = onSnapshot(
      programsCollection,
      (snapshot) => {
        const programList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPrograms(programList);
      },
      (error) => {
        console.error("Error fetching programs:", error);
      }
    );

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user]);

  // Real-time listener for the selected program
  useEffect(() => {
    if (!user || !program?.id) {
      setProgram(null);
      return;
    }
  
    const programRef = doc(db, "users", user.uid, "programs", program.id);
  
    const unsubscribe = onSnapshot(
      programRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const programData = { id: docSnap.id, ...docSnap.data() };
  
          // Remove the isEqual check
          setProgram(programData);
        } else {
          console.error("Program not found");
        }
      },
      (error) => {
        console.error("Error fetching program:", error);
      }
    );
  
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user, program?.id]);


  useEffect(() => {
    if (!user || !programId) {
      setProgram(null);
      return;
    }

    const programRef = doc(db, "users", user.uid, "programs", programId);

    const unsubscribe = onSnapshot(
      programRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const programData = { id: docSnap.id, ...docSnap.data() };
          setProgram(programData);
        } else {
          console.error("Program not found");
        }
      },
      (error) => {
        console.error("Error fetching program:", error);
      }
    );

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user, programId]);
  

  // Create a new program
  const createProgram = useCallback(
    async (programName, programType, description = "") => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const programsCollection = collection(db, "users", user.uid, "programs");
        let newProgramData = {
          name: programName,
          userId: user.uid,
          programType,
          description,
          workouts: [],
          createdAt: new Date(),
        };

        // Map the programType to the filter categories
        if (programType === "Macrocycle") {
          newProgramData.type = "macrocycle";
          newProgramData.programBlocks = [];
        } else if (programType.startsWith("Mesocycle")) {
          newProgramData.type = "mesocycle";
          newProgramData.status = "Upcoming";
          newProgramData.goals = [];
        } else {
          newProgramData.type = "workout";
        }

        const newProgramRef = await addDoc(programsCollection, newProgramData);
        const newProgram = {
          id: newProgramRef.id,
          ...newProgramData,
        };

        return newProgram;
      } catch (error) {
        console.error("Error creating program:", error);
        throw error;
      }
    },
    [user]
  );

  // Update program description
  const updateProgramDescription = useCallback(
    async (programId, newDescription) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const programRef = doc(db, "users", user.uid, "programs", programId);
        await updateDoc(programRef, { description: newDescription });
        console.log("Program description updated successfully");
      } catch (error) {
        console.error("Error updating program description:", error);
        throw error;
      }
    },
    [user]
  );

  // Delete a program
  const deleteProgram = useCallback(
    async (programId) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const programRef = doc(db, "users", user.uid, "programs", programId);
        const programSnap = await getDoc(programRef);

        if (!programSnap.exists()) {
          console.error("Program not found");
          return;
        }

        const programData = programSnap.data();

        if (programData.programType === "Mesocycle Advanced") {
          const macrocyclesQuery = query(
            collection(db, "users", user.uid, "programs"),
            where("programType", "==", "Macrocycle")
          );
          const macrocyclesSnap = await getDocs(macrocyclesQuery);

          const batch = writeBatch(db);

          macrocyclesSnap.forEach((macroDoc) => {
            const macroData = macroDoc.data();
            if (macroData.programBlocks && macroData.programBlocks.includes(programId)) {
              const updatedBlocks = macroData.programBlocks.filter((id) => id !== programId);
              batch.update(macroDoc.ref, { programBlocks: updatedBlocks });
            }
          });

          await batch.commit();
        }

        await deleteDoc(programRef);

        console.log("Program deleted successfully and references removed");
      } catch (error) {
        console.error("Error deleting program:", error);
        throw error;
      }
    },
    [user]
  );

  // Rename a program
  const renameProgram = useCallback(
    async (programId, newName) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const programRef = doc(db, "users", user.uid, "programs", programId);
        await updateDoc(programRef, { name: newName });
        console.log("Program renamed successfully");
      } catch (error) {
        console.error("Error renaming program:", error);
        throw error;
      }
    },
    [user]
  );

  // Duplicate a program
  const duplicateProgram = useCallback(
    async (program) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const programsCollection = collection(db, "users", user.uid, "programs");
        const newProgramData = { ...program };
        delete newProgramData.id;
        newProgramData.name = `${program.name} (Copy)`;

        if (newProgramData.programType.startsWith("Mesocycle")) {
          newProgramData.status = "Upcoming";
        }

        if (newProgramData.programType === "Macrocycle") {
          newProgramData.programBlocks = [];
        }

        const newProgramRef = await addDoc(programsCollection, newProgramData);
        const newProgram = { id: newProgramRef.id, ...newProgramData };

        return newProgram;
      } catch (error) {
        console.error("Error duplicating program:", error);
        throw error;
      }
    },
    [user]
  );

  // Update period length
  const updatePeriodLength = useCallback(
    async (newLength) => {
      if (!program || isUpdating || !user) return;

      setIsUpdating(true);
      console.log("Updating Period Length:", newLength);

      const docRef = doc(db, "users", user.uid, "programs", program.id);
      try {
        await updateDoc(docRef, { periodLength: newLength });
        console.log("Period length updated successfully in Firestore");
      } catch (error) {
        console.error("Error updating period length in Firestore:", error);
      } finally {
        setIsUpdating(false);
      }
    },
    [program?.id, isUpdating, user]
  );

  // Adjust period length
  const adjustPeriodLength = useCallback(
    (adjustment) => {
      if (!program || isUpdating || !user) return;

      const currentLength = program.periodLength || 0;
      const newLength = currentLength + adjustment;
      if (newLength < 1) return;

      updatePeriodLength(newLength);
    },
    [program?.periodLength, updatePeriodLength, isUpdating, user]
  );



  // Import mesocycle to macrocycle
  const importMesocycleToMacrocycle = useCallback(
    async (macrocycleId, mesocycleId) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const macrocycleRef = doc(db, `users/${user.uid}/programs/${macrocycleId}`);
        const mesocycleRef = doc(db, `users/${user.uid}/programs/${mesocycleId}`);

        // Get the current data for both programs
        const [macrocycleDoc, mesocycleDoc] = await Promise.all([
          getDoc(macrocycleRef),
          getDoc(mesocycleRef),
        ]);

        if (!macrocycleDoc.exists() || !mesocycleDoc.exists()) {
          throw new Error("One or both programs do not exist");
        }

        const macrocycleData = macrocycleDoc.data();
        const mesocycleData = mesocycleDoc.data();

        // Ensure valid program types
        if (
          macrocycleData.programType !== "Macrocycle" ||
          !mesocycleData.programType.startsWith("Mesocycle")
        ) {
          throw new Error("Invalid program types for import");
        }

        // Check if the mesocycle is already in the macrocycle
        if (
          macrocycleData.programBlocks &&
          macrocycleData.programBlocks.includes(mesocycleId)
        ) {
          throw new Error("This mesocycle is already part of the macrocycle");
        }

        // Use batch write for atomic updates
        const batch = writeBatch(db);

        batch.update(macrocycleRef, { programBlocks: arrayUnion(mesocycleId) });

        // If this is the first mesocycle, set its status to "Current"
        if (!macrocycleData.programBlocks || macrocycleData.programBlocks.length === 0) {
          batch.update(mesocycleRef, { status: "Current" });
        }

        await batch.commit();

        console.log(
          `Mesocycle ${mesocycleId} imported successfully into Macrocycle ${macrocycleId}`
        );
      } catch (error) {
        console.error("Error importing mesocycle to macrocycle:", error);
        throw error;
      }
    },
    [user]
  );

  // Update a macrocycle
  const updateMacrocycle = useCallback(
    async (macrocycleId, updates) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const macrocycleRef = doc(db, `users/${user.uid}/programs/${macrocycleId}`);
        await updateDoc(macrocycleRef, updates);
        console.log("Macrocycle updated successfully");
      } catch (error) {
        console.error("Error updating macrocycle:", error);
        throw error;
      }
    },
    [user]
  );

  // Share a program
  const shareProgram = useCallback(
    async (friendUserId, programId) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/users/share-program`,
          {
            currentUserId: user.uid,
            friendUserId,
            programId,
          }
        );
        console.log("Program shared successfully");
      } catch (error) {
        console.error("Error sharing program:", error);
        throw error;
      }
    },
    [user]
  );

  // Remove mesocycle from macrocycle
  const removeMesocycleFromMacrocycle = useCallback(
    async (macrocycleId, mesocycleId) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        const macrocycleRef = doc(db, `users/${user.uid}/programs/${macrocycleId}`);

        await updateDoc(macrocycleRef, {
          programBlocks: arrayRemove(mesocycleId),
        });

        console.log(
          `Mesocycle ${mesocycleId} removed from Macrocycle ${macrocycleId}`
        );
      } catch (error) {
        console.error("Error removing mesocycle from macrocycle:", error);
        throw error;
      }
    },
    [user]
  );

  // Get count of completed workouts
  const getCompletedWorkoutsCount = useCallback(
    (programId) => {
      if (!user) return 0;

      const program = programs.find((p) => p.id === programId);
      if (!program || !program.workouts) return 0;

      return program.workouts.filter((workout) => workout.completed).length;
    },
    [user, programs]
  );

  // Fetch mesocycles associated with a macrocycle
  const fetchMesocycles = useCallback(
    async (macrocycle) => {
      if (!user || !macrocycle || !macrocycle.programBlocks) return [];

      try {
        const mesocycleRefs = macrocycle.programBlocks.map((blockId) =>
          doc(db, "users", user.uid, "programs", blockId)
        );
        const mesocycleDocs = await Promise.all(
          mesocycleRefs.map((ref) => getDoc(ref))
        );

        const mesocycles = mesocycleDocs
          .filter((doc) => doc.exists())
          .map((doc) => ({ id: doc.id, ...doc.data() }));

        return mesocycles;
      } catch (error) {
        console.error("Error fetching mesocycles:", error);
        return [];
      }
    },
    [user]
  );

  // Fetch a mesocycle by ID
  const fetchMesocycleById = useCallback(
    async (mesocycleId) => {
      if (!user) {
        console.error("No authenticated user found");
        return null;
      }

      try {
        const mesocycleRef = doc(db, "users", user.uid, "programs", mesocycleId);
        const mesocycleDoc = await getDoc(mesocycleRef);
        if (mesocycleDoc.exists()) {
          return { id: mesocycleDoc.id, ...mesocycleDoc.data() };
        } else {
          console.error("Mesocycle not found");
          return null;
        }
      } catch (error) {
        console.error("Error fetching mesocycle:", error);
        return null;
      }
    },
    [user]
  );


  const updateMesocycleStatuses = useCallback(
    async (macrocycleId, updatedMesocycleId, newStatus) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }

      const batch = writeBatch(db);
      const macrocycleRef = doc(db, `users/${user.uid}/programs/${macrocycleId}`);
      const macrocycleSnap = await getDoc(macrocycleRef);

      if (!macrocycleSnap.exists()) {
        console.error("Macrocycle not found");
        return;
      }

      const macrocycle = macrocycleSnap.data();
      const { programBlocks } = macrocycle;

      if (!programBlocks || !programBlocks.length) {
        console.error("No mesocycles in this macrocycle");
        return;
      }

      const updatedIndex = programBlocks.indexOf(updatedMesocycleId);
      if (updatedIndex === -1) {
        console.error("Updated mesocycle not found in macrocycle");
        return;
      }

      // Update the status of the changed mesocycle
      const updatedMesocycleRef = doc(db, `users/${user.uid}/programs/${updatedMesocycleId}`);
      batch.update(updatedMesocycleRef, { status: newStatus });

      if (newStatus === 'Current') {
        // Set the next mesocycle (if exists) to 'Upcoming'
        if (updatedIndex < programBlocks.length - 1) {
          const nextMesocycleId = programBlocks[updatedIndex + 1];
          const nextMesocycleRef = doc(db, `users/${user.uid}/programs/${nextMesocycleId}`);
          batch.update(nextMesocycleRef, { status: 'Upcoming' });
        }

        // Reset other mesocycles
        for (let i = 0; i < programBlocks.length; i++) {
          if (i !== updatedIndex && i !== updatedIndex + 1) {
            const mesocycleRef = doc(db, `users/${user.uid}/programs/${programBlocks[i]}`);
            batch.update(mesocycleRef, { status: 'Not Set' });
          }
        }
      }

      await batch.commit();
      console.log("Mesocycle statuses updated successfully");
    },
    [user]
  );

  

  const updateMesocycleStatus = useCallback(
    async (mesocycleId, newStatus) => {
      if (!user) {
        console.error("No authenticated user found");
        return;
      }
      try {
        // Find the macrocycle that contains the mesocycle
        const macrocycle = programs.find(
          (p) =>
            p.programType === "Macrocycle" &&
            p.programBlocks?.includes(mesocycleId)
        );
  
        if (!macrocycle) {
          console.error("Macrocycle containing the mesocycle not found");
          return;
        }
  
        await updateMesocycleStatuses(macrocycle.id, mesocycleId, newStatus);
      } catch (error) {
        console.error("Error updating mesocycle status:", error);
        throw error;
      }
    },
    [user, programs, updateMesocycleStatuses]
  );

  const updateTotalWeeks = useCallback(async (programId, totalWeeks) => {
    if (!user) {
      console.error("No authenticated user found");
      return;
    }
    try {
      const programRef = doc(db, "users", user.uid, "programs", programId);
      await updateDoc(programRef, { totalWeeks });
      console.log("Total weeks updated successfully");
    } catch (error) {
      console.error("Error updating total weeks:", error);
      throw error;
    }
  }, [user]);


  // Memoize context value
  const value = useMemo(
    () => ({
      program,
      // updateProgramWithWorkout,
      workouts: program?.workouts || [],
      periodLength: program?.periodLength || 0,
      fetchProgram: setProgram, // Restored fetchProgram
      updatePeriodLength,
      // addWorkout,
      adjustPeriodLength,
      createProgram,
      updateProgramDescription,
      deleteProgram,
      programs,
      duplicateProgram,
      shareProgram,
      renameProgram,
      importMesocycleToMacrocycle,
      updateMacrocycle,
      removeMesocycleFromMacrocycle,
      getCompletedWorkoutsCount,
      fetchMesocycles,
      fetchMesocycleById,
      updateMesocycleStatus,
      updateTotalWeeks,
    }),
    [
      program,
      // updateProgramWithWorkout,
      setProgram,
      updatePeriodLength,
      // addWorkout,
      adjustPeriodLength,
      createProgram,
      updateProgramDescription,
      deleteProgram,
      programs,
      duplicateProgram,
      shareProgram,
      renameProgram,
      importMesocycleToMacrocycle,
      updateMacrocycle,
      removeMesocycleFromMacrocycle,
      getCompletedWorkoutsCount,
      fetchMesocycles,
      fetchMesocycleById,
      updateMesocycleStatus,
      updateTotalWeeks,
    ]
  );

  return (
    <ProgramContext.Provider value={value}>
      {children}
    </ProgramContext.Provider>
  );
};
