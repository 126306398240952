// import * as THREE from "three";

// class DayTile {
//   constructor(
//     id,
//     position,
//     dimensions = { width: 1, height: 0.2, depth: 1 },
//     date = null
//   ) {
//     const geometry = new THREE.BoxGeometry(
//       dimensions.width,
//       dimensions.height,
//       dimensions.depth
//     );

//     const material = new THREE.MeshBasicMaterial({
//       color: new THREE.Color("#5cd0fa"),
//     });

//     this.mesh = new THREE.Mesh(geometry, material);
//     this.mesh.position.set(position.x, position.y, position.z);
//     this.mesh.userData = { id };

//     if (date !== null) {
//       this.addDateText(date);
//     }
//   }

//   addDateText(date) {
//     console.log("Rendering date:", date); // Log the date to verify
  
//     const dayOfMonth = date.getDate(); // Extract the day of the month
  
//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");
//     const fontSize = 500; // Increased font size for better clarity
//     const canvasSize = 512; // Increased canvas size
  
//     canvas.width = canvasSize;
//     canvas.height = canvasSize;
  
//     context.font = `${fontSize}px Arial`;
//     context.fillStyle = "black";
//     context.textAlign = "center";
//     context.textBaseline = "middle";
//     context.fillText(dayOfMonth, canvasSize / 2, canvasSize / 2); // Render the day of the month
  
//     const texture = new THREE.CanvasTexture(canvas);
//     texture.needsUpdate = true;
  
//     const material = new THREE.SpriteMaterial({ map: texture });
//     const sprite = new THREE.Sprite(material);
  
//     const scaleRatio = 0.3; // Adjusted scale ratio for better fit
//     sprite.scale.set(scaleRatio, scaleRatio, scaleRatio);
//     sprite.position.set(0, 0.2, 0); // Adjusted position to fit above the tile
  
//     this.mesh.add(sprite);
//   }
  
// }

// export default DayTile;



import * as THREE from "three";

class DayTile {
  constructor(
    id,
    position,
    dimensions = { width: 1, height: 0.2, depth: 1 },
    date = null
  ) {
    const geometry = new THREE.BoxGeometry(
      dimensions.width,
      dimensions.height,
      dimensions.depth
    );

    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color("#5cd0fa"),
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.position.set(position.x, position.y, position.z);
    this.mesh.userData = { id };

    if (date !== null) {
      this.addDateText(date);
    }
  }

  addDateText(date) {
    console.log("Rendering date:", date); // Log the date to verify

    const dayOfMonth = date.getDate(); // Extract the day of the month

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const fontSize = 500; // Font size for better clarity
    const canvasSize = 512; // Canvas size

    canvas.width = canvasSize;
    canvas.height = canvasSize;

    context.font = `${fontSize}px Arial`;
    context.fillStyle = "black";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(dayOfMonth, canvasSize / 2, canvasSize / 2); // Render the day of the month

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;  // Ensure texture update

    const material = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(material);

    const scaleRatio = 0.3; // Adjusted scale ratio for better fit
    sprite.scale.set(scaleRatio, scaleRatio, scaleRatio);
    sprite.position.set(0, 0.2, 0); // Position the sprite

    this.mesh.add(sprite);

    // Ensure previous textures are disposed
    sprite.onBeforeRender = () => {
      if (sprite.material.map) {
        sprite.material.map.dispose();
      }
    };
  }
}

export default DayTile;
