
// import React, { useState, useEffect, useCallback } from "react";
// import styled from "styled-components";
// import ReactDOM from "react-dom";
// import { useDrag, useDrop, DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { FaArrowRight, FaCopy, FaTimes } from "react-icons/fa";

// import WorkoutForm from "../workouts/WorkoutForm";
// import AddWorkoutButton from "../workouts/AddWorkoutButton";
// import WorkoutItem from "../workouts/WorkoutItem";
// import ProgramWorkoutDrawer from "../program/ProgramWorkoutDrawer";
// import { useProgram } from "../../components/context/ProgramContext";
// import { useWorkout } from "../../components/context/WorkoutContext";
// import { useAuth } from "../../hooks/useAuth";
// import { useLoading } from "../../components/context/LoadingContext"; // Import the useLoading hook

// // Styled components definitions remain the same

// const WeekContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   padding: 16px 12px;
//   justify-content: flex-start;
//   gap: 20px;
//   overflow-x: auto;
//   overflow-y: hidden;
//   white-space: nowrap;
//   width: 100%;
//   height: calc(100vh - 100px); // Adjust this value based on your layout

//   /* Hide scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     height: 8px;
//     background: transparent;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: rgba(255, 255, 255, 0.2);
//     border-radius: 4px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: rgba(255, 255, 255, 0.3);
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: transparent;
//   }

//   /* Firefox */
//   scrollbar-width: thin;
//   scrollbar-color: rgba(255, 255, 255, 0.2) transparent;

//   /* For IE and Edge */
//   -ms-overflow-style: -ms-autohiding-scrollbar;

//   /* Ensure the container takes full width */
//   box-sizing: border-box;

//   /* Add some padding to the bottom to ensure scrollbar visibility */
//   padding-bottom: 16px;
// `;

// const WeekColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #2c2c2c;
//   border-radius: 8px;
//   padding: 10px;
//   min-width: 12em;
//   max-width: 12em;
//   max-height: 65vh; // Adjust this value as needed
//   overflow-y: auto;

//   /* Webkit browsers (Chrome, Safari, newer versions of Edge) */
//   &::-webkit-scrollbar {
//     width: 10px;
//   }

//   &::-webkit-scrollbar-track {
//     background: #1e1e1e;
//     border-radius: 5px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #4a4a4a;
//     border-radius: 5px;
//     border: 2px solid #1e1e1e;
//   }

//   &::-webkit-scrollbar-thumb:hover {
//     background: #5a5a5a;
//   }

//   /* Firefox */
//   scrollbar-width: thin;
//   scrollbar-color: #4a4a4a #1e1e1e;

//   /* For IE and Edge (pre-Chromium) */
//   -ms-overflow-style: none;

//   /* Hide default scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   /* Custom scrollbar using pseudo-element for better cross-browser support */
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 10px;
//     height: 100%;
//     background-color: #1e1e1e;
//     border-radius: 0 8px 8px 0;
//     opacity: 0;
//     transition: opacity 0.3s ease;
//   }

//   &:hover::after {
//     opacity: 1;
//   }

//   &:hover {
//     &::-webkit-scrollbar {
//       display: block;
//     }
//   }
// `;

// const WeekTitle = styled.h3`
//   color: #fff;
// `;

// const AddWeekSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   // background-color: #2c2c2c;
//   border-radius: 8px;
//   min-width: 200px;
//   overflow: hidden;
// `;

// const AddWeekHeader = styled.button`
//   background-color: #3a3a3a;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   text-align: left;
//   font-size: 14px;
//   width: 100%;

//   &:hover {
//     background-color: #525252;
//   }
// `;

// const AddWeekSkeleton = styled.div`
//  height: 100%;
//   margin-top: 35px;
//   background: linear-gradient(
//     180deg,
//     #2c2c2c,
//     #3a3a3a, 0) 100%
//   );
// `;

// const DropOptionsOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.75);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000; // Higher than drawer
// `;

// const DropOptionsCard = styled.div`
//   background-color: #2a2a2a;
//   border-radius: 12px;
//   padding: 24px;
//   width: 320px;
//   box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
//   z-index: 1000;
// `;

// const DropOptionsTitle = styled.h3`
//   color: #ffffff;
//   font-size: 20px;
//   font-weight: 600;
//   margin-bottom: 16px;
// `;

// const DropOptionsText = styled.p`
//   color: #b0b0b0;
//   font-size: 14px;
//   margin-bottom: 24px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const ActionButton = styled.button`
//   background-color: ${(props) => (props.primary ? "#f02a5b" : "#3a3a3a")};
//   color: #ffffff;
//   border: none;
//   border-radius: 8px;
//   padding: 10px 16px;
//   font-size: 14px;
//   font-weight: 500;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     background-color: ${(props) => (props.primary ? "#f02a5b" : "#4a4a4a")};
//     transform: translateY(-2px);
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// const DropOptionsPortal = ({ children }) => {
//   return ReactDOM.createPortal(children, document.body);
// };

// const IncompleteWorkoutDialog = ({ onConfirm, onCancel }) => {
//   return (
//     <DropOptionsPortal>
//     <DropOptionsOverlay>
//       <DropOptionsCard>
//         <DropOptionsTitle>Incomplete Workout</DropOptionsTitle>
//         <DropOptionsText>
//           This workout is missing required fields (name and intensity). 
//           Closing will delete the workout. Are you sure you want to close?
//         </DropOptionsText>
//         <ButtonContainer>
//           <ActionButton onClick={onCancel}>
//              Keep Working
//           </ActionButton>
//           <ActionButton primary onClick={onConfirm}>
//           <FaTimes />Nah fuck this shit
//           </ActionButton>
//         </ButtonContainer>
//       </DropOptionsCard>
//     </DropOptionsOverlay>
//     </DropOptionsPortal>
//   );
// };

// const WorkoutDropOptions = ({ onMove, onDuplicate, onCancel }) => {
//   return (
//     <DropOptionsOverlay>
//       <DropOptionsCard>
//         <DropOptionsTitle>Workout Action</DropOptionsTitle>
//         <DropOptionsText>
//           Choose an action for the dropped workout:
//         </DropOptionsText>
//         <ButtonContainer>
//           <ActionButton primary onClick={onMove}>
//             <FaArrowRight /> Move
//           </ActionButton>
//           <ActionButton onClick={onDuplicate}>
//             <FaCopy /> Duplicate
//           </ActionButton>
//           <ActionButton onClick={onCancel}>
//             <FaTimes /> Cancel
//           </ActionButton>
//         </ButtonContainer>
//       </DropOptionsCard>
//     </DropOptionsOverlay>
//   );
// };

// const WeekColumnDropTarget = ({ week, onDropWorkout, children }) => {
//   const [, dropRef] = useDrop({
//     accept: "WORKOUT",
//     drop: (item) => onDropWorkout(item.id, week.weekNumber),
//   });

//   return (
//     <WeekColumn ref={dropRef}>
//       <WeekTitle>Week {week.weekNumber}</WeekTitle>
//       {children}
//     </WeekColumn>
//   );
// };

// const MesocycleProgramContent = ({ program, programId }) => {
//   const { user } = useAuth();
//   const { setIsLoading } = useLoading(); // Use the loading context

//   const {
//     workouts,
//     currentWorkout,
//     subscribeToWorkouts,
//     fetchWorkoutFromFirestore,
//     updateWorkout,
//     deleteWorkout,
//     duplicateWorkout,
//     saveWorkoutToFirestore,
//   } = useWorkout();
//   const { updateTotalWeeks } = useProgram();
//   const [selectedWorkout, setSelectedWorkout] = useState(null);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [weeks, setWeeks] = useState([]);
//   const [dropInfo, setDropInfo] = useState(null);
//   const [showIncompleteWorkoutDialog, setShowIncompleteWorkoutDialog] = useState(false);
//   const [workoutToClose, setWorkoutToClose] = useState(null);


//   // Subscribe to workouts
//   useEffect(() => {
//     if (program && user && program.id) {
//       const unsubscribe = subscribeToWorkouts(program.id, async (fetchedWorkouts) => {
//         // Find and delete any incomplete workouts that are older than 5 minutes
//         const now = Date.now();
//         const incompleteWorkouts = fetchedWorkouts.filter(workout => {
//           const isIncomplete = !workout.mainMovement?.trim() || !workout.intensity?.trim();
//           const workoutAge = now - new Date(workout.createdAt || 0).getTime();
//           return isIncomplete && workoutAge > 5 * 60 * 1000; // 5 minutes in milliseconds
//         });
  
//         // Delete incomplete workouts
//         await Promise.all(
//           incompleteWorkouts.map(workout => deleteWorkout(workout.id, program.id))
//         );
//       });
  
//       return () => {
//         if (unsubscribe) unsubscribe();
//       };
//     }
//   }, [program, user, subscribeToWorkouts, program.id, deleteWorkout]);





//   // Process workouts into weeks
//   useEffect(() => {
//     const groupedByWeek = workouts.reduce((acc, workout) => {
//       const weekNumber = workout.week || 1;
//       if (!acc[weekNumber]) acc[weekNumber] = [];
//       acc[weekNumber].push(workout);
//       return acc;
//     }, {});

//     setWeeks(
//       Object.entries(groupedByWeek).map(([week, workouts]) => ({
//         weekNumber: parseInt(week, 10),
//         workouts,
//       }))
//     );
//   }, [workouts]);

// // #################################################

//   const handleAddWeek = async () => {
//     if (!program) return;
  
//     const newWeekNumber = weeks.length + 1;
    
//     try {
//       // Update the total weeks in Firestore
//       await updateTotalWeeks(program.id, newWeekNumber);
      
//       // Local state update (this will be overwritten by the Firestore listener, but provides immediate feedback)
//       setWeeks([...weeks, { weekNumber: newWeekNumber, workouts: [] }]);
      
//       console.log(`Week ${newWeekNumber} added successfully`);
//     } catch (error) {
//       console.error("Error adding new week:", error);
//     }
//   };




// // #################################################

// const handleAddWorkoutToWeek = async (weekNumber) => {
//   if (!program) return;

//   const newWorkoutOrder =
//     (weeks.find((week) => week.weekNumber === weekNumber)?.workouts.length ||
//       0) + 1;

//   const newWorkout = {
//     id: `workout-${Date.now()}`,
//     status: "unpublished",
//     order: newWorkoutOrder,
//     week: weekNumber,
//     createdAt: new Date().toISOString(), // Add creation timestamp
//     categories: {
//       corrective: [],
//       activation: [],
//       exercises: [],
//       esd: [],
//     },
//   };

//   const savedWorkout = await saveWorkoutToFirestore(newWorkout, program.id);
//   setSelectedWorkout(savedWorkout);
//   setDrawerOpen(true);
// };



// // #################################################



//   const handleSelectWorkout = async (workout) => {
//     console.log(`Selected Workout ID: ${workout.id}`);
//     try {
//       const fetchedWorkout = await fetchWorkoutFromFirestore(
//         workout.id,
//         program.id
//       );
//       if (fetchedWorkout) {
//         console.log('Fetched Workout Data:', fetchedWorkout);
//         setSelectedWorkout(fetchedWorkout);
//         setDrawerOpen(true);
//       } else {
//         console.error("Workout not found");
//       }
//     } catch (error) {
//       console.error("Error fetching workout:", error);
//     }
//   };

//   const handleWorkoutDeleted = (workoutId) => {
//     // The workouts state will be updated via the context's real-time listener
//     if (selectedWorkout && selectedWorkout.id === workoutId) {
//       setSelectedWorkout(null);
//       setDrawerOpen(false);
//     }
//   };

//   const handleDropWorkout = (workoutId, targetWeekNumber) => {
//     const sourceWeek = weeks.find((week) =>
//       week.workouts.some((w) => w.id === workoutId)
//     );
//     const targetWeek = weeks.find(
//       (week) => week.weekNumber === targetWeekNumber
//     );

//     if (sourceWeek && targetWeek) {
//       const workoutToMove = sourceWeek.workouts.find((w) => w.id === workoutId);
//       setDropInfo({ workoutId, sourceWeek, targetWeek, workoutToMove });
//     }
//   };

//   const handleMoveWorkout = async () => {
//     if (dropInfo) {
//       const { workoutToMove, targetWeek } = dropInfo;

//       const updatedWorkout = {
//         ...workoutToMove,
//         week: targetWeek.weekNumber,
//       };

//       await updateWorkout(updatedWorkout, program.id);

//       setDropInfo(null);
//     }
//   };

//   const handleDuplicateWorkout = async () => {
//     if (dropInfo) {
//       const { workoutToMove, targetWeek } = dropInfo;
//       try {
//         const duplicatedWorkout = await duplicateWorkout(
//           workoutToMove,
//           program.id
//         );
//         const updatedWorkout = {
//           ...duplicatedWorkout,
//           week: targetWeek.weekNumber,
//         };

//         await saveWorkoutToFirestore(updatedWorkout, program.id);

//         setDropInfo(null);
//       } catch (error) {
//         console.error("Error duplicating workout:", error);
//       }
//     }
//   };

//   const handleCancelDrop = () => {
//     setDropInfo(null);
//   };

//   const handleShareClick = () => {
//     console.log("Share clicked");
//   };



//   const isWorkoutComplete = useCallback((workout) => {
//     return workout.mainMovement?.trim() && workout.intensity?.trim();
//   }, []);



//   const handleDrawerClose = useCallback(() => {
//     console.log('handleDrawerClose called', selectedWorkout); // Log the workout
//     if (selectedWorkout && (!selectedWorkout.mainMovement?.trim() || !selectedWorkout.intensity?.trim())) {
//       console.log('Setting show incomplete dialog to true'); // Log the dialog state change
//       setShowIncompleteWorkoutDialog(true);
//     } else {
//       setSelectedWorkout(null);
//       setDrawerOpen(false);
//     }
//   }, [selectedWorkout]);
  
//   // In handleWarningConfirm

//   const handleWarningConfirm = useCallback(async () => {
//     console.log('handleWarningConfirm called', selectedWorkout, program?.id); // Added program.id log
//     if (selectedWorkout && program?.id) { // Check for both workout and program id
//       try {
//         console.log('Attempting to delete workout', selectedWorkout.id, program.id);
//         await deleteWorkout(selectedWorkout.id, program.id); // Use program.id directly
//         console.log('Delete successful');
//         setShowIncompleteWorkoutDialog(false);
//         setSelectedWorkout(null);
//         setDrawerOpen(false);
//       } catch (error) {
//         console.error("Error deleting incomplete workout:", error);
//       }
//     }
//   }, [selectedWorkout, deleteWorkout, program?.id]);

//   const handleWarningCancel = useCallback(() => {
//     setShowIncompleteWorkoutDialog(false);
//   }, []);

//   if (!program || !user) {
//     return null; // Return null instead of a loading message
//   }

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div>
//         <WeekContainer>
//           {weeks.map((week) => (
//             <WeekColumnDropTarget
//               key={week.weekNumber}
//               week={week}
//               onDropWorkout={handleDropWorkout}
//             >
//               {week.workouts.map((workout) => (
//                 <WorkoutItem
//                   key={workout.id}
//                   workout={workout}
//                   onSelectWorkout={handleSelectWorkout}
//                 />
//               ))}
//               <AddWorkoutButton
//                 onAddWorkout={() => handleAddWorkoutToWeek(week.weekNumber)}
//               />
//             </WeekColumnDropTarget>
//           ))}
//           <AddWeekSection>
//             <AddWeekHeader onClick={handleAddWeek}>+ Add Week</AddWeekHeader>
//             <AddWeekSkeleton />
//           </AddWeekSection>
//         </WeekContainer>

//         <ProgramWorkoutDrawer
//           isOpen={drawerOpen}
//           onClose={handleDrawerClose}
//         >
//           {selectedWorkout && (
//             <WorkoutForm
//               workout={selectedWorkout}
//               programId={program.id}
//               onDelete={handleWorkoutDeleted}
//               onClose={handleDrawerClose}
//             />
//           )}
//         </ProgramWorkoutDrawer>

//         {dropInfo && (
//           <WorkoutDropOptions
//             onMove={handleMoveWorkout}
//             onDuplicate={handleDuplicateWorkout}
//             onCancel={handleCancelDrop}
//           />
//         )}

// {showIncompleteWorkoutDialog && (
//           <IncompleteWorkoutDialog
//             onConfirm={handleWarningConfirm}
//             onCancel={handleWarningCancel}
//           />
//         )}
//       </div>
//     </DndProvider>
//   );
// };

// export default MesocycleProgramContent;


// ######Testing optimized chatGPT version of code ################


import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaArrowRight, FaCopy, FaTimes } from "react-icons/fa";

import WorkoutForm from "../workouts/WorkoutForm";
import AddWorkoutButton from "../workouts/AddWorkoutButton";
import WorkoutItem from "../workouts/WorkoutItem";
import ProgramWorkoutDrawer from "../program/ProgramWorkoutDrawer";
import { useProgram } from "../../components/context/ProgramContext";
import { useWorkout } from "../../components/context/WorkoutContext";
import { useAuth } from "../../hooks/useAuth";
import { useLoading } from "../../components/context/LoadingContext";

// Styled components definitions
const WeekContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 12px;
  justify-content: flex-start;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  height: calc(100vh - 100px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    height: 8px;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;

  /* For IE and Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar;

  /* Ensure the container takes full width */
  box-sizing: border-box;

  /* Add some padding to the bottom to ensure scrollbar visibility */
  padding-bottom: 16px;
`;

const WeekColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 10px;
  min-width: 12em;
  max-width: 12em;
  max-height: 65vh;
  overflow-y: auto;

  /* Hide default scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }
`;

const WeekTitle = styled.h3`
  color: #fff;
`;

const AddWeekSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 200px;
  overflow: hidden;
`;

const AddWeekHeader = styled.button`
  background-color: #3a3a3a;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  width: 100%;

  &:hover {
    background-color: #525252;
  }
`;

const AddWeekSkeleton = styled.div`
  height: 100%;
  margin-top: 35px;
  background: linear-gradient(180deg, #2c2c2c, #3a3a3a, 0) 100%);
`;

const DropOptionsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DropOptionsCard = styled.div`
  background-color: #2a2a2a;
  border-radius: 12px;
  padding: 24px;
  width: 320px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const DropOptionsTitle = styled.h3`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const DropOptionsText = styled.p`
  color: #b0b0b0;
  font-size: 14px;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.button`
  background-color: ${(props) => (props.primary ? "#f02a5b" : "#3a3a3a")};
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.primary ? "#f02a5b" : "#4a4a4a")};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  svg {
    margin-right: 8px;
  }
`;

const DropOptionsPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const IncompleteWorkoutDialog = ({ onConfirm, onCancel }) => {
  return (
    <DropOptionsPortal>
      <DropOptionsOverlay onClick={onCancel}>
        <DropOptionsCard onClick={(e) => e.stopPropagation()}>
          <DropOptionsTitle>Incomplete Workout</DropOptionsTitle>
          <DropOptionsText>
            This workout is missing required fields (name and intensity). Closing will delete the workout. Are you sure you want to close?
          </DropOptionsText>
          <ButtonContainer>
            <ActionButton onClick={(e) => { e.stopPropagation(); onCancel(); }}>Keep Working</ActionButton>
            <ActionButton primary onClick={(e) => { e.stopPropagation(); onConfirm(); }}>
              <FaTimes />
              Discard Workout
            </ActionButton>
          </ButtonContainer>
        </DropOptionsCard>
      </DropOptionsOverlay>
    </DropOptionsPortal>
  );
};


const WorkoutDropOptions = ({ onMove, onDuplicate, onCancel }) => {
  return (
    <DropOptionsPortal>
      <DropOptionsOverlay>
        <DropOptionsCard>
          <DropOptionsTitle>Workout Action</DropOptionsTitle>
          <DropOptionsText>Choose an action for the dropped workout:</DropOptionsText>
          <ButtonContainer>
            <ActionButton primary onClick={onMove}>
              <FaArrowRight /> Move
            </ActionButton>
            <ActionButton onClick={onDuplicate}>
              <FaCopy /> Duplicate
            </ActionButton>
            <ActionButton onClick={onCancel}>
              <FaTimes /> Cancel
            </ActionButton>
          </ButtonContainer>
        </DropOptionsCard>
      </DropOptionsOverlay>
    </DropOptionsPortal>
  );
};

const WeekColumnDropTarget = ({ week, onDropWorkout, children }) => {
  const [, dropRef] = useDrop({
    accept: "WORKOUT",
    drop: (item) => onDropWorkout(item.id, week.weekNumber),
  });

  return (
    <WeekColumn ref={dropRef}>
      <WeekTitle>Week {week.weekNumber}</WeekTitle>
      {children}
    </WeekColumn>
  );
};

const MesocycleProgramContent = ({ program, programId }) => {
  const { user } = useAuth();
  const { setIsLoading } = useLoading();

  const {
    workouts,
    subscribeToWorkouts,
    fetchWorkoutFromFirestore,
    updateWorkout,
    deleteWorkout,
    duplicateWorkout,
    saveWorkoutToFirestore,
  } = useWorkout();
  const { updateTotalWeeks } = useProgram();

  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [dropInfo, setDropInfo] = useState(null);
  const [showIncompleteWorkoutDialog, setShowIncompleteWorkoutDialog] = useState(false);

  // Subscribe to workouts and clean up incomplete ones
  useEffect(() => {
    let isMounted = true;

    const fetchAndCleanWorkouts = async () => {
      if (program?.id && user) {
        const unsubscribe = subscribeToWorkouts(program.id, async (fetchedWorkouts) => {
          if (!isMounted) return;

          const now = Date.now();
          const incompleteWorkouts = fetchedWorkouts.filter((workout) => {
            const isIncomplete = !workout.mainMovement?.trim() || !workout.intensity?.trim();
            const workoutAge = now - new Date(workout.createdAt || 0).getTime();
            return isIncomplete && workoutAge > 5 * 60 * 1000;
          });

          // Delete incomplete workouts
          await Promise.all(
            incompleteWorkouts.map((workout) => deleteWorkout(workout.id, program.id))
          );
        });

        return () => {
          isMounted = false;
          if (unsubscribe) unsubscribe();
        };
      }
    };

    fetchAndCleanWorkouts();
  }, [program?.id, user, subscribeToWorkouts, deleteWorkout]);

  // Group workouts into weeks
  useEffect(() => {
    const groupedByWeek = workouts.reduce((acc, workout) => {
      const weekNumber = workout.week || 1;
      if (!acc[weekNumber]) acc[weekNumber] = [];
      acc[weekNumber].push(workout);
      return acc;
    }, {});

    setWeeks(
      Object.entries(groupedByWeek).map(([week, workouts]) => ({
        weekNumber: parseInt(week, 10),
        workouts,
      }))
    );
  }, [workouts]);

  // Handle adding a new week
  const handleAddWeek = async () => {
    if (!program?.id) return;

    const newWeekNumber = weeks.length + 1;

    try {
      // Update the total weeks in Firestore
      await updateTotalWeeks(program.id, newWeekNumber);

      // Optimistically update local state
      setWeeks((prevWeeks) => [...prevWeeks, { weekNumber: newWeekNumber, workouts: [] }]);

      console.log(`Week ${newWeekNumber} added successfully`);
    } catch (error) {
      console.error("Error adding new week:", error);
      // Optionally, display error to user
    }
  };

  // Handle adding a workout to a week
  const handleAddWorkoutToWeek = async (weekNumber) => {
    if (!program?.id) return;

    const newWorkoutOrder =
      (weeks.find((week) => week.weekNumber === weekNumber)?.workouts.length || 0) + 1;

    const newWorkout = {
      id: `workout-${Date.now()}`,
      status: "unpublished",
      order: newWorkoutOrder,
      week: weekNumber,
      createdAt: new Date().toISOString(),
      categories: {
        corrective: [],
        activation: [],
        exercises: [],
        esd: [],
      },
    };

    try {
      const savedWorkout = await saveWorkoutToFirestore(newWorkout, program.id);
      setSelectedWorkout(savedWorkout);
      setDrawerOpen(true);
    } catch (error) {
      console.error("Error saving new workout:", error);
      // Optionally, display error to user
    }
  };

  // Handle selecting a workout
  const handleSelectWorkout = async (workout) => {
    try {
      const fetchedWorkout = await fetchWorkoutFromFirestore(workout.id, program.id);
      if (fetchedWorkout) {
        setSelectedWorkout(fetchedWorkout);
        setDrawerOpen(true);
      } else {
        console.error("Workout not found");
      }
    } catch (error) {
      console.error("Error fetching workout:", error);
      // Optionally, display error to user
    }
  };

  // Handle workout deletion
  const handleWorkoutDeleted = (workoutId) => {
    if (selectedWorkout?.id === workoutId) {
      setSelectedWorkout(null);
      setDrawerOpen(false);
    }
  };

  // Handle dropping a workout onto a week
  const handleDropWorkout = (workoutId, targetWeekNumber) => {
    const sourceWeek = weeks.find((week) => week.workouts.some((w) => w.id === workoutId));
    const targetWeek = weeks.find((week) => week.weekNumber === targetWeekNumber);

    if (sourceWeek && targetWeek) {
      const workoutToMove = sourceWeek.workouts.find((w) => w.id === workoutId);
      setDropInfo({ workoutId, sourceWeek, targetWeek, workoutToMove });
    }
  };

  // Handle moving a workout to another week
  const handleMoveWorkout = async () => {
    if (dropInfo && program?.id) {
      const { workoutToMove, targetWeek } = dropInfo;

      const updatedWorkout = {
        ...workoutToMove,
        week: targetWeek.weekNumber,
      };

      try {
        await updateWorkout(updatedWorkout, program.id);
        setDropInfo(null);
      } catch (error) {
        console.error("Error moving workout:", error);
        // Optionally, display error to user
      }
    }
  };

  // Handle duplicating a workout
  const handleDuplicateWorkout = async () => {
    if (dropInfo && program?.id) {
      const { workoutToMove, targetWeek } = dropInfo;
      try {
        const duplicatedWorkout = await duplicateWorkout(workoutToMove, program.id);
        const updatedWorkout = {
          ...duplicatedWorkout,
          week: targetWeek.weekNumber,
        };

        await saveWorkoutToFirestore(updatedWorkout, program.id);
        setDropInfo(null);
      } catch (error) {
        console.error("Error duplicating workout:", error);
        // Optionally, display error to user
      }
    }
  };

  // Cancel the drop action
  const handleCancelDrop = () => {
    setDropInfo(null);
  };

  // Check if a workout is complete
  const isWorkoutComplete = useCallback((workout) => {
    return workout?.mainMovement?.trim() && workout?.intensity?.trim();
  }, []);

  // Handle closing the drawer

// MesocycleProgramContent.js
// MesocycleProgramContent.js

const handleDrawerClose = useCallback(async () => {
  if (showIncompleteWorkoutDialog) {
    // Do nothing if the dialog is already open
    return;
  }


  if (selectedWorkout && program?.id) {
    try {
      // Fetch the latest version of the workout
      const latestWorkout = await fetchWorkoutFromFirestore(selectedWorkout.id, program.id);
      if (!latestWorkout.mainMovement?.trim() || !latestWorkout.intensity?.trim()) {
        setShowIncompleteWorkoutDialog(true);
      } else {
        setSelectedWorkout(null);
        setDrawerOpen(false);
      }
    } catch (error) {
      console.error("Error fetching latest workout:", error);
      // Optionally, handle the error or inform the user
      setSelectedWorkout(null);
      setDrawerOpen(false);
    }
  } else {
    setSelectedWorkout(null);
    setDrawerOpen(false);
  }
}, [selectedWorkout, fetchWorkoutFromFirestore, program?.id]);



  // const handleDrawerClose = useCallback(() => {
  //   if (selectedWorkout && !isWorkoutComplete(selectedWorkout)) {
  //     setShowIncompleteWorkoutDialog(true);
  //   } else {
  //     setSelectedWorkout(null);
  //     setDrawerOpen(false);
  //   }
  // }, [selectedWorkout, isWorkoutComplete]);

  // Confirm discarding an incomplete workout
  const handleWarningConfirm = useCallback(async () => {
    if (selectedWorkout && program?.id) {
      try {
        await deleteWorkout(selectedWorkout.id, program.id);
        setShowIncompleteWorkoutDialog(false);
        setSelectedWorkout(null);
        setDrawerOpen(false);
      } catch (error) {
        console.error("Error deleting incomplete workout:", error);
        // Optionally, display error to user
      }
    } else {
      console.error("Cannot delete workout: Missing workout or program ID.");
    }
  }, [selectedWorkout, deleteWorkout, program?.id]);

  // Cancel discarding the workout
// MesocycleProgramContent.js

const handleWarningCancel = () => {
  console.log('handleWarningCancel called');
  setShowIncompleteWorkoutDialog(false);
};




  // Render component
  if (!program?.id || !user) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <WeekContainer>
          {weeks.map((week) => (
            <WeekColumnDropTarget
              key={week.weekNumber}
              week={week}
              onDropWorkout={handleDropWorkout}
            >
              {week.workouts.map((workout) => (
                <WorkoutItem
                  key={workout.id}
                  workout={workout}
                  onSelectWorkout={handleSelectWorkout}
                />
              ))}
              <AddWorkoutButton onAddWorkout={() => handleAddWorkoutToWeek(week.weekNumber)} />
            </WeekColumnDropTarget>
          ))}
          <AddWeekSection>
            <AddWeekHeader onClick={handleAddWeek}>+ Add Week</AddWeekHeader>
            <AddWeekSkeleton />
          </AddWeekSection>
        </WeekContainer>

        <ProgramWorkoutDrawer 
        isOpen={drawerOpen} 
        onClose={handleDrawerClose}
        disableBackdropClick={showIncompleteWorkoutDialog}
        >
          {selectedWorkout && (
            <WorkoutForm
              workout={selectedWorkout}
              programId={program.id}
              onDelete={handleWorkoutDeleted}
              onClose={handleDrawerClose}
              onUpdate={(updatedWorkout) => setSelectedWorkout(updatedWorkout)}
            />
          )}
        </ProgramWorkoutDrawer>

        {dropInfo && (
          <WorkoutDropOptions
            onMove={handleMoveWorkout}
            onDuplicate={handleDuplicateWorkout}
            onCancel={handleCancelDrop}
          />
        )}

        {showIncompleteWorkoutDialog && (
          <IncompleteWorkoutDialog
            onConfirm={handleWarningConfirm}
            onCancel={handleWarningCancel}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default MesocycleProgramContent;
