import React, { useState, useEffect } from "react";
import { db } from "../../../services/firebaseConfig"; // Adjust the path
import { collection, addDoc, doc, getDocs } from "firebase/firestore";
import { useAuth } from "../../../hooks/useAuth"; // Adjust the path

const GoalBuilder = ({ programId }) => {
  const { user } = useAuth(); // Access the authenticated user
  const [exercises, setExercises] = useState([]);
  const [attributes, setAttributes] = useState([
    "Weight",
    "Reps",
    "1RM",
    "Sets",
    "Volume",
    "Distance",
    "Duration",
  ]);
  const [operators, setOperators] = useState([">=", "<=", "==", ">", "<"]);
  const [selectedExercise, setSelectedExercise] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [logic, setLogic] = useState("AND");
  const [conditions, setConditions] = useState([]);

  // Fetch exercises from Firestore or use sample data
  useEffect(() => {
    const fetchExercises = async () => {
      if (user) {
        const exercisesRef = collection(db, "users", user.uid, "exercises");
        const exercisesSnapshot = await getDocs(exercisesRef);
        const exercisesList = exercisesSnapshot.docs.map(
          (doc) => doc.data().name
        );
        setExercises(exercisesList);
      }
    };

    fetchExercises();
  }, [user]);

  const addCondition = () => {
    if (
      selectedExercise &&
      selectedAttribute &&
      selectedOperator &&
      targetValue !== ""
    ) {
      setConditions([
        ...conditions,
        {
          exercise: selectedExercise,
          attribute: selectedAttribute,
          operator: selectedOperator,
          value: parseFloat(targetValue),
        },
      ]);
      // Reset fields
      setSelectedExercise("");
      setSelectedAttribute("");
      setSelectedOperator("");
      setTargetValue("");
    } else {
      alert("Please fill in all fields before adding a condition.");
    }
  };

  const saveGoal = async () => {
    if (conditions.length === 0) {
      alert("Please add at least one condition to save a goal.");
      return;
    }

    const goal = {
      userId: user.uid,
      programId,
      conditions,
      logic,
    };

    try {
      await addDoc(
        collection(db, "users", user.uid, "programs", programId, "goals"),
        goal
      );
      alert("Goal saved successfully!");
      setConditions([]);
    } catch (error) {
      console.error("Error saving goal:", error);
      alert("Error saving goal. Please try again.");
    }
  };

  return (
    <div className="goal-builder">
      <h2>Create New Goal</h2>
      <div className="condition-builder">
        <div>
          <label>Exercise:</label>
          <select
            value={selectedExercise}
            onChange={(e) => setSelectedExercise(e.target.value)}
          >
            <option value="">Select Exercise</option>
            {exercises.map((exercise) => (
              <option key={exercise} value={exercise}>
                {exercise}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Attribute:</label>
          <select
            value={selectedAttribute}
            onChange={(e) => setSelectedAttribute(e.target.value)}
          >
            <option value="">Select Attribute</option>
            {attributes.map((attribute) => (
              <option key={attribute} value={attribute}>
                {attribute}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Operator:</label>
          <select
            value={selectedOperator}
            onChange={(e) => setSelectedOperator(e.target.value)}
          >
            <option value="">Select Operator</option>
            {operators.map((operator) => (
              <option key={operator} value={operator}>
                {operator}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Target Value:</label>
          <input
            type="number"
            value={targetValue}
            onChange={(e) => setTargetValue(e.target.value)}
          />
        </div>
        <button onClick={addCondition}>Add Condition</button>
      </div>

      <div className="logic-operator">
        <label>Logic Operator:</label>
        <select value={logic} onChange={(e) => setLogic(e.target.value)}>
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select>
      </div>

      <h3>Conditions:</h3>
      <ul>
        {conditions.map((cond, index) => (
          <li key={index}>
            {cond.exercise} {cond.attribute} {cond.operator} {cond.value}
          </li>
        ))}
      </ul>

      <button onClick={saveGoal}>Save Goal</button>
    </div>
  );
};

export default GoalBuilder;
