// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import { useAuth } from "../../hooks/useAuth";
// import Card from "../program/ProgramsCard";
// import RenameModal from "../../components/RenameModal";
// import ShareProgramModal from "./ShareProgramModal";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { useProgram } from "../context/ProgramContext";

// const ProgramsListContainer = styled.div`
//   padding: 20px;
//   background-color: var(--background-color);
//   color: var(--text-color);
//   position: relative;
//   width: 100%;
//   box-sizing: border-box;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
//   h2 {
//     color: #fff;
//     // background-color: #33333375;
//   }
// `;

// const ProgramsFlexContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
//   width: 55%;
//   max-width: 100%;
//   overflow-y: auto;
//   padding: 20px;
//   box-sizing: border-box;
//   background: #2c2c2c;
//   backdrop-filter: blur(10px);
//   border-radius: 10px;
//   min-height: 50vh;
// `;

// const ProgramCard = styled.div`
//   flex: 0 0 calc(50% - 10px);
//   width: calc(50% - 10px);
//   min-width: 160px;
//   aspect-ratio: 1 / 1;

//   @media (max-width: 480px) {
//     flex: 0 0 100%;
//     width: 100%;
//   }
// `;

// const ProgramsGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   gap: 20px;
//   width: 55%;
//   max-width: 100%;
//   overflow-y: auto;
//   padding: 20px;
//   box-sizing: border-box;
//   min-height: 400px;
//   background: #33333375;
//   backdrop-filter: blur(10px);
//   border-radius: 10px;

//   /* Height calculation for 2 rows on desktop */
//   height: calc((100vw * 0.55 / 3) * 2 + 20px);
//   max-height: calc((160px * 2) + 20px);

//   @media (max-width: 1024px) {
//     grid-template-columns: repeat(2, 1fr);
//     width: 100%;
//     gap: 10px;

//     /* Adjusted height calculation for 3 shorter rows on mobile */
//     height: calc((100vw / 2 * 0.75) * 3 + 20px);
//     max-height: calc((120px * 3) + 20px);
//   }

//   /* Ensure scrolling */
//   overflow-y: auto;
//   scrollbar-width: thin;
//   scrollbar-color: #3d3d3d #2b2e30;

//   &::-webkit-scrollbar {
//     width: 6px;
//   }

//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #3d3d3d;
//   }

//   &::-webkit-scrollbar-thumb:hover {
//     background: #3d3d3d;
//   }
// `;

// const Title = styled.h2`
//   font-size: 24px;
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     font-size: 20px;
//     margin-bottom: 15px;
//   }
// `;

// // Define the deepCopyProgram function
// const deepCopyProgram = (program) => {
//   return JSON.parse(JSON.stringify(program));
// };

// const ProgramsList = () => {
//   const { user } = useAuth();
//   const {
//     programs,
//     deleteProgram,
//     duplicateProgram,
//     shareProgram,
//     renameProgram,
//   } = useProgram();
//   const [renameModalOpen, setRenameModalOpen] = useState(false);
//   const [programToRename, setProgramToRename] = useState(null);
//   const [shareModalOpen, setShareModalOpen] = useState(false);
//   const [programToShare, setProgramToShare] = useState(null);
//   const navigate = useNavigate();

//   // Remove the useEffect that calls fetchPrograms

//   const handleDeleteProgram = (programId) => deleteProgram(programId);
//   const handleDuplicateProgram = (program) => duplicateProgram(program);
//   const handleShareProgram = (friendUserId, programId) =>
//     shareProgram(friendUserId, programId);
//   const handleRenameProgram = (program) => {
//     setProgramToRename(program);
//     setRenameModalOpen(true);
//   };

//   const handleRenameSubmit = async (newName) => {
//     await renameProgram(programToRename.id, newName);
//     setRenameModalOpen(false);
//   };

//   const handleEditProgram = (programId) => {
//     navigate(`/program-creator/${programId}`);
//   };

//   const closeRenameModal = () => {
//     setRenameModalOpen(false);
//   };

//   const closeShareModal = () => {
//     setShareModalOpen(false);
//     setProgramToShare(null);
//   };

//   return (
//     <ProgramsListContainer>
//       <h2>Your Programs</h2>
//       <ProgramsGrid>
//         {programs.map((program) => (
//           <Card
//             key={program.id}
//             image="https://via.placeholder.com/240x320"
//             title={program.name}
//             content={program.description || ""}
//             username={program.username}
//             onDelete={() => handleDeleteProgram(program.id)}
//             onDuplicate={() => handleDuplicateProgram(program)}
//             onShare={() => {
//               setShareModalOpen(true);
//               setProgramToShare(program);
//             }}
//             onRename={() => handleRenameProgram(program)}
//             onEdit={() => handleEditProgram(program.id)}
//             programId={program.id}
//           />
//         ))}
//       </ProgramsGrid>
//       <RenameModal
//         isOpen={renameModalOpen}
//         onClose={closeRenameModal}
//         onRename={handleRenameSubmit}
//         currentName={programToRename?.name || ""}
//       />
//       <ShareProgramModal
//         isOpen={shareModalOpen}
//         onClose={closeShareModal}
//         onShare={(friendUserId) =>
//           handleShareProgram(friendUserId, programToShare.id)
//         }
//         friends={user.friends}
//       />
//     </ProgramsListContainer>
//   );
// };

// export default ProgramsList;

// ############Ensuring touch scroll works on mobile devices############

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import Card from "../ProgramsPage/ProgramsCard";
import RenameModal from "../RenameModal";
import ShareProgramModal from "../program/ShareProgramModal";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useProgram } from "../context/ProgramContext";
import CreateProgramButton from "../program/CreateProgramButton";

const ProgramsListContainer = styled.div`
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
  h2 {
    color: #fff;
  }
`;

const TabBarContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }
`;

const TabBar = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #2c2c2c;
  border-radius: 10px 10px 0 0;
  padding: 0 20px;
  white-space: nowrap;
`;

const Tab = styled.button`
  padding: 12px 20px;
  background: ${(props) => (props.active ? "#33333375" : "transparent")};
  border: none;
  color: ${(props) => (props.active ? "#ffffff" : "#a0a0a0")};
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  border-bottom: 2px solid
    ${(props) => (props.active ? "#ffffff" : "transparent")};

  &:hover {
    background: ${(props) => (props.active ? "#33333375" : "#3a3a3a")};
    color: #ffffff;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 10px 15px;
  }
`;

const ProgramsFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 55%;
  max-width: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background: #2c2c2c;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  min-height: 50vh;
`;

const ProgramCard = styled.div`
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  min-width: 160px;
  aspect-ratio: 1 / 1;

  @media (max-width: 480px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 1fr;
  gap: 15px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #33333375;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  overflow-y: auto;
  min-height: 150px;
  max-height: calc(100vh - 150px);

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: #3d3d3d #2b2e30;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #2b2e30;
  }

  &::-webkit-scrollbar-thumb {
    background: #3d3d3d;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4d4d4d;
  }
`;

const ProgramCardWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

// Define the deepCopyProgram function
const deepCopyProgram = (program) => {
  return JSON.parse(JSON.stringify(program));
};

const ProgramsList = () => {
  const [activeTab, setActiveTab] = useState("all");
  const tabBarRef = useRef(null);

  const { user } = useAuth();
  const {
    programs,
    deleteProgram,
    duplicateProgram,
    shareProgram,
    renameProgram,
  } = useProgram();
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [programToRename, setProgramToRename] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [programToShare, setProgramToShare] = useState(null);
  const navigate = useNavigate();
  const gridRef = useRef(null);

  useEffect(() => {
    const scrollToActiveTab = () => {
      const activeTabElement = tabBarRef.current.querySelector(
        'button[data-active="true"]'
      );
      if (activeTabElement) {
        const scrollLeft =
          activeTabElement.offsetLeft - tabBarRef.current.offsetLeft;
        tabBarRef.current.scrollTo({ left: scrollLeft, behavior: "smooth" });
      }
    };

    scrollToActiveTab();
  }, [activeTab]);

  const handleDeleteProgram = (programId) => deleteProgram(programId);
  const handleDuplicateProgram = (program) => duplicateProgram(program);
  const handleShareProgram = (friendUserId, programId) =>
    shareProgram(friendUserId, programId);
  const handleRenameProgram = (program) => {
    setProgramToRename(program);
    setRenameModalOpen(true);
  };

  const handleRenameSubmit = async (newName) => {
    await renameProgram(programToRename.id, newName);
    setRenameModalOpen(false);
  };

  const handleEditProgram = (programId) => {
    navigate(`/program-creator/${programId}`);
  };

  const closeRenameModal = () => {
    setRenameModalOpen(false);
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
    setProgramToShare(null);
  };

  const filteredPrograms = programs.filter((program) => {
    if (activeTab === "all") return true;
    return program.type === activeTab;
  });

  useEffect(() => {
    const gridElement = gridRef.current;
    let startY;
    let startScrollTop;
    let currentScrollTop;

    const handleTouchStart = (e) => {
      startY = e.touches[0].pageY;
      startScrollTop = gridElement.scrollTop;
      currentScrollTop = startScrollTop;
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].pageY;
      const deltaY = startY - currentY;
      currentScrollTop = startScrollTop + deltaY;
      gridElement.scrollTop = currentScrollTop;

      if (
        (currentScrollTop <= 0 && deltaY < 0) ||
        (currentScrollTop + gridElement.clientHeight >=
          gridElement.scrollHeight &&
          deltaY > 0)
      ) {
        e.preventDefault();
      }
    };

    if (gridElement) {
      gridElement.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      gridElement.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      if (gridElement) {
        gridElement.removeEventListener("touchstart", handleTouchStart);
        gridElement.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  return (
    <ProgramsListContainer>
      <TitleSection>
        <Title>Your Programs</Title>
        <CreateProgramButton />
      </TitleSection>
      <TabBarContainer ref={tabBarRef}>
        <TabBar>
          <Tab active={activeTab === "all"} onClick={() => setActiveTab("all")}>
            All
          </Tab>
          <Tab
            active={activeTab === "macrocycle"}
            onClick={() => setActiveTab("macrocycle")}
          >
            Macrocycles
          </Tab>
          <Tab
            active={activeTab === "mesocycle"}
            onClick={() => setActiveTab("mesocycle")}
          >
            Mesocycles
          </Tab>
          {/* <Tab active={activeTab === 'workout'} onClick={() => setActiveTab('workout')}>Workouts</Tab> */}
        </TabBar>
      </TabBarContainer>
      <ProgramsGrid>
        {filteredPrograms.map((program) => (
          // {programs.map((program) => (
          <ProgramCardWrapper key={program.id}>
            <Card
              title={program.name}
              content={program.description || ""}
              username={program.username}
              onDelete={() => handleDeleteProgram(program.id)}
              onDuplicate={() => handleDuplicateProgram(program)}
              onShare={() => {
                setShareModalOpen(true);
                setProgramToShare(program);
              }}
              onRename={() => handleRenameProgram(program)}
              onEdit={() => handleEditProgram(program.id)}
              programId={program.id}
            />
          </ProgramCardWrapper>
        ))}
      </ProgramsGrid>
      <RenameModal
        isOpen={renameModalOpen}
        onClose={closeRenameModal}
        onRename={handleRenameSubmit}
        currentName={programToRename?.name || ""}
      />
      <ShareProgramModal
        isOpen={shareModalOpen}
        onClose={closeShareModal}
        onShare={(friendUserId) =>
          handleShareProgram(friendUserId, programToShare.id)
        }
        friends={user.friends}
      />
    </ProgramsListContainer>
  );
};

export default ProgramsList;
