import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";
import FriendsList from "../components/profile/FriendsList";
import TabBar from "../components/profile/ProfileTabBar";
import ActivityFeed from "../components/profile/ActivityFeed";
import PhotosSection from "../components/profile/PhotosSection";
import AboutSection from "../components/profile/AboutSection";
import FriendRequestButton from "../components/profile/FriendRequestButton";
import TopBar from "../components/TopBar";
import ProfileHero from "../components/profile/ProfileHero";
import FriendsModal from "../components/profile/FriendsModal";

const PageWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #1a1a2e, #16213e);
`;

const CoverPhoto = styled.div`
  height: 260px;
  background: linear-gradient(
    120deg,
    rgba(66, 66, 150, 0.2),
    rgba(123, 67, 151, 0.3)
  );
  position: relative;
`;

const CameraButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  svg {
    color: white;
    font-size: 18px;
  }
`;

const ProfileContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
`;

const ProfileHeader = styled.div`
  position: relative;
  margin-top: -80px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 4px solid #1a1a2e;
  overflow: hidden;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-left: 24px;
    flex: 1;
  }
`;

const Username = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin: 0;
  margin-bottom: 8px;
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CardTitle = styled.h2`
  font-size: 1.5rem;
  color: white;
  margin: 0;
`;

// const AboutSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// `;

const AboutTextArea = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 16px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4a9eff;
  }
`;

const SocialLinksSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const SocialLinkInput = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    font-size: 20px;
    min-width: 20px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 14px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4a9eff;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const SocialLink = styled.a`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    color: ${(props) => {
      switch (props.platform) {
        case "instagram":
          return "#E1306C";
        case "reddit":
          return "#FF4500";
        case "twitter":
          return "#1DA1F2";
        case "youtube":
          return "#FF0000";
        default:
          return "white";
      }
    }};
  }
`;

const AboutText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 0;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  svg {
    font-size: 18px;
    color: ${(props) => props.color || "rgba(255, 255, 255, 0.8)"};
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const SettingsButton = styled(IconButton)`
  background: rgba(255, 255, 255, 0.05);

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const StyledFriendRequestButton = styled(FriendRequestButton)`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4ecdc4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45b7aa;
  }
`;

const TabContent = styled.div`
  width: 100%;
`;

const PublicProfile = () => {
  const { username } = useParams();
  const { user: currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("about");
  const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);
  const [counts, setCounts] = useState({
    friends: 0,
    activities: 0,
    photos: 0,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/users/username/${username}`
        );
        setUserData(response.data);
        setCounts({
          friends: response.data.friends?.length || 0,
          activities: response.data.activities?.length || 0,
          photos: response.data.photos?.length || 0,
        });
      } catch (error) {
        setError("Error fetching user data");
      }
    };

    fetchUserData();
  }, [username]);

  useEffect(() => {
    setIsFriendsModalOpen(false);
  }, [username]);

  // Observe modal state changes
  useEffect(() => {}, [isFriendsModalOpen]);

  if (error)
    return (
      <PageWrapper>
        <div style={{ color: "white", textAlign: "center", padding: "20px" }}>
          {error}
        </div>
      </PageWrapper>
    );

  if (!userData)
    return (
      <PageWrapper>
        <div style={{ color: "white", textAlign: "center", padding: "20px" }}>
          Loading...
        </div>
      </PageWrapper>
    );

  return (
    <>
      <TopBar />
      <PageWrapper>
        <ProfileHero
          user={userData}
          isCurrentUser={false}
          socialLinks={userData.socialLinks}
          friendCount={counts.friends}
          isFriendsModalOpen={isFriendsModalOpen} // Add this
          setIsFriendsModalOpen={setIsFriendsModalOpen} // Add this
          currentUser={currentUser} // Pass the current user
        />
      </PageWrapper>
    </>
  );
};

export default PublicProfile;
