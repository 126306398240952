


import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const SummaryContainer = styled.div`
  padding: 20px;
  background-color: #333;
  color: white;
  border-radius: 8px;
`;

const Section = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const SectionTitle = styled.h3`
  margin-bottom: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #545454;
  color: #545454;
  cursor: pointer;
`;

const SectionTitleText = styled.span`
  display: flex;
  align-items: center;
`;

const ExerciseCount = styled.span`
  font-size: 0.8em;
  margin-left: 10px;
  color: #9100ff;
`;

const SectionContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding-top: 10px;
`;

const ExerciseItem = styled.div`
  margin-bottom: 15px;
`;

const ExerciseName = styled.h4`
  margin-bottom: 0;
  padding: 5px 0;
  color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #444;
`;

const ExerciseContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding-top: 10px;
`;

const SetComparison = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  font-size: 0.9em;
  margin-bottom: 5px;
`;

const SetLabel = styled.span`
  font-weight: bold;
  color: #545454;
`;

const RepsLabel = styled.span`
  font-weight: bold;
  color: #545454;
`;

const ValueComparison = styled.span`
  display: inline-block;
  width: 100px;
`;

const GoalValue = styled.span`
  color: #808080;
`;

const ExerciseScore = styled.span`
  font-size: 0.8em;
  margin-left: 10px;
  font-weight: bold;
  background: ${props => props.score > 100 ? 'radial-gradient(circle, #9100ff, #ff00ec, #ffc800)' : 'none'};
  background-clip: ${props => props.score > 100 ? 'text' : 'none'};
  -webkit-background-clip: ${props => props.score > 100 ? 'text' : 'none'};
  -webkit-text-fill-color: ${props => props.score > 100 ? 'transparent' : 'inherit'};
  color: ${props => {
    if (props.score > 100) return 'transparent'; // Color handled by gradient
    if (props.score >= 90) return '#4caf50'; // Green for 90-100%
    if (props.score >= 70) return '#ffd700'; // Gold for 70-89%
    if (props.score >= 50) return '#ffa500'; // Orange for 50-69%
    return '#ff4d4d'; // Red for below 50%
  }};
  background-size: 200% 200%;
  animation: ${props => props.score > 100 ? 'animateGradient 5s ease infinite' : 'none'};

  @keyframes animateGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const OutstandingLabel = styled.span`
  margin-left: 5px;
  font-size: 0.7em;
  vertical-align: super;
  color: #ffd700;
`;

const PerformedValue = styled.span`
  color: ${props => {
    if (props.compare === 'less') return '#ff4d4d';
    if (props.compare === 'equal') return '#4caf50';
    if (props.compare === 'greater') return '#ffd700';
    return 'inherit';
  }};
`;

const compareValues = (goal, performed) => {
  if (performed === '') return 'equal';
  const goalNum = parseFloat(goal);
  const performedNum = parseFloat(performed);
  if (isNaN(goalNum) || isNaN(performedNum)) return 'equal';
  if (performedNum < goalNum) return 'less';
  if (performedNum > goalNum) return 'greater';
  return 'equal';
};

const WorkoutSummary = ({ workout }) => {
  const [openCategories, setOpenCategories] = useState({});
  const [openExercises, setOpenExercises] = useState({});

  const toggleCategory = (category) => {
    setOpenCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const toggleExercise = (exerciseId) => {
    setOpenExercises(prev => ({ ...prev, [exerciseId]: !prev[exerciseId] }));
  };


//   ##############Score Caluclation################


const calculateExerciseScore = (exercise) => {
    let totalGoalReps = 0;
    let totalGoalWeight = 0;
    let totalPerformedReps = 0;
    let totalPerformedWeight = 0;
  
    exercise.goal.sets.forEach((goalSet, index) => {
      const performedSet = exercise.performed.sets[index] || { reps: '0', weight: '0' };
      
      totalGoalReps += parseInt(goalSet.reps) || 0;
      totalGoalWeight += parseFloat(goalSet.weight) || 0;
      totalPerformedReps += parseInt(performedSet.reps) || 0;
      totalPerformedWeight += parseFloat(performedSet.weight) || 0;
    });
  
    const repsScore = totalGoalReps > 0 ? (totalPerformedReps / totalGoalReps) * 100 : 100;
    const weightScore = totalGoalWeight > 0 ? (totalPerformedWeight / totalGoalWeight) * 100 : 100;
  
    // Average of reps and weight scores, allowing for scores over 100%
    const finalScore = (repsScore + weightScore) / 2;
  
    return Math.round(finalScore);
  };




  const renderExercises = (category) => {
    return (
      <>
        {workout.categories[category]?.map((exercise) => {
          const score = calculateExerciseScore(exercise);
          return (
            <ExerciseItem key={exercise.id}>
              <ExerciseName onClick={() => toggleExercise(exercise.id)}>
                {exercise.value}
                <ExerciseScore score={score}>
                  {score}%
                  {score > 100 && (
                    <OutstandingLabel>
                      Holy shit you son of a bitch! 
                    </OutstandingLabel>
                  )}
                </ExerciseScore>
                {openExercises[exercise.id] ? <FaChevronUp /> : <FaChevronDown />}
              </ExerciseName>
              <ExerciseContent isOpen={openExercises[exercise.id]}>
                {exercise.goal.sets.map((goalSet, index) => {
                  const performedSet = exercise.performed.sets[index] || { reps: '', weight: '' };
                  return (
                    <SetComparison key={index}>
                      <div>
                        <SetLabel>Set {index + 1} </SetLabel>
                        <ValueComparison>
                          <GoalValue>{goalSet.reps || '-'}</GoalValue>/
                          <PerformedValue compare={compareValues(goalSet.reps, performedSet.reps)}>
                            {performedSet.reps || '-'}
                          </PerformedValue>
                        </ValueComparison>
                       <RepsLabel>reps </RepsLabel>   

                        <ValueComparison>
                          <GoalValue>{goalSet.weight || '-'}</GoalValue>/
                          <PerformedValue compare={compareValues(goalSet.weight, performedSet.weight)}>
                            {performedSet.weight || '-'}
                          </PerformedValue>
                        </ValueComparison>
                        {exercise.goal.weightUnit}
                      </div>
                    </SetComparison>
                  );
                })}
              </ExerciseContent>
            </ExerciseItem>
          );
        })}
      </>
    );
  };

  return (
    <SummaryContainer>
      {["corrective", "activation", "exercises", "esd"].map((category) => {
        const exerciseCount = workout.categories[category]?.length || 0;
        return (
          <Section key={category}>
            <SectionTitle onClick={() => toggleCategory(category)}>
              <SectionTitleText>
                {category.charAt(0).toUpperCase() + category.slice(1)}
                <ExerciseCount>({exerciseCount})</ExerciseCount>
              </SectionTitleText>
              {openCategories[category] ? <FaChevronUp /> : <FaChevronDown />}
            </SectionTitle>
            <SectionContent isOpen={openCategories[category]}>
              {renderExercises(category)}
            </SectionContent>
          </Section>
        );
      })}
    </SummaryContainer>
  );
};

export default WorkoutSummary;