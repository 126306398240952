import React from "react";
import styled from "styled-components";
import MultiStepSignUp from "./MultiStepSignUp";

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  // background-color: #fff;
  background-color: #1e2130;

  margin: auto;
  padding: 40px 20px;
  // border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: animateTop 0.4s;
  text-align: center;

  @keyframes animateTop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
`;

const SignUpModal = ({ show, onClose }) => {
  if (!show) {
    return null; // Only render modal if `show` is true
  }

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <MultiStepSignUp />
      </ModalContent>
    </ModalOverlay>
  );
};

export default SignUpModal;
