import React from 'react';
import styled from 'styled-components';
import ProgramDescriptionEditor from './ProgramDescriptionEditor';

const OverviewContainer = styled.div`
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 20px;
  font-family: Arial, sans-serif;
  height: 100vh;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ProjectRoles = styled.div`
  margin-top: 20px;
`;

const AddMemberButton = styled.button`
  background-color: transparent;
  border: 1px dashed #888;
  color: #888;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const MemberList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const MemberAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.span`
  font-weight: bold;
`;

const MemberRole = styled.span`
  font-size: 12px;
  color: #888;
`;

const ProgramOverview = ({ programId, initialDescription, username, avatarUrl }) => {
  return (
    <OverviewContainer>
      <SectionTitle>Program description</SectionTitle>
      <ProgramDescriptionEditor 
        programId={programId} 
        initialDescription={initialDescription} 
      />
      
      <ProjectRoles>

        <MemberList>
          {/* use the actual user avatar */}
          <MemberAvatar src={avatarUrl || "/api/placeholder/40/40"} alt={username} />
          <MemberInfo>
{/* use the username instead the hardcoded value */}
            <MemberName>{username}</MemberName>
            <MemberRole>Program owner</MemberRole>
          </MemberInfo>
        </MemberList>
        {/* Create a section that displays who the program is shared with */}
        <SectionTitle>Shared With</SectionTitle>
        <AddMemberButton>
          <span style={{marginRight: '5px'}}>+</span> Add member
        </AddMemberButton>
      </ProjectRoles>
      {/* Create sectiosn to display: Goals summary, main exercises, Program emphasis(strength, endurance, hypertrophy, etc.)  */}
    </OverviewContainer>
  );
};

export default ProgramOverview;