const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default {
  gridSize: 7,
  rows: 7,
  spacing: 1.2,
  grid: [
    [
      "WeekDay",
      "WeekDay",
      "WeekDay",
      "WeekDay",
      "WeekDay",
      "WeekDay",
      "WeekDay",
    ],
    ["Day", "Day", "Day", "Day", "Day", "Day", "Day"],
    ["Day", "Day", "Day", "Day", "Day", "Day", "Day"],
    ["Day", "Day", "Day", "Day", "Day", "Day", "Day"],
    ["Day", "Day", "Day", "Day", "Day", "Day", "Day"],
    ["Day", "Day", "Day", "Day", "Day", "Day", "Day"],
    ["Day", "Day", "Day", "Day", "Day", "Day", "Day"],
  ],
  cameraPosition: { x: 0, y: 20, z: 2 },
  dayOpacity: 1.0,
  daysOfWeek, // Add this line to include the days of the week
};
