import React, { createContext, useState, useContext, useCallback } from 'react';
import LoadingComponent from '../LoadingComponent';


const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = useCallback((duration = 1000) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), duration);
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, showLoading }}>
      {isLoading && <LoadingComponent />}
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);