import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const GalleryContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
`;

const GalleryTrack = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const Card = styled.div`
  width: 200px;
  height: 300px;
  background-color: #9d7cce;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  border-radius: 10px;
  flex-shrink: 0;
`;

const Button = styled.button`
  position: absolute;
  bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 5px;
  
  &:hover {
    background-color: #666;
  }
`;

const PrevButton = styled(Button)`
  left: 20px;
`;

const NextButton = styled(Button)`
  right: 20px;
`;

const ScrollGallery = ({ items = 10 }) => {
  const trackRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const track = trackRef.current;
    const container = containerRef.current;

    // Set up the initial position
    gsap.set(track, { x: 0 });

    // Create the scrolling animation
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,
        start: "top top",
        end: "+=3000",
        scrub: 1,
      }
    });

    // Animate the track
    tl.to(track, {
      x: () => -(track.scrollWidth - container.offsetWidth),
      ease: "none",
    });

    return () => {
      // Clean up ScrollTrigger on component unmount
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [items]);

  const handleNext = () => {
    gsap.to(trackRef.current, {
      x: `-=${window.innerWidth / 2}`,
      duration: 0.5,
      ease: "power2.out",
    });
  };

  const handlePrev = () => {
    gsap.to(trackRef.current, {
      x: `+=${window.innerWidth / 2}`,
      duration: 0.5,
      ease: "power2.out",
    });
  };

  return (
    <GalleryContainer ref={containerRef}>
      <GalleryTrack ref={trackRef}>
        {[...Array(items)].map((_, index) => (
          <Card key={index}>{index + 1}</Card>
        ))}
      </GalleryTrack>
      <PrevButton onClick={handlePrev}>Prev</PrevButton>
      <NextButton onClick={handleNext}>Next</NextButton>
    </GalleryContainer>
  );
};

export default ScrollGallery;