// components/profile/AboutSection.js
import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaReddit, FaTwitter, FaYoutube } from 'react-icons/fa';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AboutTextArea = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 16px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4a9eff;
  }
`;

const SocialLinksSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const SocialLinkInput = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  
  svg {
    font-size: 20px;
    min-width: 20px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 14px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4a9eff;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const AboutText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 0;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const SocialLink = styled.a`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    color: ${props => {
      switch (props.platform) {
        case 'instagram': return '#E1306C';
        case 'reddit': return '#FF4500';
        case 'twitter': return '#1DA1F2';
        case 'youtube': return '#FF0000';
        default: return 'white';
      }
    }};
  }
`;

const AboutSection = ({
  isEditing,
  aboutText,
  socialLinks,
  onAboutChange,
  onSocialChange,
}) => {
  const formatUrl = (url) => {
    if (!url || url === "-") return "-";
    if (url.match(/^https?:\/\//)) return url;
    return `https://${url}`;
  };

  const renderSocialLinks = () => {
    if (!isEditing && socialLinks) {
      return (
        <SocialLinks>
          {socialLinks.instagram !== "-" && (
            <SocialLink 
              href={formatUrl(socialLinks.instagram)} 
              target="_blank" 
              platform="instagram"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </SocialLink>
          )}
          {socialLinks.reddit !== "-" && (
            <SocialLink 
              href={formatUrl(socialLinks.reddit)} 
              target="_blank" 
              platform="reddit"
              rel="noopener noreferrer"
            >
              <FaReddit />
            </SocialLink>
          )}
          {socialLinks.twitter !== "-" && (
            <SocialLink 
              href={formatUrl(socialLinks.twitter)} 
              target="_blank" 
              platform="twitter"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </SocialLink>
          )}
          {socialLinks.youtube !== "-" && (
            <SocialLink 
              href={formatUrl(socialLinks.youtube)} 
              target="_blank" 
              platform="youtube"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </SocialLink>
          )}
        </SocialLinks>
      );
    }
    return null;
  };

  return (
    <AboutContainer>
      {isEditing ? (
        <>
          <AboutTextArea
            value={aboutText}
            onChange={(e) => onAboutChange(e.target.value)}
            placeholder="Write something about yourself..."
          />
          <SocialLinksSection>
            <SocialLinkInput>
              <FaInstagram color="#E1306C" />
              <Input
                type="text"
                placeholder="instagram.com/username"
                value={socialLinks.instagram}
                onChange={(e) => onSocialChange('instagram')(e)}
              />
            </SocialLinkInput>
            <SocialLinkInput>
              <FaReddit color="#FF4500" />
              <Input
                type="text"
                placeholder="reddit.com/user/username"
                value={socialLinks.reddit}
                onChange={(e) => onSocialChange('reddit')(e)}
              />
            </SocialLinkInput>
            <SocialLinkInput>
              <FaTwitter color="#1DA1F2" />
              <Input
                type="text"
                placeholder="twitter.com/username"
                value={socialLinks.twitter}
                onChange={(e) => onSocialChange('twitter')(e)}
              />
            </SocialLinkInput>
            <SocialLinkInput>
              <FaYoutube color="#FF0000" />
              <Input
                type="text"
                placeholder="youtube.com/c/channelname"
                value={socialLinks.youtube}
                onChange={(e) => onSocialChange('youtube')(e)}
              />
            </SocialLinkInput>
          </SocialLinksSection>
        </>
      ) : (
        <>
          <AboutText>
            {aboutText || "No about information provided yet."}
          </AboutText>
          {renderSocialLinks()}
        </>
      )}
    </AboutContainer>
  );
};

export default AboutSection;