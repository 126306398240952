import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import { db } from "../services/firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  position: relative;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #333;
  }
`;

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  color: #333;
  font-size: 24px;
`;

const NotificationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NotificationItem = styled.li`
  background: #f8f9fa;
  margin: 12px 0;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background: #e9ecef;
  }
`;

const NotificationMessage = styled.span`
  flex: 1;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
`;

const AcceptButton = styled(Button)`
  background-color: #4caf50;
  color: white;
  &:hover {
    background-color: #45a049;
  }
`;

const AcknowledgeButton = styled(Button)`
  background-color: #2196f3;
  color: white;
  &:hover {
    background-color: #1e88e5;
  }
`;

const ClearButton = styled(Button)`
  background-color: #f44336;
  color: white;
  &:hover {
    background-color: #e53935;
  }
`;

const ClearAllButton = styled(Button)`
  margin-top: 16px;
  background-color: #ff9800;
  color: white;
  &:hover {
    background-color: #fb8c00;
  }
`;

const NoNotification = styled.div`
  color: #666;
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
`;

const NotificationModal = ({ isOpen, onClose, currentUserId }) => {
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen && currentUserId) {
      const userRef = doc(db, "users", currentUserId);
      const unsubscribe = onSnapshot(
        userRef,
        (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            setNotifications(userData.notifications || []);
          } else {
            setError("User data not found");
          }
        },
        (error) => {
          setError("Failed to fetch notifications. Please try again later.");
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [isOpen, currentUserId]);

  const handleApiCall = async (apiFunction, ...args) => {
    try {
      setError(null);
      await apiFunction(...args);
      // No need to manually fetch notifications here, the listener will update automatically
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    }
  };

  const acceptFriendRequest = async (fromUsername, index) => {
    await handleApiCall(async () => {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/accept-friend-request`,
        {
          currentUserId,
          friendUsername: fromUsername,
        }
      );
    });
  };

  const acknowledgeProgramShare = async (index) => {
    await handleApiCall(async () => {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/acknowledge-program-share`,
        {
          currentUserId,
          index,
        }
      );
    });
  };

  const clearNotification = async (index) => {
    await handleApiCall(async () => {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/clear-notification`,
        {
          currentUserId,
          index,
        }
      );
    });
  };

  const clearAllNotifications = async () => {
    await handleApiCall(async () => {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/clear-all-notifications`,
        {
          currentUserId,
        }
      );
    });
  };

  if (!isOpen) return null;
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Title>Notifications</Title>
        {error && <div className="error-message">{error}</div>}
        {notifications.length === 0 ? (
          <NoNotification>No notifications</NoNotification>
        ) : (
          <>
            <NotificationList>
              {notifications.map((notification, index) => (
                <NotificationItem key={index}>
                  <NotificationMessage>
                    {notification.message}
                  </NotificationMessage>
                  <ButtonGroup>
                    {notification.type === "friendRequest" && (
                      <AcceptButton
                        onClick={() =>
                          acceptFriendRequest(notification.fromUsername, index)
                        }
                      >
                        Accept
                      </AcceptButton>
                    )}
                    {notification.type === "programShare" && (
                      <AcknowledgeButton
                        onClick={() => acknowledgeProgramShare(index)}
                      >
                        Acknowledge
                      </AcknowledgeButton>
                    )}
                    <ClearButton onClick={() => clearNotification(index)}>
                      Clear
                    </ClearButton>
                  </ButtonGroup>
                </NotificationItem>
              ))}
            </NotificationList>
            <ClearAllButton onClick={clearAllNotifications}>
              Clear All
            </ClearAllButton>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default NotificationModal;
