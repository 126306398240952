export const getFirstDayOfMonth = (year, month) => {
  const firstDay = new Date(year, month, 1);
  return firstDay.getDay(); // 0 for Sunday, 1 for Monday, etc.
};

export const mapDatesToGrid = (year, month) => {
  const firstDay = getFirstDayOfMonth(year, month);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const dateGrid = Array.from({ length: 7 }, () => Array(7).fill(""));

  let currentDate = 1;

  for (let week = 0; week < 7; week++) {
    // Start from week 1
    for (let day = 0; day < 7; day++) {
      if (week === 0 && day < firstDay) {
        // Skip days before the first day of the month
        continue;
      }
      if (currentDate <= daysInMonth) {
        dateGrid[week][day] = currentDate++;
      }
    }
  }

  return dateGrid;
};
