// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { usePayment } from "../context/PaymentContext"; // Adjust the import path as needed
// import { useAuth } from "../../hooks/useAuth"; // Import useAuth
// import Modal from "../Modal";
// import LogoutButton from "../LogoutButton";
// import DarkModeToggle from "../DarkModeToggle";

// import {
//   FaUser,
//   FaCog,
//   FaCreditCard,
//   FaBell,
//   FaLock,
//   FaBars,
//   FaTimes,
//   FaSignOutAlt,
//   FaRegCreditCard,
//   FaCrown,
//   FaDumbbell,
// } from "react-icons/fa";

// const ModalContent = styled.div`
//   display: flex;
//   flex-direction: row; // Change to row for all screen sizes
//   height: 100%;
//   max-height: 100vh;
//   width: 100%; // Ensure it takes full width
// `;

// const TabContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #2c2c2c;
//   transition: width 0.3s ease;
//   overflow: hidden;
//   width: ${(props) => (props.isExpanded ? "200px" : "60px")};
// `;

// const Tab = styled.button.attrs((props) => ({
//   // This ensures that the 'active' prop is not passed to the DOM
//   active: undefined,
// }))`
//   background-color: ${(props) => (props.$active ? "#4ecdc4" : "transparent")};
//   color: ${(props) => (props.$active ? "#fff" : "#ccc")};
//   border: none;
//   padding: 15px 20px;
//   text-align: left;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   display: flex;
//   align-items: center;

//   &:hover {
//     background-color: ${(props) => (props.$active ? "#45b7aa" : "#444")};
//   }

//   svg {
//     margin-right: ${(props) => (props.isExpanded ? "10px" : "0")};
//   }

//   span {
//     display: ${(props) => (props.isExpanded ? "inline" : "none")};
//   }
// `;

// const ContentContainer = styled.div`
//   flex-grow: 1;
//   // padding: 20px;
//   background-color: #333;
//   overflow-y: auto;
// `;

// const ToggleButton = styled.button`
//   background: none;
//   border: none;
//   color: #fff;
//   font-size: 24px;
//   cursor: pointer;
//   padding: 10px;
//   align-self: flex-start;
// `;

// const CloseButton = styled(ToggleButton)`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   z-index: 1000;
// `;

// const LogoutContainer = styled.div`
//   margin-top: auto;
//   padding: 20px;
// `;

// const SubscriptionForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// `;

// const PaymentSection = styled.div`
//   max-width: 400px;
//   margin: 0 auto;
// `;

// const CreditCardContainer = styled.div`
//   background: linear-gradient(45deg, #1a1a1a, #2c2c2c);
//   border-radius: 10px;
//   padding: 20px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   margin-bottom: 20px;
// `;

// const CardHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;
// `;

// const UserEmail = styled.div`
//   font-size: 14px;
//   color: #4ecdc4;
//   word-break: break-all;
//   max-width: 80%;
// `;

// const StyledCardElement = styled(CardElement)`
//   background-color: #333;
//   padding: 12px;
//   border-radius: 5px;
//   border: 1px solid #4ecdc4;
//   color: white;
// `;

// const ErrorMessage = styled.div`
//   color: #ff6b6b;
//   margin-top: 10px;
//   text-align: center;
// `;

// const SuccessMessage = styled.div`
//   color: #4ecdc4;
//   margin-top: 10px;
//   text-align: center;
// `;

// const StyledLogoutButton = styled(LogoutButton)`
//   margin-top: auto;
//   background-color: transparent;
//   color: #ccc;
//   border: none;
//   padding: 15px;
//   text-align: left;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   display: flex;
//   align-items: center;

//   &:hover {
//     background-color: #444;
//   }

//   svg {
//     margin-right: ${(props) => (props.isExpanded ? "10px" : "0")};
//   }

//   span {
//     display: ${(props) => (props.isExpanded ? "inline" : "none")};
//   }
// `;

// const CardList = styled.ul`
//   list-style: none;
//   padding: 0;
// `;

// const CardItem = styled.li`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px;
//   background-color: #444;
//   margin-bottom: 10px;
//   border-radius: 5px;
// `;

// const RemoveCardButton = styled.button`
//   background-color: #ff6b6b;
//   color: white;
//   border: none;
//   padding: 5px 10px;
//   border-radius: 3px;
//   cursor: pointer;

//   &:hover {
//     background-color: #ff5252;
//   }
// `;

// const SubscriptionContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   background: linear-gradient(135deg, #2c3e50, #4ca1af);
//   border-radius: 10px;
//   color: white;
// `;

// const SubscriptionTitle = styled.h2`
//   font-size: 18px;
//   margin-bottom: 20px;
// `;

// const SubscriptionOptions = styled.div`
//   display: flex;
//   justify-content: space-around;
//   width: 100%;
//   margin-bottom: 20px;
// `;

// const SubscriptionOption = styled.div`
//   background-color: rgba(255, 255, 255, 0.1);
//   border-radius: 10px;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const OptionTitle = styled.h3`
//   font-size: 16px;
//   margin-bottom: 10px;
// `;

// const OptionPrice = styled.p`
//   font-size: 14px;
//   font-weight: bold;
//   margin-bottom: 10px;
// `;

// const OptionFeatures = styled.ul`
//   list-style-type: none;
//   padding: 0;
//   font-size: 12px;
//   margin-bottom: 20px;
// `;

// const OptionFeature = styled.li`
//   margin-bottom: 2px;
// `;

// const SubscribeButton = styled.button`
//   background-color: #4ecdc4;
//   color: white;
//   border: none;
//   // padding: 12px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
//   font-size: 16px;

//   &:hover {
//     background-color: #45b7aa;
//   }

//   &:disabled {
//     background-color: #2c2c2c;
//     cursor: not-allowed;
//   }
//   @media (max-width: 768px) {
//     font-size: 12px;
//   }
// `;

// const SettingsModal = ({ isOpen, onClose }) => {
//   const [activeTab, setActiveTab] = useState("account");
//   const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);

//   const [isLoading, setIsLoading] = useState(false);
//   const stripe = useStripe();
//   const elements = useElements();

//   const [paymentError, setPaymentError] = useState(null);
//   const [paymentSuccess, setPaymentSuccess] = useState(false);
//   const { user } = useAuth(); // Use the useAuth hook to get the user
//   const {
//     makePayment,
//     addPaymentMethod,
//     removePaymentMethod,
//     fetchPaymentMethods,
//     paymentMethods,
//     cancelSubscription,
//     createSubscription,
//     loading: paymentLoading,
//   } = usePayment();

//   const tabs = [
//     { id: "account", icon: <FaUser />, label: "Account" },
//     { id: "preferences", icon: <FaCog />, label: "Preferences" },
//     { id: "payment", icon: <FaCreditCard />, label: "Payment" },
//     { id: "subscription", icon: <FaCrown />, label: "Subscription" },
//     { id: "notifications", icon: <FaBell />, label: "Notifications" },
//     { id: "privacy", icon: <FaLock />, label: "Privacy" },
//   ];

//   useEffect(() => {
//     if (activeTab === "payment") {
//       fetchPaymentMethods(); // Call fetchPaymentMethods only when 'payment' tab is active
//     }
//   }, [activeTab, fetchPaymentMethods]); // Only re-fetch when 'activeTab' changes

//   const handleAddCard = async (event) => {
//     event.preventDefault();
//     setIsLoading(true);
//     setPaymentError(null);
//     setPaymentSuccess(false);

//     if (!stripe || !elements) {
//       setPaymentError("Stripe.js hasn't loaded yet.");
//       setIsLoading(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     try {
//       // Create payment method using Stripe.js
//       const { error, paymentMethod } = await stripe.createPaymentMethod({
//         type: "card",
//         card: cardElement,
//       });

//       if (error) {
//         setPaymentError(error.message);
//         setIsLoading(false);
//         return;
//       }

//       // Add payment method via context
//       const result = await addPaymentMethod(paymentMethod.id);

//       if (result.success) {
//         setPaymentSuccess(true);
//         await fetchPaymentMethods();
//       } else {
//         setPaymentError(result.error || "Failed to add payment method");
//       }
//     } catch (error) {
//       setPaymentError("Error adding card: " + error.message);
//     }

//     setIsLoading(false);
//   };

//   const handleRemoveCard = async (paymentMethodId) => {
//     setIsLoading(true);
//     setPaymentError(null);
//     try {
//       const result = await removePaymentMethod(paymentMethodId);
//       if (result.success) {
//         setPaymentSuccess(true);
//       } else {
//         setPaymentError(result.error || "Failed to remove payment method");
//       }
//     } catch (error) {
//       setPaymentError("Error removing card: " + error.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleSubscribe = async (type) => {
//     setIsLoading(true);
//     setPaymentError(null);
//     setPaymentSuccess(false);

//     try {
//       // Fetch payment methods to ensure we have the latest data
//       await fetchPaymentMethods();

//       if (paymentMethods.length === 0) {
//         throw new Error(
//           "No payment method available. Please add a card first."
//         );
//       }

//       const priceId =
//         type === "member"
//           ? "price_1PxH6200rs5s6phQ3DBXX13s"
//           : "price_1PxH7300rs5s6phQWlgzl8lA";

//       const result = await createSubscription(
//         priceId,
//         type === "member" ? "Subscriber" : "Trainer"
//       );

//       if (result.requiresAction) {
//         // Handle payment confirmation if required
//         const { error } = await stripe.confirmCardPayment(result.clientSecret);
//         if (error) {
//           throw new Error(error.message);
//         } else {
//           setPaymentSuccess(true);
//           // Update subscription status in user context if necessary
//         }
//       } else if (result.error) {
//         throw new Error(result.error);
//       } else {
//         setPaymentSuccess(true);
//         // Update subscription status in user context if necessary
//       }
//     } catch (error) {
//       setPaymentError("Error creating subscription: " + error.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleCancelSubscription = async () => {
//     setIsLoading(true);
//     setPaymentError(null);
//     setPaymentSuccess(false);

//     try {
//       const result = await cancelSubscription();

//       if (result.success) {
//         setPaymentSuccess(true);
//         // Update user's subscription status if necessary
//       } else {
//         setPaymentError(result.error || "Failed to cancel subscription");
//       }
//     } catch (error) {
//       setPaymentError("Error cancelling subscription: " + error.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const toggleDrawer = () => {
//     setIsDrawerExpanded(!isDrawerExpanded);
//   };

//   const renderContent = () => {
//     switch (activeTab) {
//       case "account":
//         return <div>Account settings content</div>;
//       case "preferences":
//         return (
//           <div>
//             <DarkModeToggle />
//           </div>
//         );
//       case "payment":
//         return (
//           <PaymentSection>
//             <h3>Payment Methods</h3>

//             {paymentMethods.length > 0 ? (
//               // If a payment method exists, display the card and the remove button
//               <CardList>
//                 {paymentMethods.map((method) => (
//                   <CardItem key={method.id}>
//                     {method.card.brand} **** **** **** {method.card.last4}
//                     <RemoveCardButton
//                       onClick={() => handleRemoveCard(method.id)}
//                       disabled={isLoading}
//                     >
//                       Remove
//                     </RemoveCardButton>
//                   </CardItem>
//                 ))}
//               </CardList>
//             ) : (
//               // If no payment method exists, display the message
//               <p>Please add a payment method</p>
//             )}

//             {/* Conditionally render the Add New Card form if no payment method exists */}
//             {paymentMethods.length === 0 && (
//               <>
//                 <h4>Add New Card</h4>
//                 <form onSubmit={handleAddCard}>
//                   <CreditCardContainer>
//                     <StyledCardElement
//                       options={{
//                         style: {
//                           base: {
//                             fontSize: "16px",
//                             color: "#ffffff",
//                             "::placeholder": {
//                               color: "#aab7c4",
//                             },
//                           },
//                           invalid: {
//                             color: "#ff6b6b",
//                           },
//                         },
//                       }}
//                     />
//                   </CreditCardContainer>
//                   <SubscribeButton
//                     type="submit"
//                     disabled={isLoading || !stripe}
//                   >
//                     {isLoading ? "Processing..." : "Add Card"}
//                   </SubscribeButton>
//                 </form>
//               </>
//             )}

//             {paymentError && <ErrorMessage>{paymentError}</ErrorMessage>}
//             {paymentSuccess && (
//               <SuccessMessage>Operation completed successfully!</SuccessMessage>
//             )}
//           </PaymentSection>
//         );
//       case "subscription":
//         return (
//           <SubscriptionContainer>
//             <SubscriptionTitle>
//               Unlock Your Potential with Roshi Health
//             </SubscriptionTitle>
//             <SubscriptionOptions>
//               <SubscriptionOption>
//                 <OptionTitle>Roshi Member</OptionTitle>
//                 <OptionPrice>$4.99/month</OptionPrice>
//                 <OptionFeatures>
//                   <OptionFeature>Access to all workouts</OptionFeature>
//                   <OptionFeature>Personalized meal plans</OptionFeature>
//                   <OptionFeature>Progress tracking</OptionFeature>
//                 </OptionFeatures>
//                 <SubscribeButton
//                   onClick={() => handleSubscribe("member")}
//                   disabled={isLoading || user.subscriptionStatus === "active"}
//                 >
//                   {isLoading ? "Processing..." : "Subscribe"}
//                 </SubscribeButton>
//               </SubscriptionOption>
//               <SubscriptionOption>
//                 <OptionTitle>Roshi Trainer</OptionTitle>
//                 <OptionPrice>$24.99/month</OptionPrice>
//                 <OptionFeatures>
//                   <OptionFeature>All Member features</OptionFeature>
//                   <OptionFeature>Create and sell workouts</OptionFeature>
//                   <OptionFeature>Access to trainer tools</OptionFeature>
//                   <OptionFeature>Priority support</OptionFeature>
//                 </OptionFeatures>
//                 <SubscribeButton
//                   onClick={() => handleSubscribe("trainer")}
//                   disabled={isLoading || user.subscriptionStatus === "active"}
//                   aria-disabled={
//                     isLoading || user.subscriptionStatus === "active"
//                   }
//                 >
//                   {isLoading ? "Processing..." : "Become a Trainer"}
//                 </SubscribeButton>
//               </SubscriptionOption>
//             </SubscriptionOptions>
//             {user.subscriptionStatus === "active" && (
//               <div>
//                 <p>
//                   You are currently subscribed. Enjoy your premium features!
//                 </p>
//                 <SubscribeButton onClick={handleCancelSubscription}>
//                   Cancel Subscription
//                 </SubscribeButton>
//               </div>
//             )}
//             {paymentError && <ErrorMessage>{paymentError}</ErrorMessage>}
//             {paymentSuccess && (
//               <SuccessMessage>
//                 Successfully subscribed! Welcome to Roshi Health premium.
//               </SuccessMessage>
//             )}
//           </SubscriptionContainer>
//         );
//       case "notifications":
//         return <div>Notifications settings content</div>;
//       case "privacy":
//         return <div>Privacy settings content</div>;
//       default:
//         return null;
//     }
//   };

//   return (
//     <Modal isOpen={isOpen} onClose={onClose}>
//       <CloseButton onClick={onClose}>
//         <FaTimes />
//       </CloseButton>
//       <ModalContent>
//         <TabContainer isExpanded={isDrawerExpanded}>
//           <ToggleButton onClick={toggleDrawer}>
//             <FaBars />
//           </ToggleButton>
//           {tabs.map((tab) => (
//             <Tab
//               key={tab.id}
//               $active={activeTab === tab.id}
//               onClick={() => setActiveTab(tab.id)}
//               isExpanded={isDrawerExpanded}
//             >
//               {tab.icon}
//               <span>{tab.label}</span>
//             </Tab>
//           ))}
//           {/* <LogoutButton /> */}
//           <StyledLogoutButton isExpanded={isDrawerExpanded}>
//             <FaSignOutAlt />
//             <span>Logout</span>
//           </StyledLogoutButton>
//         </TabContainer>
//         <ContentContainer>{renderContent()}</ContentContainer>
//       </ModalContent>
//     </Modal>
//   );
// };

// export default SettingsModal;

// #########removing payment:

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { usePayment } from "../context/PaymentContext"; // Adjust the import path as needed
import { useAuth } from "../../hooks/useAuth"; // Import useAuth
import Modal from "../Modal";
import LogoutButton from "../LogoutButton";
import DarkModeToggle from "../DarkModeToggle";

import {
  FaUser,
  FaCog,
  FaCreditCard,
  FaBell,
  FaLock,
  FaBars,
  FaTimes,
  FaSignOutAlt,
  FaRegCreditCard,
  FaCrown,
  FaDumbbell,
} from "react-icons/fa";

const ModalContent = styled.div`
  display: flex;
  flex-direction: row; // Change to row for all screen sizes
  height: 100%;
  max-height: 100vh;
  width: 100%; // Ensure it takes full width
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  transition: width 0.3s ease;
  overflow: hidden;
  width: ${(props) => (props.isExpanded ? "200px" : "60px")};
`;

const Tab = styled.button.attrs((props) => ({
  // This ensures that the 'active' prop is not passed to the DOM
  active: undefined,
}))`
  background-color: ${(props) => (props.$active ? "#4ecdc4" : "transparent")};
  color: ${(props) => (props.$active ? "#fff" : "#ccc")};
  border: none;
  padding: 15px 20px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.$active ? "#45b7aa" : "#444")};
  }

  svg {
    margin-right: ${(props) => (props.isExpanded ? "10px" : "0")};
  }

  span {
    display: ${(props) => (props.isExpanded ? "inline" : "none")};
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  // padding: 20px;
  background-color: #333;
  overflow-y: auto;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  align-self: flex-start;
`;

const CloseButton = styled(ToggleButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;

const LogoutContainer = styled.div`
  margin-top: auto;
  padding: 20px;
`;

const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PaymentSection = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const CreditCardContainer = styled.div`
  background: linear-gradient(45deg, #1a1a1a, #2c2c2c);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const UserEmail = styled.div`
  font-size: 14px;
  color: #4ecdc4;
  word-break: break-all;
  max-width: 80%;
`;

const StyledCardElement = styled(CardElement)`
  background-color: #333;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #4ecdc4;
  color: white;
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  margin-top: 10px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: #4ecdc4;
  margin-top: 10px;
  text-align: center;
`;

const StyledLogoutButton = styled(LogoutButton)`
  margin-top: auto;
  background-color: transparent;
  color: #ccc;
  border: none;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #444;
  }

  svg {
    margin-right: ${(props) => (props.isExpanded ? "10px" : "0")};
  }

  span {
    display: ${(props) => (props.isExpanded ? "inline" : "none")};
  }
`;

const CardList = styled.ul`
  list-style: none;
  padding: 0;
`;

const CardItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #444;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const RemoveCardButton = styled.button`
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #ff5252;
  }
`;

const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #2c3e50, #4ca1af);
  border-radius: 10px;
  color: white;
`;

const SubscriptionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 20px;
`;

const SubscriptionOptions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
`;

const SubscriptionOption = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const OptionTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
`;

const OptionPrice = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const OptionFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 12px;
  margin-bottom: 20px;
`;

const OptionFeature = styled.li`
  margin-bottom: 2px;
`;

const SubscribeButton = styled.button`
  background-color: #4ecdc4;
  color: white;
  border: none;
  // padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;

  &:hover {
    background-color: #45b7aa;
  }

  &:disabled {
    background-color: #2c2c2c;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const SettingsModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("account");
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);

  const { user } = useAuth(); // Use the useAuth hook to get the user

  const tabs = [
    { id: "account", icon: <FaUser />, label: "Account" },
    { id: "preferences", icon: <FaCog />, label: "Preferences" },
    { id: "notifications", icon: <FaBell />, label: "Notifications" },
    { id: "privacy", icon: <FaLock />, label: "Privacy" },
  ];

  const toggleDrawer = () => {
    setIsDrawerExpanded(!isDrawerExpanded);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "account":
        return <div>Account settings content</div>;
      case "preferences":
        return (
          <div>
            <DarkModeToggle />
          </div>
        );

      case "notifications":
        return <div>Notifications settings content</div>;
      case "privacy":
        return <div>Privacy settings content</div>;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <FaTimes />
      </CloseButton>
      <ModalContent>
        <TabContainer isExpanded={isDrawerExpanded}>
          <ToggleButton onClick={toggleDrawer}>
            <FaBars />
          </ToggleButton>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              $active={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
              isExpanded={isDrawerExpanded}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </Tab>
          ))}
          {/* <LogoutButton /> */}
          <StyledLogoutButton isExpanded={isDrawerExpanded}>
            <FaSignOutAlt />
            <span>Logout</span>
          </StyledLogoutButton>
        </TabContainer>
        <ContentContainer>{renderContent()}</ContentContainer>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
