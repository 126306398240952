// import React, { useState, useRef, useEffect } from "react";
// import styled, { keyframes, css } from "styled-components";
// import { gsap } from "gsap";
// import { FaHome, FaDumbbell, FaCalendarAlt, FaTrophy, FaChartLine, FaBullseye, FaCog, FaCubes } from "react-icons/fa";

// const HeaderContainer = styled.div`
//   background-color: rgba(44, 44, 44, 0.7); // Slightly transparent background
//   backdrop-filter: blur(1px); // Add blur effect
//   -webkit-backdrop-filter: blur(10px); // For Safari support
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// //   padding: 16px;
//   color: #fff;
//   z-index: 1;
//   position: relative; // Ensure z-index works correctly
// `;

// const TopSection = styled.div`
// //   background-color: rgba(44, 44, 44, 0.7); // Slightly transparent background
// //   backdrop-filter: blur(1px); // Add blur effect
// //   -webkit-backdrop-filter: blur(10px); // For Safari support
// //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//   display: flex;
//   justify-content: space-between;
// //   align-items: right;
// //   margin-bottom: 16px;
// padding: 10px 0px 0px 10px;
// `;

// const ProgramTitle = styled.h1`
//   margin: 0;
//   font-size: 24px;
//   font-weight: 500;
// `;

// const ShareButton = styled.button`
//   background-color: #3a3f47;
//   color: #fff;
//   border: none;
//   padding: 8px 16px;
//   border-radius: 4px;
//   cursor: pointer;
//   font-size: 14px;
//   transition: background-color 0.2s;

//   &:hover {
//     background-color: #4a5561;
//   }
// `;

// const TabContainer = styled.div`
//   display: flex;
//   margin-bottom: 16px;
// `;

// const MainTab = styled.button`
//   background-color: transparent;
//   color: ${(props) => (props.active ? "#fff" : "#8e9297")};
//   border: none;
//   font-size: 15px;
//   font-weight: ${(props) => (props.active ? "600" : "400")};
//   cursor: pointer;
//   transition: color 0.2s;

//   &:hover {
//     color: #fff;
//     background: none;
//   }
// `;

// const SubTabContainer = styled.div`
//   position: relative;
//   height: 65px;
//   overflow-x: auto;
//   overflow-y: hidden;
// //   padding-bottom: 10px;

//   /* Hide scrollbar for WebKit browsers */
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   /* Hide scrollbar for Firefox */
//   scrollbar-width: none;

//   /* Hide scrollbar for IE and Edge */
//   -ms-overflow-style: none;
// `;

// const SubTabWrapper = styled.div`
//   display: flex;
//   height: 100%;
//   /* Remove position: absolute; */
//   /* Remove transition on transform */
// `;

// const SubTab = styled.div`
//   width: ${(props) => (props.active ? "3em" : "2em")};
//   height: ${(props) => (props.active ? "3em" : "2em")};
//   margin-right: 16px;
//   position: relative;
//   cursor: pointer;
//   transition: all 0.3s ease;
// `;

// const SubTabThumbnail = styled.div`
//   width: 100%;
//   height: 100%;
//   background-color: #2a2a2a;
//   border-radius: 8px;
//   overflow: hidden;
//   transition: all 0.3s ease;
//   position: relative;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const SubTabImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   border-radius: 14px;
//   transition: all 0.3s ease;

//   ${(props) =>
//         props.active &&
//         css`
//       transform: scale(1.05);
//     `}
// `;

// const ActiveTabText = styled.div`
//   position: absolute;
//   left: 104px; // Position to the right of the active tab
//   top: 36px; // Position below the other tabs
//   font-size: 12px;
//   font-weight: 600;
//   color: #fff;
//   opacity: 0;
//   transition: opacity 0.1s ease;
// `;

// const SubTabPlaceholder = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: ${props => props.bgcolor};
//   color: white;
//   font-size: 20px;
//   border-radius: 14px;
//   padding: 8px;
//   text-align: center;
// `;

// const MesocycleHeader = ({ programName, activeTab, onTabChange, onShare, programType, programBlocks }) => {
//     const [activeMainTab, setActiveMainTab] = useState("program");
//     const wrapperRef = useRef(null);
//     const subTabRefs = useRef([]);
//     const activeTabTextRef = useRef(null);

//     const tabIcons = {
//         "Overview": { icon: FaHome, color: "#4a90e2" },
//         "Exercises": { icon: FaDumbbell, color: "#50e3c2" },
//         "Calendar": { icon: FaCalendarAlt, color: "#f5a623" },
//         "Records": { icon: FaTrophy, color: "#b8e986" },
//         "Macrocycle": { icon: FaChartLine, color: "#bd10e0" },
//         "Program": { icon: FaCog, color: "#9013fe" },
//         "Training Blocks": { icon: FaCubes, color: "#ff6b6b" },
//         "Goals": { icon: FaBullseye, color: "#7ed321" },
//     };

//     const getAvailableTabs = () => {
//         const programTabs = {
//             "Mesocycle Basic": [
//                 { name: "Overview", icon: "/path/to/overview-icon.png" },
//                 { name: "Program", icon: "/path/to/training-block-icon.png" },
//                 { name: "Exercises", icon: "/path/to/exercises-icon.png" },
//                 // { name: "Records", icon: "/path/to/records-icon.png" },
//             ],
//             "Mesocycle Advanced": [
//                 { name: "Overview", icon: "/path/to/overview-icon.png" },
//                 { name: "Program", icon: "/path/to/training-block-icon.png" },
//                 { name: "Exercises", icon: "/path/to/exercises-icon.png" },
//                 { name: "Calendar", icon: "/path/to/calendar-icon.png" },
//                 // { name: "Records", icon: "/path/to/records-icon.png" },
//                 { name: "Goals", icon: "/path/to/goals-icon.png" },
//             ],
//             "Macrocycle": [
//                 { name: "Overview", icon: "/path/to/overview-icon.png" },
//                 // { name: "Macrocycle", icon: "/path/to/macrocycle-icon.png" },
//                 { name: "Exercises", icon: "/path/to/exercises-icon.png" },
//                 { name: "Calendar", icon: "/path/to/calendar-icon.png" },
//                 // { name: "Records", icon: "/path/to/records-icon.png" },
//             ],
//         };

//         const currentCycleTabs = {
//             "Macrocycle": [
//                 // { name: "Training Blocks", icon: "/path/to/training-block-icon.png" },
//                 { name: "Program", icon: "/path/to/training-block-icon.png" },
//                 // { name: "Goals", icon: "/path/to/goals-icon.png" },
//             ],
//         };

//         return {
//             program: programTabs[programType] || programTabs["Mesocycle Basic"],
//             "current-cycle": currentCycleTabs[programType] || [],
//         };
//     };

//     const mainTabs = getAvailableTabs();

//     const handleMainTabClick = (tab) => {
//         setActiveMainTab(tab);
//         onTabChange(mainTabs[tab][0].name.toLowerCase().replace(" ", "-"));
//     };

//     const handleSubTabClick = (tabName) => {
//         onTabChange(tabName.toLowerCase().replace(" ", "-"));
//     };

//     useEffect(() => {
//         const activeTabIndex = mainTabs[activeMainTab].findIndex(
//             (tab) => tab.name.toLowerCase().replace(" ", "-") === activeTab
//         );

//         if (
//             activeTabIndex !== -1 &&
//             wrapperRef.current &&
//             activeTabTextRef.current
//         ) {
//             const wrapperElement = wrapperRef.current;
//             const activeTabTextElement = activeTabTextRef.current;
//             const tabWidth = 48; // Width of inactive tab
//             const tabMargin = 0; // Margin between tabs
//             const activeTabExtraWidth = 10; // Additional width for active tab

//             // Calculate the position to place the active tab in the second position
//             let targetPosition =
//                 -(activeTabIndex - 1) * (tabWidth + tabMargin) - activeTabExtraWidth;

//             gsap.to(wrapperElement, {
//                 x: targetPosition,
//                 duration: 0.5,
//                 ease: "power2.out",
//             });

//             // Animate the active tab text
//             gsap.to(activeTabTextElement, {
//                 opacity: 1,
//                 duration: 0.3,
//                 delay: 0.2,
//             });
//         }

//         // Hide the active tab text when changing tabs
//         return () => {
//             if (activeTabTextRef.current) {
//                 gsap.to(activeTabTextRef.current, {
//                     opacity: 0,
//                     duration: 0.2,
//                 });
//             }
//         };
//     }, [activeTab, activeMainTab]);

//     return (
//         <HeaderContainer>
//             <TopSection>
//                 <TabContainer>
//                     <MainTab
//                         active={activeMainTab === "program"}
//                         onClick={() => handleMainTabClick("program")}
//                     >
//                         Program
//                     </MainTab>

//                     {programType === "Macrocycle" && (
//                         <MainTab
//                             active={activeMainTab === "current-cycle"}
//                             onClick={() => handleMainTabClick("current-cycle")}
//                         >
//                             Training Block
//                         </MainTab>
//                     )}
//                 </TabContainer>
//                 {/* <ShareButton onClick={onShare}>Share</ShareButton> */}
//             </TopSection>

//             <SubTabContainer>
//                 <SubTabWrapper ref={wrapperRef}>
//                     {mainTabs[activeMainTab]?.map((tab, index) => (
//                         <SubTab
//                             key={tab.name}
//                             ref={(el) => (subTabRefs.current[index] = el)}
//                             onClick={() => handleSubTabClick(tab.name)}
//                             active={tab.name.toLowerCase().replace(" ", "-") === activeTab}
//                         >
//                             <SubTabThumbnail
//                                 active={tab.name.toLowerCase().replace(" ", "-") === activeTab}
//                             >
//                                 <SubTabPlaceholder bgcolor={tabIcons[tab.name]?.color || "#2a2a2a"}>
//                                     {tabIcons[tab.name] ? React.createElement(tabIcons[tab.name].icon) : null}
//                                 </SubTabPlaceholder>
//                             </SubTabThumbnail>
//                         </SubTab>
//                     ))}
//                 </SubTabWrapper>
//                 <ActiveTabText ref={activeTabTextRef}>
//                     {mainTabs[activeMainTab]?.find(
//                         (tab) => tab.name.toLowerCase().replace(" ", "-") === activeTab
//                     )?.name}
//                 </ActiveTabText>
//             </SubTabContainer>
//         </HeaderContainer>
//     );
// };

// export default MesocycleHeader;

// ###########################################################

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import styled, { keyframes, css } from "styled-components";
import { gsap } from "gsap";
import {
  FaHome,
  FaDumbbell,
  FaCalendarAlt,
  FaTrophy,
  FaChartLine,
  FaBullseye,
  FaCog,
  FaCubes,
} from "react-icons/fa";

const HeaderContainer = styled.div`
  background-color: rgba(44, 44, 44, 0.7); // Slightly transparent background
  backdrop-filter: blur(1px); // Add blur effect
  -webkit-backdrop-filter: blur(10px); // For Safari support
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  //   padding: 16px;
  color: #fff;
  z-index: 1;
  position: relative; // Ensure z-index works correctly
`;

const TopSection = styled.div`
  //   background-color: rgba(44, 44, 44, 0.7); // Slightly transparent background
  //   backdrop-filter: blur(1px); // Add blur effect
  //   -webkit-backdrop-filter: blur(10px); // For Safari support
  //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: space-between;
  //   align-items: right;
  //   margin-bottom: 16px;
  padding: 10px 0px 0px 10px;
`;

const ProgramTitle = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
`;

const ShareButton = styled.button`
  background-color: #3a3f47;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #4a5561;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const MainTab = styled.button`
  background-color: transparent;
  color: ${(props) => (props.active ? "#fff" : "#8e9297")};
  border: none;
  font-size: 15px;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #fff;
    background: none;
  }
`;

const SubTabContainer = styled.div`
  position: relative;
  height: 65px;
  overflow-x: auto;
  overflow-y: hidden;
  //   padding-bottom: 10px;

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
`;

const SubTabWrapper = styled.div`
  display: flex;
  height: 100%;
  /* Remove position: absolute; */
  /* Remove transition on transform */
`;

const SubTab = styled.div`
  width: ${(props) => (props.active ? "3em" : "2em")};
  height: ${(props) => (props.active ? "3em" : "2em")};
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const SubTabThumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubTabImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  transition: all 0.3s ease;

  ${(props) =>
    props.active &&
    css`
      transform: scale(1.05);
    `}
`;

const ActiveTabText = styled.div`
  position: absolute;
  left: 104px; // Position to the right of the active tab
  top: 36px; // Position below the other tabs
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  transition: opacity 0.1s ease;
`;

const SubTabPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgcolor};
  color: white;
  font-size: 20px;
  border-radius: 14px;
  padding: 8px;
  text-align: center;
`;

const MesocycleHeader = ({
  programName,
  activeTab,
  onTabChange,
  onShare,
  programType,
  programBlocks,
}) => {
  const [activeMainTab, setActiveMainTab] = useState("program");
  const wrapperRef = useRef(null);
  const subTabRefs = useRef([]);
  const activeTabTextRef = useRef(null);

  const tabIcons = useMemo(
    () => ({
      Overview: { icon: FaHome, color: "#4a90e2" },
      Exercises: { icon: FaDumbbell, color: "#50e3c2" },
      // "Calendar": { icon: FaCalendarAlt, color: "#f5a623" },
      Records: { icon: FaTrophy, color: "#b8e986" },
      Macrocycle: { icon: FaChartLine, color: "#bd10e0" },
      Program: { icon: FaCog, color: "#9013fe" },
      "Training Blocks": { icon: FaCubes, color: "#ff6b6b" },
      Goals: { icon: FaBullseye, color: "#7ed321" },
    }),
    []
  );

  const getAvailableTabs = useCallback(() => {
    const programTabs = {
      "Mesocycle Basic": [
        // { name: "Overview", icon: "/path/to/overview-icon.png" },
        { name: "Program", icon: "/path/to/training-block-icon.png" },
        { name: "Exercises", icon: "/path/to/exercises-icon.png" },
        // { name: "Records", icon: "/path/to/records-icon.png" },
      ],
      "Mesocycle Advanced": [
        { name: "Overview", icon: "/path/to/overview-icon.png" },
        { name: "Program", icon: "/path/to/training-block-icon.png" },
        { name: "Exercises", icon: "/path/to/exercises-icon.png" },
        // { name: "Calendar", icon: "/path/to/calendar-icon.png" },
        // { name: "Records", icon: "/path/to/records-icon.png" },
        // { name: "Goals", icon: "/path/to/goals-icon.png" },
      ],
      Macrocycle: [
        { name: "Overview", icon: "/path/to/overview-icon.png" },
        // { name: "Macrocycle", icon: "/path/to/macrocycle-icon.png" },
        { name: "Exercises", icon: "/path/to/exercises-icon.png" },
        // { name: "Calendar", icon: "/path/to/calendar-icon.png" },
        // { name: "Records", icon: "/path/to/records-icon.png" },
      ],
    };

    const currentCycleTabs = {
      Macrocycle: [
        // { name: "Training Blocks", icon: "/path/to/training-block-icon.png" },
        { name: "Program", icon: "/path/to/training-block-icon.png" },
        // { name: "Goals", icon: "/path/to/goals-icon.png" },
      ],
    };

    return {
      program: programTabs[programType] || programTabs["Mesocycle Basic"],
      "current-cycle": currentCycleTabs[programType] || [],
    };
  }, [programType]);

  const mainTabs = getAvailableTabs();

  const handleMainTabClick = (tab) => {
    setActiveMainTab(tab);
    onTabChange(mainTabs[tab][0].name.toLowerCase().replace(" ", "-"));
  };

  const handleSubTabClick = useCallback(
    (tabName) => {
      onTabChange(tabName.toLowerCase().replace(" ", "-"));
    },
    [onTabChange]
  );

  useEffect(() => {
    const activeTabIndex = mainTabs[activeMainTab].findIndex(
      (tab) => tab.name.toLowerCase().replace(" ", "-") === activeTab
    );

    if (
      activeTabIndex !== -1 &&
      wrapperRef.current &&
      activeTabTextRef.current
    ) {
      const wrapperElement = wrapperRef.current;
      const activeTabTextElement = activeTabTextRef.current;
      const tabWidth = 48; // Width of inactive tab
      const tabMargin = 0; // Margin between tabs
      const activeTabExtraWidth = 10; // Additional width for active tab

      // Calculate the position to place the active tab in the second position
      let targetPosition =
        -(activeTabIndex - 1) * (tabWidth + tabMargin) - activeTabExtraWidth;

      gsap.to(wrapperElement, {
        x: targetPosition,
        duration: 0.5,
        ease: "power2.out",
      });

      // Animate the active tab text
      gsap.to(activeTabTextElement, {
        opacity: 1,
        duration: 0.3,
        delay: 0.2,
      });
    }

    // Hide the active tab text when changing tabs
    return () => {
      if (activeTabTextRef.current) {
        gsap.to(activeTabTextRef.current, {
          opacity: 0,
          duration: 0.2,
        });
      }
    };
  }, [activeTab, activeMainTab]);

  return (
    <HeaderContainer>
      <TopSection>
        <TabContainer>
          <MainTab
            active={activeMainTab === "program"}
            onClick={() => handleMainTabClick("program")}
          >
            Program
          </MainTab>

          {programType === "Macrocycle" && (
            <MainTab
              active={activeMainTab === "current-cycle"}
              onClick={() => handleMainTabClick("current-cycle")}
            >
              Training Block
            </MainTab>
          )}
        </TabContainer>
        {/* <ShareButton onClick={onShare}>Share</ShareButton> */}
      </TopSection>

      <SubTabContainer>
        <SubTabWrapper ref={wrapperRef}>
          {mainTabs[activeMainTab]?.map((tab, index) => (
            <SubTab
              key={tab.name}
              ref={(el) => (subTabRefs.current[index] = el)}
              onClick={() => handleSubTabClick(tab.name)}
              active={tab.name.toLowerCase().replace(" ", "-") === activeTab}
            >
              <SubTabThumbnail
                active={tab.name.toLowerCase().replace(" ", "-") === activeTab}
              >
                <SubTabPlaceholder
                  bgcolor={tabIcons[tab.name]?.color || "#2a2a2a"}
                >
                  {tabIcons[tab.name]
                    ? React.createElement(tabIcons[tab.name].icon)
                    : null}
                </SubTabPlaceholder>
              </SubTabThumbnail>
            </SubTab>
          ))}
        </SubTabWrapper>
        <ActiveTabText ref={activeTabTextRef}>
          {
            mainTabs[activeMainTab]?.find(
              (tab) => tab.name.toLowerCase().replace(" ", "-") === activeTab
            )?.name
          }
        </ActiveTabText>
      </SubTabContainer>
    </HeaderContainer>
  );
};

export default MesocycleHeader;
