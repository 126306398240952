import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Programs from "../pages/Programs";
import NotFound from "../pages/NotFound";
// import Welcome from "../pages/Welcome";
import PublicProfile from "../pages/PublicProfile";
import CurrentProgram from "../pages/CurrentProgram";
import AppLayout from "./AppLayout";

const AppRoutes = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  const AuthenticatedRoute = ({ children }) => {
    if (!user) return <Navigate to="/" />;
    return <AppLayout>{children}</AppLayout>;
  };

  return (
    <Routes>
      <Route path="/" element={user ? <Navigate to="/programs" /> : <Home />} />

      <Route path="/profile/:username" element={<PublicProfile />} />
      <Route path="*" element={<NotFound />} />

      <Route
        path="/profile"
        element={
          <AuthenticatedRoute>
            <Profile />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/programs"
        element={
          <AuthenticatedRoute>
            <Programs />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/program/:programId"
        element={
          <AuthenticatedRoute>
            <CurrentProgram />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
