// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import { useDrag, useDrop, DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { FaArrowRight, FaCopy, FaTimes } from "react-icons/fa";

// import WorkoutForm from "../workouts/WorkoutForm";
// import AddWorkoutButton from "../workouts/AddWorkoutButton";
// import WorkoutItem from "../workouts/WorkoutItem";
// import ProgramWorkoutDrawer from "../program/ProgramWorkoutDrawer";
// import { useWorkout } from "../../components/context/WorkoutContext";
// import { collection, doc, getDocs, setDoc } from "firebase/firestore";
// import { db } from "../../services/firebaseConfig.js";
// import { useAuth } from "../../hooks/useAuth";
// import { useLoading } from "../../components/context/LoadingContext"; // Import the useLoading hook


// const WeekContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   padding: 16px 12px;
//   justify-content: flex-start;
//   gap: 20px;
//   overflow-x: auto;
//   overflow-y: hidden;
//   white-space: nowrap;
//   width: 100%;
//   height: calc(100vh - 100px); // Adjust this value based on your layout

//   /* Hide scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     height: 8px;
//     background: transparent;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: rgba(255, 255, 255, 0.2);
//     border-radius: 4px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: rgba(255, 255, 255, 0.3);
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: transparent;
//   }

//   /* Firefox */
//   scrollbar-width: thin;
//   scrollbar-color: rgba(255, 255, 255, 0.2) transparent;

//   /* For IE and Edge */
//   -ms-overflow-style: -ms-autohiding-scrollbar;

//   /* Ensure the container takes full width */
//   box-sizing: border-box;

//   /* Add some padding to the bottom to ensure scrollbar visibility */
//   padding-bottom: 16px;
// `;

// const WeekColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #2c2c2c;
//   border-radius: 8px;
//   padding: 10px;
//   min-width: 200px;
//   max-height: 65vh; // Adjust this value as needed
//   overflow-y: auto;

//   /* Webkit browsers (Chrome, Safari, newer versions of Edge) */
//   &::-webkit-scrollbar {
//     width: 10px;
//   }

//   &::-webkit-scrollbar-track {
//     background: #1e1e1e;
//     border-radius: 5px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #4a4a4a;
//     border-radius: 5px;
//     border: 2px solid #1e1e1e;
//   }

//   &::-webkit-scrollbar-thumb:hover {
//     background: #5a5a5a;
//   }

//   /* Firefox */
//   scrollbar-width: thin;
//   scrollbar-color: #4a4a4a #1e1e1e;

//   /* For IE and Edge (pre-Chromium) */
//   -ms-overflow-style: none;

//   /* Hide default scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   /* Custom scrollbar using pseudo-element for better cross-browser support */
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 10px;
//     height: 100%;
//     background-color: #1e1e1e;
//     border-radius: 0 8px 8px 0;
//     opacity: 0;
//     transition: opacity 0.3s ease;
//   }

//   &:hover::after {
//     opacity: 1;
//   }

//   &:hover {
//     &::-webkit-scrollbar {
//       display: block;
//     }
//   }
// `;

// const WeekTitle = styled.h3`
//   color: #fff;
// `;

// const AddWeekSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   // background-color: #2c2c2c;
//   border-radius: 8px;
//   min-width: 200px;
//   overflow: hidden;
// `;

// const AddWeekHeader = styled.button`
//   background-color: #3a3a3a;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   text-align: left;
//   font-size: 14px;
//   width: 100%;

//   &:hover {
//     background-color: #525252;
//   }
// `;

// const AddWeekSkeleton = styled.div`
//  height: 100%;
//   margin-top: 35px;
//   background: linear-gradient(
//     180deg,
//     #2c2c2c,
//     #3a3a3a, 0) 100%
//   );
// `;

// const DropOptionsOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.75);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// `;

// const DropOptionsCard = styled.div`
//   background-color: #2a2a2a;
//   border-radius: 12px;
//   padding: 24px;
//   width: 320px;
//   box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
// `;

// const DropOptionsTitle = styled.h3`
//   color: #ffffff;
//   font-size: 20px;
//   font-weight: 600;
//   margin-bottom: 16px;
// `;

// const DropOptionsText = styled.p`
//   color: #b0b0b0;
//   font-size: 14px;
//   margin-bottom: 24px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const ActionButton = styled.button`
//   background-color: ${(props) => (props.primary ? "#3a86ff" : "#3a3a3a")};
//   color: #ffffff;
//   border: none;
//   border-radius: 8px;
//   padding: 10px 16px;
//   font-size: 14px;
//   font-weight: 500;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     background-color: ${(props) => (props.primary ? "#2a75f0" : "#4a4a4a")};
//     transform: translateY(-2px);
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// const WorkoutDropOptions = ({ onMove, onDuplicate, onCancel }) => {
//   return (
//     <DropOptionsOverlay>
//       <DropOptionsCard>
//         <DropOptionsTitle>Workout Action</DropOptionsTitle>
//         <DropOptionsText>
//           Choose an action for the dropped workout:
//         </DropOptionsText>
//         <ButtonContainer>
//           <ActionButton primary onClick={onMove}>
//             <FaArrowRight /> Move
//           </ActionButton>
//           <ActionButton onClick={onDuplicate}>
//             <FaCopy /> Duplicate
//           </ActionButton>
//           <ActionButton onClick={onCancel}>
//             <FaTimes /> Cancel
//           </ActionButton>
//         </ButtonContainer>
//       </DropOptionsCard>
//     </DropOptionsOverlay>
//   );
// };

// const WeekColumnDropTarget = ({ week, onDropWorkout, children }) => {
//   const [, dropRef] = useDrop({
//     accept: "WORKOUT",
//     drop: (item) => onDropWorkout(item.id, week.weekNumber),
//   });

//   return (
//     <WeekColumn ref={dropRef}>
//       <WeekTitle>Week {week.weekNumber}</WeekTitle>
//       {children}
//     </WeekColumn>
//   );
// };

// const MesocycleProgram = ({ program }) => {
//   const { user } = useAuth();
//   const { setIsLoading } = useLoading(); // Use the loading context

//   const {
//     handleAddWorkout,
//     workouts,
//     setWorkouts,
//     fetchWorkoutFromFirestore,
//     duplicateWorkout,
//   } = useWorkout();
//   const [selectedWorkout, setSelectedWorkout] = useState(null);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [weeks, setWeeks] = useState([]);
//   // const [activeTab, setActiveTab] = useState("current-cycle");
//   const [dropInfo, setDropInfo] = useState(null);

//   useEffect(() => {
//     const fetchWorkouts = async () => {
//       if (program && user && program.id) {
//         // setIsLoading(true); // Start loading
//         await new Promise(resolve => setTimeout(resolve, 1500));

//         try {
//           const workoutsRef = collection(
//             db,
//             "users",
//             user.uid,
//             "programs",
//             program.id,
//             "workouts"
//           );
//           const workoutsSnapshot = await getDocs(workoutsRef);
//           const workoutsList = workoutsSnapshot.docs.map((doc) => ({
//             id: doc.id,
//             ...doc.data(),
//           }));

//           const groupedByWeek = workoutsList.reduce((acc, workout) => {
//             const weekNumber = workout.week || 1;
//             if (!acc[weekNumber]) acc[weekNumber] = [];
//             acc[weekNumber].push(workout);
//             return acc;
//           }, {});

//           setWeeks(
//             Object.entries(groupedByWeek).map(([week, workouts]) => ({
//               weekNumber: parseInt(week, 10),
//               workouts,
//             }))
//           );
//         } catch (error) {
//           console.error("Error fetching workouts:", error);
//         } finally {
//           setIsLoading(false); // Stop loading
//         }
//       }
//     };

//     fetchWorkouts();
//   }, [program, user, setIsLoading]);

//   const handleAddWeek = () => {
//     const newWeekNumber = weeks.length + 1;
//     setWeeks([...weeks, { weekNumber: newWeekNumber, workouts: [] }]);
//   };

//   const handleAddWorkoutToWeek = async (weekNumber) => {
//     if (!program) return;

//     const newWorkoutOrder = weeks[weekNumber - 1].workouts.length + 1;

//     const newWorkout = {
//       id: `workout-${Date.now()}`,
//       status: "unpublished",
//       order: newWorkoutOrder,
//       week: weekNumber,
//       categories: {
//         corrective: [],
//         activation: [],
//         exercises: [],
//         esd: [],
//       },
//     };

//     setWeeks((prevWeeks) =>
//       prevWeeks.map((week) =>
//         week.weekNumber === weekNumber
//           ? { ...week, workouts: [...week.workouts, newWorkout] }
//           : week
//       )
//     );

//     const docRef = doc(
//       db,
//       "users",
//       user.uid,
//       "programs",
//       program.id,
//       "workouts",
//       newWorkout.id
//     );
//     await setDoc(docRef, newWorkout);
//   };

//   const handleSelectWorkout = async (workout) => {
//     console.log(`Selected Workout ID: ${workout.id}`);
//     try {
//       const fetchedWorkout = await fetchWorkoutFromFirestore(
//         workout.id,
//         program.id
//       );
//       if (fetchedWorkout) {
//         setSelectedWorkout(fetchedWorkout);
//         setDrawerOpen(true);
//       } else {
//         console.error("Workout not found");
//       }
//     } catch (error) {
//       console.error("Error fetching workout:", error);
//     }
//   };

//   const handleWorkoutDeleted = (workoutId) => {
//     setWeeks((prevWeeks) =>
//       prevWeeks.map((week) => ({
//         ...week,
//         workouts: week.workouts.filter((workout) => workout.id !== workoutId),
//       }))
//     );

//     if (selectedWorkout && selectedWorkout.id === workoutId) {
//       setSelectedWorkout(null);
//       setDrawerOpen(false);
//     }
//   };

//   const handleDropWorkout = (workoutId, targetWeekNumber) => {
//     const sourceWeek = weeks.find((week) =>
//       week.workouts.some((w) => w.id === workoutId)
//     );
//     const targetWeek = weeks.find(
//       (week) => week.weekNumber === targetWeekNumber
//     );

//     if (sourceWeek && targetWeek) {
//       const workoutToMove = sourceWeek.workouts.find((w) => w.id === workoutId);
//       setDropInfo({ workoutId, sourceWeek, targetWeek, workoutToMove });
//     }
//   };

//   const handleMoveWorkout = () => {
//     if (dropInfo) {
//       const { workoutId, sourceWeek, targetWeek, workoutToMove } = dropInfo;

//       const updatedSourceWeekWorkouts = sourceWeek.workouts.filter(
//         (w) => w.id !== workoutId
//       );

//       const updatedTargetWeekWorkouts = [
//         ...targetWeek.workouts,
//         { ...workoutToMove, week: targetWeek.weekNumber },
//       ];

//       setWeeks((prevWeeks) =>
//         prevWeeks.map((week) =>
//           week.weekNumber === sourceWeek.weekNumber
//             ? { ...week, workouts: updatedSourceWeekWorkouts }
//             : week.weekNumber === targetWeek.weekNumber
//             ? { ...week, workouts: updatedTargetWeekWorkouts }
//             : week
//         )
//       );

//       const docRef = doc(
//         db,
//         "users",
//         user.uid,
//         "programs",
//         program.id,
//         "workouts",
//         workoutId
//       );
//       setDoc(docRef, { ...workoutToMove, week: targetWeek.weekNumber });

//       setDropInfo(null);
//     }
//   };

//   const handleDuplicateWorkout = async () => {
//     if (dropInfo) {
//       const { workoutToMove, targetWeek } = dropInfo;
//       try {
//         const duplicatedWorkout = await duplicateWorkout(
//           workoutToMove,
//           program.id
//         );
//         const updatedWorkout = {
//           ...duplicatedWorkout,
//           week: targetWeek.weekNumber,
//         };

//         setWeeks((prevWeeks) =>
//           prevWeeks.map((week) =>
//             week.weekNumber === targetWeek.weekNumber
//               ? { ...week, workouts: [...week.workouts, updatedWorkout] }
//               : week
//           )
//         );

//         const docRef = doc(
//           db,
//           "users",
//           user.uid,
//           "programs",
//           program.id,
//           "workouts",
//           updatedWorkout.id
//         );
//         setDoc(docRef, updatedWorkout);

//         setDropInfo(null);
//       } catch (error) {
//         console.error("Error duplicating workout:", error);
//       }
//     }
//   };

//   const handleCancelDrop = () => {
//     setDropInfo(null);
//   };

//   const handleShareClick = () => {
//     console.log("Share clicked");
//   };

//   if (!program || !user) {
//     return null; // Return null instead of a loading message
//   }

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div>
//         <WeekContainer>
//           {weeks.map((week) => (
//             <WeekColumnDropTarget
//               key={week.weekNumber}
//               week={week}
//               onDropWorkout={handleDropWorkout}
//             >
//               {week.workouts.map((workout) => (
//                 <WorkoutItem
//                   key={workout.id}
//                   workout={workout}
//                   onSelectWorkout={handleSelectWorkout}
//                 />
//               ))}
//               <AddWorkoutButton
//                 onAddWorkout={() => handleAddWorkoutToWeek(week.weekNumber)}
//               />
//             </WeekColumnDropTarget>
//           ))}
//           <AddWeekSection>
//             <AddWeekHeader onClick={handleAddWeek}>+ Add Week</AddWeekHeader>
//             <AddWeekSkeleton />
//           </AddWeekSection>
//         </WeekContainer>

//         <ProgramWorkoutDrawer
//           isOpen={drawerOpen}
//           onClose={() => setDrawerOpen(false)}
//         >
//           {selectedWorkout && (
//             <WorkoutForm
//               workout={selectedWorkout}
//               programId={program.id}
//               onDelete={handleWorkoutDeleted}
//             />
//           )}
//         </ProgramWorkoutDrawer>

//         {dropInfo && (
//           <WorkoutDropOptions
//             onMove={handleMoveWorkout}
//             onDuplicate={handleDuplicateWorkout}
//             onCancel={handleCancelDrop}
//           />
//         )}
//       </div>
//     </DndProvider>
//   );
// };

// export default MesocycleProgram;


// ###################Trying to add realtime data sync####################


// import React, { useState, useEffect } from "react";
// import styled from "styled-components";
// import { useDrag, useDrop, DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { FaArrowRight, FaCopy, FaTimes } from "react-icons/fa";

// import WorkoutForm from "../workouts/WorkoutForm";
// import AddWorkoutButton from "../workouts/AddWorkoutButton";
// import WorkoutItem from "../workouts/WorkoutItem";
// import ProgramWorkoutDrawer from "../program/ProgramWorkoutDrawer";
// import { useWorkout } from "../../components/context/WorkoutContext";
// import { useAuth } from "../../hooks/useAuth";
// import { useLoading } from "../../components/context/LoadingContext"; // Import the useLoading hook

// // Styled components definitions remain the same

// const WeekContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   padding: 16px 12px;
//   justify-content: flex-start;
//   gap: 20px;
//   overflow-x: auto;
//   overflow-y: hidden;
//   white-space: nowrap;
//   width: 100%;
//   height: calc(100vh - 100px); // Adjust this value based on your layout

//   /* Hide scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     height: 8px;
//     background: transparent;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: rgba(255, 255, 255, 0.2);
//     border-radius: 4px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: rgba(255, 255, 255, 0.3);
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: transparent;
//   }

//   /* Firefox */
//   scrollbar-width: thin;
//   scrollbar-color: rgba(255, 255, 255, 0.2) transparent;

//   /* For IE and Edge */
//   -ms-overflow-style: -ms-autohiding-scrollbar;

//   /* Ensure the container takes full width */
//   box-sizing: border-box;

//   /* Add some padding to the bottom to ensure scrollbar visibility */
//   padding-bottom: 16px;
// `;

// const WeekColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #2c2c2c;
//   border-radius: 8px;
//   padding: 10px;
//   min-width: 200px;
//   max-height: 65vh; // Adjust this value as needed
//   overflow-y: auto;

//   /* Webkit browsers (Chrome, Safari, newer versions of Edge) */
//   &::-webkit-scrollbar {
//     width: 10px;
//   }

//   &::-webkit-scrollbar-track {
//     background: #1e1e1e;
//     border-radius: 5px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #4a4a4a;
//     border-radius: 5px;
//     border: 2px solid #1e1e1e;
//   }

//   &::-webkit-scrollbar-thumb:hover {
//     background: #5a5a5a;
//   }

//   /* Firefox */
//   scrollbar-width: thin;
//   scrollbar-color: #4a4a4a #1e1e1e;

//   /* For IE and Edge (pre-Chromium) */
//   -ms-overflow-style: none;

//   /* Hide default scrollbar for Chrome, Safari and Opera */
//   &::-webkit-scrollbar {
//     display: none;
//   }

//   /* Custom scrollbar using pseudo-element for better cross-browser support */
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 10px;
//     height: 100%;
//     background-color: #1e1e1e;
//     border-radius: 0 8px 8px 0;
//     opacity: 0;
//     transition: opacity 0.3s ease;
//   }

//   &:hover::after {
//     opacity: 1;
//   }

//   &:hover {
//     &::-webkit-scrollbar {
//       display: block;
//     }
//   }
// `;

// const WeekTitle = styled.h3`
//   color: #fff;
// `;

// const AddWeekSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   // background-color: #2c2c2c;
//   border-radius: 8px;
//   min-width: 200px;
//   overflow: hidden;
// `;

// const AddWeekHeader = styled.button`
//   background-color: #3a3a3a;
//   color: #fff;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   text-align: left;
//   font-size: 14px;
//   width: 100%;

//   &:hover {
//     background-color: #525252;
//   }
// `;

// const AddWeekSkeleton = styled.div`
//  height: 100%;
//   margin-top: 35px;
//   background: linear-gradient(
//     180deg,
//     #2c2c2c,
//     #3a3a3a, 0) 100%
//   );
// `;

// const DropOptionsOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.75);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// `;

// const DropOptionsCard = styled.div`
//   background-color: #2a2a2a;
//   border-radius: 12px;
//   padding: 24px;
//   width: 320px;
//   box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
// `;

// const DropOptionsTitle = styled.h3`
//   color: #ffffff;
//   font-size: 20px;
//   font-weight: 600;
//   margin-bottom: 16px;
// `;

// const DropOptionsText = styled.p`
//   color: #b0b0b0;
//   font-size: 14px;
//   margin-bottom: 24px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const ActionButton = styled.button`
//   background-color: ${(props) => (props.primary ? "#3a86ff" : "#3a3a3a")};
//   color: #ffffff;
//   border: none;
//   border-radius: 8px;
//   padding: 10px 16px;
//   font-size: 14px;
//   font-weight: 500;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     background-color: ${(props) => (props.primary ? "#2a75f0" : "#4a4a4a")};
//     transform: translateY(-2px);
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   }

//   svg {
//     margin-right: 8px;
//   }
// `;

// const WorkoutDropOptions = ({ onMove, onDuplicate, onCancel }) => {
//   return (
//     <DropOptionsOverlay>
//       <DropOptionsCard>
//         <DropOptionsTitle>Workout Action</DropOptionsTitle>
//         <DropOptionsText>
//           Choose an action for the dropped workout:
//         </DropOptionsText>
//         <ButtonContainer>
//           <ActionButton primary onClick={onMove}>
//             <FaArrowRight /> Move
//           </ActionButton>
//           <ActionButton onClick={onDuplicate}>
//             <FaCopy /> Duplicate
//           </ActionButton>
//           <ActionButton onClick={onCancel}>
//             <FaTimes /> Cancel
//           </ActionButton>
//         </ButtonContainer>
//       </DropOptionsCard>
//     </DropOptionsOverlay>
//   );
// };

// const WeekColumnDropTarget = ({ week, onDropWorkout, children }) => {
//   const [, dropRef] = useDrop({
//     accept: "WORKOUT",
//     drop: (item) => onDropWorkout(item.id, week.weekNumber),
//   });

//   return (
//     <WeekColumn ref={dropRef}>
//       <WeekTitle>Week {week.weekNumber}</WeekTitle>
//       {children}
//     </WeekColumn>
//   );
// };

// const MesocycleProgram = ({ program }) => {
//   const { user } = useAuth();
//   const { setIsLoading } = useLoading(); // Use the loading context

//   const {
//     workouts,
//     currentWorkout,
//     subscribeToWorkouts,
//     fetchWorkoutFromFirestore,
//     updateWorkout,
//     deleteWorkout,
//     duplicateWorkout,
//     saveWorkoutToFirestore,
//   } = useWorkout();
//   const [selectedWorkout, setSelectedWorkout] = useState(null);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [weeks, setWeeks] = useState([]);
//   const [dropInfo, setDropInfo] = useState(null);
  

//   // Subscribe to workouts
//   useEffect(() => {
//     if (program && user && program.id) {
//       const unsubscribe = subscribeToWorkouts(program.id);
//       return () => {
//         if (unsubscribe) unsubscribe();
//       };
//     }
//   }, [program, user, subscribeToWorkouts]);

//   // Process workouts into weeks
//   useEffect(() => {
//     const groupedByWeek = workouts.reduce((acc, workout) => {
//       const weekNumber = workout.week || 1;
//       if (!acc[weekNumber]) acc[weekNumber] = [];
//       acc[weekNumber].push(workout);
//       return acc;
//     }, {});

//     setWeeks(
//       Object.entries(groupedByWeek).map(([week, workouts]) => ({
//         weekNumber: parseInt(week, 10),
//         workouts,
//       }))
//     );
//   }, [workouts]);

//   const handleAddWeek = () => {
//     const newWeekNumber = weeks.length + 1;
//     setWeeks([...weeks, { weekNumber: newWeekNumber, workouts: [] }]);
//   };

//   const handleAddWorkoutToWeek = async (weekNumber) => {
//     if (!program) return;

//     const newWorkoutOrder =
//       (weeks.find((week) => week.weekNumber === weekNumber)?.workouts.length ||
//         0) + 1;

//     const newWorkout = {
//       id: `workout-${Date.now()}`,
//       status: "unpublished",
//       order: newWorkoutOrder,
//       week: weekNumber,
//       categories: {
//         corrective: [],
//         activation: [],
//         exercises: [],
//         esd: [],
//       },
//     };

//     await saveWorkoutToFirestore(newWorkout, program.id);
//   };

//   const handleSelectWorkout = async (workout) => {
//     console.log(`Selected Workout ID: ${workout.id}`);
//     try {
//       const fetchedWorkout = await fetchWorkoutFromFirestore(
//         workout.id,
//         program.id
//       );
//       if (fetchedWorkout) {
//         setSelectedWorkout(fetchedWorkout);
//         setDrawerOpen(true);
//       } else {
//         console.error("Workout not found");
//       }
//     } catch (error) {
//       console.error("Error fetching workout:", error);
//     }
//   };

//   const handleWorkoutDeleted = (workoutId) => {
//     // The workouts state will be updated via the context's real-time listener
//     if (selectedWorkout && selectedWorkout.id === workoutId) {
//       setSelectedWorkout(null);
//       setDrawerOpen(false);
//     }
//   };

//   const handleDropWorkout = (workoutId, targetWeekNumber) => {
//     const sourceWeek = weeks.find((week) =>
//       week.workouts.some((w) => w.id === workoutId)
//     );
//     const targetWeek = weeks.find(
//       (week) => week.weekNumber === targetWeekNumber
//     );

//     if (sourceWeek && targetWeek) {
//       const workoutToMove = sourceWeek.workouts.find((w) => w.id === workoutId);
//       setDropInfo({ workoutId, sourceWeek, targetWeek, workoutToMove });
//     }
//   };

//   const handleMoveWorkout = async () => {
//     if (dropInfo) {
//       const { workoutToMove, targetWeek } = dropInfo;

//       const updatedWorkout = {
//         ...workoutToMove,
//         week: targetWeek.weekNumber,
//       };

//       await updateWorkout(updatedWorkout, program.id);

//       setDropInfo(null);
//     }
//   };

//   const handleDuplicateWorkout = async () => {
//     if (dropInfo) {
//       const { workoutToMove, targetWeek } = dropInfo;
//       try {
//         const duplicatedWorkout = await duplicateWorkout(
//           workoutToMove,
//           program.id
//         );
//         const updatedWorkout = {
//           ...duplicatedWorkout,
//           week: targetWeek.weekNumber,
//         };

//         await saveWorkoutToFirestore(updatedWorkout, program.id);

//         setDropInfo(null);
//       } catch (error) {
//         console.error("Error duplicating workout:", error);
//       }
//     }
//   };

//   const handleCancelDrop = () => {
//     setDropInfo(null);
//   };

//   const handleShareClick = () => {
//     console.log("Share clicked");
//   };

//   if (!program || !user) {
//     return null; // Return null instead of a loading message
//   }

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div>
//         <WeekContainer>
//           {weeks.map((week) => (
//             <WeekColumnDropTarget
//               key={week.weekNumber}
//               week={week}
//               onDropWorkout={handleDropWorkout}
//             >
//               {week.workouts.map((workout) => (
//                 <WorkoutItem
//                   key={workout.id}
//                   workout={workout}
//                   onSelectWorkout={handleSelectWorkout}
//                 />
//               ))}
//               <AddWorkoutButton
//                 onAddWorkout={() => handleAddWorkoutToWeek(week.weekNumber)}
//               />
//             </WeekColumnDropTarget>
//           ))}
//           <AddWeekSection>
//             <AddWeekHeader onClick={handleAddWeek}>+ Add Week</AddWeekHeader>
//             <AddWeekSkeleton />
//           </AddWeekSection>
//         </WeekContainer>

//         <ProgramWorkoutDrawer
//           isOpen={drawerOpen}
//           onClose={() => setDrawerOpen(false)}
//         >
//           {selectedWorkout && (
//             <WorkoutForm
//               workout={selectedWorkout}
//               programId={program.id}
//               onDelete={handleWorkoutDeleted}
//             />
//           )}
//         </ProgramWorkoutDrawer>

//         {dropInfo && (
//           <WorkoutDropOptions
//             onMove={handleMoveWorkout}
//             onDuplicate={handleDuplicateWorkout}
//             onCancel={handleCancelDrop}
//           />
//         )}
//       </div>
//     </DndProvider>
//   );
// };

// export default MesocycleProgram;


// MesocycleProgram.js

import React from 'react';
import { WorkoutProvider } from '../../components/context/WorkoutContext';
import MesocycleProgramContent from './MesocycleProgramContent'; // Create this component

const MesocycleProgram = ({ program }) => {
  return (
    <WorkoutProvider programId={program.id}>
      <MesocycleProgramContent program={program} />
    </WorkoutProvider>
  );
};

export default MesocycleProgram;
