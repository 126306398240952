// // components/profile/ProfileHero.js
// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { FaEdit, FaCheck, FaTimes, FaInstagram, FaReddit, FaTwitter, FaYoutube, FaUserFriends } from 'react-icons/fa';
// import FriendRequestButton from './FriendRequestButton';
// import Modal from '../Modal';
// import FriendsList from './FriendsList';

// const HeroContainer = styled.div`
//   position: relative;
//   padding: 60px 24px;
//   background: linear-gradient(to bottom, rgba(26, 26, 46, 0.9), rgba(22, 33, 62, 0.9)),
//               url(${props => props.coverUrl || ''}) center/cover no-repeat;
//   margin-bottom: 24px;

//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(22, 33, 62, 1) 100%);
//     pointer-events: none;
//   }
// `;

// const HeroContent = styled.div`
//   max-width: 1200px;
//   margin: 0 auto;
//   position: relative;
//   z-index: 1;
//   display: flex;
//   gap: 40px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//   }
// `;

// const AvatarSection = styled.div`
//   flex-shrink: 0;
// `;

// const AvatarContainer = styled.div`
//   width: 180px;
//   height: 180px;
//   border-radius: 50%;
//   border: 4px solid rgba(255, 255, 255, 0.1);
//   overflow: hidden;
//   background: linear-gradient(45deg, #2a2a3c, #1e1e2d);

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const InfoSection = styled.div`
//   flex-grow: 1;
//   color: white;
// `;

// const Username = styled.h1`
//   font-size: 2.5rem;
//   margin: 0 0 8px 0;
//   color: white;
// `;

// const Stats = styled.div`
//   display: flex;
//   gap: 24px;
//   margin-bottom: 24px;

//   @media (max-width: 768px) {
//     justify-content: center;
//   }
// `;

// const StatButton = styled.button`
//   background: none;
//   border: none;
//   color: rgba(255, 255, 255, 0.8);
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   padding: 8px 16px;
//   border-radius: 8px;
//   transition: all 0.3s ease;

//   &:hover {
//     background: rgba(255, 255, 255, 0.1);
//   }

//   svg {
//     font-size: 20px;
//   }
// `;

// const AboutSection = styled.div`
//   margin-bottom: 24px;
//   max-width: 600px;
// `;

// const AboutText = styled.p`
//   color: rgba(255, 255, 255, 0.8);
//   line-height: 1.6;
//   margin: 0;
//   font-size: 1.1rem;
// `;

// const EditButton = styled.button`
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   background: rgba(255, 255, 255, 0.1);
//   border: none;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   color: white;

//   &:hover {
//     background: rgba(255, 255, 255, 0.2);
//   }
// `;

// const SocialLinks = styled.div`
//   display: flex;
//   gap: 16px;

//   @media (max-width: 768px) {
//     justify-content: center;
//   }
// `;

// const SocialLink = styled.a`
//   color: rgba(255, 255, 255, 0.8);
//   font-size: 24px;
//   transition: all 0.3s ease;

//   &:hover {
//     transform: translateY(-2px);
//     color: ${props => {
//       switch (props.platform) {
//         case 'instagram': return '#E1306C';
//         case 'reddit': return '#FF4500';
//         case 'twitter': return '#1DA1F2';
//         case 'youtube': return '#FF0000';
//         default: return 'white';
//       }
//     }};
//   }
// `;

// const ActionButtons = styled.div`
//   display: flex;
//   gap: 16px;
//   margin-top: 16px;
// `;

// const StyledFriendRequestButton = styled(FriendRequestButton)`
//   padding: 10px 20px;
//   background-color: #4ecdc4;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #45b7aa;
//   }
// `;

// const AvatarImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

// const AvatarPlaceholder = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 64px;
//   font-weight: 600;
//   color: rgba(255, 255, 255, 0.8);
//   text-transform: uppercase;
//   background: linear-gradient(45deg, #2a2a3c, #1e1e2d);
// `;

// const ProfileHero = ({
//   user,
//   isCurrentUser,
//   onEditClick,
//   socialLinks,
//   friendCount,
//   currentUser,
//   isFriendsModalOpen,  // Add these props
//   setIsFriendsModalOpen  // Add these props
// }) => {
// //   const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);

// useEffect(() => {
//   }, [isFriendsModalOpen]);

//   const renderAvatar = () => {
//     if (user?.avatarUrl) {
//       return <AvatarImage src={user.avatarUrl} alt={`${user.username}'s avatar`} />;
//     }

//     return (
//       <AvatarPlaceholder>
//         {user?.username?.charAt(0) || '?'}
//       </AvatarPlaceholder>
//     );
//   };

//   return (
//     <>
//       <HeroContainer coverUrl={user?.coverUrl}>
//         <HeroContent>
//  <AvatarSection>
//           <AvatarContainer>
//             {renderAvatar()}
//           </AvatarContainer>
//         </AvatarSection>

//           <InfoSection>
//             <Username>{user?.username}</Username>

//             <Stats>
//               <StatButton onClick={() => setIsFriendsModalOpen(true)}>
//                 <FaUserFriends />
//                 <span>{friendCount} Friends</span>
//               </StatButton>
//             </Stats>

//             <AboutSection>
//               <AboutText>
//                 {user?.about || "No about information provided yet."}
//               </AboutText>
//             </AboutSection>

//             <SocialLinks>
//               {socialLinks?.instagram && (
//                 <SocialLink href={socialLinks.instagram} target="_blank" platform="instagram">
//                   <FaInstagram />
//                 </SocialLink>
//               )}
//               {socialLinks?.reddit && (
//                 <SocialLink href={socialLinks.reddit} target="_blank" platform="reddit">
//                   <FaReddit />
//                 </SocialLink>
//               )}
//               {socialLinks?.twitter && (
//                 <SocialLink href={socialLinks.twitter} target="_blank" platform="twitter">
//                   <FaTwitter />
//                 </SocialLink>
//               )}
//               {socialLinks?.youtube && (
//                 <SocialLink href={socialLinks.youtube} target="_blank" platform="youtube">
//                   <FaYoutube />
//                 </SocialLink>
//               )}
//             </SocialLinks>

//             {/* Add Friend Request Button */}
//             {!isCurrentUser && currentUser && (
//               <ActionButtons>
//                 <StyledFriendRequestButton
//                   currentUserId={currentUser.uid}
//                   friendUsername={user.username}
//                 />
//               </ActionButtons>
//             )}
//           </InfoSection>
//         </HeroContent>

//         {isCurrentUser && (
//           <EditButton onClick={onEditClick}>
//             <FaEdit />
//           </EditButton>
//         )}
//       </HeroContainer>

//       <Modal
//         isOpen={isFriendsModalOpen}
//         onClose={() => {
//           setIsFriendsModalOpen(false);
//         }}
//       >
//         <FriendsList
//           userId={user?.uid}
//           username={user?.username}
//           isPublicView={!isCurrentUser}
//           onNavigate={() => {
//             setIsFriendsModalOpen(false);
//           }}
//         />
//       </Modal>
//     </>
//   );
// };

// export default ProfileHero;

// #################################################

// components/profile/ProfileHero.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaEdit,
  FaCheck,
  FaTimes,
  FaInstagram,
  FaReddit,
  FaTwitter,
  FaYoutube,
  FaUserFriends,
} from "react-icons/fa";
import FriendRequestButton from "./FriendRequestButton";
import Modal from "../Modal";
import FriendsList from "./FriendsList";
import SettingsModal from "./SettingsModal";

const HeroContainer = styled.div`
  position: relative;
  padding: 60px 24px;
  background: linear-gradient(
      to bottom,
      rgba(26, 26, 46, 0.9),
      rgba(22, 33, 62, 0.9)
    ),
    url(${(props) => props.coverUrl || ""}) center/cover no-repeat;
  margin-bottom: 24px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(22, 33, 62, 1) 100%
    );
    pointer-events: none;
  }
`;

const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const AvatarSection = styled.div`
  flex-shrink: 0;
`;

const AvatarContainer = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  background: linear-gradient(45deg, #2a2a3c, #1e1e2d);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const InfoSection = styled.div`
  flex-grow: 1;
  color: white;
`;

const Username = styled.h1`
  font-size: 2.5rem;
  margin: 0 0 8px 0;
  color: white;
`;

const Stats = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const StatButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    font-size: 20px;
  }
`;

const AboutSection = styled.div`
  margin-bottom: 24px;
  max-width: 600px;
`;

const AboutText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 0;
  font-size: 1.1rem;
`;

const EditButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialLink = styled.a`
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    color: ${(props) => {
      switch (props.platform) {
        case "instagram":
          return "#E1306C";
        case "reddit":
          return "#FF4500";
        case "twitter":
          return "#1DA1F2";
        case "youtube":
          return "#FF0000";
        default:
          return "white";
      }
    }};
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const StyledFriendRequestButton = styled(FriendRequestButton)`
  padding: 10px 20px;
  background-color: #4ecdc4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45b7aa;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  background: linear-gradient(45deg, #2a2a3c, #1e1e2d);
`;

const EditableAbout = styled.textarea`
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1.1rem;
  line-height: 1.6;
  resize: vertical;
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border-color: #4a9eff;
  }
`;

const SocialLinkInput = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  svg {
    font-size: 24px;
    min-width: 24px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #4a9eff;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const EditActions = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const ActionButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &.save {
    background: rgba(78, 205, 196, 0.2);
    &:hover {
      background: rgba(78, 205, 196, 0.4);
    }
  }

  &.cancel {
    background: rgba(255, 107, 107, 0.2);
    &:hover {
      background: rgba(255, 107, 107, 0.4);
    }
  }
`;

const ProfileHero = ({
  user,
  isCurrentUser,
  socialLinks,
  friendCount,
  currentUser,
  isFriendsModalOpen,
  setIsFriendsModalOpen,
  onUpdateProfile,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedAbout, setEditedAbout] = useState(user?.about || "");
  const [editedSocialLinks, setEditedSocialLinks] = useState({
    instagram: socialLinks?.instagram || "",
    reddit: socialLinks?.reddit || "",
    twitter: socialLinks?.twitter || "",
    youtube: socialLinks?.youtube || "",
  });

  useEffect(() => {
    setEditedAbout(user?.about || "");
    setEditedSocialLinks({
      instagram: socialLinks?.instagram || "",
      reddit: socialLinks?.reddit || "",
      twitter: socialLinks?.twitter || "",
      youtube: socialLinks?.youtube || "",
    });
  }, [user, socialLinks]);

  const handleSave = async () => {
    if (onUpdateProfile) {
      await onUpdateProfile({
        about: editedAbout,
        socialLinks: editedSocialLinks,
      });
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedAbout(user?.about || "");
    setEditedSocialLinks({
      instagram: socialLinks?.instagram || "",
      reddit: socialLinks?.reddit || "",
      twitter: socialLinks?.twitter || "",
      youtube: socialLinks?.youtube || "",
    });
    setIsEditing(false);
  };

  const renderAvatar = () => {
    if (user?.avatarUrl) {
      return (
        <AvatarImage src={user.avatarUrl} alt={`${user.username}'s avatar`} />
      );
    }
    return (
      <AvatarPlaceholder>{user?.username?.charAt(0) || "?"}</AvatarPlaceholder>
    );
  };

  const renderSocialInputs = () => (
    <div>
      <SocialLinkInput>
        <FaInstagram color="#E1306C" />
        <Input
          type="text"
          placeholder="instagram.com/username"
          value={editedSocialLinks.instagram}
          onChange={(e) =>
            setEditedSocialLinks((prev) => ({
              ...prev,
              instagram: e.target.value,
            }))
          }
        />
      </SocialLinkInput>
      <SocialLinkInput>
        <FaReddit color="#FF4500" />
        <Input
          type="text"
          placeholder="reddit.com/user/username"
          value={editedSocialLinks.reddit}
          onChange={(e) =>
            setEditedSocialLinks((prev) => ({
              ...prev,
              reddit: e.target.value,
            }))
          }
        />
      </SocialLinkInput>
      <SocialLinkInput>
        <FaTwitter color="#1DA1F2" />
        <Input
          type="text"
          placeholder="twitter.com/username"
          value={editedSocialLinks.twitter}
          onChange={(e) =>
            setEditedSocialLinks((prev) => ({
              ...prev,
              twitter: e.target.value,
            }))
          }
        />
      </SocialLinkInput>
      <SocialLinkInput>
        <FaYoutube color="#FF0000" />
        <Input
          type="text"
          placeholder="youtube.com/c/channelname"
          value={editedSocialLinks.youtube}
          onChange={(e) =>
            setEditedSocialLinks((prev) => ({
              ...prev,
              youtube: e.target.value,
            }))
          }
        />
      </SocialLinkInput>
    </div>
  );

  return (
    <>
      <HeroContainer coverUrl={user?.coverUrl}>
        <HeroContent>
          <AvatarSection>
            <AvatarContainer>{renderAvatar()}</AvatarContainer>
          </AvatarSection>

          <InfoSection>
            <Username>{user?.username}</Username>

            <Stats>
              <StatButton onClick={() => setIsFriendsModalOpen(true)}>
                <FaUserFriends />
                <span>{friendCount} Friends</span>
              </StatButton>
            </Stats>

            <AboutSection>
              {isEditing ? (
                <>
                  <EditableAbout
                    value={editedAbout}
                    onChange={(e) => setEditedAbout(e.target.value)}
                    placeholder="Write something about yourself..."
                  />
                  {renderSocialInputs()}
                </>
              ) : (
                <>
                  <AboutText>
                    {user?.about || "No about information provided yet."}
                  </AboutText>
                  <SocialLinks>
                    {socialLinks?.instagram && (
                      <SocialLink
                        href={socialLinks.instagram}
                        target="_blank"
                        platform="instagram"
                      >
                        <FaInstagram />
                      </SocialLink>
                    )}
                    {socialLinks?.reddit && (
                      <SocialLink
                        href={socialLinks.reddit}
                        target="_blank"
                        platform="reddit"
                      >
                        <FaReddit />
                      </SocialLink>
                    )}
                    {socialLinks?.twitter && (
                      <SocialLink
                        href={socialLinks.twitter}
                        target="_blank"
                        platform="twitter"
                      >
                        <FaTwitter />
                      </SocialLink>
                    )}
                    {socialLinks?.youtube && (
                      <SocialLink
                        href={socialLinks.youtube}
                        target="_blank"
                        platform="youtube"
                      >
                        <FaYoutube />
                      </SocialLink>
                    )}
                  </SocialLinks>
                </>
              )}
            </AboutSection>

            {!isCurrentUser && currentUser && (
              <ActionButtons>
                <StyledFriendRequestButton
                  currentUserId={currentUser.uid}
                  friendUsername={user.username}
                />
              </ActionButtons>
            )}
          </InfoSection>
        </HeroContent>

        {isCurrentUser && (
          <EditActions>
            {isEditing ? (
              <>
                <ActionButton className="save" onClick={handleSave}>
                  <FaCheck />
                </ActionButton>
                <ActionButton className="cancel" onClick={handleCancel}>
                  <FaTimes />
                </ActionButton>
              </>
            ) : (
              <ActionButton onClick={() => setIsEditing(true)}>
                <FaEdit />
              </ActionButton>
            )}
          </EditActions>
        )}
      </HeroContainer>

      <Modal
        isOpen={isFriendsModalOpen}
        onClose={() => setIsFriendsModalOpen(false)}
      >
        <FriendsList
          userId={user?.uid}
          username={user?.username}
          isPublicView={!isCurrentUser}
          onNavigate={() => setIsFriendsModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ProfileHero;
